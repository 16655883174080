import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaZonas/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getZonasAbove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaZonas/notbelow/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getZonesForSelector(plantId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaZonas/zonesSelector/${plantId}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getNextLevelOfZones(plantaId, zonaId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  if (!zonaId) {
    return fetch(`${baseUrl}/api/plantaZonas/bylevels/fromplant/${plantaId}`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });
  } else {
    return fetch(`${baseUrl}/api/plantaZonas/bylevels/fromzone/${zonaId}`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });
  }
}

export function getPlantInfo(plantaId = 0) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaZonas/plantinfo/${plantaId}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getTreeView(
  plantaId = 0,
  zonaId = 0,
  zoneLevel = 0,
  savedZoneId = 0
) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/plantaZonas/treeview/${plantaId}/${zonaId}/${zoneLevel}/${savedZoneId}`,
    {
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );
}

export function getTreeViewByQuery(plantaId = 0, searchQuery = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaZonas/treeview/${plantaId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(searchQuery),
  });
}

export function add(zona) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaZonas`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(zona),
  });
}

export function update(zona) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaZonas/${zona.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(zona),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaZonas/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}
