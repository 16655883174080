import {
  Avatar,
  Button,
  Card,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Badge,
} from "@material-ui/core";
import {
  AddCircleOutline,
  ExpandLess,
  ExpandMore,
  Group,
  GroupAdd,
  GroupTwoTone,
  Info,
  MoreVert,
  People,
  Flag,
  Stars,
  SupervisedUserCircle,
} from "@material-ui/icons";
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import RelativeBackdrop from "../../RelativeBackdrop";
import i18n from "../../../locales/i18n";
import { ClientesAPI, CountriesAPI, PartnersAPI } from "../../../services/API";
import SearchField from "../../molecule/SearchField/SearchField";
import AuthService from "../../../services/AuthService";
import AppContext from "../../../contexts/AppContext";
import {
  CardList_Style,
  Common_Style,
  DetailTabs_Style,
} from "../../../assets/css/Styles";
import InfiniteScroll from "react-infinite-scroll-component";
import PartnerFormInList from "./PartnerFormInList";
import ClientFormInList from "./ClientFormInList";
import PartnerClientIcon from "../../atoms/partnerClientIcon";
import {
  SnackbarError,
  SnackbarSuccess,
  SnackbarWarning,
} from "../../../helpers/Common";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SubscriptionManagementDialog from "../../molecule/subscriptionManagementDialog/subscriptionManagementDialog";
import ListFilter from "../../molecule/ListFilter/ListFilter";

//para filtro de plantas
const originalFilter = {
  itemsRendered: 0,
  searchQuery: "",
  countryId: 0,
  hideDemo: true,
  arrayException: [],
  itemInFirstPlace: null,
};

export default function PartnersClientsList(props) {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const { keyuser, history, readOnly } = props;
  const isDSC = AuthService.hasRoleDSC();
  const isPartner = AuthService.hasRolePartner();
  const DSCisRegionManager = AuthService.getUserInfo().isRegionManager;
  const currentUserHasRoleEndCustomer = AuthService.hasRoleEndCustomer();
  const subscriptionManagementDialog = useRef(null);
  const [isGestor, setIsGestor] = useState(false);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFirstInput, setIsFirstInput] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const styles = CardList_Style();
  const detailStyles = DetailTabs_Style();
  const commonStyles = Common_Style();
  //Para los 2 formularios
  const [showOnlyClients, setShowOnlyClients] = useState(
    currentUserHasRoleEndCustomer ? true : false
  );
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(
    AuthService.getUserInfo().countryid
  );
  const [clientFormMode, setClientFormMode] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [demoPartnerDialogOpen, setDemoPartnerDialogOpen] = useState(false);
  //Para formulario de Partners
  const [hasMorePartners, setHasMorePartners] = useState(true);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [isNewPartner, setIsNewPartner] = useState(false);
  // Para formulario Clientes
  const [hasMoreClients, setHasMoreClients] = useState(true);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [clientFormReadOnly, setClientFormReadOnly] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [clients, setClients] = useState([]);
  const [partnerClientFilter, setPartnerClientFilter] =
    useState(originalFilter);

  //getPartners
  const getPartners = useCallback((partnerFilter, isNewQuery = true) => {
    setLoading(true);
    if (!partnerFilter) {
      partnerFilter = originalFilter;
    }
    PartnersAPI.getInfPartnersRefined(partnerFilter)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        //Nueva Query
        if (isNewQuery) {
          if (partnerFilter.itemInFirstPlace) {
            //Cuando se agrega un nuevo partner, se pone en primer lugar
            var newPartners = [...data.partners];
            var partnerOnFirstPlace = partnerFilter.itemInFirstPlace;
            //colocar nuevo partner en primer lugar
            newPartners.unshift(partnerOnFirstPlace);
            setPartners(newPartners);
            setSelectedPartnerId(partnerOnFirstPlace.id);
          } else {
            setPartners(data.partners);
            if (data.partners.length > 0) {
              setSelectedPartnerId(data.partners[0].id);
              setIsNewPartner(false);
              setNotFound(false);
            }
            //En el caso de que no haya partners en la lista y se puedan crear nuevos
            else if (
              data.partners.length === 0 &&
              partnerFilter.searchQuery.trim().length === 0
            ) {
              setNotFound(false);
              setIsNewPartner(true);
              setSelectedPartnerId(null);
              //no se han encontrado resultados y no se puede crear nuevos
            } else {
              setNotFound(true);
              setSelectedPartnerId(null);
              setIsNewPartner(false);
            }
          }
          //No es nueva query
        } else {
          setPartners((old) => old.concat(data.partners));
        }
        setHasMorePartners(data.hasMorePartners);
        AuthService.updateUserAccessPermisions(data.userAccessPermissions);
        const totalPartners =
          data.partners.length + partnerFilter.itemsRendered;
        setPartnerClientFilter({
          ...partnerFilter,
          itemsRendered: totalPartners,
        });
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const checkClientAccess = useCallback(
    (clientId) => {
      const isGestor = AuthService.isGestorFromPartnerForClient(clientId);
      setClientFormReadOnly(readOnly || !(keyuser || isDSC || isGestor));
      setIsGestor(isGestor);
    },
    [isDSC, keyuser, readOnly]
  );

  //Get SOLO Clientes (lista de solo clientes)
  const getClients = useCallback(
    (clientFilter, isNewQuery) => {
      setLoading(true);
      if (!clientFilter) {
        clientFilter = originalFilter;
      }
      ClientesAPI.getInfClientsRefined(clientFilter)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          //Nueva Query
          if (isNewQuery) {
            if (clientFilter.itemInFirstPlace) {
              //Cuando se agrega un nuevo partner, se pone en primer lugar
              var newClients = [...data.clientes];
              var clientOnFirstPlace = clientFilter.itemInFirstPlace;
              newClients.unshift(clientOnFirstPlace);
              setClients(newClients);
              setSelectedClientId(clientOnFirstPlace.id);
              setIsNewPartner(false);
            } else {
              setClients(data.clientes);
              if (data.clientes.length > 0) {
                setSelectedClientId(data.clientes[0].id);
                checkClientAccess(data.clientes[0].id);
                setNotFound(false);
              } else if (
                data.clientes.length === 0 &&
                clientFilter.searchQuery.trim().length === 0
              ) {
                setSelectedClientId(null);
                setNotFound(false);
                //no se han encontrado resultados y no se puede crear nuevos
              } else {
                setNotFound(true);
                setSelectedClientId(null);
              }
              setIsNewPartner(false);
            }
            //No es nueva query
          } else {
            setClients((old) => {
              let list = [...old];

              for (let i = 0; i < data.clientes.length; i++) {
                let index = list.indexOf(data.clientes[i].id);

                if (index !== -1) {
                  list[index] = data.clientes[i];
                } else {
                  list = list.concat(data.clientes[i]);
                }
              }

              return list;
            });
          }
          setHasMoreClients(data.hasMoreClients);
          AuthService.updateUserAccessPermisions(data.userAccessPermissions);
          const totalClients =
            data.clientes.length + clientFilter.itemsRendered;
          setPartnerClientFilter({
            ...clientFilter,
            itemsRendered: totalClients,
          });
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    },
    [checkClientAccess]
  );

  //Get countries
  const getCountries = useCallback(() => {
    CountriesAPI.getWithRegion()
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        data.sort((a, b) =>
          i18n.t(`country.${a.name}`).localeCompare(i18n.t(`country.${b.name}`))
        );
        setCountries(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  //getPartners
  const getClientsFromPartner = useCallback(
    (partnerId, clientOnFirstPlace) => {
      setLoadingClient(true);
      setLoading(true);
      PartnersAPI.getClientsFromPartner(partnerId)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          AuthService.updateUserAccessPermisions(data.userAccessPermissions);
          var copyArrayPartner = [...partners];
          const partnerIndex = copyArrayPartner.findIndex(
            (partner) => partner.id === partnerId
          );
          copyArrayPartner[partnerIndex].clientList = data.clients;
          //En caso de que se haya agregado un nuevo cliente
          if (clientOnFirstPlace) {
            //se busca por id
            const clientIndex = copyArrayPartner[
              partnerIndex
            ].clientList.findIndex(
              (client) => client.id === clientOnFirstPlace.id
            );
            //colocar en primer lugar
            if (clientIndex !== -1) {
              const clientFind = copyArrayPartner[
                partnerIndex
              ].clientList.splice(clientIndex, 1)[0];
              copyArrayPartner[partnerIndex].clientList.unshift(clientFind);
              setIsGestor(true);
              setClientFormReadOnly(false);
              setSelectedClientId(clientOnFirstPlace.id);
            }
          }
          setPartners(copyArrayPartner);
        })
        .finally(() => {
          setLoadingClient(false);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          window.location.reload();
        });
    },
    [partners]
  );

  const getAvatarText = (title) => {
    const abbreviatonLetters = title.split(" ");
    const abbreviaton =
      abbreviatonLetters.length > 1
        ? (abbreviatonLetters[0][0] + abbreviatonLetters[1][0]).toUpperCase()
        : title.substr(0, 2).toUpperCase();
    return abbreviaton;
  };

  //aplicar filtros a lista
  const handleApplyFilter = (genericFilter) => {
    setPartners([]);
    setClients([]);

    const searchQuery = partnerClientFilter.searchQuery;
    //variables de filtro
    const countryId = genericFilter.countryId || 0;
    const hideDemo = genericFilter.hideDemo;

    //nuevo filtro
    const newPartnerClientFilter = {
      ...originalFilter,
      searchQuery: searchQuery,
      countryId: countryId,
      hideDemo: hideDemo,
    };

    setPartnerClientFilter(newPartnerClientFilter);

    if (showOnlyClients) {
      getClients(newPartnerClientFilter, true);
    } else {
      getPartners(newPartnerClientFilter, true);
    }
  };

  //Limpiar filtros
  const handleClearFilter = () => {
    setPartners([]);
    setClients([]);

    if (showOnlyClients) {
      getClients(null, true);
    } else {
      getPartners(null, true);
    }
  };

  const handleShowOnlyClients = () => {
    var newShowOnlyClients = !showOnlyClients;
    setShowOnlyClients(newShowOnlyClients);
    setIsNewPartner(false);
    setAnchorEl(null);
    setPartners([]);
    setClients([]);

    //para mantener propiedades del filtro entre partners y clientes
    const countryId = partnerClientFilter.countryId || 0;
    const hideDemo = partnerClientFilter.hideDemo;

    //nuevo filtro
    const newPartnerClientFilter = {
      ...originalFilter,
      countryId: countryId,
      hideDemo: hideDemo,
    };

    setPartnerClientFilter(newPartnerClientFilter);

    if (newShowOnlyClients) {
      setClientFormMode(true);
      getClients(newPartnerClientFilter, true);
    } else {
      setClientFormMode(false);
      getPartners(newPartnerClientFilter, true);
    }
  };

  const handleNewPartner = () => {
    setSelectedPartnerId(null);
    setIsNewPartner(true);
    setClientFormMode(false);
    setNotFound(false);
  };

  const handleNewClientFromClientList = () => {
    document.getElementById("partnerclient-container").scrollTop = 0;
    setSelectedClientId(null);
    setClientFormMode(true);
    setNotFound(false);
    //Reset de partner
    if (keyuser || isDSC) {
      setSelectedPartnerId(null);
    }
  };

  //Funcion recursiva para colapsar y expandir partners
  const expandPartner = (partner) => {
    if (!partner.expanded) {
      getClientsFromPartner(partner.id);
    }
    setSelectedPartnerId(partner.id);
    var copyArrayPartner = [...partners];
    const partnerId = partner.id;
    const partnerIndex = copyArrayPartner.findIndex(
      (partner) => partner.id === partnerId
    );
    copyArrayPartner[partnerIndex].expanded =
      !copyArrayPartner[partnerIndex].expanded;
    setPartners(copyArrayPartner);
    setSelectedClientId(null);
    setIsNewPartner(false);
    setClientFormMode(false);
  };

  const handlePartner = (partnerId) => {
    setSelectedPartnerId(partnerId);
    setSelectedClientId(null);
    setClientFormMode(false);
    setIsNewPartner(false);
    setNotFound(false);
  };

  const handleClient = (partnerId, clientId) => {
    checkClientAccess(clientId);
    setSelectedPartnerId(partnerId);
    setSelectedClientId(clientId);
    setClientFormMode(true);
    setIsNewPartner(false);
    setNotFound(false);
  };

  //Función boton de nuevo cliente en lista de partners
  const handleNewClient = (partnerId) => {
    setSelectedPartnerId(partnerId);
    setSelectedClientId(null);
    setClientFormMode(true);
    setIsNewPartner(false);
    setNotFound(false);
  };

  const partnerDeleted = () => {
    //Resetear busqueda
    document.getElementById("partnerclient-container").scrollTop = 0;
    //recargar partners con demoHide
    var isHideDemo = partnerClientFilter.hideDemo;
    var partnerFilter = originalFilter;
    partnerFilter.hideDemo = isHideDemo;
    getPartners(partnerFilter, true);
  };

  const partnerAdded = (partner) => {
    //Scroll al principio en la lista
    document.getElementById("partnerclient-container").scrollTop = 0;
    setIsNewPartner(false);
    setIsFirstInput(false);
    let partnerFilter = { ...partnerClientFilter };
    let newArrayException = [parseInt(partner.id, 10)];
    partnerFilter.itemInFirstPlace = partner;
    partnerFilter.searchQuery = "";
    partnerFilter.itemsRendered = 0;
    partnerFilter.arrayException = newArrayException;
    getPartners(partnerFilter, true);
  };

  const partnerUpdated = (partnerUpdated) => {
    //Añadir a array de excepciones en caso de que no esté
    var copyArrayPartnerException = partnerClientFilter.arrayException;
    const partnerId = parseInt(partnerUpdated.id, 10);
    if (!copyArrayPartnerException.includes(partnerId)) {
      copyArrayPartnerException.push(partnerId);
    }
    setPartnerClientFilter({
      ...partnerClientFilter,
      arrayException: copyArrayPartnerException,
    });
    const updatedPartners = partners.map((partner) =>
      partner.id.toString() === partnerUpdated.id.toString()
        ? { ...partner, ...partnerUpdated }
        : partner
    );
    setPartners(updatedPartners);
  };

  const addDemoPartner = () => {
    PartnersAPI.createDemoPartner(selectedCountry)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(i18n.t("partnersClients.errorAddingDemoPartner"));
        }
      })
      .then((data) => {
        setDemoPartnerDialogOpen(false);
        setAnchorEl(null);
        if (data.existe) {
          SnackbarWarning(
            setGlobal,
            i18n.t("partnersClients.demoPartnerAlreasyExists")
          );
        } else {
          SnackbarSuccess(
            setGlobal,
            i18n.t("partnersClients.successAddingDemoPartner")
          );
          //cargar lista de partners con los demo y el nuevo demo en primer lugar
          let partnerFilter = originalFilter;
          partnerFilter.arrayException = [data.partner.id];
          partnerFilter.itemInFirstPlace = data.partner;
          partnerFilter.hideDemo = false;
          getPartners(partnerFilter, true);
        }
      })
      .catch((err) => SnackbarError(setGlobal, err.message));
  };

  const clientDeleted = (PartnerId) => {
    document.getElementById("partnerclient-container").scrollTop = 0;
    if (showOnlyClients) {
      let isHideDemo = partnerClientFilter.hideDemo;
      let clientFilter = originalFilter;
      clientFilter.hideDemo = isHideDemo;
      getClients(clientFilter, true);
    } else {
      getClientsFromPartner(PartnerId);
      setClientFormMode(false);
      setSelectedPartnerId(PartnerId);
      setSelectedClientId(null);
    }
  };

  const clientAdded = (partnerId, client) => {
    document.getElementById("partnerclient-container").scrollTop = 0;
    //Resetear busqueda
    if (showOnlyClients) {
      var newArrayException = [parseInt(client.id, 10)];
      let clientFilter = { ...partnerClientFilter };
      clientFilter.searchQuery = "";
      clientFilter.itemsRendered = 0;
      clientFilter.arrayException = newArrayException;
      clientFilter.itemInFirstPlace = client;
      getClients(clientFilter, true);
    } else {
      //Volver a cargar clientes en partners
      getClientsFromPartner(partnerId, client);
    }
  };

  const clientUpdated = (partnerId, clientUpdated) => {
    if (showOnlyClients) {
      //Modificar cliente en lista y añadir al array de excepciones
      var copyArrayClientException = partnerClientFilter.arrayException;
      const clientId = parseInt(clientUpdated.id, 10);
      if (!copyArrayClientException.includes(clientId)) {
        copyArrayClientException.push(clientId);
      }
      setPartnerClientFilter({
        ...partnerClientFilter,
        arrayException: copyArrayClientException,
      });
      const updatedClients = clients.map((client) =>
        client.id.toString() === clientUpdated.id.toString()
          ? { ...client, ...clientUpdated }
          : client
      );
      setClients(updatedClients);
    } else {
      //copia de partners
      var copyArrayPartner = [...partners];
      const partnerIndex = copyArrayPartner.findIndex(
        (partner) => partner.id === partnerId
      );

      //se busca por id
      const clientIndex = copyArrayPartner[partnerIndex].clientList.findIndex(
        (client) => client.id === clientUpdated.id
      );
      //Modificar clienmte
      if (clientIndex !== -1) {
        copyArrayPartner[partnerIndex].clientList[clientIndex] = clientUpdated;
      }
      setPartners(copyArrayPartner);
    }
  };

  const openActionsMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  //Busqueda con delay
  useEffect(() => {
    const searchDelay = setTimeout(() => {
      if (isFirstInput) {
        setIsNewPartner(false);
        setSelectedClientId(null);
        setSelectedPartnerId(null);
        if (showOnlyClients) {
          setClients([]);
          getClients(partnerClientFilter, true);
        } else {
          setPartners([]);
          getPartners(partnerClientFilter, true);
        }
        setIsFirstInput(false);
      }
    }, 600);
    return () => clearTimeout(searchDelay);
  }, [
    getClients,
    getPartners,
    isFirstInput,
    partnerClientFilter,
    showOnlyClients,
  ]);

  //primera funcion al entrar getPartners
  useEffect(() => {
    if (isFirstRender) {
      //Para el infinite scroll se reinicie
      setLoading(true);
      document.getElementById("main-content").scrollTop = 0;
      document.getElementById("partnerclient-container").scrollTop = 0;
      setClients([]);
      setPartners([]);
      setIsFirstInput(false);
      //Cargar partners o clientes
      if (showOnlyClients || currentUserHasRoleEndCustomer) {
        setSelectedClientId(null);
        setSelectedPartnerId(null);
        getClients(null, true);
        setClientFormMode(true);
      } else {
        setSelectedClientId(null);
        setSelectedPartnerId(null);
        getPartners(null, true);
        setClientFormMode(false);
      }
      setIsFirstRender(false);
    }
  }, [
    currentUserHasRoleEndCustomer,
    getClients,
    getCountries,
    getPartners,
    isFirstRender,
    showOnlyClients,
  ]);

  //Titulo de la pagina
  useEffect(() => {
    getCountries();
    if (showOnlyClients) {
      setGlobal((prev) => ({
        ...prev,
        pageTitle: i18n.t("partnersClients.titleClients"),
      }));
    } else {
      setGlobal((prev) => ({
        ...prev,
        pageTitle: i18n.t("partnersClients.titlePartnerClient"),
      }));
    }
  }, [getCountries, setGlobal, showOnlyClients]);

  const UserCountry = countries?.find(
    (c) => c.id.toString() === selectedCountry?.toString()
  );
  const filteredOptions =
    isDSC && DSCisRegionManager
      ? countries.filter((c) => c?.regionId === UserCountry?.regionId)
      : countries.sort((a, b) => (a.regionId > b.regionId ? 1 : -1));

  return (
    <RelativeBackdrop hidden={!loading}>
      <div className={commonStyles.mainContainer}>
        <div className={commonStyles.searchFieldContainer}>
          <div className={commonStyles.searchField}>
            <SearchField
              inputBaseProps={{
                placeholder: i18n.t("common.search"),
                value: partnerClientFilter.searchQuery,
                onChange: (event) => {
                  setIsFirstInput(true);
                  setPartnerClientFilter({
                    ...partnerClientFilter,
                    searchQuery: event.target.value,
                    itemsRendered: 0,
                    arrayException: [],
                  });
                },
              }}
            />
            {!currentUserHasRoleEndCustomer && (
              <>
                <Button
                  data-testid="partnerClients-actionButton"
                  variant="contained"
                  className={commonStyles.primaryButtonRaw}
                  onClick={openActionsMenu}
                >
                  <MoreVert className={styles.addCircleOutline} />
                  {i18n.t("planta.actions.actionButton")}
                </Button>
                {!showOnlyClients ? (
                  <>
                    <Menu
                      className={detailStyles.menuMargin}
                      keepMounted
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={() => {
                        setAnchorEl(null);
                      }}
                    >
                      {(keyuser || isDSC) && !readOnly && (
                        <Tooltip
                          arrow
                          placement="left"
                          title={i18n.t("partnersClients.addPartner")}
                        >
                          <MenuItem
                            data-testid="newPartner-Button"
                            className={detailStyles.menuItemSpace}
                            onClick={() => {
                              handleNewPartner();
                              setAnchorEl(null);
                            }}
                          >
                            <ListItemIcon>
                              <AddCircleOutline
                                className={detailStyles.addCircleOutline}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              {i18n.t("partnersClients.addPartner")}
                            </ListItemText>
                          </MenuItem>
                        </Tooltip>
                      )}
                      {((isDSC && !partners.some((p) => p.isDemo === true)) ||
                        keyuser ||
                        DSCisRegionManager) && (
                        <Tooltip
                          arrow
                          placement="left"
                          title={i18n.t("partner.demo")}
                        >
                          <MenuItem
                            className={detailStyles.menuItemSpace}
                            onClick={() => {
                              if (!keyuser && !DSCisRegionManager) {
                                addDemoPartner();
                              } else {
                                setDemoPartnerDialogOpen(true);
                              }
                            }}
                          >
                            <ListItemIcon>
                              <SupervisedUserCircle
                                className={styles.addCircleOutline}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              {i18n.t("partner.demo")}
                            </ListItemText>
                          </MenuItem>
                        </Tooltip>
                      )}
                      <Tooltip
                        arrow
                        placement="left"
                        title={i18n.t("partnersClients.onlyCLientsButton")}
                      >
                        <MenuItem
                          data-testid="showClientsOnly-Button"
                          className={detailStyles.menuItemSpace}
                          onClick={() => {
                            handleShowOnlyClients();
                          }}
                        >
                          <ListItemIcon>
                            <People className={styles.addCircleOutline} />
                          </ListItemIcon>
                          <ListItemText>
                            {i18n.t("partnersClients.onlyCLientsButton")}
                          </ListItemText>
                        </MenuItem>
                      </Tooltip>
                      {(isDSC || keyuser) && (
                        <Tooltip
                          arrow
                          placement="left"
                          title={i18n.t("partnersClients.manageSubscriptions")}
                        >
                          <MenuItem
                            data-testid="partnerClients-premiumButton"
                            className={detailStyles.menuItemSpace}
                            onClick={() => {
                              subscriptionManagementDialog.current.open();
                              setAnchorEl(null);
                            }}
                          >
                            <ListItemIcon>
                              <Stars className={styles.addCircleOutline} />
                            </ListItemIcon>
                            <ListItemText>
                              {i18n.t("partnersClients.manageSubscriptions")}
                            </ListItemText>
                          </MenuItem>
                        </Tooltip>
                      )}
                      {isPartner && (
                        <Tooltip
                          arrow
                          placement="left"
                          title={i18n.t("partnersClients.manageSubscriptions")}
                        >
                          <MenuItem
                            data-testid="partnerClients-premiumButton"
                            className={detailStyles.menuItemSpace}
                            onClick={() => {
                              subscriptionManagementDialog.current.open();
                              setAnchorEl(null);
                            }}
                          >
                            <ListItemIcon>
                              <Stars className={styles.addCircleOutline} />
                            </ListItemIcon>
                            <ListItemText>
                              {i18n.t("partnersClients.manageSubscriptions")}
                            </ListItemText>
                          </MenuItem>
                        </Tooltip>
                      )}
                    </Menu>
                  </>
                ) : (
                  <>
                    <Menu
                      className={detailStyles.menuMargin}
                      keepMounted
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={() => {
                        setAnchorEl(null);
                      }}
                    >
                      {!readOnly && (
                        <Tooltip
                          arrow
                          placement="left"
                          title={i18n.t("partnersClients.addClient")}
                        >
                          <MenuItem
                            data-testid="newClient-Button"
                            className={detailStyles.menuItemSpace}
                            onClick={() => {
                              setAnchorEl(null);
                              handleNewClientFromClientList();
                            }}
                          >
                            <ListItemIcon>
                              <AddCircleOutline
                                className={styles.addCircleOutline}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              {i18n.t("partnersClients.addClient")}
                            </ListItemText>
                          </MenuItem>
                        </Tooltip>
                      )}
                      {(keyuser || isDSC || isPartner) && (
                        <Tooltip
                          arrow
                          placement="left"
                          title={i18n.t("partnersClients.titlePartnerClient")}
                        >
                          <MenuItem
                            data-testid="showPartnerClients-Button"
                            className={detailStyles.menuItemSpace}
                            onClick={() => {
                              handleShowOnlyClients();
                            }}
                          >
                            <ListItemIcon>
                              <PartnerClientIcon
                                color={"#707070"}
                                style={{ marginRight: "6px" }}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              {i18n.t("partnersClients.titlePartnerClient")}
                            </ListItemText>
                          </MenuItem>
                        </Tooltip>
                      )}
                    </Menu>
                  </>
                )}
              </>
            )}
            <Badge
              badgeContent={""}
              variant="dot"
              //mostrar cuando haya algun filtro
              invisible={
                partnerClientFilter.countryId === 0 &&
                partnerClientFilter.hideDemo
              }
              className={styles.filterBadge}
            >
              <ListFilter
                filterTitle={i18n.t("partnersClients.partnerClientFilterTitle")}
                //filtro pais
                hasCountryFilter
                countryId={partnerClientFilter.countryId}
                //Mostrar o no demo
                hideDemo={partnerClientFilter.hideDemo}
                hideDemoLabel={
                  currentUserHasRoleEndCustomer
                    ? false
                    : //mostrar solo en caso de no ser endcustomer
                    showOnlyClients
                    ? i18n.t("partnersClients.hideClientsDemo")
                    : i18n.t("partnersClients.hidePartnersDemo")
                }
                //funciones
                handleClearFilter={handleClearFilter}
                handleApplyFilter={handleApplyFilter}
              />
            </Badge>
          </div>
        </div>
        <Card className={detailStyles.PartnerClientListMainCard}>
          <div
            id="partnerclient-container"
            className={detailStyles.infinityPartnerClientListContainer}
          >
            {!showOnlyClients ? (
              <InfiniteScroll
                scrollableTarget="partnerclient-container"
                dataLength={partners.length}
                style={{ overflow: "hidden" }}
                next={() => {
                  if (!loading) {
                    let partnerFilter = { ...partnerClientFilter };
                    partnerFilter.itemsRendered = partners.length;
                    getPartners(partnerFilter, false);
                  }
                }}
                hasMore={hasMorePartners}
                loader
              >
                {partners.length === 0 && !loading && (
                  // En caso de que no haya partners en la lista
                  <Tooltip
                    title={
                      partnerClientFilter.searchQuery.trim().length === 0
                        ? i18n.t("partnersClients.empyPartnerTooltip")
                        : i18n.t("partnersClients.elementsNotFound")
                    }
                    placement="bottom"
                    arrow
                  >
                    <ListItem
                      className={detailStyles.partnerClientListItem}
                      data-testid="partners-empty"
                    >
                      <ListItemIcon>
                        <Info style={{ color: "#46B9F3" }} />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        secondaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        primary={i18n.t("partnersClients.noPartnerPrimary")}
                        secondary={
                          partnerClientFilter.searchQuery.trim().length === 0
                            ? keyuser || isDSC
                              ? i18n.t(
                                  "partnersClients.noPartnerSecondaryAccess"
                                )
                              : i18n.t(
                                  "partnersClients.noPartnerSecondaryNoAccess"
                                )
                            : i18n.t("partnersClients.elementsNotFound")
                        }
                      />
                    </ListItem>
                  </Tooltip>
                )}

                {partners.map((partner, indx) => (
                  /*      Lista Partners      */
                  <div key={indx} data-cy="CardInfo">
                    <ListItem
                      data-testid={"partner-" + partner.razonSocial}
                      button
                      className={detailStyles.partnerClientListItem}
                      onClick={() => {
                        expandPartner(partner);
                      }}
                      selected={selectedPartnerId === partner.id}
                    >
                      <ListItemIcon>
                        <Avatar
                          className={detailStyles.partnerClientListAvatar}
                        >
                          {partner.isDemo ? (
                            <Flag />
                          ) : (
                            getAvatarText(partner.razonSocial)
                          )}
                        </Avatar>
                      </ListItemIcon>

                      <ListItemText
                        primaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        secondaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        primary={partner.razonSocial}
                        secondary={partner.direccion}
                      />

                      {partner.expanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    {partner?.clientList?.length <= 0 && !keyuser && !isDSC && (
                      <Collapse
                        /*      Sin clientes     */
                        key={"noClient" + indx}
                        in={partner.expanded}
                        appear={loadingClient}
                        timeout={360}
                        className={detailStyles.partnerClientListInactive}
                      >
                        <ListItem>
                          <ListItemText
                            primary={i18n.t(
                              "partnersClients.noCustomerInParner"
                            )}
                          />
                        </ListItem>
                      </Collapse>
                    )}

                    {!readOnly && (!partner.isDemo || isDSC || keyuser) && (
                      <Collapse
                        /*      NUEVO CLIENTE EN LISTA PARTNER    */
                        key={"newPartner" + indx}
                        in={partner.expanded}
                        appear={loadingClient}
                        timeout={360}
                        className={detailStyles.partnerClientListCollapse}
                      >
                        <ListItem
                          data-testid={"newClient-" + partner.razonSocial}
                          button
                          onClick={() => {
                            handleNewClient(partner.id);
                          }}
                        >
                          <ListItemIcon>
                            <Avatar
                              className={
                                detailStyles.partnerClientListAvatarNewClient
                              }
                            >
                              <GroupAdd />
                            </Avatar>
                          </ListItemIcon>

                          <ListItemText
                            primary={i18n.t(
                              "partnersClients.addClientOnPartner"
                            )}
                          />
                        </ListItem>
                      </Collapse>
                    )}

                    {partner &&
                      /* Lista clientes en Partner */
                      partner.clientList?.map((client) => (
                        <Collapse
                          in={partner.expanded}
                          key={"collapse" + client.id}
                          appear={loadingClient}
                          timeout={360}
                          className={detailStyles.partnerClientListCollapse}
                        >
                          <ListItem
                            data-testid={"client-" + client.razonSocial}
                            key={"client" + client.id}
                            button
                            selected={selectedClientId === client.id}
                            onClick={() => {
                              handleClient(partner.id, client.id);
                            }}
                          >
                            <ListItemIcon>
                              <Avatar
                                className={
                                  client.isDemo
                                    ? detailStyles.partnerClientListAvatar
                                    : client.isTest
                                    ? detailStyles.partnerTestClientListAvatar
                                    : detailStyles.partnerClientListAvatar
                                }
                              >
                                {partner.isDemo ? (
                                  <Flag />
                                ) : client.isTest ? (
                                  <GroupTwoTone />
                                ) : (
                                  <Group />
                                )}
                              </Avatar>
                            </ListItemIcon>

                            <ListItemText
                              primaryTypographyProps={{
                                className:
                                  detailStyles.partnerClientListItemText,
                              }}
                              secondaryTypographyProps={{
                                className:
                                  detailStyles.partnerClientListItemText,
                              }}
                              primary={client.razonSocial}
                              secondary={client.direccion}
                            />
                          </ListItem>
                        </Collapse>
                      ))}
                  </div>
                ))}
              </InfiniteScroll>
            ) : (
              <InfiniteScroll
                /*      Lista SOLO Clientes      */
                scrollableTarget="partnerclient-container"
                dataLength={clients.length}
                next={() => {
                  if (!loading) {
                    let clientFilter = { ...partnerClientFilter };
                    clientFilter.itemsRendered = clients.length;
                    getClients(clientFilter, false);
                  }
                }}
                hasMore={hasMoreClients}
                loader
              >
                {clients.length === 0 && !loading && (
                  // En caso de que no haya partners en la lista
                  <Tooltip
                    title={
                      partnerClientFilter.searchQuery.trim().length === 0
                        ? i18n.t("partnersClients.empyPartnerTooltip")
                        : i18n.t("partnersClients.elementsNotFound")
                    }
                    placement="bottom"
                    arrow
                  >
                    <ListItem
                      className={detailStyles.partnerClientListItem}
                      data-testid={"client-empty"}
                    >
                      <ListItemIcon>
                        <Info style={{ color: "#46B9F3" }} />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        secondaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        primary={i18n.t("partnersClients.noClientPrimary")}
                        secondary={
                          partnerClientFilter.searchQuery.trim().length === 0
                            ? keyuser || isDSC || isPartner
                              ? i18n.t(
                                  "partnersClients.noClientSecondaryAccess"
                                )
                              : i18n.t(
                                  "partnersClients.noClientSecondaryNoAccess"
                                )
                            : i18n.t("partnersClients.elementsNotFound")
                        }
                      />
                    </ListItem>
                  </Tooltip>
                )}

                {clients.map((client, indx) => (
                  <div key={indx} data-cy="CardInfo">
                    <ListItem
                      data-testid={"client-" + client.razonSocial}
                      button
                      className={detailStyles.partnerClientListItem}
                      onClick={() => {
                        handleClient(null, client.id);
                      }}
                      selected={selectedClientId === client.id}
                    >
                      <ListItemIcon>
                        <Avatar
                          className={
                            client.isTest
                              ? detailStyles.partnerTestClientListAvatar
                              : detailStyles.partnerClientListAvatar
                          }
                        >
                          {client.isDemo ? (
                            <Flag />
                          ) : (
                            getAvatarText(client.razonSocial)
                          )}
                        </Avatar>
                      </ListItemIcon>

                      <ListItemText
                        primaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        secondaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        primary={client.razonSocial}
                        secondary={client.direccion}
                      />
                    </ListItem>
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>

          <div className={detailStyles.partnerClientFormContainer}>
            {!loading && (
              <>
                {clientFormMode || showOnlyClients ? (
                  <ClientFormInList
                    clientId={selectedClientId}
                    partnerId={selectedPartnerId}
                    history={history}
                    keyuser={keyuser}
                    readOnly={readOnly || clientFormReadOnly}
                    isGestor={isGestor}
                    countries={countries}
                    clientDeleted={clientDeleted}
                    clientAdded={clientAdded}
                    clientUpdated={clientUpdated}
                    notFound={notFound}
                  />
                ) : (
                  <PartnerFormInList
                    partnerId={selectedPartnerId}
                    isNewPartner={isNewPartner}
                    keyuser={keyuser}
                    readOnly={readOnly}
                    partnerDeleted={partnerDeleted}
                    partnerAdded={partnerAdded}
                    partnerUpdated={partnerUpdated}
                    countries={countries}
                    notFound={notFound}
                  />
                )}
              </>
            )}
          </div>
        </Card>
        <Dialog
          open={demoPartnerDialogOpen}
          onClose={() => setDemoPartnerDialogOpen(false)}
        >
          <DialogTitle>
            <SupervisedUserCircle className={styles.addCircleOutline} />{" "}
            {i18n.t("partner.demo")}
          </DialogTitle>
          <DialogContent style={{ minWidth: 350 }}>
            <Autocomplete
              open={!keyuser && !DSCisRegionManager ? false : undefined}
              autoComplete={!keyuser && !DSCisRegionManager}
              closeIcon={!keyuser && !DSCisRegionManager ? false : undefined}
              freeSolo={!keyuser && !DSCisRegionManager}
              groupBy={(option) =>
                keyuser ? option?.region?.regionCode : undefined
              }
              fullWidth
              autoHighlight
              options={filteredOptions}
              getOptionLabel={(c) => i18n.t(`country.${c.name || "null"}`)}
              value={
                selectedCountry
                  ? countries?.find(
                      (c) => c.id.toString() === selectedCountry?.toString()
                    ) || null
                  : null
              }
              className={detailStyles.materialTextField}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={i18n.t("planta.pais")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              onChange={(event, newValue) => {
                setSelectedCountry(newValue && newValue.id);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              className={detailStyles.closeDialogButton}
              onClick={() => {
                setDemoPartnerDialogOpen(false);
              }}
              style={{ textTransform: "none" }}
            >
              {i18n.t("common.cancel")}
            </Button>
            <Button
              onClick={() => {
                addDemoPartner();
              }}
              className={detailStyles.acceptDialogButton}
            >
              {i18n.t("common.create")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <SubscriptionManagementDialog
        dialogRef={subscriptionManagementDialog}
        handlePartner={handlePartner}
        handleClient={handleClient}
        isPartner={isPartner}
      />
    </RelativeBackdrop>
  );
}
