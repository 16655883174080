import { Avatar, Card, CardHeader, Typography } from "@material-ui/core";
import { EventAvailableTwoTone } from "@material-ui/icons";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import React from "react";
import {
  CardList_Style,
  DetailTabs_Style,
  globalColors,
} from "../../../assets/css/Styles";
import i18n from "../../../locales/i18n";
import withHover from "../../withHover";

const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

/**
 * Card que aparece en la lista de Plantas y de Clientes
 * @Component
 * @param {Object} props
 * @param {string} props.title //Titulo Principal de la Card
 * @param {string} props.subtitle // Informacin secundaria de la cad
 * @param {Array} props.contents // Lineas de datos
 * @param {Object} props.onClick //evento click
 * @param {boolean} props.isDanfossOwned // Si es propiedad de Danfoss
 * @param {string} props.type // Tipo de calrd [Cliente, Planta]
 * @param {Object} [props.style] // Fondo y estilo de la card
 * @param {string} [props.className] // Fondo y estilo de la card
 * @param {boolean} [props.isTest] // Fondo y estilo de la card
 * @param {boolean} [props.isDemo] // Indica si una planta es demo
 *
 */
function CardPlantClient(props) {
  const { title, subtitle, contents, onClick, type, style, className, isDemo, isTest } =
    props;
  const abbreviatonLetters = title.trim().split(" ");
  const styles = DetailTabs_Style();
  const abbreviaton =
    abbreviatonLetters.length > 1
      ? (abbreviatonLetters[0][0] + abbreviatonLetters[1][0]).toUpperCase()
      : title.substr(0, 2).toUpperCase();
  return (
    <CardWithHover
      style={{ position: "relative" }}
      className={`${className}`}
      onClick={onClick}
      data-cy="CardInfo"
    >
      {/* Tener en cuenta que hay que hacer una ternaria en el merge, ya que la "misma" pildora se muestra cuando la planta sea test */}
      {isDemo ? (
        <div className={styles.demoPillContainer}>
          <div className={styles.flexWarningPill}>
            <div className={styles.pillText}>{i18n.t("planta.demo")}</div>
          </div>
        </div>
      ) : isTest ? (
        <div className={styles.testPillContainer}>
          <div
            style={{
              display: "flex",
              alignContent: "flex-end",
              justifyContent: "flex-end",
              width: "100%",
            }}
          ></div>

          <div className={styles.flexWarningPill}>
            <span className={styles.pillText}>{i18n.t("common.test")}</span>
          </div>
        </div>) : undefined}
      <CardHeader
        classes={{ content: "overflow-hidden" }}
        titleTypographyProps={{
          noWrap: true,
          variant: "body2",
        }}
        subheaderTypographyProps={{ noWrap: true, variant: "body2" }}
        title={<div className={style.plantCardTitle}>{title}</div>}
        subheader={<div className={style.plantCardSubtitle}>{subtitle}</div>}
        avatar={
          <Avatar aria-label="avatar" className={style.plantCardAvatar}>
            {abbreviaton}
          </Avatar>
        }
        action={
          props.isDanfossOwned ? (
            <BookmarkIcon style={{ color: globalColors.activeColor }} />
          ) : undefined
        }
      />
      <div className={style.plantCardContent}>
        {contents.map((value, index) => (
          <Typography
            key={index}
            style={{ fontFamily: "MyriadPro" }}
            variant="body2"
            display="block"
            noWrap
          >
            <span style={{ fontWeight: "bold" }}>
              {i18n.t(type + "." + value.key)}
            </span>{" "}
            : {value.value}
          </Typography>
        ))}
      </div>
    </CardWithHover>
  );
}
//ContactPageTwoTone

export default CardPlantClient;
