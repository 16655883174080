import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Lock, ArrowDropDown } from "@material-ui/icons";
import RiskCalculation from "../../../helpers/riskCalculation.json";
import i18n from "../../../locales/i18n";
import MouseOverPopover from "../../MouseOverPopover";
import { HelpOutline, Close } from "@material-ui/icons";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import { Autocomplete } from "@material-ui/lab";

function FormularioRiesgoVariador({
  equipo,
  handleInputChange,
  readOnly,
  hasPartialAccess,
}) {
  const recomendationOptions = [
    i18n.t("equipo.recomendacionOptions.preventiveM"),
    i18n.t("equipo.recomendacionOptions.retrofit"),
    i18n.t("equipo.recomendacionOptions.spareD"),
    i18n.t("equipo.recomendacionOptions.spareP"),
    i18n.t("equipo.recomendacionOptions.contract"),
    i18n.t("equipo.recomendacionOptions.inspection"),
    i18n.t("equipo.recomendacionOptions.extendedW"),
    i18n.t("equipo.recomendacionOptions.noA"),
  ];

  const getRisk = () => {
    var risk = RiskCalculation.find(
      (c) =>
        c.lifeCycle === equipo.cicloVida &&
        c.criticality === equipo.criticidad &&
        c.condition === equipo.estado
    );

    if (!risk)
      return {
        value: -1,
        text: i18n.t("common.indefinido"),
      };
    return risk.result === 0
      ? {
          value: 0,
          text: i18n.t(`common.alto`),
        }
      : risk.result === 1
      ? {
          value: 1,
          text: i18n.t(`common.medio`),
        }
      : risk.result === 2
      ? { value: 2, text: i18n.t(`common.bajo`) }
      : {
          value: -1,
          text: i18n.t(`common.indefinido`),
        };
  };
  const styles = DetailTabs_Style();

  const classNameIndex = [
    {
      lifeState: styles.sucessStatusBgColor,
      critRisk: styles.errorStatusBgColor,
    },
    {
      lifeState: styles.warningStatusBgColor,
      critRisk: styles.warningStatusBgColor,
    },
    {
      lifeState: styles.errorStatusBgColor,
      critRisk: styles.sucessStatusBgColor,
    },
  ];

  return (
    <div className={styles.formCardContainer}>
      <div className={styles.flexRowForm}>
        <div className={styles.flexCellForm}>
          <h5>{i18n.t("equipo.riesgo")}</h5>
          <hr className={styles.separatorLineForm} />
        </div>
      </div>
      <div className={styles.flexRowForm}>
        <div className={styles.flexCellForm}>
          <FormControl fullWidth required className={styles.materialTextField}>
            <InputLabel
              className={styles.withInputLabelLifeCicle}
              required={false}
            >
              <>
                {i18n.t("equipo.cicloVida")}
                <MouseOverPopover
                  content={
                    <>
                      <b>{i18n.t("common.activo.singularM")}</b>:
                      {i18n.t("equipo.cicloVidaHelp.active")}
                      <br />
                      <b>{i18n.t("common.limitado")}</b>:{" "}
                      {i18n.t("equipo.cicloVidaHelp.limited")}
                      <br />
                      <b>{i18n.t("common.inactivo")}</b>:{" "}
                      {i18n.t("equipo.cicloVidaHelp.inactive")}
                      <br />
                    </>
                  }
                  parent={<HelpOutline className={styles.outlineIcon} />}
                />
              </>
            </InputLabel>
            <Select
              required
              value={
                equipo.cicloVida !== undefined ? equipo.cicloVida.toString() : 3
              }
              className={`${
                classNameIndex[equipo.cicloVida]?.lifeState || ""
              } ${
                readOnly
                  ? styles.selectReadOnlyFormRisckDrive
                  : styles.selectFormRisckDrive
              }`}
              name="cicloVida"
              displayEmpty
              onChange={handleInputChange}
              inputProps={{
                readOnly: readOnly || equipo.cicloVidaDanfossDbLock,
              }}
              IconComponent={
                equipo.cicloVidaDanfossDbLock
                  ? Lock
                  : readOnly
                  ? () => null
                  : ArrowDropDown
              }
            >
              <MenuItem value={0}>{i18n.t("common.activo.singularM")}</MenuItem>
              <MenuItem value={1}>{i18n.t("common.limitado")}</MenuItem>
              <MenuItem value={2}>{i18n.t("common.inactivo")}</MenuItem>
              <MenuItem value={3}>{i18n.t("common.indefinido")}</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={styles.flexCellForm}>
          <FormControl fullWidth required className={styles.materialTextField}>
            <InputLabel
              className={styles.withInputLabelLifeCicle}
              required={false}
            >
              {i18n.t("equipo.estadoEquipo")}
              <MouseOverPopover
                content={
                  <>
                    <b>{i18n.t("common.bueno")}</b>:{" "}
                    {i18n.t("equipo.estadoHelp.good")}
                    <br />
                    <b>{i18n.t("common.medio")}</b>:{" "}
                    {i18n.t("equipo.estadoHelp.medium")}
                    <br />
                    <b>{i18n.t("common.pobre")}</b>:{" "}
                    {i18n.t("equipo.estadoHelp.poor")}
                    <br />
                  </>
                }
                parent={<HelpOutline className={styles.outlineIcon} />}
              />
            </InputLabel>
            <Select
              required
              value={equipo.estado !== undefined ? equipo.estado.toString() : 3}
              className={`${classNameIndex[equipo.estado]?.lifeState || ""} ${
                readOnly
                  ? styles.selectReadOnlyFormRisckDrive
                  : styles.selectFormRisckDrive
              }`}
              name="estado"
              displayEmpty
              onChange={handleInputChange}
              inputProps={{
                readOnly: readOnly,
              }}
              IconComponent={readOnly ? () => null : ArrowDropDown}
            >
              <MenuItem value={0}>{i18n.t("common.bueno")}</MenuItem>
              <MenuItem value={1}>{i18n.t("common.medio")}</MenuItem>
              <MenuItem value={2}>{i18n.t("common.pobre")}</MenuItem>
              <MenuItem value={3}>{i18n.t("common.indefinido")}</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={styles.flexCellForm}>
          <FormControl fullWidth required className={styles.materialTextField}>
            <InputLabel
              className={styles.withInputLabelLifeCicle}
              required={false}
            >
              {i18n.t("equipo.criticidad")}
              <MouseOverPopover
                content={
                  <>
                    <b>{i18n.t("common.alto")}</b>:{" "}
                    {i18n.t("equipo.criticidadHelp.high")}
                    <br />
                    <b>{i18n.t("common.medio")}</b>:{" "}
                    {i18n.t("equipo.criticidadHelp.medium")}
                    <br />
                    <b>{i18n.t("common.bajo")}</b>:{" "}
                    {i18n.t("equipo.criticidadHelp.low")}
                    <br />
                  </>
                }
                parent={<HelpOutline className={styles.outlineIcon} />}
              />
            </InputLabel>
            <Select
              style={{ paddingLeft: 5 }}
              required
              value={
                equipo.criticidad !== undefined
                  ? equipo.criticidad.toString()
                  : "3"
              }
              className={`${
                classNameIndex[equipo.criticidad]?.critRisk || ""
              } ${
                readOnly && !hasPartialAccess
                  ? styles.selectReadOnlyFormRisckDrive
                  : styles.selectFormRisckDrive
              }`}
              name="criticidad"
              displayEmpty
              onChange={handleInputChange}
              inputProps={{
                readOnly: readOnly && !hasPartialAccess,
              }}
              IconComponent={
                readOnly && !hasPartialAccess ? () => null : ArrowDropDown
              }
            >
              <MenuItem value={0}>{i18n.t("common.alto")}</MenuItem>
              <MenuItem value={1}>{i18n.t("common.medio")}</MenuItem>
              <MenuItem value={2}>{i18n.t("common.bajo")}</MenuItem>
              <MenuItem value={3}>{i18n.t("common.indefinido")}</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={styles.flexRowForm}>
        <div className={styles.flexCellForm}>
          <FormControl fullWidth required className={styles.materialTextField}>
            <TextField
              fullWidth
              value={getRisk().text}
              label={
                <>
                  {i18n.t("equipo.riesgo")}
                  <MouseOverPopover
                    content={
                      <>
                        <b>{i18n.t("common.alto")}</b>:{" "}
                        {i18n.t("equipo.riesgoHelp.high")}
                        <br />
                        <b>{i18n.t("common.medio")}</b>:{" "}
                        {i18n.t("equipo.riesgoHelp.medium")}
                        <br />
                        <b>{i18n.t("common.bajo")}</b>:{" "}
                        {i18n.t("equipo.riesgoHelp.low")}
                        <br />
                      </>
                    }
                    parent={<HelpOutline className={styles.outlineIcon} />}
                  />
                </>
              }
              InputLabelProps={{
                shrink: true,
                style: { width: "134%" },
              }}
              inputProps={{
                style: { paddingLeft: 5 },
                readOnly: true,
                className: `${classNameIndex[getRisk().value]?.critRisk || ""}`,
              }}
            />
          </FormControl>
        </div>
        <div className={styles.flexCellForm}>
          <FormControl fullWidth required className={styles.materialTextField}>
            <Autocomplete
              options={
                equipo.recommendation
                  ? recomendationOptions.filter((r) =>
                      r
                        .toLowerCase()
                        .includes(equipo.recommendation.toLowerCase())
                    )
                  : recomendationOptions
              }
              value={equipo.recommendation || ""}
              clearOnBlur={false}
              onChange={(e, newValue) => {
                let fakeEvent = {
                  target: {
                    value: newValue,
                    type: "text",
                    name: "recommendation",
                  },
                };
                handleInputChange(fakeEvent);
              }}
              getOptionSelected={(option, value) =>
                recomendationOptions.find((row) => row === value)
                  ? option === value
                  : option ===
                    recomendationOptions.filter((r) =>
                      r.toLowerCase().includes(value.toLowerCase())
                    )[0]
              }
              autoComplete={!readOnly}
              closeIcon={readOnly ? null : <Close />}
              openOnFocus={!readOnly}
              freeSolo={readOnly}
              open={!readOnly ? undefined : false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: readOnly,
                  }}
                  InputLabelProps={{
                    shrink: readOnly ? true : undefined,
                  }}
                  name={"recommendation"}
                  onChange={(e) => handleInputChange(e)}
                  label={i18n.t("equipo.recomendacion")}
                  variant="standard"
                />
              )}
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default FormularioRiesgoVariador;
