import packageJson from "../../../../package.json";
import React, { useState, useEffect } from "react";
import i18n from "../../../locales/i18n";
import { DetailTabs_Style } from "../../../assets/css/Styles";
export default function NewsContent() {
  // @ts-ignore

  const [versions, setVersions] = useState([]);
  const styles = DetailTabs_Style();

  useEffect(() => {
    const objVersiones = [
      {
        webTitle: i18n.t("newsPage.web.title") + "v" + packageJson.version,
        webFeatures: [
          i18n.t("newsPage.web.v130.f1"),
          i18n.t("newsPage.web.v130.f2"),
          i18n.t("newsPage.web.v130.f3"),
          i18n.t("newsPage.web.v130.f4"),
          i18n.t("newsPage.web.v130.f5"),
          i18n.t("newsPage.web.v130.f6"),
          i18n.t("newsPage.web.v130.f7"),
          i18n.t("newsPage.web.v130.f8"),
          i18n.t("newsPage.web.v130.f9"),
          i18n.t("newsPage.web.v130.f10"),
          i18n.t("newsPage.web.v130.f11"),
          i18n.t("newsPage.web.v130.f12"),
          i18n.t("newsPage.web.v130.f13"),
          i18n.t("newsPage.web.v130.f14"),
          i18n.t("newsPage.web.v130.f15"),
          i18n.t("newsPage.web.v130.f16"),
          i18n.t("newsPage.web.v130.f17"),
          i18n.t("newsPage.web.v130.f18"),
          i18n.t("newsPage.web.v130.f19"),
          i18n.t("newsPage.web.v130.f20"),
          i18n.t("newsPage.web.v130.f21"),
          i18n.t("newsPage.errors"),
        ],

        appTitle: i18n.t("newsPage.app.title") + "v1.1.0",
        appFeatures: [
          i18n.t("newsPage.app.v110.f1"),
          i18n.t("newsPage.app.v110.f2"),
          i18n.t("newsPage.errors"),
        ],
      },
      //Veriones Anteriores
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.0.0",
        webFeatures: [
          i18n.t("newsPage.web.v100.f1"),
          i18n.t("newsPage.web.v100.f2"),
          i18n.t("newsPage.web.v100.f3"),
          i18n.t("newsPage.web.v100.f4"),
          i18n.t("newsPage.web.v100.f5"),
          i18n.t("newsPage.web.v100.f6"),
          i18n.t("newsPage.errors"),
        ],

        appTitle: i18n.t("newsPage.app.title") + "v1.0.0",
        appFeatures: [
          i18n.t("newsPage.app.v100.f1"),
          i18n.t("newsPage.app.v100.f2"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v0.9.0",
        webFeatures: [
          i18n.t("newsPage.web.v090.f1"),
          i18n.t("newsPage.web.v090.f2"),
          i18n.t("newsPage.web.v090.f3"),
          i18n.t("newsPage.web.v090.f4"),
          i18n.t("newsPage.web.v090.f5"),
          i18n.t("newsPage.web.v090.f6"),
          i18n.t("newsPage.web.v090.f7"),
          i18n.t("newsPage.errors"),
        ],

        appTitle: i18n.t("newsPage.app.title") + "v0.9.0",
        appFeatures: [
          i18n.t("newsPage.app.v090.f1"),
          i18n.t("newsPage.app.v090.f2"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v0.8.0",
        webFeatures: [
          i18n.t("newsPage.web.v080.f1"),
          i18n.t("newsPage.web.v080.f2"),
          i18n.t("newsPage.web.v080.f3"),
          i18n.t("newsPage.web.v080.f4"),
          i18n.t("newsPage.web.v080.f5"),
          i18n.t("newsPage.web.v080.f6"),
          i18n.t("newsPage.web.v080.f7"),
          i18n.t("newsPage.web.v080.f8"),
          i18n.t("newsPage.web.v080.f9"),
          i18n.t("newsPage.web.v080.f10"),
          i18n.t("newsPage.web.v080.f11"),
          i18n.t("newsPage.web.v080.f12"),
          i18n.t("newsPage.errors"),
        ],

        appTitle: i18n.t("newsPage.app.title") + "v0.8.0",
        appFeatures: [
          i18n.t("newsPage.app.v080.f1"),
          i18n.t("newsPage.app.v080.f2"),
          i18n.t("newsPage.app.v080.f3"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v0.7.0",
        webFeatures: [
          i18n.t("newsPage.web.v070.f1"),
          i18n.t("newsPage.web.v070.f2"),
          i18n.t("newsPage.web.v070.f3"),
          i18n.t("newsPage.web.v070.f4"),
          i18n.t("newsPage.web.v070.f5"),
          i18n.t("newsPage.web.v070.f6"),
          i18n.t("newsPage.web.v070.f7"),
          i18n.t("newsPage.web.v070.f8"),
          i18n.t("newsPage.web.v070.f9"),
          i18n.t("newsPage.web.v070.f10"),
          i18n.t("newsPage.web.v070.f11"),
          i18n.t("newsPage.web.v070.f12"),
          i18n.t("newsPage.web.v070.f13"),
          i18n.t("newsPage.web.v070.f14"),
          i18n.t("newsPage.web.v070.f15"),
          i18n.t("newsPage.web.v070.f16"),
          i18n.t("newsPage.errors"),
        ],

        appTitle: i18n.t("newsPage.app.title") + "v0.7.0",
        appFeatures: [
          i18n.t("newsPage.app.v070.f1"),
          i18n.t("newsPage.app.v070.f2"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v0.6.1",
        webFeatures: [
          i18n.t("newsPage.web.v060.f1"),
          i18n.t("newsPage.web.v060.f2"),
          i18n.t("newsPage.web.v060.f3"),
          i18n.t("newsPage.errors"),
        ],

        appTitle: i18n.t("newsPage.app.title") + "v0.6.0",
        appFeatures: [
          i18n.t("newsPage.app.v060.f1"),
          i18n.t("newsPage.app.v060.f2"),
          i18n.t("newsPage.app.v060.f3"),
          i18n.t("newsPage.app.v060.f4"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v0.5.2",
        webFeatures: [
          i18n.t("newsPage.web.v050.f1"),
          i18n.t("newsPage.web.v050.f2"),
          i18n.t("newsPage.web.v050.f3"),
          i18n.t("newsPage.web.v050.f4"),
          i18n.t("newsPage.web.v050.f5"),
          i18n.t("newsPage.web.v050.f6"),
          i18n.t("newsPage.errors"),
        ],

        appTitle: i18n.t("newsPage.app.title") + "v0.5.0",
        appFeatures: [
          i18n.t("newsPage.app.v050.f1"),
          i18n.t("newsPage.app.v050.f2"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v0.4.1",
        webFeatures: [
          i18n.t("newsPage.web.v040.f1"),
          i18n.t("newsPage.web.v040.f2"),
          i18n.t("newsPage.web.v040.f3"),
          i18n.t("newsPage.errors"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v0.4.1",
        appFeatures: [
          i18n.t("newsPage.app.v040.f1"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v0.3.4",
        webFeatures: [
          i18n.t("newsPage.web.v031.f1"),
          i18n.t("newsPage.web.v031.f2"),
          i18n.t("newsPage.web.v031.f3"),
          i18n.t("newsPage.web.v031.f4"),
          i18n.t("newsPage.web.v031.f5"),
          i18n.t("newsPage.web.v031.f6"),
          i18n.t("newsPage.errors"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v0.3.1",
        appFeatures: [
          i18n.t("newsPage.app.v031.f1"),
          i18n.t("newsPage.app.v031.f2"),
          i18n.t("newsPage.app.v031.f3"),
          i18n.t("newsPage.app.v031.f4"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v0.3.0",
        webFeatures: [
          i18n.t("newsPage.web.v030.f1"),
          i18n.t("newsPage.web.v030.f2"),
          i18n.t("newsPage.web.v030.f3"),
          i18n.t("newsPage.web.v030.f4"),
          i18n.t("newsPage.web.v030.f5"),
          i18n.t("newsPage.errors"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v0.3.0",
        appFeatures: [
          i18n.t("newsPage.app.v030.f1"),
          i18n.t("newsPage.app.v030.f2"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v0.2.0",
        webFeatures: [
          i18n.t("newsPage.web.v020.f1"),
          i18n.t("newsPage.web.v020.f2"),
          i18n.t("newsPage.web.v020.f3"),
          i18n.t("newsPage.web.v020.f4"),
          i18n.t("newsPage.web.v020.f5"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v0.2.0",
        appFeatures: [
          i18n.t("newsPage.app.v020.f1"),
          i18n.t("newsPage.app.v020.f2"),
          i18n.t("newsPage.app.v020.f3"),
          i18n.t("newsPage.app.v020.f4"),
          i18n.t("newsPage.app.v020.f5"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v0.1.0",
        webFeatures: [
          i18n.t("newsPage.web.v010.f1"),
          i18n.t("newsPage.web.v010.f2"),
          i18n.t("newsPage.web.v010.f3"),
          i18n.t("newsPage.web.v010.f4"),
          i18n.t("newsPage.web.v010.f5"),
          i18n.t("newsPage.web.v010.f6"),
          i18n.t("newsPage.web.v010.f7"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v0.1.0",
        appFeatures: [
          i18n.t("newsPage.app.v010.f1"),
          i18n.t("newsPage.app.v010.f2"),
          i18n.t("newsPage.app.v010.f3"),
          i18n.t("newsPage.app.v010.f4"),
          i18n.t("newsPage.app.v010.f5"),
          i18n.t("newsPage.app.v010.f6"),
          i18n.t("newsPage.app.v010.f7"),
        ],
      },
    ];
    setVersions(objVersiones);
  }, []);

  if (versions.length === 0) {
    return <h2 className={styles.newsPageTitle}>Working...</h2>;
  } else {
    return (
      <div className={styles.newsPage}>
        <h4>{i18n.t("newsPage.actualVerson")}</h4>
        <div>
          <h5>{versions[0].webTitle}</h5>
          <ul>
            {versions[0].webFeatures.map((feature) => (
              <li>{feature}</li>
            ))}
          </ul>
          <h5>{versions[0].appTitle}</h5>
          <ul>
            {versions[0].appFeatures.map((feature) => (
              <li>{feature}</li>
            ))}
          </ul>
        </div>
        <hr />
        <h4>{i18n.t("newsPage.prevVersion")}</h4>
        <div>
          {versions.slice(1).map((version) => (
            <>
              <h5>{version.webTitle}</h5>
              <ul>
                {version.webFeatures.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>
              <h5>{version.appTitle}</h5>
              <ul>
                {version.appFeatures.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>
            </>
          ))}
        </div>
      </div>
    );
  }
}
