import packageJson from "../../../package.json"
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Auth_Style } from "../../assets/css/Styles";
import {
  Button,
  Link,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Slide,
} from "@material-ui/core";
import i18n from "../../locales/i18n";
import { SnackbarClose, termsUrl } from "../../helpers/Common";
import AppContext from "../../contexts/AppContext";
import { PartnersAPI } from "../../services/API";
import { ExpandMore, Translate } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

/**
 * Padre de todas las páginas de autenticación
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {string} [props.title] - título de la página
 * @param {function} [props.setChildrenLang] - función para cambiar el idioma de los hijos
 * @param {*} props.children - hijos de la página
 */
export default function Auth({ title, setChildrenLang, children }) {
  const styles = Auth_Style();
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
  const [locale, setLocale] = useState(i18n.locale || i18n.defaultLocale);

  // @ts-ignore
  const { global, setGlobal } = useContext(AppContext);

  const getPartnerLogin = useCallback(
    (ptr) => {
      PartnersAPI.getLogin(ptr)
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 404) {
            return null;
          } else {
            throw new Error(res.statusText);
          }
        })
        .then(
          (data) => data && setGlobal((old) => ({ ...old, partnerLogin: data }))
        )
        .catch((err) => console.log(err));
    },
    [setGlobal]
  );

  useEffect(() => {
    const ptr = new URLSearchParams(window.location.search).get("ptr");

    if (ptr && ptr !== "undefined") {
      setGlobal((old) => ({ ...old, ptr }));
      getPartnerLogin(ptr);
    }
  }, [getPartnerLogin, setGlobal]);

  const handleLangClick = (event) => {
    setLanguageMenuAnchor(event.currentTarget);
  };

  const handleLangClose = (locale) => {
    setLanguageMenuAnchor(null);
    let currentLocale = i18n.locale || i18n.defaultLocale;

    const availableLangs = ["es", "en", "de", "pl", "it", "fr"];

    if (locale !== currentLocale && availableLangs.includes(locale)) {
      localStorage.setItem("locale", locale);
      i18n.locale = locale;
      setChildrenLang(locale);
      setLocale(locale);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <div className={styles.danfossLogo} />
          {global?.partnerLogin?.logo && (
            <img
              className={styles.partnerLogo}
              src={global?.partnerLogin?.logo}
              alt="partner logo"
            />
          )}
        </div>
        <div className={styles.authCardContainer}>
          <Paper elevation={3} className={styles.authCard}>
            <div className={styles.d360LifeLogoContainer}>
              <div className={styles.d360LifeLogo} />
              <h1 className={styles.d360LifeTitle}>DrivePro® 360Live</h1>
              <div className={styles.separator} />
            </div>
            <div className={styles.authForm}>
              <h3 className={styles.authTitle}>
                {title || ""}
                {setChildrenLang && (
                  <>
                    <Button
                      aria-haspopup="true"
                      onClick={handleLangClick}
                      startIcon={<Translate />}
                      endIcon={<ExpandMore />}
                    >
                      {i18n.t("settings.languageName")}
                    </Button>
                    <Menu
                      keepMounted
                      anchorEl={languageMenuAnchor}
                      open={Boolean(languageMenuAnchor)}
                      onClose={handleLangClose}
                    >
                      <MenuItem onClick={() => handleLangClose("es")}>
                        {i18n.t("settings.languageName", { locale: "es" })}
                      </MenuItem>
                      <MenuItem onClick={() => handleLangClose("en")}>
                        {i18n.t("settings.languageName", { locale: "en" })}
                      </MenuItem>
                      <MenuItem onClick={() => handleLangClose("de")}>
                        {i18n.t("settings.languageName", { locale: "de" })}
                      </MenuItem>
                      <MenuItem onClick={() => handleLangClose("pl")}>
                        {i18n.t("settings.languageName", { locale: "pl" })}
                      </MenuItem>
                      <MenuItem onClick={() => handleLangClose("it")}>
                        {i18n.t("settings.languageName", { locale: "it" })}
                      </MenuItem>
                      <MenuItem onClick={() => handleLangClose("fr")}>
                        {i18n.t("settings.languageName", { locale: "fr" })}
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </h3>
              {children}
            </div>
          </Paper>
        </div>
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={global?.snackbar?.open || false}
          TransitionComponent={Slide}
          onClose={() => SnackbarClose(setGlobal)}
        >
          <Alert
            elevation={9}
            variant="outlined"
            severity={global?.snackbar?.severity || "success"}
            style={{ backgroundColor: "white" }}
          >
            {global?.snackbar?.message || ""}
          </Alert>
        </Snackbar>
      </div>
      <div className={styles.footer}>
        <div>
          <Link
            target="_blank"
            className={styles.footerLink}
            href={`${termsUrl}/TermsAndConditions?culture=${locale}&appId=assetcare`}
          >
            <span className={styles.footerText}>{i18n.t("sidebar.terms")}</span>
          </Link>
          <Link
            target="_blank"
            className={styles.footerLink}
            href={`${termsUrl}/privacypolicy?culture=${locale}`}
          >
            <span className={styles.footerText}>
              {i18n.t("sidebar.privacity")}
            </span>
          </Link>
        </div>
        <div className={styles.footerLink}>
          v{packageJson.version}
        </div>
      </div>
    </>
  );
}
