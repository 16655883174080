import { Paper, Tab, Tabs, Tooltip } from "@material-ui/core";
import {
  Assignment,
  Attachment,
  CalendarToday,
  MenuOpen,
} from "@material-ui/icons";
import React, { useEffect, useState, useCallback, useContext } from "react";
import i18n from "../../../locales/i18n";
import { EquiposAPI, PlantasAPI, BrandAPI } from "../../../services/API";
import Actividades from "../../../components/Actividades";
import DocumentList from "../../../components/DocumentList";
import Equipo from "../../../models/equipo";
import TabVariador from "../../../components/organism/tabVariador/tabVariador";
import TabMantenimiento from "../../../components/organism/tabMantenimiento/tabMantenimiento";
import AppContext from "../../../contexts/AppContext";
import { Common_Style, DetailTabs_Style } from "../../../assets/css/Styles";
import TabEquipoLog from "../../organism/tabEquipoLog/tabEquipoLog";
import DriveIcon from "../../atoms/driveIcon";
import AuthService from "../../../services/AuthService";
import {
  CloneProps,
  EndcustomerCanMangeThisZone,
  EndcustomerHasSomeZoneManager,
} from "../../../helpers/Common";

export default function EquiposDetails(props) {
  const { keyuser } = props;
  const { id, plantaId, zonaId } = props.match.params;
  const readOnly = props.readOnly && !keyuser;
  const isEndCustomer = AuthService.hasRoleEndCustomer();
  const hasPartialAccess = AuthService.isPartialFromPlant(plantaId);
  const isPremium = AuthService.plantPremium(plantaId);
  const locale = i18n.locale ? i18n.locale : i18n.defaultLocale;
  const colWith = props.fullWidth ? 2 : 6;
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const [equipo, setEquipo] = useState(new Equipo());
  const [zonas, setZonas] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [mantFlag, setMantFlag] = useState(false);
  const [frameChanged, setframeChanged] = useState(false);
  //Para permisos endcustomer
  const [endcustomerZonas, setEndcustomerZonas] = useState([]);
  const [readOnlyUser, setReadOnlyUser] = useState(readOnly);
  const [driveLoaded, setDriveLoaded] = useState(false);
  const [brandStored, setBrandStored] = useState(false);
  const [dirty, setDirty] = useState(false);
  //estilos
  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    window["AltidVars"] = {
      ...window["AltidVars"],
      Tabs_EquiposDetails: newValue,
    };
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "añoFabricacion" || name === "tempAmbiental") {
      if (Number(value) >= 0 && !isNaN(Number(value))) {
        setEquipo((prevEquipo) => ({ ...prevEquipo, [name]: value }));
      }
    } else {
      setEquipo((prevEquipo) => ({ ...prevEquipo, [name]: value }));
    }
    // }
    if (name === "brandId") {
      setMantFlag(true);
      setframeChanged(true);
      setEquipo((old) => ({
        ...old,
        model: undefined,
        modelId: undefined,
        frame: undefined,
        frameId: undefined,
      }));
    }
    if (name === "frame") {
      setframeChanged(true);
    }
    if (name === "cicloVida" && value === 2) {
      setEquipo((old) => ({
        ...old,
        [name]: value,
        recommendation: i18n.t("equipo.recomendacionOptions.retrofit"),
      }));
    }

    setDirty(true);
  };

  //comprobar si el endcustomer puede gestionar alguna zona de la planta
  const checkEndcustomerCanManageSomeZone = (plantId) => {
    let canManage = false;
    canManage = EndcustomerHasSomeZoneManager(plantId);

    return canManage;
  };

  //comprobar si el endcustomer puede gestionar la zona del equipo
  const endcustomerManageThisZone = (zoneId) => {
    let canManage = false;
    canManage = EndcustomerCanMangeThisZone(zoneId);

    return canManage;
  };

  const goBack = () => {
    goBackHandler();
  };

  const goBackHandler = () => {
    if (props.history.length > 1) {
      props.history.goBack();
    } else {
      if (zonaId) {
        props.history.replace({
          pathname: `/plantas/${plantaId}/zonas/${zonaId}`,
          state: { tabZonaValue: 2 },
        });
      } else {
        props.history.replace({
          pathname: `/plantas/${plantaId}`,
          state: { tabPlantaValue: 2 },
        });
      }
    }
  };

  const getEquipo = useCallback(async () => {
    await EquiposAPI.get(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setEquipo({ ...data, int: data.in });
        setTabValue(data.ioTDeviceId ? 1 : 0);
        setBrandStored(data.brand ? true : false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setDriveLoaded(true);
      });
  }, [id]);

  const getZonas = useCallback(async () => {
    return PlantasAPI.getZonas(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setZonas(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [plantaId]);

  //carga solo las zonas que el endcustomer puede gestionar
  const endcustomerZones = useCallback(async () => {
    return PlantasAPI.getEndcustomerZonas(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setEndcustomerZonas(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [plantaId]);

  const getBrands = useCallback(async () => {
    return BrandAPI.get()
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(
            "Something went wrong on EquiposDetails -> getBrands()"
          );
        }
      })
      .then((data) => {
        setBrands(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
    if (
      window["AltidVars"] !== undefined &&
      window["AltidVars"].Tabs_EquiposDetails !== undefined
    ) {
      setTabValue(window["AltidVars"].Tabs_EquiposDetails);
    }
  }, []);

  useEffect(() => {
    setGlobal((prev) => ({ ...prev, pageTitle: i18n.t("equipo.titulo") }));
  }, [setGlobal]);

  useEffect(() => {
    setLoading(true);
    let loading = 3;

    Promise.all([getBrands()]).then(() => --loading === 0 && setLoading(false));
    Promise.all([getZonas()]).then(() => --loading === 0 && setLoading(false));

    //llamada a las zonas del endcustomer
    if (isEndCustomer) {
      loading = loading + 1;
      Promise.all([endcustomerZones()]).then(
        () => --loading === 0 && setLoading(false)
      );
    }

    if (id) {
      loading = loading + 1;
      Promise.all([getEquipo()]).then(
        () => --loading === 0 && setLoading(false)
      );
    } else {
      --loading === 0 && setLoading(false);
      setDriveLoaded(true);
    }
  }, [id, getZonas, getEquipo, getBrands, isEndCustomer, endcustomerZones]);

  useEffect(() => {
    if (zonas.length && zonaId) {
      setEquipo((prevEquipo) => ({ ...prevEquipo, zonaId: zonaId }));
    }
  }, [zonaId, zonas]);

  //comprobar si el endcustomer puede gestionar la zona
  useEffect(() => {
    if (isEndCustomer && driveLoaded) {
      let canManage = false;
      //Es un nuevo equipo
      if (!equipo?.id) {
        canManage = checkEndcustomerCanManageSomeZone(plantaId);
        setReadOnlyUser(!canManage);
      }

      //Equipo existe
      if (equipo.id) {
        //zona de equipo o de la planta
        const zoneToCheck = zonaId || equipo.zonaId;
        if (zoneToCheck) {
          canManage = endcustomerManageThisZone(zoneToCheck);
          setReadOnlyUser(!canManage);
        }
      }
    }
  }, [driveLoaded, equipo, isEndCustomer, plantaId, zonaId]);

  return (
    <div className={commonStyles.mainContainer}>
      <Paper className={styles.plantPaper} square>
        <Tabs
          scrollButtons="on"
          variant="scrollable"
          indicatorColor="secondary"
          textColor="inherit"
          classes={{ indicator: styles.tabsHeader }}
          value={tabValue}
          onChange={handleChangeTab}
        >
          <Tab
            label={
              <span className={styles.detailTabLabel}>
                {i18n.t("equipo.itemTitle")}
              </span>
            }
            icon={<DriveIcon color={"#212121"} />}
            id="simple-tab-0"
          />
          {id && (
            <CloneProps value={1}>
              {(tabProps) => (
                <Tooltip
                  title={i18n.t("common.isPremium")}
                  placement={"bottom"}
                  disableHoverListener={isPremium}
                  arrow
                >
                  <div className="custom-tab">
                    <Tab
                      {...tabProps}
                      label={
                        <span className={styles.detailTabLabel}>
                          {i18n.t("maintenance.title")}
                        </span>
                      }
                      onClick={(e) => handleChangeTab(e, 1)}
                      disabled={!isPremium}
                      icon={<CalendarToday />}
                      id="simple-tab-1"
                    />
                  </div>
                </Tooltip>
              )}
            </CloneProps>
          )}
          {id && (
            <Tab
              label={
                <span className={styles.detailTabLabel}>
                  {i18n.t("common.documentos")}
                </span>
              }
              icon={<Attachment />}
              id="simple-tab-2"
            />
          )}
          {id && (
            <Tab
              label={
                <span className={styles.detailTabLabel}>
                  {i18n.t("actividades.titulo")}
                </span>
              }
              icon={<Assignment />}
              id="simple-tab-3"
            />
          )}
          {id && !isEndCustomer && (
            <Tab
              label={
                <span className={styles.detailTabLabel}>
                  {i18n.t("logs.titulo")}
                </span>
              }
              icon={<MenuOpen />}
              id="simple-tab-4"
            />
          )}
        </Tabs>
      </Paper>
      {tabValue === 0 && (
        <TabVariador
          id={id}
          locale={locale}
          readOnly={readOnlyUser}
          hasPartialAccess={hasPartialAccess}
          isPremium={isPremium}
          keyuser={keyuser}
          zonas={zonas}
          brands={brands}
          plantaId={props.match.params.plantaId}
          equipo={equipo}
          setEquipo={setEquipo}
          getEquipo={getEquipo}
          errors={errors}
          setErrors={setErrors}
          loading={loading}
          setLoading={setLoading}
          goBack={goBack}
          handleInputChange={handleInputChange}
          setBrandStored={setBrandStored}
          setDirty={setDirty}
          history={props.history}
          setframeChanged={setframeChanged}
          endcustomerZonas={endcustomerZonas}
          isEndCustomer={isEndCustomer}
        />
      )}
      {tabValue === 1 && id && (
        <TabMantenimiento
          id={id}
          equipo={equipo}
          setEquipo={setEquipo}
          readOnly={readOnlyUser || isEndCustomer}
          isPremium={isPremium}
          brandStored={brandStored}
          colWith={colWith}
          goBack={goBack}
          loading={loading}
          setLoading={setLoading}
          errors={errors}
          setErrors={setErrors}
          mantFlag={mantFlag}
          setMantFlag={setMantFlag}
          frameChanged={frameChanged}
        />
      )}
      {tabValue === 2 && (
        <DocumentList
          equipo={equipo}
          goBack={goBack}
          readOnly={readOnlyUser}
          hasPartialAccess={hasPartialAccess}
          isPremium={isPremium}
        />
      )}
      {tabValue === 3 && (
        <Actividades
          id={equipo.id}
          equipoActividades={equipo.id ? equipo.equipoActividades : []}
          readOnly={readOnlyUser}
          goBack={goBack}
        />
      )}
      {tabValue === 4 && (
        <TabEquipoLog {...props} equipmentId={id} plantaId={plantaId} />
      )}
    </div>
  );
}
