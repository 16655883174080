import {
  Avatar,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import React, { useEffect, useState, useContext, useCallback } from "react";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import i18n from "../../../locales/i18n";
import { ClientesSolicitudesAPI, UsersAPI } from "../../../services/API";
import SearchField from "../../molecule/SearchField/SearchField";
import moment from "moment";
import withHover from "../../../components/withHover";
import AppContext from "../../../contexts/AppContext";
import { CardList_Style, Common_Style } from "../../../assets/css/Styles";
import AuthService from "../../../services/AuthService";
import InfiniteScroll from "react-infinite-scroll-component";
import ListFilter from "../../molecule/ListFilter/ListFilter";
const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

export default function UsersList(props) {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const [solicitudes, setSolicitudes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMoreRequest, setHasMoreRequest] = useState(true);
  const [isFirstInput, setIsFirstInput] = useState(false);
  //Para lista de usuarios
  const [statusFilter, setStatusFilter] = useState(4);
  const [users, setUsers] = useState([]);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);
  const [requestNumber, setRequestNumber] = useState(0);
  const [userFilter, setUserFilter] = useState({
    usersRendered: 0,
    searchQuery: "",
    countryId: 0,
  });
  //Access calculation
  const userInfo = AuthService.getUserInfo();
  const isReadOnlyDsc = userInfo.isGlobal || userInfo.isReadOnly;
  const currentUserHasRolePartner = AuthService.hasRolePartner();
  const currentUserHasRoleEndCustomer = AuthService.hasRoleEndCustomer();
  if (currentUserHasRoleEndCustomer) {
    window.location.replace("/unauthorized");
  }
  const hasCountryFilter =
    !currentUserHasRoleEndCustomer && !currentUserHasRolePartner;
  const hasUserFilter = !isReadOnlyDsc;
  //styles
  const styles = CardList_Style();
  const commonStyles = Common_Style();

  const getNumberOfRequests = useCallback(() => {
    ClientesSolicitudesAPI.getCount()
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.statusText);
        }
      })
      .then((data) => {
        setRequestNumber(data.requestNumber);
      })
      .catch((err) => {
        console.error("Get request error", err);
      });
  }, []);

  //getUsers
  const GetUsers = useCallback((userFilter, isNewQuery) => {
    setLoading(true);
    if (!userFilter) {
      userFilter = {
        usersRendered: 0,
        searchQuery: "",
        countryId: 0,
      };
    }
    UsersAPI.getInfiniteUsers(userFilter)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then(({ users, inactivos, userAccessPermissions, hasMoreUsers }) => {
        inactivos.forEach((inactiveUser) => {
          users.forEach((usuario) => {
            if (usuario.id === inactiveUser.id) {
              let userId = users.indexOf(usuario);
              users[userId] = { ...usuario, inactivo: true };
            }
          });
        });
        if (isNewQuery) {
          setUsers(users);
        } else {
          setUsers((old) => old.concat(users));
        }
        //actualizar el total de usuarios
        let totalUsers =
          users.length + inactivos.length + userFilter.usersRendered;
        setUserFilter({ ...userFilter, usersRendered: totalUsers });
        setHasMoreUsers(hasMoreUsers);

        AuthService.updateUserAccessPermisions(userAccessPermissions);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  // Get Solicitudes
  const getSolicitudes = useCallback((userFilter, statusFilter, isNewQuery) => {
    if (statusFilter === 4) {
      return;
    }
    if (!userFilter) {
      userFilter = {
        usersRendered: 0,
        searchQuery: "",
        countryId: 0,
      };
    }
    setLoading(true);
    ClientesSolicitudesAPI.getInfiniteRequest(
      statusFilter.toString(),
      userFilter
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        if (isNewQuery) {
          setSolicitudes(data.clientRequests);
        } else {
          setSolicitudes((old) => old.concat(data.clientRequests));
        }
        setHasMoreRequest(data.hasMoreRequest);
        //actualizar el total de usuarios
        const totalUsers =
          data.clientRequests.length + userFilter.usersRendered;
        setUserFilter({
          ...userFilter,
          usersRendered: totalUsers,
        });
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  //delete Solicitudes
  const deleteClienteSolicitud = async (id) => {
    setLoading(true);
    await ClientesSolicitudesAPI.remove(id)
      .then(async (response) => {
        if (response.ok) {
          setSolicitudes((prev) => prev.filter((s) => s.id !== id));
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
    getNumberOfRequests();
    setLoading(false);
  };

  //accept Solicitudes
  const acceptRequest = (solicitud) => {
    setGlobal((prev) => ({ ...prev, userRequest: solicitud }));
    props.history.push(`/newuser`);
  };

  //reject Solicitudes
  const rejectRequest = async (id) => {
    setLoading(true);
    await ClientesSolicitudesAPI.reject(id)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .then((data) => {
        const index = solicitudes.findIndex((s) => s.id === id);
        const solicitudesNew = [...solicitudes];
        data.name = solicitudes[index].name;
        data.surname = solicitudes[index].surname;
        data.mail = solicitudes[index].mail;
        data.country = solicitudes[index].country;
        solicitudesNew[index] = data;
        setSolicitudes(solicitudesNew);
        setGlobal((prev) => ({ ...prev, update: true }));
      })
      .catch((error) => {
        alert(error.message);
      });
    getNumberOfRequests();
    setLoading(false);
  };

  //lista por defecto
  const handleClearFilter = () => {
    setSolicitudes([]);
    setUsers([]);
    setStatusFilter(4);
    GetUsers(null, true);
  };

  //aplicar filtros a lista
  const handleApplyFilter = (genericFilter) => {
    setSolicitudes([]);
    setUsers([]);

    const filter = genericFilter.statusFilter;
    const countryId = genericFilter.countryId || 0;

    const newUserFilter = {
      usersRendered: 0,
      searchQuery: userFilter.searchQuery,
      countryId: countryId,
    };

    setStatusFilter(filter);
    setUserFilter(newUserFilter);

    if (filter === 4) {
      GetUsers(newUserFilter, true);
    } else {
      getSolicitudes(newUserFilter, filter, true);
    }
  };

  //Busqueda con delay
  useEffect(() => {
    const searchDelay = setTimeout(() => {
      if (isFirstInput) {
        setSolicitudes([]);
        setUsers([]);
        if (statusFilter === 4) {
          GetUsers(userFilter, true);
        } else {
          getSolicitudes(userFilter, statusFilter, true);
        }
        setIsFirstInput(false);
      }
    }, 600);
    return () => clearTimeout(searchDelay);
  }, [getSolicitudes, isFirstInput, statusFilter, GetUsers, userFilter]);

  //Titulo de la pagina
  useEffect(() => {
    setGlobal((prev) => ({
      ...prev,
      pageTitle: i18n.t("gestorUsuarios.titulo"),
    }));
    document.getElementById("main-content").scrollTop = 0;
  }, [setGlobal]);

  //primer getUsuarios al entrar a la pagina
  useEffect(() => {
    GetUsers();
    getNumberOfRequests();
  }, [GetUsers, getNumberOfRequests]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <div className={commonStyles.mainContainer}>
        <div className={commonStyles.searchFieldContainer}>
          <div className={commonStyles.searchField}>
            <SearchField
              inputBaseProps={{
                placeholder: i18n.t("common.search"),
                value: userFilter.searchQuery,
                onChange: (event) => {
                  setIsFirstInput(true);
                  setUserFilter({
                    ...userFilter,
                    usersRendered: 0,
                    searchQuery: event.target.value,
                  });
                },
              }}
            />
            {!isReadOnlyDsc && (
              <Badge
                badgeContent={requestNumber}
                className={styles.userFilterBadge}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <ListFilter
                  filterTitle={i18n.t("registros.filterregisters.title")}
                  //paises
                  hasCountryFilter={hasCountryFilter}
                  countryId={userFilter.countryId}
                  //usuarios
                  hasUserFilter={hasUserFilter}
                  requestNumber={requestNumber}
                  statusFilter={statusFilter}
                  //funciones
                  handleClearFilter={handleClearFilter}
                  handleApplyFilter={handleApplyFilter}
                />
                <Badge
                  badgeContent={""}
                  variant="dot"
                  //mostrar cuando haya algun filtro
                  invisible={statusFilter === 4 && !userFilter.countryId}
                  className={styles.filterBadge}
                />
              </Badge>
            )}
          </div>
        </div>

        {statusFilter === 4 ? (
          //Lista de Usuarios en la aplicacion
          <InfiniteScroll
            scrollableTarget="main-content"
            className={styles.requestAccessCardContainer}
            style={{ overflow: "ignore" }}
            dataLength={users.length}
            next={() => GetUsers(userFilter, false)}
            hasMore={hasMoreUsers}
            loader
          >
            {users.map((user, indx) => (
              <UserCard {...user} {...props} key={indx} />
            ))}
          </InfiniteScroll>
        ) : (
          //Lista de Solicitudes
          <InfiniteScroll
            scrollableTarget="main-content"
            className={styles.requestAccessCardContainer}
            style={{ overflow: "ignore" }}
            dataLength={solicitudes.length}
            next={() => getSolicitudes(userFilter, statusFilter, false)}
            hasMore={hasMoreRequest}
            loader
          >
            {solicitudes.length > 0 &&
              solicitudes.map((solicitud) => (
                <SolicitudCard
                  key={solicitud.id}
                  onReject={() => rejectRequest(solicitud.id)}
                  onDelete={() => deleteClienteSolicitud(solicitud.id)}
                  onAccept={() => acceptRequest(solicitud)}
                  {...solicitud}
                  {...props}
                />
              ))}
          </InfiniteScroll>
        )}
      </div>
    </RelativeBackdrop>
  );
}

//Funcion para mostrar las cards usuarios loggeados
function UserCard(props) {
  const { nombre, apellidos, roleNotIncluded, country, email, id, inactivo } =
    props;
  const styles = CardList_Style();
  const avatarText =
    nombre && apellidos ? nombre.charAt(0) + apellidos.charAt(0) : null;

  return (
    <CardWithHover
      className={inactivo ? styles.userCardDissabled : styles.userCard}
      onClick={() => props.history.push(`./user/${id}`)}
      data-cy="CardInfo"
    >
      <CardHeader
        title={`${nombre} ${apellidos}`}
        classes={{ content: "overflow-hidden" }}
        titleTypographyProps={{ noWrap: true, variant: "body2" }}
        subheaderTypographyProps={{ noWrap: true, variant: "body2" }}
        subheader={email}
        avatar={
          <Avatar className={styles.tabHeaderAvatar}>{avatarText}</Avatar>
        }
      />
      <CardContent className={styles.userCardContent}>
        <div>
          <div className={styles.userCardPropietyText}>
            {i18n.t("user.pais") + ":"}
          </div>
          <div className={styles.userCardDataText}>
            {i18n.t(`country.${country?.name || "null"}`)}
          </div>
        </div>
        <div>
          <div className={styles.userCardPropietyText}>
            {i18n.t("user.region") + ":"}
          </div>
          <div className={styles.userCardDataText}>
            {country?.region?.regionCode}
          </div>
        </div>
        <div>
          <div className={styles.userCardPropietyText}>
            {i18n.t("registros.level") + ":"}
          </div>
          <div className={styles.userCardDataText}>{roleNotIncluded}</div>
        </div>
      </CardContent>
    </CardWithHover>
  );
}

//Funcion para mostrar las cards solicitudes
function SolicitudCard(props) {
  const {
    company,
    country,
    level,
    status,
    name,
    surname,
    mail,
    onReject,
    requestDate,
    onAccept,
    actionDate,
    actionUser,
  } = props;

  const region = country.region.regionCode;

  const styles = CardList_Style();
  const commonStyles = Common_Style();

  const abbreviatonLetters = company
    ? company.split(" ")
    : (name + " " + surname).split(" ");
  const abbreviaton =
    abbreviatonLetters.length > 1
      ? (abbreviatonLetters[0][0] + abbreviatonLetters[1][0]).toUpperCase()
      : company.substr(0, 2).toUpperCase();

  return (
    <CardWithHover className={styles.requestAccessCard} data-cy="CardInfo">
      <CardHeader
        classes={{ content: "overflow-hidden" }}
        titleTypographyProps={{ noWrap: true }}
        subheaderTypographyProps={{ noWrap: true }}
        title={`${name} ${surname}`}
        subheader={status.status}
        avatar={
          <Avatar className={styles.tabHeaderAvatar}>{abbreviaton}</Avatar>
        }
      />
      <CardContent className={styles.userCardContent}>
        <div>
          <div className={styles.userCardPropietyText}>
            {i18n.t("user.email") + ":"}
          </div>
          <div className={styles.userCardDataText}>{mail}</div>
        </div>
        <div>
          <div className={styles.userCardPropietyText}>
            {i18n.t("user.empresa") + ":"}
          </div>
          <div className={styles.userCardDataText}>{company}</div>
        </div>
        <div>
          <div className={styles.userCardPropietyText}>
            {i18n.t("registros.level") + ":"}
          </div>
          <div className={styles.userCardDataText}>{level}</div>
        </div>
        <div>
          <div className={styles.userCardPropietyText}>
            {i18n.t("user.pais") + ":"}
          </div>
          <div className={styles.userCardDataText}>
            {i18n.t(`country.${country.name || "null"}`)}
          </div>
        </div>
        <div>
          <div className={styles.userCardPropietyText}>
            {i18n.t("user.region") + ":"}
          </div>
          <div className={styles.userCardDataText}>{region}</div>
        </div>
        <div>
          <div className={styles.userCardPropietyText}>
            {i18n.t("user.fecha") + ":"}
          </div>
          <div className={styles.userCardDataText}>
            {moment(requestDate).format("DD-MM-YYYY")}
          </div>
        </div>

        {status.id !== 1 && (
          <>
            <div>
              <div className={styles.userCardPropietyText}>
                {i18n.t("registros.actionDate") + ":"}
              </div>
              <div className={styles.userCardDataText}>
                {moment(actionDate).format("DD-MM-YYYY")}
              </div>
            </div>
            <div>
              <div className={styles.userCardPropietyText}>
                {i18n.t("registros.actionUser") + ":"}
              </div>
              <div className={styles.userCardDataText}>
                {actionUser?.userName}
              </div>
            </div>
          </>
        )}
      </CardContent>
      <CardActions>
        {status.id !== 2 && (
          <Button
            className={commonStyles.primaryButton}
            variant="contained"
            onClick={(event) => {
              event.stopPropagation();
              onAccept();
            }}
          >
            {i18n.t("registros.accept")}
          </Button>
        )}
        {status.id === 1 && (
          <Button
            variant="outlined"
            className={styles.secondaryButton}
            color="secondary"
            onClick={(event) => {
              event.stopPropagation();
              onReject();
            }}
          >
            {i18n.t("registros.reject")}
          </Button>
        )}
      </CardActions>
    </CardWithHover>
  );
}
