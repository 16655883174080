import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getPlantasForSelector() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/selector`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getPlantasByPartnerForSelector(partnerId, clientId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/plantas/partner/${partnerId}/selector${
      clientId ? "?clientId=" + clientId : ""
    }`,
    {
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );
}

export function getWithUserAccessPermisions() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/withUserAccessPermisions`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getWithUserAccessPermisionsLight() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/withUserAccessPermisionsLight`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getInfinitePlants(plantFilter) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/infinitePlants`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(plantFilter),
  });
}

export function getZonas(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}/zonas`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getEndcustomerZonas(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}/zonas/endcustomer`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getUsuariosPlanta(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}/usuarios`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function add(planta) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(planta),
  });
}

export function addMirrorPalntByClient(clientId, partnerId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/plantas/plantByClient/${clientId}/${partnerId}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    }
  );
}

export function update(planta, saleforceIdChanged) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${planta.id}/${saleforceIdChanged}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(planta),
  });
}

export function changeClient(plantId, clientId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${plantId}/client/${clientId}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function changeClientMassive(clientId, plantIdList) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/client/${clientId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(plantIdList),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function downloadMantenimientosPrevistos(id, locale) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}/mantenimientosPrevistos`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ locale: locale }),
  });
}

export function updateLifeCycleOfDrives(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}/updatelifecycle`, {
    method: "POST",
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getFilters(plantId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${plantId}/filters`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getPlantaInfoBySalesForce(salesforceId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/salesforceId/${salesforceId}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getPlantLogs(plantId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${plantId}/logs`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}
