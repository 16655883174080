import {
  Avatar,
  Button,
  CardHeader,
  CircularProgress,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  FormControlLabel,
  Checkbox,
  Menu,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  Business,
  Delete,
  Group,
  Save,
  Send,
  AddCircleOutline,
  Cancel,
  Domain,
  InfoOutlined,
  ReportProblemOutlined,
  Flag,
  Link,
  MoreVert,
} from "@material-ui/icons";
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import AlertDialog from "../../../components/AlertDialog";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import SearchField from "../../molecule/SearchField/SearchField";
import i18n from "../../../locales/i18n";
import { ClientesAPI, PartnersAPI, PlantasAPI } from "../../../services/API";
import { filter } from "../../../helpers/Filter";
import AuthService from "../../../services/AuthService";
import CardPlantClient from "../../../components/molecule/cardPlantClient/cardPlantClient";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  SnackbarSuccess,
  SnackbarError,
  SnackbarWarning,
} from "../../../helpers/Common";
import Cliente from "../../../models/cliente";
import ClienteBase from "../../../models/clienteBase";
import Partner from "../../../models/partner";
import AppContext from "../../../contexts/AppContext";
import {
  DetailTabs_Style,
  Common_Style,
  globalStatusColors,
  CardList_Style,
} from "../../../assets/css/Styles";
import moment from "moment";
import ClientLinkPlantsDialog from "../../molecule/clientLinkPlantsDialog/ClientLinkPlantsDialog";

export default function ClientFormInList(props) {
  const {
    clientId,
    partnerId,
    history,
    keyuser,
    readOnly,
    isGestor,
    countries,
    clientDeleted,
    clientAdded,
    clientUpdated,
    notFound,
  } = props;
  {
    const isDSC = AuthService.hasRoleDSC();
    const isPartner = AuthService.hasRolePartner();
    const currentUserHasRoleEndCustomer = AuthService.hasRoleEndCustomer();
    const [cliente, setCliente] = useState(new Cliente({}));
    const [loading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [plantasSearch, setPlantasSearch] = useState("");
    const [partnersList, setPartnersList] = useState([]);
    const [errors, setErrors] = useState({});
    const [salefoceLength, setSalefoceLength] = useState(0);
    const canEdit = !readOnly;
    const [partner, setPartner] = useState(new Partner({}));
    const [infoBySalesforce, setInfoBySalesforce] = useState(false);
    const [provinceEmpty, setProvinceEmpty] = useState(false);
    const [salesforceBtnLoading, setSalesforceBtnLoading] = useState(false);
    const [salesforceIdChanged, setSalesforceIdChanged] = useState(false);
    const [auxValue, setAuxValue] = useState(undefined);
    const [actionsAnchor, setActionsAnchor] = useState(null);

    const linkPlantsDialogRef = useRef(null);
    const [openLinkPlantsDialog, setOpenLinkPlantsDialog] = useState(false);

    // @ts-ignore
    const { setGlobal } = useContext(AppContext);
    const deleteDialog = useRef(null);
    const cantDeleteDialog = useRef(null);
    const clientExistDialog = useRef(null);
    const clientEditedExistDialog = useRef(null);
    const createMirrorPlantDialog = useRef(null);
    const changeDialog = useRef(null);
    const styles = DetailTabs_Style();
    const commonStyles = Common_Style();
    const cardListStyles = CardList_Style();
    const fromPartner = partnerId ? true : false;

    const handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      if (name === "salesforceId") {
        setSalefoceLength(value.length);
      }
      if (name === "isDanfossOwnedClient") {
        setCliente((prevCliente) => ({
          ...prevCliente,
          [name]: value,
          isPremium: false,
          isTryout: false,
        }));
      } else if (name === "premiumStartDate") {
        var startDate = moment(value).format("YYYY-MM-DD");
        var endDate = moment(value).add(1, "years").format("YYYY-MM-DD");
        setCliente((prevCliente) => ({
          ...prevCliente,
          premiumStartDate: startDate,
          premiumEndDate: endDate,
        }));
      } else if (name === "isPremium") {
        setCliente((prevCliente) => ({
          ...prevCliente,
          [name]: value,
          premiumStartDate: prevCliente.premiumStartDate
            ? prevCliente.premiumStartDate
            : value
            ? moment().format("YYYY-MM-DD")
            : undefined,
          premiumEndDate: prevCliente.premiumEndDate
            ? prevCliente.premiumEndDate
            : value
            ? moment().add(1, "years").format("YYYY-MM-DD")
            : undefined,
        }));
      } else if (name === "isTryout") {
        var tryoutStart = moment();
        var tryoutEnd = moment(tryoutStart).add(4, "months");
        var premiumStart = moment(tryoutEnd).add(1, "days");
        var premiumEnd = moment(premiumStart).add(1, "years");
        setCliente((prevCliente) => ({
          ...prevCliente,
          [name]: value,
          tryoutStartDate: prevCliente.tryoutStartDate
            ? prevCliente.tryoutStartDate
            : value
            ? tryoutStart.format("YYYY-MM-DD")
            : undefined,
          tryoutEndDate: prevCliente.tryoutEndDate
            ? prevCliente.tryoutEndDate
            : value
            ? tryoutEnd.format("YYYY-MM-DD")
            : undefined,
          premiumStartDate: prevCliente.premiumStartDate
            ? prevCliente.premiumStartDate
            : value
            ? premiumStart.format("YYYY-MM-DD")
            : undefined,
          premiumEndDate: prevCliente.premiumEndDate
            ? prevCliente.premiumEndDate
            : value
            ? premiumEnd.format("YYYY-MM-DD")
            : undefined,
        }));
      } else if (name === "tryoutStartDate") {
        var trystartDate = moment(value).format("YYYY-MM-DD");
        var tryendDate = moment(value).add(4, "months").format("YYYY-MM-DD");
        setCliente((prevCliente) => ({
          ...prevCliente,
          tryoutStartDate: trystartDate,
          tryoutEndDate: tryendDate,
        }));
      } else if (name === "tryoutEndDate") {
        setAuxValue(value);
        changeDialog.current.open();
      } else {
        setCliente((prevCliente) => ({ ...prevCliente, [name]: value }));
      }
    };

    const handleChangeTab = (event, newValue) => {
      setTabValue(newValue);
      window["AltidVars"] = {
        ...window["AltidVars"],
        Tabs_ClientesDetails: newValue,
      };
    };

    const getCliente = useCallback(async () => {
      setLoading(true);
      ClientesAPI.get(clientId.toString())
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          if (data.id) {
            setCliente(data);
            setLoading(false);
          } else {
            SnackbarError(setGlobal, i18n.t("cliente.snackbar.update.error"));
            throw new Error("This client does not exist");
          }
        })
        .catch((error) => {
          history.push("/partnersclients");
          setLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId]);

    const addCliente = async () => {
      if (cliente?.salesforceId?.length !== 18) {
        setErrors({ salesforceIBadLength: true });
        return;
      }
      setLoading(true);
      const clientB = new ClienteBase(cliente);
      if (!keyuser && !isDSC) {
        clientB.partnerId = AuthService.getPartnerId();
      }
      ClientesAPI.add(clientB)
        .then(async (response) => {
          if (response.ok) {
            SnackbarSuccess(setGlobal, i18n.t("cliente.snackbar.new.success"));
            return response.json();
          } else {
            var json = await response.json();
            if (json?.errors) {
              setErrors(json.errors);
            }
            if (json.error === "The SaleforceId is already in use") {
              clientExistDialog.current.open();
            }
            SnackbarError(setGlobal, i18n.t("cliente.snackbar.new.error"));
          }
          setLoading(false);
        })
        .then((data) => {
          // add the access to the new client
          AuthService.addTempAccessToClient(data.partnerId, data.id);
          clientAdded(data.partnerId, data);
          setErrors({});
          setLoading(false);
        })
        .catch((error) => {
          SnackbarError(setGlobal, i18n.t("cliente.snackbar.new.error"));
          setLoading(false);
          console.error("Error:", error);
        });
    };

    const updateCliente = async () => {
      if (cliente?.salesforceId?.length !== 18) {
        setErrors({ salesforceIBadLength: true });
        return;
      }
      setLoading(true);
      const clientB = new ClienteBase(cliente);
      ClientesAPI.update(clientB, salesforceIdChanged)
        .then(async (response) => {
          if (response.ok) {
            //All right
            SnackbarSuccess(
              setGlobal,
              i18n.t("cliente.snackbar.update.success")
            );
            setErrors({});
            clientUpdated(clientB.partnerId, clientB);
          } else {
            if (response.status === 404) {
              history.push("/partnersclients");
              SnackbarError(setGlobal, i18n.t("partner.snackbar.update.error"));
            } else {
              var json = await response.json();
              if (json.error === "The SaleforceId is already in use") {
                clientExistDialog.current.open();
              }
              if (json?.errors) {
                setErrors(json.errors);
              }
              SnackbarError(setGlobal, i18n.t("cliente.snackbar.update.error"));
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          SnackbarError(setGlobal, i18n.t("cliente.snackbar.update.error"));
          setLoading(false);
        });
    };

    const deleteCliente = async () => {
      if ((keyuser || isDSC) && !partnerId) {
        deleteDialog.current.close();
        cantDeleteDialog.current.open();
      } else if (keyuser && partnerId) {
        setLoading(true);
        ClientesAPI.removeFromPartner(clientId, partnerId)
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 404) {
                history.push("/partnersclients");
              }
              throw new Error("Something went wrong");
            }
          })
          .then((data) => {
            showDeleteStatus(data);
          })
          .catch((error) => {
            SnackbarError(setGlobal, i18n.t("cliente.snackbar.deleted.error"));
            setLoading(false);
          });
      } else {
        setLoading(true);
        ClientesAPI.remove(clientId)
          .then((response) => {
            if (response.ok) {
              SnackbarSuccess(
                setGlobal,
                i18n.t("cliente.snackbar.deleted.success")
              );
              return response.json();
            } else {
              if (response.status === 404) {
                console.error("Client not found");
                history.push("/partnersclients");
              }
              throw new Error("Something went wrong");
            }
          })
          .then((data) => {
            showDeleteStatus(data);
          })
          .catch((error) => {
            SnackbarError(setGlobal, i18n.t("cliente.snackbar.deleted.error"));
            setLoading(false);
          });
      }
    };

    const addMirrorPlant = async () => {
      const partnerId = AuthService.getPartnerId();
      setLoading(true);
      PlantasAPI.addMirrorPalntByClient(cliente.id, partnerId)
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          } else {
            var json = await response.json();
            if (json?.errors) {
              setErrors(json.errors);
            }
            if (json?.errors?.salesforcePlantIdDuplicated) {
              SnackbarError(
                setGlobal,
                i18n.t("cliente.salesforcePlantIdDuplicated")
              );
            } else if (json?.errors?.salesforceIdDuplicated) {
              SnackbarError(
                setGlobal,
                i18n.t("cliente.salesforceIdDuplicated")
              );
            } else if (json?.errors?.salesforceRegistered) {
              SnackbarError(setGlobal, i18n.t("cliente.salesforceRegistered"));
            } else {
              SnackbarError(setGlobal, i18n.t("planta.snackbar.new.error"));
            }
            if (createMirrorPlantDialog.current) {
              createMirrorPlantDialog.current.close();
            }
          }
        })
        .then((dataPlanta) => {
          if (dataPlanta) {
            if (createMirrorPlantDialog.current) {
              createMirrorPlantDialog.current.close();
            }
            SnackbarSuccess(setGlobal, i18n.t("planta.snackbar.new.success"));
            AuthService.addTempAccessToPlant(dataPlanta.id);
            if (dataPlanta?.id) {
              history.push(`/plantas/${dataPlanta.id}`);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("error", error);
          SnackbarError(setGlobal, i18n.t("planta.snackbar.new.error"));
          setLoading(false);
          if (createMirrorPlantDialog.current) {
            createMirrorPlantDialog.current.close();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const showDeleteStatus = (data) => {
      if (data.status === "Ok") {
        SnackbarSuccess(setGlobal, i18n.t("cliente.snackbar.deleted.success"));
        clientDeleted(cliente.partnerId, cliente.id);
      } else {
        SnackbarError(setGlobal, i18n.t("cliente.snackbar.deleted.error"));
        setLoading(false);
      }
      if (deleteDialog.current) {
        deleteDialog.current.close();
      }
    };

    const handleClientInfoBySalesforce = (newValues) => {
      let objAux = {};
      const newValuesKeys = Object.keys(newValues);
      for (let i = 0; i <= newValuesKeys.length; i++) {
        const name = newValuesKeys[i];
        objAux = { ...objAux, [name]: newValues[name] };
      }
      setCliente((prevState) => ({
        ...prevState,
        ...objAux,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        RazonSocial: undefined,
        Direccion: undefined,
        CP: undefined,
        Poblacion: undefined,
        Provincia: undefined,
        CountryId: undefined,
      }));
    };

    const btnEvent_checkSalesforceId = () => {
      if (cliente?.salesforceId?.length !== 18) {
        setErrors({ salesforceIBadLength: true });
        return;
      }
      if (!cliente.salesforceId) {
        setInfoBySalesforce(false);
      } else {
        setSalesforceBtnLoading(true);
        // Obtenr la info de Salesforce
        ClientesAPI.getClientInfoBySalesForce(cliente.salesforceId)
          .then(async (response) => {
            if (response.ok) {
              const data = await response.json();
              // Here set data into planta object
              handleClientInfoBySalesforce({
                infoBySalesforce: true,
                razonSocial: data.razonSocial,
                direccion: data.direccion,
                cp: data.cp,
                poblacion: data.poblacion,
                provincia: data.provincia,
                countryId: data.countryId,
              });
              setProvinceEmpty(
                !data.provincia || data.provincia?.trim() === ""
              );
              setSalesforceBtnLoading(false);
              SnackbarSuccess(
                setGlobal,
                i18n.t("planta.info_from_salesforce_found")
              );
              setSalesforceBtnLoading(false);
            } else if (response.status === 404) {
              // Not found
              handleClientInfoBySalesforce({
                infoBySalesforce: false,
              });
              SnackbarWarning(
                setGlobal,
                i18n.t("planta.info_from_salesforce_not_found")
              );
              setSalesforceBtnLoading(false);
            } else {
              var json = await response.json();
              if (json?.errors) {
                setErrors(json.errors);
              } else {
                console.error("Error:", response);
                throw new Error("Something went wrong");
              }
            }
          })
          .catch((error) => {
            console.error(error);
            setSalesforceBtnLoading(false);
          })
          .finally(() => {
            setSalesforceBtnLoading(false);
          });
      }
    };

    const handleInputChangeSalesforceId = (event) => {
      let inputValue = event.target.value;
      event.target.value = inputValue
        .replace(/\s+/g, "")
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .trim();
      setSalesforceIdChanged(true);
      handleInputChange(event);
    };

    const resetCliente = () => {
      if (clientId) {
        getCliente();
      } else {
        setCliente(new Cliente({ partnerId: partnerId }));
      }
      setSalefoceLength(0);
      setErrors({});
    };

    //Cliente ya existe
    useEffect(() => {
      if (clientId) {
        if (
          window["AltidVars"] !== undefined &&
          window["AltidVars"].Tabs_ClientesDetails !== undefined
        ) {
          setTabValue(window["AltidVars"].Tabs_ClientesDetails);
        }
        getCliente();
        document.getElementById("main-content").scrollTop = 0;
      }
    }, [clientId, getCliente]);

    //Nuevo CLiente
    useEffect(() => {
      if (!clientId) {
        //resetear tab de formulario
        setTabValue(0);
        document.getElementById("main-content").scrollTop = 0;
        //viene desde partner
        if (partnerId) {
          setCliente(new Cliente({ partnerId: partnerId }));
        } else {
          //nuevo cliente desde lista de clientes
          setCliente(new Cliente({}));
          setPartner(new Partner({}));
        }
      }
    }, [clientId, partnerId]);

    useEffect(() => {
      if ((keyuser || isDSC) && !clientId) {
        setLoading(true);
        PartnersAPI.get()
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error("Something went wrong");
            }
          })
          .then((data) => {
            setPartnersList(data);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    }, [keyuser, clientId, isDSC]);

    useEffect(() => {
      if (!clientId && (keyuser || isDSC) && partnerId) {
        cliente.partnerId = partnerId;
      }
    }, [clientId, keyuser, partnerId, cliente.partnerId, isDSC]);

    useEffect(() => {
      if (cliente?.partnerId) {
        setLoading(true);
        PartnersAPI.get(cliente.partnerId.toString())
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error("Something went wrong");
            }
          })
          .then((data) => {
            setPartner(data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("error: ", error);
            setLoading(false);
          });
      }
    }, [cliente.partnerId]);

    useEffect(() => {
      setInfoBySalesforce(cliente.infoBySalesforce);
    }, [cliente.infoBySalesforce]);

    return (
      <div className={commonStyles.mainContainer} style={{ width: "100%" }}>
        {tabValue === 0 && (
          <RelativeBackdrop hidden={!loading}>
            <div>
              {clientId && (
                <div>
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    centered
                    classes={{ indicator: styles.tabsHeader }}
                  >
                    <Tab
                      data-testid="clientTab-client"
                      label={
                        <span className={styles.detailTabLabel}>
                          {i18n.t("cliente.tituloCard")}
                        </span>
                      }
                      icon={<Group />}
                      id="simple-tab-0"
                    />
                    {clientId && (
                      <Tab
                        data-testid="clientTab-plants"
                        label={
                          <span className={styles.detailTabLabel}>
                            {i18n.t("planta.tituloCardLista")}
                          </span>
                        }
                        icon={<Business />}
                        id="simple-tab-1"
                      />
                    )}
                  </Tabs>
                </div>
              )}

              <div className={styles.mainCardFormContainer}>
                <div className={styles.partnerClientHeader}>
                  <CardHeader
                    title={cliente.razonSocial}
                    subheader={partner.razonSocial}
                    avatar={
                      <Avatar
                        aria-label="avatar"
                        className={styles.tabHeaderAvatar}
                      >
                        {cliente.isDemo ? <Flag /> : <Group />}
                      </Avatar>
                    }
                  />
                </div>

                <div>
                  {infoBySalesforce && (
                    <div
                      className={styles.labelInfoFromSalesforce}
                      style={{ backgroundColor: globalStatusColors.infoColor }}
                    >
                      <InfoOutlined className={commonStyles.bannerIcon} />
                      {i18n.t("planta.helpText_salesforce")}
                    </div>
                  )}
                  {!infoBySalesforce && clientId && (
                    <div
                      className={styles.labelInfoFromSalesforce}
                      style={{
                        backgroundColor: globalStatusColors.warningColor,
                      }}
                    >
                      <ReportProblemOutlined
                        className={commonStyles.bannerIcon}
                      />
                      {i18n.t("planta.helpText_Notsalesforce")}
                    </div>
                  )}
                </div>
                <div className={styles.formCardContainer}>
                  <div className={styles.formContainerWithLogo}>
                    <div style={{ width: "100%" }}>
                      <div className={styles.flexRowForm}>
                        <div className={styles.flexCellForm}>
                          {/*Salesforce Id */}
                          <TextField
                            required
                            fullWidth
                            disabled={notFound}
                            className={styles.materialTextField}
                            value={cliente?.salesforceId || ""}
                            label={i18n.t("planta.salesforceId")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              maxLength: 18,
                              readOnly: !canEdit,
                            }}
                            error={
                              errors["salesforceId"] !== undefined ||
                              errors["salesforceIdDuplicated"] !== undefined ||
                              errors["salesforcePlantIdDuplicated"] !==
                                undefined ||
                              errors["salesforceRegistered"] !== undefined ||
                              errors["salesforceIBadLength"] !== undefined
                            }
                            helperText={
                              salefoceLength > 0 &&
                              !errors["salesforceId"] &&
                              !errors["salesforceRegistered"] &&
                              !errors["salesforceIdDuplicated"]
                                ? i18n.t("cliente.salesforceLength", {
                                    salefoceLength,
                                  })
                                : errors["salesforceId"]
                                ? i18n.t("textField.required")
                                : errors["salesforceIdDuplicated"]
                                ? i18n.t("cliente.salesforceIdDuplicated")
                                : errors["salesforceRegistered"]
                                ? i18n.t("cliente.salesforceRegistered")
                                : errors["salesforcePlantIdDuplicated"]
                                ? i18n.t("cliente.salesforcePlantIdDuplicated")
                                : errors["salesforceIBadLength"]
                                ? i18n.t("cliente.salesforceIBadLength")
                                : undefined
                            }
                            name="salesforceId"
                            onChange={handleInputChangeSalesforceId}
                          />
                        </div>
                        <div className={styles.flexCellForm}>
                          {((!clientId && (keyuser || isDSC || isPartner)) ||
                            (!readOnly && (keyuser || isGestor))) &&
                            (salesforceBtnLoading ? (
                              <Button
                                variant="contained"
                                className={styles.primaryFormButton}
                                disabled={true}
                              >
                                <CircularProgress size={24} color="inherit" />
                              </Button>
                            ) : (
                              <Button
                                disabled={notFound}
                                variant="contained"
                                className={styles.primaryFormButton}
                                onClick={() => btnEvent_checkSalesforceId()}
                              >
                                <Send className={styles.saveIcon} />
                                {i18n.t("planta.btn_salesforce_check")}
                              </Button>
                            ))}
                        </div>
                      </div>
                      <div className={styles.flexRowForm}>
                        <div className={styles.flexCellForm}>
                          {/* Bussiness Name - Company Name */}
                          <TextField
                            disabled={notFound}
                            required
                            fullWidth
                            className={styles.materialTextField}
                            value={cliente.razonSocial || ""}
                            label={i18n.t("cliente.razonSocial")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: !canEdit || infoBySalesforce,
                            }}
                            error={errors["RazonSocial"] !== undefined}
                            helperText={
                              errors["RazonSocial"]
                                ? i18n.t("textField.required")
                                : undefined
                            }
                            name="razonSocial"
                            onChange={handleInputChange}
                          />
                        </div>
                        {(keyuser || isDSC) && !clientId && (
                          <div className={styles.flexCellForm}>
                            {/*Partner */}
                            <TextField
                              disabled={notFound}
                              fullWidth
                              select={!fromPartner}
                              label={i18n.t("cliente.partner")}
                              value={
                                !fromPartner
                                  ? cliente?.partnerId || ""
                                  : partnersList.find(
                                      (element) =>
                                        element.id?.toString() ===
                                        cliente.partnerId?.toString()
                                    )?.razonSocial || ""
                              }
                              className={styles.materialTextField}
                              inputProps={{
                                readOnly: (!canEdit && clientId) || fromPartner,
                              }}
                              error={errors["PartnerId"] !== undefined}
                              required
                              name="partnerId"
                              onChange={handleInputChange}
                              InputLabelProps={{ shrink: true }}
                              helperText={
                                errors["PartnerId"]
                                  ? i18n.t("textField.required")
                                  : undefined
                              }
                            >
                              {partnersList.map((element) => (
                                <MenuItem
                                  key={element.id.toString()}
                                  value={element.id}
                                >
                                  {element.razonSocial}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        )}
                      </div>
                      <div className={styles.flexRowForm}>
                        <div className={styles.flexCellForm}>
                          {/*Country*/}
                          {(!canEdit || infoBySalesforce) && (
                            <TextField
                              required
                              fullWidth
                              disabled={notFound}
                              className={styles.materialTextField}
                              value={
                                cliente.countryId
                                  ? countries.find(
                                      (c) => c.id === cliente.countryId
                                    )?.name
                                  : ""
                              }
                              label={i18n.t("planta.pais")}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                readOnly: true,
                              }}
                              error={errors["CountryId"] !== undefined}
                              helperText={
                                errors["CountryId"]
                                  ? i18n.t("textField.required")
                                  : undefined
                              }
                              name="countryId"
                            />
                          )}
                          {canEdit && !infoBySalesforce && (
                            <Autocomplete
                              disabled={notFound}
                              open={!canEdit ? false : undefined}
                              autoComplete={!canEdit}
                              closeIcon={!canEdit ? undefined : undefined}
                              freeSolo={!canEdit}
                              fullWidth
                              autoHighlight
                              options={countries}
                              groupBy={(option) =>
                                keyuser ? option?.region?.regionCode : undefined
                              }
                              getOptionLabel={(c) =>
                                i18n.t(`country.${c.name || "undefined"}`)
                              }
                              value={
                                cliente.countryId
                                  ? countries.find(
                                      (c) => c.id === cliente.countryId
                                    ) || null
                                  : null
                              }
                              className={styles.materialTextField}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={i18n.t("planta.pais")}
                                  error={errors["CountryId"] !== undefined}
                                  required
                                  name="countryId"
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: !canEdit,
                                  }}
                                  helperText={
                                    errors["CountryId"]
                                      ? i18n.t("textField.required")
                                      : undefined
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  disabled={notFound}
                                />
                              )}
                              onChange={(event, newValue) => {
                                handleInputChange({
                                  target: {
                                    name: "countryId",
                                    value: newValue ? newValue.id : undefined,
                                  },
                                });
                              }}
                            />
                          )}
                        </div>
                        <div className={styles.flexCellForm}>
                          {/* City */}
                          <TextField
                            required
                            fullWidth
                            disabled={notFound}
                            className={styles.materialTextField}
                            value={cliente.poblacion || ""}
                            label={i18n.t("cliente.poblacion")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: !canEdit || infoBySalesforce,
                            }}
                            error={errors["Poblacion"] !== undefined}
                            helperText={
                              errors["Poblacion"]
                                ? i18n.t("textField.required")
                                : undefined
                            }
                            name="poblacion"
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className={styles.flexCellForm}>
                          {/* Address */}
                          <TextField
                            required
                            fullWidth
                            disabled={notFound}
                            className={styles.materialTextField}
                            value={cliente.direccion || ""}
                            label={i18n.t("cliente.direccion")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: !canEdit || infoBySalesforce,
                            }}
                            error={errors["Direccion"] !== undefined}
                            helperText={
                              errors["Direccion"]
                                ? i18n.t("textField.required")
                                : undefined
                            }
                            name="direccion"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className={styles.flexRowForm}>
                        <div className={styles.flexCellForm}>
                          {/* Province */}
                          <TextField
                            fullWidth
                            disabled={notFound}
                            className={styles.materialTextField}
                            value={cliente.provincia || ""}
                            label={i18n.t("cliente.provincia")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly:
                                !provinceEmpty &&
                                (!canEdit || infoBySalesforce),
                            }}
                            error={errors["Provincia"] !== undefined}
                            helperText={
                              errors["Provincia"]
                                ? i18n.t("textField.required")
                                : undefined
                            }
                            name="provincia"
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className={styles.flexCellForm}>
                          {/* ZIP code */}
                          <TextField
                            fullWidth
                            disabled={notFound}
                            className={styles.materialTextField}
                            value={cliente.cp || ""}
                            label={i18n.t("cliente.cp")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: !canEdit || infoBySalesforce,
                            }}
                            error={errors["CP"] !== undefined}
                            helperText={
                              errors["CP"]
                                ? i18n.t("textField.required")
                                : undefined
                            }
                            name="cp"
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className={styles.flexCellForm}>
                          {/*Companty tax Code - Vat Code */}
                          <TextField
                            fullWidth
                            disabled={notFound}
                            className={styles.materialTextField}
                            value={cliente.cif || ""}
                            label={i18n.t("cliente.cif")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly:
                                (!canEdit && clientId) ||
                                currentUserHasRoleEndCustomer,
                            }}
                            error={
                              errors["CIF"] !== undefined ||
                              errors["CIF_Unique"] !== undefined
                            }
                            helperText={
                              errors["CIF"]
                                ? i18n.t("textField.required")
                                : errors["CIF_Unique"]
                                ? i18n.t("cliente.cifInUse")
                                : undefined
                            }
                            name="cif"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className={styles.flexRowForm}>
                        {(AuthService.hasRoleDSC() ||
                          AuthService.hasRoleAdmin()) && (
                          <div className={styles.flexCellForm}>
                            <FormControlLabel
                              disabled={!canEdit}
                              control={
                                <Checkbox
                                  disabled={notFound}
                                  name="isDanfossOwnedClient"
                                  className={styles.checkBoxForm}
                                  checked={
                                    cliente.isDanfossOwnedClient || false
                                  }
                                  onChange={handleInputChange}
                                />
                              }
                              label={i18n.t("cliente.isDanfossOwnedClient")}
                            />
                          </div>
                        )}
                        {(isDSC || AuthService.hasRoleAdmin() || isPartner) && (
                          <div className={styles.flexCellForm}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="isTest"
                                  disabled={notFound || cliente.isDemo}
                                  className={styles.checkBoxForm}
                                  checked={cliente.isTest || false}
                                  onChange={handleInputChange}
                                />
                              }
                              label={i18n.t("cliente.isTest")}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.flexRowForm}>
                    {(AuthService.hasRoleDSC() ||
                      AuthService.hasRoleAdmin()) && (
                      <div className={styles.flexCellForm}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isPremium"
                              disabled={
                                !canEdit ||
                                !cliente.isDanfossOwnedClient ||
                                notFound
                              }
                              className={
                                !cliente.isDanfossOwnedClient
                                  ? styles.checkBoxFormDisabled
                                  : styles.checkBoxForm
                              }
                              checked={cliente.isPremium || false}
                              onChange={handleInputChange}
                            />
                          }
                          label={i18n.t("cliente.isPremium")}
                        />
                      </div>
                    )}
                  </div>
                  {cliente.isPremium && (
                    <div>
                      <div className={styles.flexRowForm}>
                        <div className={styles.flexCellForm}>
                          <TextField
                            className={styles.materialTextField}
                            required
                            fullWidth
                            type="date"
                            value={
                              cliente.premiumStartDate
                                ? cliente.premiumStartDate.substring(0, 10)
                                : ""
                            }
                            label={i18n.t("cliente.premiumStartDate")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: readOnly,
                            }}
                            name="premiumStartDate"
                            error={errors["premiumStartDate"] !== undefined}
                            helperText={
                              errors["premiumStartDate"]
                                ? i18n.t("textField.required")
                                : null
                            }
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className={styles.flexCellForm}>
                          <TextField
                            className={styles.materialTextField}
                            required
                            fullWidth
                            type="date"
                            value={
                              cliente.premiumEndDate
                                ? cliente.premiumEndDate.substring(0, 10)
                                : ""
                            }
                            label={i18n.t("cliente.premiumEndDate")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: readOnly,
                            }}
                            name="premiumEndDate"
                            error={errors["premiumEndDate"] !== undefined}
                            helperText={
                              errors["premiumEndDate"]
                                ? i18n.t("textField.required")
                                : null
                            }
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {(AuthService.hasRoleDSC() || AuthService.hasRoleAdmin()) && (
                    <div className={styles.flexRowForm}>
                      <div className={styles.flexCellForm}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isTryout"
                              inputProps={{
                                readOnly: readOnly,
                              }}
                              disabled={
                                !canEdit ||
                                !cliente.isDanfossOwnedClient ||
                                notFound
                              }
                              className={
                                !cliente.isDanfossOwnedClient
                                  ? styles.checkBoxFormDisabled
                                  : styles.checkBoxForm
                              }
                              checked={cliente.isTryout || false}
                              onChange={handleInputChange}
                            />
                          }
                          label={i18n.t("cliente.isTryout")}
                        />
                      </div>
                    </div>
                  )}
                  {cliente.isTryout && (
                    <div>
                      <div className={styles.flexRowForm}>
                        <div className={styles.flexCellForm}>
                          <TextField
                            className={styles.materialTextField}
                            required
                            fullWidth
                            type="date"
                            value={
                              cliente.tryoutStartDate
                                ? cliente.tryoutStartDate.substring(0, 10)
                                : ""
                            }
                            label={i18n.t("cliente.tryoutStartDate")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: readOnly,
                            }}
                            name="tryoutStartDate"
                            error={errors["tryoutStartDate"] !== undefined}
                            helperText={
                              errors["tryoutStartDate"]
                                ? i18n.t("textField.required")
                                : null
                            }
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className={styles.flexCellForm}>
                          <TextField
                            className={styles.materialTextField}
                            required
                            fullWidth
                            type="date"
                            value={
                              cliente.tryoutEndDate
                                ? cliente.tryoutEndDate.substring(0, 10)
                                : ""
                            }
                            label={i18n.t("cliente.tryoutEndDate")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: readOnly,
                            }}
                            name="tryoutEndDate"
                            error={errors["tryoutEndDate"] !== undefined}
                            helperText={
                              errors["tryoutEndDate"]
                                ? i18n.t("textField.required")
                                : null
                            }
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {!notFound && !currentUserHasRoleEndCustomer && (
                  <div className={styles.flexRowForm}>
                    <div className={styles.flexCellForm}>
                      {((canEdit && clientId) || !clientId) && (
                        <>
                          <Button
                            variant="contained"
                            className={styles.primaryFormButton}
                            onClick={() =>
                              clientId ? updateCliente() : addCliente()
                            }
                          >
                            <Save className={styles.saveIcon} />
                            {i18n.t("common.save")}
                          </Button>
                          <Button
                            variant="outlined"
                            className={styles.secondaryFormButton}
                            onClick={() => resetCliente()}
                          >
                            <Cancel className={styles.saveIcon} />
                            {i18n.t("common.cancel")}
                          </Button>

                          {clientId && (
                            <Button
                              data-testid="client-actionButton"
                              variant="outlined"
                              className={styles.secondaryFormButton}
                              style={{ marginLeft: "auto" }}
                              onClick={(e) =>
                                setActionsAnchor(e?.currentTarget)
                              }
                            >
                              <MoreVert className={styles.addCircleOutline} />
                              {i18n.t("planta.actions.actionButton")}
                            </Button>
                          )}
                          <Menu
                            anchorEl={actionsAnchor}
                            keepMounted
                            open={Boolean(actionsAnchor)}
                            onClose={() => setActionsAnchor(null)}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem
                              disabled={
                                !isGestor ||
                                !clientId ||
                                cliente?.plantas.length > 0
                              }
                              onClick={() => {
                                createMirrorPlantDialog.current.open();
                                setActionsAnchor(null);
                              }}
                            >
                              <ListItemIcon>
                                <Domain />
                              </ListItemIcon>
                              <ListItemText>
                                {i18n.t("cliente.addMirrorPlant")}
                              </ListItemText>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setOpenLinkPlantsDialog(true);
                                linkPlantsDialogRef.current.open();
                                setActionsAnchor(null);
                              }}
                            >
                              <ListItemIcon>
                                <Link />
                              </ListItemIcon>
                              <ListItemText>
                                {i18n.t("cliente.linkPlants")}
                              </ListItemText>
                            </MenuItem>
                            {(keyuser || isDSC || isGestor) && clientId && (
                              <MenuItem
                                data-testid="client-deleteButton"
                                onClick={() => {
                                  deleteDialog.current.open();
                                  setActionsAnchor(null);
                                }}
                              >
                                <ListItemIcon>
                                  <Delete />
                                </ListItemIcon>
                                <ListItemText>
                                  {i18n.t("common.delete")}
                                </ListItemText>
                              </MenuItem>
                            )}
                          </Menu>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </RelativeBackdrop>
        )}
        {clientId && tabValue === 1 && (
          <div className={commonStyles.mainContainer} style={{ gap: "15px" }}>
            <div>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                centered
                classes={{ indicator: styles.tabsHeader }}
              >
                <Tab
                  data-testid="clientTab-client"
                  label={
                    <span className={styles.detailTabLabel}>
                      {i18n.t("cliente.tituloCard")}
                    </span>
                  }
                  icon={<Group />}
                  id="simple-tab-0"
                />
                {clientId && (
                  <Tab
                    data-testid="clientTab-plants"
                    label={
                      <span className={styles.detailTabLabel}>
                        {i18n.t("planta.tituloCardLista")}
                      </span>
                    }
                    icon={<Business />}
                    id="simple-tab-1"
                  />
                )}
              </Tabs>
            </div>
            <RelativeBackdrop hidden={!loading}>
              <div className={commonStyles.searchFieldContainer}>
                <div className={commonStyles.searchField}>
                  <SearchField
                    inputBaseProps={{
                      id: "plantSearchField",
                      placeholder: i18n.t("common.search"),
                      value: plantasSearch,
                      onChange: (event) => setPlantasSearch(event.target.value),
                    }}
                  />
                  {isGestor && (
                    <Button
                      data-testid="client-newPlant-button"
                      variant="contained"
                      className={commonStyles.primaryButtonRaw}
                      onClick={() =>
                        history.push(`/clientes/${clientId}/nueva-planta`)
                      }
                    >
                      <AddCircleOutline className={styles.addCircleOutline} />
                      {i18n.t("common.new.femenine")}
                    </Button>
                  )}
                  {isGestor && clientId && cliente?.plantas.length === 0 && (
                    <Button
                      variant="outlined"
                      className={commonStyles.secondaryButtonRaw}
                      onClick={() => createMirrorPlantDialog.current.open()}
                    >
                      <Domain className={styles.deleteIcon} />
                      {i18n.t("cliente.addMirrorPlant")}
                    </Button>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <div className={commonStyles.cardsContainerBase}>
                  {filter(cliente.plantas, plantasSearch).map((planta) => {
                    planta.cliente = cliente;
                    return (
                      <div
                        data-testid={"clientPlantCard-" + planta.denominacion}
                        key={planta.id.toString()}
                        style={{
                          height: "fit-content",
                        }}
                      >
                        <CardPlantClient
                          isDanfossOwned={false}
                          title={planta.denominacion}
                          subtitle={`${planta.cliente.razonSocial}`}
                          isTest={planta.isTest}
                          contents={[
                            { key: "direccion", value: planta.direccion },
                            { key: "cp", value: planta.cp },
                            { key: "poblacion", value: planta.poblacion },
                            { key: "provincia", value: planta.provincia },
                            {
                              key: "pais",
                              value: i18n.t(
                                `country.${planta.country.name || "null"}`
                              ),
                            },
                            { key: "nDrives", value: planta.nDrivers },
                            {
                              key: "creationDate",
                              value: planta.fechaCreacion
                                ? moment(planta.fechaCreacion).format(
                                    "DD/MM/YYYY"
                                  )
                                : i18n.t("common.n/a"),
                            },
                          ]}
                          type="planta"
                          className={
                            planta?.infoBySalesforce
                              ? commonStyles.cardBase
                              : commonStyles.cardBaseNotValidated
                          }
                          isDemo={planta.isDemo}
                          style={cardListStyles}
                          onClick={() => {
                            history.push(`/plantas/${planta.id}`);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </RelativeBackdrop>
          </div>
        )}
        {clientId && cliente.partnerId && (
          <ClientLinkPlantsDialog
            dialogRef={linkPlantsDialogRef}
            open={openLinkPlantsDialog}
            setOpen={setOpenLinkPlantsDialog}
            clientId={clientId}
            partnerId={cliente.partnerId}
            cliente={cliente}
            updateCliente={getCliente}
          />
        )}
        {/* @ts-ignore */}
        <AlertDialog
          ref={deleteDialog}
          title={i18n.t("cliente.alertDialog.title")}
          text={i18n.t("cliente.alertDialog.text")}
          cancelText={i18n.t("cliente.alertDialog.cancelText")}
          confirmText={i18n.t("cliente.alertDialog.confirmText")}
          confirmAction={() => deleteCliente()}
        />
        {/* @ts-ignore */}
        <AlertDialog
          ref={cantDeleteDialog}
          title={
            keyuser
              ? i18n.t("cliente.borrar.titulo_keyuser")
              : i18n.t("cliente.borrar.titulo_dsc")
          }
          text={i18n.t("cliente.borrar.texto")}
          confirmText={i18n.t("cliente.alertDialog.close")}
          confirmAction={() => cantDeleteDialog.current.close()}
        />
        {/* @ts-ignore */}
        {/* salefoce */}
        <AlertDialog
          ref={clientExistDialog}
          title={i18n.t("cliente.existe.titulo")}
          text={i18n.t("cliente.existe.text")}
          confirmText={i18n.t("cliente.alertDialog.close")}
          confirmAction={() => clientExistDialog.current.close()}
        />
        {/* @ts-ignore */}
        <AlertDialog
          ref={clientEditedExistDialog}
          title={i18n.t("cliente.existe.titulo")}
          text={i18n.t("cliente.existe.textEdit")}
          confirmText={i18n.t("cliente.alertDialog.close")}
          confirmAction={() => clientEditedExistDialog.current.close()}
        />
        <AlertDialog
          ref={createMirrorPlantDialog}
          title={i18n.t("cliente.mirrorPlant.title")}
          text={i18n.t("cliente.mirrorPlant.text")}
          confirmText={i18n.t("cliente.mirrorPlant.confirmText")}
          cancelText={i18n.t("cliente.mirrorPlant.cancelText")}
          confirmAction={() => addMirrorPlant()}
        />
        <AlertDialog
          ref={changeDialog}
          title={i18n.t("cliente.changeDialog.title")}
          text={i18n.t("cliente.changeDialog.text")}
          cancelText={i18n.t("cliente.changeDialog.cancelText")}
          confirmText={i18n.t("cliente.changeDialog.confirmText")}
          confirmAction={() => {
            setCliente((prevCliente) => ({
              ...prevCliente,
              tryoutEndDate: auxValue,
              premiumStartDate: moment(auxValue)
                .add(1, "days")
                .format("YYYY-MM-DD"),
              premiumEndDate: moment(auxValue)
                .add(1, "days")
                .add(1, "years")
                .format("YYYY-MM-DD"),
            }));
            changeDialog.current.close();
          }}
        />
      </div>
    );
  }
}
