import React, { useState, useContext, useRef } from "react";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  FormControl,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Send, Cancel, Public } from "@material-ui/icons";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import i18n from "../../../locales/i18n";
import AppContext from "../../../contexts/AppContext";
import { BroadcastAPI } from "../../../services/API";
import {
  SnackbarSuccess,
  SnackbarError,
  SnackbarWarning,
} from "../../../helpers/Common";
import RelativeBackdrop from "../../RelativeBackdrop";
import AlertDialog from "../../AlertDialog";
import IngSelectorMultiple from "../../atoms/Ing_SelectorMultiple";
import { Autocomplete } from "@material-ui/lab";

const AllCountriesOption = {
  id: -1,
  name: "All countries",
  code: "ALL",
};

const defaultBroadcast = {
  brandId: undefined,
  modelsId: [],
  frameSizes: [],
  newLifeCicle: null,
  countriesId: [AllCountriesOption],
  message: null,
};

export default function CountryNotificationTab(props) {
  const { countries, brands, models, sizes, handleChangeDriveInfo } = props;
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const styles = DetailTabs_Style();
  const [broadcast, setBroadcast] = useState(defaultBroadcast);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const confirmDialog = useRef(null);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "brandId" && value !== broadcast.brandId) {
      setBroadcast((prevState) => ({
        ...prevState,
        modelsId: undefined,
        frameSizes: null,
      }));
    }

    setBroadcast((prevState) => ({ ...prevState, [name]: value }));

    if (errors[name]) {
      setErrors((e) => ({ ...errors, [name]: undefined }));
    }
  };

  const handleInputChangeMultiCountry = (event, value) => {
    let resultado = value;
    if (value.length > 0) {
      const prevWithAllC =
        broadcast.countriesId.filter((a) => a.id === -1).length > 0;
      const newWithAllC = value.filter((a) => a.id === -1).length > 0;

      if (!prevWithAllC && newWithAllC) {
        resultado = [AllCountriesOption];
      } else {
        // Quitamos los duplicados
        const elementosUnicos = {};
        resultado = value.filter((elemento) => {
          const clave = elemento.id;
          if (!elementosUnicos[clave]) {
            elementosUnicos[clave] = true;
            return true;
          }
          return false;
        });
        if (resultado.length > 1) {
          resultado = resultado.filter((v) => v.id > 0);
        }
      }
    } else {
      resultado = [AllCountriesOption];
    }

    setBroadcast((prevState) => ({ ...prevState, countriesId: resultado }));
  };

  const confirmWhetherThrowNotice = () => {
    if (validateForm()) {
      confirmDialog.current.open();
    }
  };

  //validar formulario
  const validateForm = () => {
    let newErrors = {};

    //comprobar campos
    if (!broadcast.brandId) {
      newErrors["brandId"] = true;
    }
    if (!broadcast.modelsId || broadcast.modelsId.length === 0) {
      newErrors["modelsId"] = true;
    }
    if (!broadcast.frameSizes || broadcast.frameSizes.length === 0) {
      newErrors["frameSizes"] = true;
    }
    if (broadcast.newLifeCicle == null) {
      newErrors["newLifeCicle"] = true;
    }
    if (!broadcast.message) {
      newErrors["message"] = true;
    }

    //actualizar errores
    setErrors(newErrors);

    //comprobar si hay errores
    if (Object.keys(newErrors).length) {
      return false;
    }
    return true;
  };

  const throwNotice = () => {
    confirmDialog.current.close();
    setLoading(true);
    const data = {
      ...broadcast,
      countriesId: broadcast.countriesId.map((c) => c.id),
      modelsId: broadcast.modelsId.map((m) => m?.id),
    };
    BroadcastAPI.throwNotice(data)
      .then(async (response) => {
        let json = await response.json();
        if (response.ok) {
          resetForm();
          setLoading(false);
          if (json === 1) {
            SnackbarSuccess(
              setGlobal,
              i18n.t("broadcast.sentDialog.bodySing", { number: json })
            );
          } else if (json > 1) {
            SnackbarSuccess(
              setGlobal,
              i18n.t("broadcast.sentDialog.bodyPlur", { number: json })
            );
          } else {
            SnackbarWarning(
              setGlobal,
              i18n.t("broadcast.sentDialog.bodyZero", { number: json })
            );
          }
        } else {
          setLoading(false);
          setErrors(json["errors"]);
        }
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("broadcast.error"));
        setLoading(false);
        console.error(error);
      });
  };

  const resetForm = () => {
    setBroadcast(defaultBroadcast);
    setErrors({});
  };

  return (
    <Card>
      <RelativeBackdrop hidden={!loading}>
        <div className={styles.mainFormContainer}>
          <div className={styles.formCardContainer}>
            <form autoComplete="off">
              <div className={styles.flexRowForm}>
                <CardHeader
                  title={i18n.t("broadcast.globalTab.titleCard")}
                  subheader={i18n.t("broadcast.globalTab.subTitleCard")}
                  avatar={
                    <Avatar
                      aria-label="avatar"
                      className={styles.tabHeaderAvatar}
                    >
                      <Public />
                    </Avatar>
                  }
                />
              </div>
              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      label={i18n.t("broadcast.brand")}
                      value={broadcast.brandId || ""}
                      className={styles.materialTextField}
                      error={errors["brandId"] !== undefined}
                      helperText={
                        errors["brandId"] ? i18n.t("textField.required") : null
                      }
                      required
                      name="brandId"
                      onChange={(e) => {
                        handleInputChange(e);
                        handleChangeDriveInfo(e);
                      }}
                    >
                      {brands.map((brand) => (
                        <MenuItem key={brand.id} value={brand.id}>
                          {brand.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </div>
                <div className={styles.flexCellForm}>
                  <FormControl fullWidth required>
                    <Autocomplete
                      multiple
                      options={models}
                      value={broadcast.modelsId || []}
                      getOptionLabel={(row) => row.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={styles.materialTextField}
                          variant="standard"
                          label={i18n.t("broadcast.model")}
                          InputLabelProps={{
                            required: true,
                          }}
                          name="modelsId"
                          error={errors["modelsId"] !== undefined}
                          helperText={
                            errors["modelsId"]
                              ? i18n.t("textField.required")
                              : null
                          }
                        />
                      )}
                      onChange={(e, newValue) => {
                        let fakeEvent = {
                          target: {
                            value: newValue,
                            type: "list",
                            name: "modelsId",
                          },
                        };
                        handleInputChange(fakeEvent);
                      }}
                    />
                  </FormControl>
                </div>
              </div>

              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <FormControl fullWidth required>
                    <Autocomplete
                      multiple
                      options={sizes}
                      value={broadcast.frameSizes || []}
                      getOptionLabel={(row) => row}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={styles.materialTextField}
                          variant="standard"
                          label={i18n.t("broadcast.frameSize")}
                          InputLabelProps={{
                            required: true,
                          }}
                          name="frameSizes"
                          error={errors["frameSizes"] !== undefined}
                          helperText={
                            errors["frameSizes"]
                              ? i18n.t("textField.required")
                              : null
                          }
                        />
                      )}
                      onChange={(e, newValue) => {
                        let fakeEvent = {
                          target: {
                            value: newValue,
                            type: "list",
                            name: "frameSizes",
                          },
                        };
                        handleInputChange(fakeEvent);
                      }}
                    />
                  </FormControl>
                </div>
                <div className={styles.flexCellForm}>
                  <FormControl fullWidth required>
                    <TextField
                      select
                      label={i18n.t("broadcast.newLifeCicle")}
                      value={
                        broadcast.newLifeCicle !== undefined &&
                        broadcast.newLifeCicle !== null
                          ? broadcast.newLifeCicle
                          : ""
                      }
                      className={styles.materialTextField}
                      error={errors["newLifeCicle"] !== undefined}
                      helperText={
                        errors["newLifeCicle"]
                          ? i18n.t("textField.required")
                          : null
                      }
                      required
                      name="newLifeCicle"
                      onChange={handleInputChange}
                    >
                      <MenuItem value={0}>
                        {i18n.t("common.activo.singularM")}
                      </MenuItem>
                      <MenuItem value={1}>{i18n.t("common.limitado")}</MenuItem>
                      <MenuItem value={2}>{i18n.t("common.inactivo")}</MenuItem>
                    </TextField>
                  </FormControl>
                </div>
              </div>

              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <FormControl fullWidth>
                    <IngSelectorMultiple
                      className={styles.materialTextField}
                      options={countries}
                      getOptionLabel={(option) =>
                        i18n.t(`country.${option.name}`)
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      label={i18n.t("broadcast.country")}
                      value={broadcast.countriesId}
                      onChange={handleInputChangeMultiCountry}
                      disabled={false}
                    />
                  </FormControl>
                </div>
              </div>
              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <TextField
                    required
                    fullWidth
                    className={styles.materialTextField}
                    value={broadcast.message || ""}
                    label={i18n.t("broadcast.message")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={5}
                    error={errors["message"] !== undefined}
                    helperText={
                      errors["message"] ? i18n.t("textField.required") : null
                    }
                    name="message"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <Button
                    variant="contained"
                    className={styles.primaryFormButton}
                    onClick={() => confirmWhetherThrowNotice()}
                  >
                    <Send className={styles.saveIcon} />
                    {i18n.t("broadcast.confirmDialog.confirmText")}
                  </Button>
                  <Button
                    variant="outlined"
                    className={styles.secondaryFormButton}
                    onClick={() => resetForm()}
                  >
                    <Cancel className={styles.saveIcon} />
                    {i18n.t("common.cancel")}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/*@ts-ignore*/}
        <AlertDialog
          ref={confirmDialog}
          title={i18n.t("broadcast.confirmDialog.title")}
          text={i18n.t("broadcast.globalTab.alertText")}
          cancelText={i18n.t("broadcast.confirmDialog.cancelText")}
          confirmText={i18n.t("broadcast.confirmDialog.confirmText")}
          confirmAction={() => throwNotice()}
        />
      </RelativeBackdrop>
    </Card>
  );
}
