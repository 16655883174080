// @ts-nocheck
// eslint-disable-next-line no-unused-vars
import { PreManOptionsModel, ReportOptionsModel } from "../helpers";
import React from "react";
import { Page, Text, View } from "@react-pdf/renderer";
import { GeneralStyles, TableStyles } from "../ReportPDFStyles";
import getText from "../helpers/traductions";
import {
  conditionAbbreviation,
  critRiskColors,
  criticalityAbbreviation,
  orderList,
  riskAbbreviation,
  sliceText,
  statCondColors,
  statusAbbreviation,
  maxWidthV,
} from "../helpers";
import WaterMark from "./WaterMarkPDF";
import Footer from "./FooterPDF";

/**
 * Pestaña de resumen (Summary) o recomendación (Recommendation) del PDF.
 * @param {object} props
 * @param {number} props.tab Cambia el contenido dependiendo de si es (0) impresión normal, (-2 | -3) impresión parcial o una (5 | 6) vista previa.
 * @param {PreManOptionsModel} props.options Opciones de la pestaña.
 * @param {ReportOptionsModel} props.reportOptions Opciones del report.
 * @param {any} props.data Datos del report.
 * @param {any} props.header Header del report.
 * @param {any} props.waterMark Marca de agua por si algun equipo tiene riesgo indefinido.
 */
function SumRecTable({ tab, options, reportOptions, data, header, waterMark }) {
  const lang = reportOptions.language;
  const dinamicWidth = 100 / options.width;

  const maxWidth = (dinamicWidth * 3).toFixed(2) + "% !important";
  const medWidth = (dinamicWidth * 2).toFixed(2) + "% !important";
  const minWidth = (dinamicWidth * 1).toFixed(2) + "% !important";

  const orientation =
    options?.width < maxWidthV
      ? reportOptions.pageOrientation || "portrait"
      : "landscape";

  const renderContent = () => {
    if (!data) {
      return <></>;
    }

    let drives = orderList(options.orderBy, options.orderType, data);
    let result = [];
    for (let i = 0; i < drives.length; i++) {
      if (i >= 50 && tab !== 0 && tab !== -2 && tab !== -3) {
        result.push(
          <View
            wrap={false}
            style={TableStyles.tableRow}
            key={`${drives[i].id}_${i}`}
          >
            <Text style={TableStyles.tableHeaderCell}>
              {getText(lang, "sumrec.preview")}
            </Text>
          </View>
        );
        break;
      } else {
        result.push(
          <View
            wrap={false}
            style={TableStyles.tableRow}
            key={`${drives[i].id}_${i}`}
          >
            {options.column[10] && (
              <View
                style={{
                  ...TableStyles.tableCell,
                  ...TableStyles.isSliced,
                  width: medWidth,
                }}
              >
                {drives[i].idCliente ? (
                  sliceText(drives[i].idCliente)
                ) : (
                  <Text>{getText(lang, "common.na")}</Text>
                )}
              </View>
            )}
            {options.column[11] && (
              <View
                style={{
                  ...TableStyles.tableCell,
                  ...TableStyles.isSliced,
                  width: medWidth,
                }}
              >
                {drives[i].nombre ? (
                  sliceText(drives[i].nombre)
                ) : (
                  <Text>{getText(lang, "common.na")}</Text>
                )}
              </View>
            )}
            <View
              style={{
                ...TableStyles.tableCell,
                ...TableStyles.isSliced,
                width: medWidth,
              }}
            >
              {drives[i].sn ? (
                sliceText(drives[i].sn)
              ) : (
                <Text>{getText(lang, "common.na")}</Text>
              )}
            </View>
            <Text
              style={{
                ...TableStyles.tableCell,
                ...TableStyles.isSliced,
                width: medWidth,
              }}
            >
              {drives[i].pn ? (
                sliceText(drives[i].pn)
              ) : (
                <Text>{getText(lang, "common.na")}</Text>
              )}
            </Text>
            {options.column[0] && (
              <View
                style={{
                  ...TableStyles.tableCell,
                  ...TableStyles.isSliced,
                  width: maxWidth,
                }}
              >
                {drives[i].tc ? (
                  sliceText(drives[i].tc)
                ) : (
                  <Text>{getText(lang, "common.na")}</Text>
                )}
              </View>
            )}
            <View
              style={{
                ...TableStyles.tableCell,
                ...TableStyles.isSliced,
                width: minWidth,
              }}
            >
              {drives[i].model?.name ? (
                sliceText(drives[i].model.name)
              ) : (
                <Text>{getText(lang, "common.na")}</Text>
              )}
            </View>
            <Text style={{ ...TableStyles.tableCell, width: minWidth }}>
              {drives[i].brand?.name
                ? sliceText(drives[i].brand.name)
                : getText(lang, "common.na")}
            </Text>
            {options.column[1] && (
              <Text style={{ ...TableStyles.tableCell, width: minWidth }}>
                {drives[i].frame || getText(lang, "common.na")}
              </Text>
            )}
            {options.column[12] && (
              <Text style={{ ...TableStyles.tableCell, width: minWidth }}>
                {drives[i].powerSize || getText(lang, "common.na")}
              </Text>
            )}
            {options.column[2] && (
              <Text style={{ ...TableStyles.tableCell, width: minWidth }}>
                {drives[i].voltage || getText(lang, "common.na")}
              </Text>
            )}
            {options.column[3] && (
              <View
                style={{
                  ...TableStyles.tableCell,
                  ...TableStyles.isSliced,
                  width: minWidth,
                }}
              >
                {drives[i].añoFabricacion ? (
                  sliceText(drives[i].añoFabricacion.toString())
                ) : (
                  <Text>{getText(lang, "common.na")}</Text>
                )}
              </View>
            )}
            {options.column[13] && (
              <Text style={{ ...TableStyles.tableCell, width: minWidth }}>
                {(drives[i].motorConectado === 0
                  ? getText(lang, "common.na")
                  : drives[i].motorConectado === 1
                  ? getText(lang, "common.affirmative")
                  : getText(lang, "common.negative")) ||
                  getText(lang, "common.na")}
              </Text>
            )}
            {options.column[4] && (
              <Text style={{ ...TableStyles.tableCell, width: medWidth }}>
                {options.column[14]
                  ? drives[i].zonesPath
                  : drives[i].zona?.descripcion || getText(lang, "common.na")}
              </Text>
            )}
            {options.column[5] && (
              <Text
                style={{
                  ...TableStyles.tableCell,
                  width: minWidth,
                  backgroundColor: statCondColors[drives[i].cicloVida],
                  textAlign: "center",
                }}
              >
                {statusAbbreviation[drives[i].cicloVida]}
              </Text>
            )}
            {options.column[6] && (
              <Text
                style={{
                  ...TableStyles.tableCell,
                  width: minWidth,
                  backgroundColor: critRiskColors[drives[i].criticidad],
                  textAlign: "center",
                }}
              >
                {criticalityAbbreviation[drives[i].criticidad]}
              </Text>
            )}
            {options.column[7] && (
              <Text
                style={{
                  ...TableStyles.tableCell,
                  width: minWidth,
                  backgroundColor: statCondColors[drives[i].estado],
                  textAlign: "center",
                }}
              >
                {conditionAbbreviation[drives[i].estado]}
              </Text>
            )}
            {options.column[8] && (
              <Text
                style={{
                  ...TableStyles.tableCell,
                  width: minWidth,
                  backgroundColor: critRiskColors[drives[i].riesgo],
                  textAlign: "center",
                }}
              >
                {riskAbbreviation[drives[i].riesgo]}
              </Text>
            )}
            {options.column[9] && (
              <Text style={{ ...TableStyles.tableCell, width: maxWidth }}>
                {drives[i].recommendation || getText(lang, "common.na")}
              </Text>
            )}
          </View>
        );
      }
    }

    if (drives.length === 0) {
      result.push(
        <View
          key={"empty"}
          wrap={false}
          style={{ ...TableStyles.tableRow, fontSize: options.font }}
        >
          <Text
            style={{
              ...TableStyles.tableCell,
              width: "100%",
              textAlign: "center",
            }}
          >
            {getText(lang, "common.noData")}
          </Text>
        </View>
      );
    }

    return result;
  };

  return (
    <Page
      style={GeneralStyles.body}
      size={reportOptions.pageSize || "A4"}
      orientation={orientation}
    >
      {header && header}
      <View fixed style={TableStyles.borderTop}></View>
      <View style={(TableStyles.table, { fontSize: options.font })}>
        <View fixed style={TableStyles.tableHeaderRow}>
          {options.column[10] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: medWidth }}>
              {getText(lang, "drive.idCliente")}
            </Text>
          )}
          {options.column[11] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: medWidth }}>
              {getText(lang, "drive.applicationName")}
            </Text>
          )}
          <Text style={{ ...TableStyles.tableHeaderCell, width: medWidth }}>
            {getText(lang, "drive.sn")}
          </Text>
          <Text style={{ ...TableStyles.tableHeaderCell, width: medWidth }}>
            {getText(lang, "drive.pn")}
          </Text>
          {options.column[0] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: maxWidth }}>
              {getText(lang, "drive.tc")}
            </Text>
          )}
          <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
            {getText(lang, "drive.model")}
          </Text>
          <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
            {getText(lang, "drive.brand")}
          </Text>
          {options.column[1] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
              {getText(lang, "drive.frame")}
            </Text>
          )}
          {options.column[12] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
              {getText(lang, "drive.powerSize")}
            </Text>
          )}
          {options.column[2] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
              {getText(lang, "drive.voltage")}
            </Text>
          )}
          {options.column[3] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
              {getText(lang, "drive.year2")}
            </Text>
          )}
          {options.column[13] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
              {getText(lang, "drive.motorConnected")}
            </Text>
          )}
          {options.column[4] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: medWidth }}>
              {getText(lang, "drive.zone")}
            </Text>
          )}
          {options.column[5] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
              {getText(lang, "drive.lifecycle2")}
            </Text>
          )}
          {options.column[6] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
              {getText(lang, "drive.criticality2")}
            </Text>
          )}
          {options.column[7] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
              {getText(lang, "drive.condition2")}
            </Text>
          )}
          {options.column[8] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
              {getText(lang, "drive.risk2")}
            </Text>
          )}
          {options.column[9] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: maxWidth }}>
              {getText(lang, "drive.recommendation")}
            </Text>
          )}
        </View>
        {renderContent()}
      </View>
      <Footer lang={lang} />
      <WaterMark text={waterMark} orientation={orientation} />
    </Page>
  );
}

export default SumRecTable;
