// eslint-disable-next-line no-unused-vars
import { ReportOptionsModel, GeneralOptionsModel } from "../pdf/helpers";
import React, { useEffect, useState } from "react";
import { ExpandMore, Cancel } from "@material-ui/icons";
import {
  Accordion,
  AccordionSummary,
  Divider,
  AccordionDetails,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Slider,
} from "@material-ui/core";
import {
  DetailTabs_Style,
  TabReportPlantaStyle,
} from "../../../../assets/css/Styles";
import i18n from "../../../../locales/i18n";
import {
  fontLimitsV,
  fontLimitsH,
  fontLimitsPM,
  maxWidthV,
} from "../pdf/helpers";

/**
 * Menu lateral para modificar las opciones del report en las pestañas de Análisis (Site Analysis), resumen (Summary), recomendación (Recommendation) y Mantenimiento Preventivo (Prev. maintenance).
 * @Component
 * @param {Object} props
 * @param {ReportOptionsModel} props.reportOptions Opciones del report.
 * @param {GeneralOptionsModel} props.options Opciones del report.
 * @param {function} props.setOptions Funcion para cambiar las opciones del report.
 */
function GeneralOptions({ reportOptions, options, setOptions }) {
  const styles = DetailTabs_Style();
  const ownStyles = TabReportPlantaStyle();
  const [expanded, setExpanded] = useState(0);

  const fontLimits =
    options.mode !== "preMan"
      ? reportOptions.pageOrientation === "portrait" &&
        options.width < maxWidthV
        ? fontLimitsV
        : fontLimitsH
      : fontLimitsPM;

  var orderBy = [];

  switch (options.mode) {
    case "analysis":
      orderBy = ["brand", "family", "driveCount"];
      break;
    case "emeSto":
      orderBy = ["pn", "tc", "countEquipos", "countAlmacen"];
      break;
    default:
      orderBy = [
        options.column[10] && "idCliente",
        "sn",
        "pn",
        "model",
        "brand",
        options.column[0] && "tc",
        options.column[3] && "añoFabricacion",
        options.column[1] && "frame",
        options.column[2] && "voltage",
        options.column[4] && "zona",
        options.column[5] && "cicloVida",
        options.column[6] && "criticidad",
        options.column[7] && "estado",
        options.column[8] && "riesgo",
        options.column[9] && "recommendation",
      ];
  }

  const ascDesc = ["ascendent", "descendent"];
  const sizes =
    options.mode === "preMan" ? [10, 9, 8, 7, 6, 5] : [12, 11, 10, 9, 8, 7, 6];
  const col = { min: 1, med: 2, max: 3 };

  useEffect(() => {
    if (
      options.font &&
      fontLimits[options.width] &&
      fontLimits[options.width] < options.font
    ) {
      setOptions((old) => ({
        ...old,
        font: fontLimits[options.width],
      }));
    }
  }, [fontLimits, options, setOptions]);

  return (
    <>
      <Accordion
        expanded={expanded === 1}
        onChange={() => setExpanded(expanded === 1 ? -1 : 1)}
      >
        <AccordionSummary
          className={expanded === 1 ? ownStyles.AcordionSelectedTitle : null}
          expandIcon={
            <ExpandMore
              className={
                expanded === 1 ? ownStyles.AcordionSelectedTitleIcon : null
              }
            />
          }
        >
          <div>{i18n.t("report.settings.order.title")}</div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={ownStyles.AcordionContainer}>
          <TextField
            fullWidth
            select
            className={styles.materialTextField}
            label={i18n.t("report.settings.order.column")}
            value={options.orderBy || ""}
            onChange={(e) =>
              setOptions((old) => ({
                ...old,
                orderBy: e.target.value,
              }))
            }
            name="orderBy"
          >
            {orderBy.map(
              (row, index) =>
                row && (
                  <MenuItem key={`${row}_${index}`} value={row}>
                    {i18n.t(`report.driveFields.${row}`)}
                  </MenuItem>
                )
            )}
          </TextField>
          <TextField
            fullWidth
            select
            className={styles.materialTextField}
            label={i18n.t("report.settings.order.direction")}
            value={options.orderType || ""}
            onChange={(e) =>
              setOptions((old) => ({
                ...old,
                orderType: e.target.value,
              }))
            }
            name="order"
          >
            {ascDesc.map((row, index) => (
              <MenuItem key={`${row}_${index}`} value={row}>
                {i18n.t(`report.settings.order.${row}`)}
              </MenuItem>
            ))}
          </TextField>
        </AccordionDetails>
      </Accordion>
      {(options.mode === "summary" ||
        options.mode === "recommendation" ||
        options.mode === "preMan") && (
        <>
          <Accordion
            expanded={expanded === 2}
            onChange={() => setExpanded(expanded === 2 ? -1 : 2)}
          >
            <AccordionSummary
              className={
                expanded === 2 ? ownStyles.AcordionSelectedTitle : null
              }
              expandIcon={
                <ExpandMore
                  className={
                    expanded === 2 ? ownStyles.AcordionSelectedTitleIcon : null
                  }
                />
              }
            >
              <div>{i18n.t("report.settings.column")}</div>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={ownStyles.columnContainer}>
              <div className={ownStyles.column}>
                <div className={ownStyles.checkboxContainer}>
                  <Checkbox
                    name="idCliente"
                    edge="start"
                    checked={options.column[10]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          ...old.column.slice(0, 10),
                          !old.column[10],
                          ...old.column.slice(11),
                        ],
                        width: !old.column[10]
                          ? old.width + col.med
                          : old.width - col.med,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.idCliente")}
                  </div>
                </div>
                <div className={ownStyles.checkboxContainer}>
                  <Checkbox
                    name="tc"
                    edge="start"
                    checked={options.column[0]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [!old.column[0], ...old.column.slice(1)],
                        width: !old.column[0]
                          ? old.width + col.max
                          : old.width - col.max,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.tc")}
                  </div>
                </div>
                <div className={ownStyles.checkboxContainer}>
                  <Checkbox
                    name="frame"
                    edge="start"
                    checked={options.column[1]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          old.column[0],
                          !old.column[1],
                          ...old.column.slice(2),
                        ],
                        width: !old.column[1]
                          ? old.width + col.min
                          : old.width - col.min,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.frame")}
                  </div>
                </div>
                <div
                  className={ownStyles.checkboxContainer}
                  hidden={
                    options.mode !== "summary" &&
                    options.mode !== "recommendation"
                  }
                >
                  <Checkbox
                    name="voltage"
                    edge="start"
                    checked={options.column[2]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          ...old.column.slice(0, 2),
                          !old.column[2],
                          ...old.column.slice(3),
                        ],
                        width: !old.column[2]
                          ? old.width + col.min
                          : old.width - col.min,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.voltage")}
                  </div>
                </div>
                <div
                  className={ownStyles.checkboxContainer}
                  hidden={
                    options.mode !== "summary" &&
                    options.mode !== "recommendation"
                  }
                >
                  <Checkbox
                    name="motorConnected"
                    edge="start"
                    checked={options.column[13]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          ...old.column.slice(0, 13),
                          !old.column[13],
                          ...old.column.slice(14),
                        ],
                        width: !old.column[13]
                          ? old.width + col.min
                          : old.width - col.min,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.motorConnected")}
                  </div>
                </div>
                <div
                  className={ownStyles.checkboxContainer}
                  hidden={
                    options.mode !== "summary" &&
                    options.mode !== "recommendation"
                  }
                >
                  <Checkbox
                    name="year"
                    edge="start"
                    checked={options.column[3]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          ...old.column.slice(0, 3),
                          !old.column[3],
                          ...old.column.slice(4),
                        ],
                        width: !old.column[3]
                          ? old.width + col.min
                          : old.width - col.min,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.añoFabricacion")}
                  </div>
                </div>
                <div className={ownStyles.checkboxContainer}>
                  <Checkbox
                    name="zone"
                    edge="start"
                    checked={options.column[4]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          ...old.column.slice(0, 4),
                          !old.column[4],
                          ...old.column.slice(5),
                        ],
                        width: !old.column[4]
                          ? old.width + col.med
                          : old.width - col.med,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.zona")}
                  </div>
                </div>
                <div className={ownStyles.checkboxContainer}>
                  <Checkbox
                    name="zoneAndSubzone"
                    edge="start"
                    checked={options.column[14] && options.column[4]}
                    disableRipple
                    disabled={!options.column[4]}
                    className={
                      options.column[4]
                        ? styles.checkBoxForm
                        : styles.checkBoxFormDisabled
                    }
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [...old.column.slice(0, 14), !old.column[14]],
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.zonaYSubzona")}
                  </div>
                </div>
              </div>
              <div className={ownStyles.column}>
                <div
                  className={ownStyles.checkboxContainer}
                  hidden={
                    options.mode !== "summary" &&
                    options.mode !== "recommendation"
                  }
                >
                  <Checkbox
                    name="applicationName"
                    edge="start"
                    checked={options.column[11]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          ...old.column.slice(0, 11),
                          !old.column[11],
                          ...old.column.slice(12),
                        ],
                        width: !old.column[11]
                          ? old.width + col.min
                          : old.width - col.min,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.applicationName")}
                  </div>
                </div>
                <div
                  className={ownStyles.checkboxContainer}
                  hidden={
                    options.mode !== "summary" &&
                    options.mode !== "recommendation"
                  }
                >
                  <Checkbox
                    name="powerSize"
                    edge="start"
                    checked={options.column[12]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          ...old.column.slice(0, 12),
                          !old.column[12],
                          ...old.column.slice(13),
                        ],
                        width: !old.column[12]
                          ? old.width + col.min
                          : old.width - col.min,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.powerSize")}
                  </div>
                </div>
                <div
                  className={ownStyles.checkboxContainer}
                  hidden={options.mode !== "summary"}
                >
                  <Checkbox
                    name="lifeCycle"
                    edge="start"
                    checked={options.column[5]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          ...old.column.slice(0, 5),
                          !old.column[5],
                          ...old.column.slice(6),
                        ],
                        width: !old.column[5]
                          ? old.width + col.min
                          : old.width - col.min,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.cicloVida")}
                  </div>
                </div>
                <div
                  className={ownStyles.checkboxContainer}
                  hidden={options.mode !== "summary"}
                >
                  <Checkbox
                    name="criticality"
                    edge="start"
                    checked={options.column[6]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          ...old.column.slice(0, 6),
                          !old.column[6],
                          ...old.column.slice(7),
                        ],
                        width: !old.column[6]
                          ? old.width + col.min
                          : old.width - col.min,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.criticidad")}
                  </div>
                </div>
                <div
                  className={ownStyles.checkboxContainer}
                  hidden={options.mode !== "summary"}
                >
                  <Checkbox
                    name="status"
                    edge="start"
                    checked={options.column[7]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          ...old.column.slice(0, 7),
                          !old.column[7],
                          ...old.column.slice(8),
                        ],
                        width: !old.column[7]
                          ? old.width + col.min
                          : old.width - col.min,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.estado")}
                  </div>
                </div>
                <div
                  className={ownStyles.checkboxContainer}
                  hidden={
                    options.mode !== "summary" &&
                    options.mode !== "recommendation"
                  }
                >
                  <Checkbox
                    name="risk"
                    edge="start"
                    checked={options.column[8]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          ...old.column.slice(0, 8),
                          !old.column[8],
                          ...old.column.slice(9),
                        ],
                        width: !old.column[8]
                          ? old.width + col.min
                          : old.width - col.min,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.riesgo")}
                  </div>
                </div>
                <div
                  className={ownStyles.checkboxContainer}
                  hidden={options.mode !== "recommendation"}
                >
                  <Checkbox
                    name="recommendation"
                    edge="start"
                    checked={options.column[9]}
                    disableRipple
                    className={styles.checkBoxForm}
                    color="default"
                    onChange={() => {
                      setOptions((old) => ({
                        ...old,
                        column: [
                          ...old.column.slice(0, 9),
                          !old.column[9],
                          ...old.column.slice(10),
                        ],
                        width: !old.column[9]
                          ? old.width + col.max
                          : old.width - col.max,
                      }));
                    }}
                  />
                  <div className={ownStyles.checkboxLabel}>
                    {i18n.t("report.driveFields.recommendation")}
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 3}
            onChange={() => setExpanded(expanded === 3 ? -1 : 3)}
          >
            <AccordionSummary
              className={
                expanded === 3 ? ownStyles.AcordionSelectedTitle : null
              }
              expandIcon={
                <ExpandMore
                  className={
                    expanded === 3 ? ownStyles.AcordionSelectedTitleIcon : null
                  }
                />
              }
            >
              <div>{i18n.t("report.settings.font")}</div>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <RadioGroup value={options.font}>
                {sizes.map((row, index) => {
                  const semaf = row > fontLimits[options.width];
                  return (
                    <FormControlLabel
                      disabled={semaf}
                      key={`${row}_${index}`}
                      value={row}
                      control={
                        semaf ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: 42,
                              height: 42,
                            }}
                          >
                            <Cancel />
                          </div>
                        ) : (
                          <Radio
                            style={{
                              color: "black",
                              backgroundColor: "transparent",
                            }}
                          />
                        )
                      }
                      label={row + ""}
                      onClick={() =>
                        !semaf && setOptions((old) => ({ ...old, font: row }))
                      }
                    />
                  );
                })}
              </RadioGroup>
            </AccordionDetails>
          </Accordion>
        </>
      )}
      {options.mode === "preMan" && (
        <Accordion
          expanded={expanded === 4}
          onChange={() => setExpanded(expanded === 4 ? -1 : 4)}
        >
          <AccordionSummary
            className={expanded === 4 ? ownStyles.AcordionSelectedTitle : null}
            expandIcon={
              <ExpandMore
                className={
                  expanded === 4 ? ownStyles.AcordionSelectedTitleIcon : null
                }
              />
            }
          >
            <div>{i18n.t("report.settings.year")}</div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className={ownStyles.AcordionContainer}>
            <Slider
              name="years"
              disabled={options.yearEnd - options.yearStart <= 10}
              defaultValue={options.years[0]}
              style={{ color: "black" }}
              valueLabelDisplay="auto"
              min={options.yearStart}
              max={options.yearEnd}
              onChangeCommitted={(e, value) =>
                setOptions((old) => {
                  let year = parseInt(value.toString());
                  return {
                    ...old,
                    years: [
                      year,
                      year + 1,
                      year + 2,
                      year + 3,
                      year + 4,
                      year + 5,
                      year + 6,
                      year + 7,
                      year + 8,
                      year + 9,
                    ],
                  };
                })
              }
            />
            <div style={{ alignSelf: "end" }}>
              {options.years[0] +
                " - " +
                options.years[options.years.length - 1]}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}

export default GeneralOptions;
