import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Cancel,
  Save,
  Close,
} from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { isVacon, EndcustomerCanMangeThisZone } from "../../../helpers/Common";
import i18n from "../../../locales/i18n";
import { DocumentsAPI } from "../../../services/API";
import AlertDialog from "../../AlertDialog";
import CardImagenesVariador from "../../molecule/cardImgVariador/cardImgVariador";
import RelativeBackdrop from "../../RelativeBackdrop";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import DriveIcon from "../../atoms/driveIcon";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import AuthService from "../../../services/AuthService";

/**
 * Dialog para Revision de variadores
 * @Component
 * @param {Object} props
 * @param {Boolean} props.open
 * @param {Boolean} props.readOnly
 * @param {() => void} props.closeFunction
 * @param {Object} props.equipo
 * @param {function} props.setEquipo
 * @param {Object} props.brands
 * @param {() => void} props.siguienteEquipo
 * @param {() => void} props.anteriorEquipo
 * @param {(any) => void} props.handleInputChange
 * @param {(inactivo, provisional) => void} props.guardarEquipo
 *
 */
export default function DialogRevisionEquipos(props) {
  const {
    open,
    closeFunction,
    equipo,
    setEquipo,
    brands,
    siguienteEquipo,
    anteriorEquipo,
    handleInputChange,
    guardarEquipo,
    readOnly,
  } = props;
  const colWith = 6;
  const errors = [];
  const isEndCustomer = AuthService.hasRoleEndCustomer();

  const [equipoId, setEquipoId] = useState(0);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [readOnlyUser, setReadOnlyUser] = useState(readOnly);
  const styles = DetailTabs_Style();

  const [brandOpen, setBrandOpen] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);

  const handleBrand = () => {
    if (!readOnlyUser) setBrandOpen(!brandOpen);
  };

  const handleModel = () => {
    if (!readOnlyUser) setModelOpen(!modelOpen);
  };

  const provisionalDialog = useRef(null);

  useEffect(() => {
    if (equipoId) {
      setImages([]);
      setLoading(true);
      DocumentsAPI.downloadEquipoImages(equipoId)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          setImages(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [equipoId]);

  useEffect(() => {
    if (equipo && equipo.id !== equipoId) {
      setEquipoId(equipo.id);
      //Comprobar si el endcustomer puede modificar este equipo
      if (isEndCustomer && equipo?.zona?.id) {
        setReadOnlyUser(!EndcustomerCanMangeThisZone(equipo.zona.id));
      }
    }
  }, [equipo, equipoId, isEndCustomer]);

  return (
    <>
      <Dialog
        open={open}
        onClose={closeFunction}
        fullWidth={true}
        maxWidth={"md"}
        style={{ marginTop: 50 }}
      >
        <RelativeBackdrop hidden={!loading}>
          <DialogTitle>
            <Grid container spacing={3}>
              <Grid item>
                <Avatar aria-label="avatar" className={styles.tabHeaderAvatar}>
                  <DriveIcon />
                </Avatar>
              </Grid>
              <Grid item>{equipo?.nombre}</Grid>
              <div style={{ marginLeft: "auto" }}>
                {equipo?.zona && equipo?.zona.isWarehouse && (
                  <div className={styles.flexPill}>
                    <span className={styles.pillText}>
                      {i18n.t("zona.warehouse")}
                    </span>
                  </div>
                )}
                {equipo?.riesgo === 3 &&
                  !equipo.zona.isWarehouse &&
                  !equipo.inactivo &&
                  !equipo.provisional && (
                    <div
                      className={styles.flexPill}
                      style={{ backgroundColor: "#FFAA00" }}
                    >
                      <span className={styles.pillText}>
                        {i18n.t("equipo.undefinedRisk")}
                      </span>
                    </div>
                  )}
                {equipo?.equipoMantenimientos &&
                  equipo?.equipoMantenimientos.length !== 0 && (
                    <div className={styles.flexPill}>
                      <span className={styles.pillText}>
                        {i18n.t("common.manteinance")}
                      </span>
                    </div>
                  )}
                {equipo?.provisional && (
                  <div className={styles.flexWarningPill}>
                    <span className={styles.pillProvisionalText}>
                      {i18n.t("equipo.provisional")}
                    </span>
                  </div>
                )}
                {equipo?.inactivo && (
                  <div className={styles.flexPill}>
                    <span className={styles.pillText}>
                      {i18n.t("equipo.inactive")}
                    </span>
                  </div>
                )}
              </div>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ height: "700px" }}>
            {/*@ts-ignore*/}
            <PerfectScrollbar>
              <Grid container>
                <Grid item lg={6}>
                  <form autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={colWith} lg={11}>
                        <TextField
                          className={styles.materialTextField}
                          required
                          fullWidth
                          value={equipo?.sn || ""}
                          label={i18n.t("equipo.sn")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnlyUser,
                          }}
                          name="sn"
                          error={
                            errors["SN"] !== undefined ||
                            errors["SN_Unique"] !== undefined
                          }
                          helperText={
                            errors["SN"]
                              ? i18n.t("textField.required")
                              : errors["SN_Unique"]
                              ? i18n.t("textField.unique", { name: "SN" })
                              : null
                          }
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <TextField
                          className={styles.materialTextField}
                          required
                          fullWidth
                          value={equipo?.pn || ""}
                          label={i18n.t("equipo.pn")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnlyUser,
                          }}
                          name="pn"
                          id="pn"
                          error={errors["PN"] !== undefined}
                          helperText={
                            errors["PN"] ? i18n.t("textField.required") : null
                          }
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <TextField
                          className={styles.materialTextField}
                          fullWidth
                          value={equipo?.tc || ""}
                          label={
                            isVacon(equipo?.brand)
                              ? i18n.t("equipo.vType")
                              : i18n.t("equipo.tc")
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnlyUser,
                          }}
                          name="tc"
                          id="tc"
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <TextField
                          className={styles.materialTextField}
                          fullWidth
                          value={equipo?.idCliente || ""}
                          label={i18n.t("equipo.idCliente")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnlyUser,
                          }}
                          name="idCliente"
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <TextField
                          className={styles.materialTextField}
                          fullWidth
                          value={equipo?.nombre || ""}
                          label={i18n.t("equipo.nombre")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnlyUser,
                          }}
                          name="nombre"
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <FormControl fullWidth>
                          <Autocomplete
                            open={readOnlyUser ? false : brandOpen}
                            onOpen={handleBrand}
                            onClose={handleBrand}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            options={brands || []}
                            getOptionLabel={(option) => option.name || ""}
                            value={equipo.brand || null}
                            autoComplete={!readOnlyUser}
                            closeIcon={readOnlyUser ? null : <Close />}
                            openOnFocus={!readOnlyUser}
                            freeSolo={readOnlyUser}
                            onChange={(event, newValue) => {
                              handleInputChange({
                                target: {
                                  name: "brandId",
                                  value: newValue?.id || "",
                                },
                              });
                              setEquipo((old) => ({ ...old, brand: newValue }));
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  readOnly: readOnlyUser,
                                }}
                                InputLabelProps={{
                                  shrink: readOnlyUser ? true : undefined,
                                }}
                                name={"brandId"}
                                label={i18n.t("maintenance.fields.brand")}
                                variant="standard"
                                className={styles.materialTextField}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <FormControl
                          fullWidth
                          className={styles.materialTextField}
                        >
                          <Autocomplete
                            open={readOnlyUser ? false : modelOpen}
                            onOpen={handleModel}
                            onClose={handleModel}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            disabled={
                              !(
                                equipo?.brand?.models &&
                                equipo.brand.models.length > 0
                              )
                            }
                            options={equipo.brand?.models || []}
                            getOptionLabel={(option) => option.name || ""}
                            value={equipo.model || null}
                            autoComplete={!readOnlyUser}
                            closeIcon={readOnlyUser ? null : <Close />}
                            openOnFocus={!readOnlyUser}
                            freeSolo={readOnlyUser}
                            onChange={(event, newValue) => {
                              handleInputChange({
                                target: {
                                  name: "modelId",
                                  value: newValue?.id || "",
                                },
                              });
                              setEquipo((old) => ({ ...old, model: newValue }));
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  readOnly: readOnlyUser,
                                }}
                                InputLabelProps={{
                                  shrink: readOnlyUser ? true : undefined,
                                }}
                                name={"modelId"}
                                label={i18n.t("maintenance.fields.model")}
                                variant="standard"
                                className={styles.materialTextField}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <TextField
                          className={styles.materialTextField}
                          fullWidth
                          value={equipo?.descripcion || ""}
                          label={i18n.t("equipo.descripcion")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnlyUser,
                          }}
                          name="descripcion"
                          error={errors["Descripcion"] !== undefined}
                          helperText={
                            errors["Descripcion"]
                              ? i18n.t("textField.required")
                              : null
                          }
                          onChange={handleInputChange}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid
                  item
                  lg={6}
                  style={{ overflowX: "auto", maxHeight: "650px" }}
                >
                  <RelativeBackdrop hidden={!loading}>
                    {images.length !== 0 ? (
                      <CardImagenesVariador
                        images={images}
                        isExchange={true}
                        hideHeader={true}
                        hideBorder={true}
                        isReview={true}
                      />
                    ) : (
                      <div style={{ alignSelf: "center" }}>
                        <Typography variant="button">
                          {i18n.t("planta.revisionEquipos.noImage")}
                        </Typography>
                      </div>
                    )}
                  </RelativeBackdrop>
                </Grid>
              </Grid>
            </PerfectScrollbar>
          </DialogContent>

          <DialogActions>
            <Grid
              item
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Button
                  startIcon={<ArrowBackIos />}
                  onClick={() => {
                    anteriorEquipo();
                  }}
                  className={styles.navigationButton}
                >
                  {i18n.t("planta.revisionEquipos.prev")}
                </Button>
              </Grid>
              <Grid item>
                {!readOnlyUser && (
                  <Button
                    onClick={() =>
                      equipo.provisional
                        ? provisionalDialog.current.open()
                        : guardarEquipo()
                    }
                    className={styles.saveDialogEquipmentButton}
                    variant="contained"
                    endIcon={<Save />}
                  >
                    {i18n.t("planta.revisionEquipos.save")}
                  </Button>
                )}
                <Button
                  onClick={() => closeFunction()}
                  endIcon={<Cancel />}
                  variant="outlined"
                  className={styles.closeDialogEquipmentButton}
                >
                  {i18n.t("planta.revisionEquipos.close")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    siguienteEquipo();
                  }}
                  className={styles.navigationButton}
                  endIcon={<ArrowForwardIos />}
                >
                  {i18n.t("planta.revisionEquipos.next")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </RelativeBackdrop>
      </Dialog>
      {/*@ts-ignore*/}
      <AlertDialog
        ref={provisionalDialog}
        loading={loading}
        title={i18n.t("equipo.provisionalTitle")}
        text={i18n.t("equipo.provisionalText")}
        cancelText={i18n.t("common.cancelar")}
        confirmText={i18n.t("common.confirmar")}
        confirmAction={() => {
          guardarEquipo(undefined, false);
          provisionalDialog.current.close();
        }}
      />
    </>
  );
}
