import React, { useRef, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
  TextField,
  Tooltip,
} from "@material-ui/core";
import i18n from "../../../locales/i18n";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { Camera, DeleteForever } from "@material-ui/icons";
import AlertDialog from "../../AlertDialog";
import { DetailTabs_Style } from "../../../assets/css/Styles";

function CardImagenesVariador(props) {
  const styles = DetailTabs_Style();
  const {
    isPremium,
    images,
    removeImage,
    isExchange,
    addImage,
    loading,
    readOnly,
    hideHeader,
  } = props;
  const [imageIndex, setImageIndex] = useState(0);
  const badImageFormatDialog = useRef(null);
  const imageDialog = useRef(null);
  const fileInputRef = useRef(null);

  const imageAdd = (file) => {
    imageDialog.current.close();
    if (file.type === "image/jpeg" || file.type === "image/png") {
      addImage(file);
    } else {
      badImageFormatDialog.current.open();
    }
  };

  return (
    <>
      <Card>
        {!hideHeader && (
          <CardHeader
            title={i18n.t("common.imagen")}
            avatar={
              <Avatar className={styles.tabHeaderAvatar}>
                <Camera />
              </Avatar>
            }
          />
        )}
        <CardContent>
          {!isExchange && !readOnly && (
            <div style={{ maxWidth: "fit-content" }}>
              {
                <Tooltip
                  title={i18n.t("common.isPremium")}
                  placement={"right"}
                  disableHoverListener={isPremium}
                  arrow
                >
                  <div>
                    <Button
                      variant="contained"
                      onClick={() => imageDialog.current.open()}
                      className={styles.primaryFormButton}
                      disabled={!isPremium}
                    >
                      {i18n.t("equipo.imagen.addImagen")}
                    </Button>
                  </div>
                </Tooltip>
              }
            </div>
          )}
          <Grid container>
            <Grid
              item
              container
              xs={2}
              style={{
                zIndex: 1,
                alignContent: "start",
                backgroundColor: "white",
              }}
            >
              {images.map((image, index) => (
                <a
                  download={`image${index}.jpg`}
                  style={{ width: "100%" }}
                  href={image.base64}
                  onClick={(e) => {
                    e.preventDefault();
                    setImageIndex(index);
                  }}
                >
                  <Paper style={{ marginTop: 10 }} variant="outlined">
                    <Typography
                      style={{
                        marginLeft: 10,
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {!hideHeader ? `image${index}.jpg` : ""}
                      {!isExchange && !readOnly && (
                        <Button
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            removeImage(image.id);
                            return false;
                          }}
                          className={styles.imageSecondaryButton}
                        >
                          <DeleteForever />
                        </Button>
                      )}
                    </Typography>
                    <div
                      className={styles.imageCardContainer}
                      style={{
                        backgroundImage: `url(${image.base64})`,
                      }}
                    ></div>
                  </Paper>
                </a>
              ))}
            </Grid>
            {images.length > 0 && (
              <div className={styles.imageReactPanZoomContainer}>
                {/* @ts-ignore */}
                <ReactPanZoom
                  image={`${images[imageIndex]?.base64}`}
                  alt="Drive"
                />
              </div>
            )}
          </Grid>
        </CardContent>
      </Card>
      {/* @ts-ignore */}
      <AlertDialog
        ref={imageDialog}
        title={i18n.t("equipo.imagen.uploadImage")}
        loading={loading}
        text={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                inputRef={fileInputRef}
                type="file"
                className={styles.materialTextField}
              />
            </Grid>
          </Grid>
        }
        cancelText={i18n.t("documents.alertDialog.cancelText")}
        confirmText="Subir"
        confirmAction={() =>
          fileInputRef.current.files.length === 0
            ? null
            : imageAdd(fileInputRef.current.files[0])
        }
      />
      {/* @ts-ignore */}
      <AlertDialog
        ref={badImageFormatDialog}
        title={i18n.t("equipo.imagen.errorAlert")}
        text={i18n.t("equipo.imagen.errorMessage")}
        confirmText={i18n.t("cliente.alertDialog.close")}
        confirmAction={() => badImageFormatDialog.current.close()}
      />
    </>
  );
}

export default CardImagenesVariador;
