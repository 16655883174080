import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function uploadInventario(plantaId, formData) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/inventario/${plantaId}/import`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    body: formData,
  });
}

export function add(equipo) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(equipo),
  });
}

export function update(equipo) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${equipo.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(equipo),
  });
}

export function updateProp(id, newValue, propName) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/prop/${propName}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ newValue: newValue }),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

//Get equipos desde tab equipos al entrar en zona de planta
export function getEquiposByZona(id = "", filter) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/zona/${id}/${filter}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getEquiposByplanta(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/planta/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getAssessment() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/assessment`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getAssessmentByPlanta(plantaId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/planta/${plantaId}/assessment`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function postAssessment(zonas) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/assessment`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(zonas),
  });
}

//get Infinite desde tab equipos en planta y zona
export function getInfiniteDrives(
  plantaId = 0,
  zonaId = 0,
  selectorFilter = 0,
  drivesRendered = 0,
  searchQuery = "",
  filters = []
) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;
  const bodyRequest = {
    searchQuery: searchQuery,
    filters: filters,
  };
  return fetch(
    `${baseUrl}/api/equipos/plant/${plantaId}/zone/${zonaId}/${selectorFilter}/${drivesRendered}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyRequest),
    }
  );
}

//Filtro de equipos
export function getDriveFilters(plantaId = 0, zonaId = 0) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipos/plant/${plantaId}/zone/${zonaId}/filters`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    }
  );
}

//check All Lista de equipos
export function getCheckDrives(
  plantaId = 0,
  zonaId = 0,
  selectorFilter = 0,
  searchQuery = "",
  filters = []
) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;
  const bodyRequest = {
    searchQuery: searchQuery,
    filters: filters,
  };
  return fetch(
    `${baseUrl}/api/equipos/plant/${plantaId}/zone/${zonaId}/${selectorFilter}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyRequest),
    }
  );
}

//get equipos desde tab equipos al entrar en planta
export function takeEquipoByPlanta(plantaId, fromId, filter) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipos/planta/${plantaId}/${fromId}/${filter}`,
    {
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );
}

//Busqueda QUERY get equipos desde planta
export function searchEquipoByPlanta(plantaId, query, filter) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipos/planta/${plantaId}/search/${query}/${filter}`,
    {
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );
}

//Busqueda QUERY get equipos desde zona de planta
export function searchEquipoByZona(zonaId, query, filter) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipos/zona/${zonaId}/search/${query}/${filter}`,
    {
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );
}

export function getReplacementEquipment(equipmentId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${equipmentId}/replacement`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getExportInventario(equipos, locale) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/inventario/export`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ equipos: equipos, locale: locale }),
  });
}

export function getExportInventarioDanfoss(equipos, locale) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/inventario/exportDanfoss`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ equipos: equipos, locale: locale }),
  });
}

export function sendOferta(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/oferta`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function getReport(plantId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/planta/${plantId}/report`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function postReport(plantId, filtros) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/planta/${plantId}/report`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(filtros),
  });
}

export async function getEquiposDanfossDb(pn, sn, brand) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/danfossdb`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      pn: pn,
      sn: sn,
      brand: brand,
    }),
  });
}

export async function getEquipmentLogs(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/logs`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getRetrofitEquipment(equipmentId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${equipmentId}/retrofit`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function sendOfertaRetrofit(id, pn, tc) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/oferta/retrofit`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ pn: pn, tc: tc }),
  });
}

export function sendOfertaExchange(id, pn, tc) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/oferta/exchange`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ pn: pn, tc: tc }),
  });
}

export function getEquiposToCopy(zonaId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/toCopy/zona/${zonaId}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}
