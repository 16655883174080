import React, { useState, useEffect, useContext, useCallback } from "react";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { Public, SupervisorAccount } from "@material-ui/icons";
import CountryNotificationTab from "../../molecule/BroadcastTabs/CountryNotificationTab";
import PartnerNotificationTab from "../../molecule/BroadcastTabs/PartnerNotificationTab";
import { DetailTabs_Style, Common_Style } from "../../../assets/css/Styles";
import i18n from "../../../locales/i18n";
import AuthService from "../../../services/AuthService";
import RelativeBackdrop from "../../RelativeBackdrop";
import AppContext from "../../../contexts/AppContext";
import { BrandAPI, CountriesAPI, PartnersAPI } from "../../../services/API";
import VaconMaintenance from "../../../helpers/vacon-maintenance-calculation.json";
import VLTMaintenance from "../../../helpers/vlt-maintenance-calculation.json";
import { isVacon } from "../../../helpers/Common";

const AllCountriesOption = {
  id: -1,
  name: "All countries",
  code: "ALL",
};

export default () => {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const isPartner = AuthService.hasRolePartner();
  const isAdmin = AuthService.hasRoleAdmin();
  const isDsc = AuthService.hasRoleDSC();
  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();
  const [brandSelected, setBrandSelected] = useState(null);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  //Para que no se pueda acceder a la pagina sin permisos
  if (!isDsc && !isPartner && !isAdmin) {
    window.location.replace("/unauthorized");
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const callBrands = useCallback(() => {
    BrandAPI.getDanfossBrands()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setBrands(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const callCountries = useCallback(() => {
    CountriesAPI.get()
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error(response.json());
        }
      })
      .then((data) => {
        data.sort((a, b) =>
          i18n.t(`country.${a.name}`).localeCompare(i18n.t(`country.${b.name}`))
        );
        data.unshift(AllCountriesOption);
        setCountries(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const callPartners = useCallback(() => {
    PartnersAPI.getPartnersBroadcast()
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error(response.json());
        }
      })
      .then((data) => {
        setPartners(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setGlobal((prev) => ({ ...prev, pageTitle: i18n.t("broadcast.title") }));
    callBrands();
    //si no es partner se llama a los paises
    if (!isPartner) {
      callPartners();
    }

    //en caso de admin se llama a los paises
    if (isAdmin) {
      callCountries();
    }
  }, [callBrands, callCountries, callPartners, isAdmin, isPartner, setGlobal]);

  const handleChangeDriveInfo = (event) => {
    let property = event.target.name;
    let value = event.target.value;

    switch (property) {
      case "brandId":
        if (value !== brandSelected) {
          setBrandSelected(value);
          let actualBrand = brands.find((brand) => brand.id === value);
          //actualizar lista de modelos
          setModels(actualBrand.models);
          //actualizar lista de framesizes
          isVacon(actualBrand)
            ? setSizes(VaconMaintenance.frames.map((f) => f.sizes).flat())
            : setSizes(VLTMaintenance.frames.map((f) => f.sizes).flat());
        }
        break;
      //por ahora no se usa pero por si acaso
      case "modelsId":
        break;
      case "frameSizes":
        break;
      default:
    }
  };

  return (
    <>
      {loading ? (
        <RelativeBackdrop hidden={false}></RelativeBackdrop>
      ) : (
        <div className={commonStyles.mainContainer}>
          {isAdmin && (
            <Paper className={styles.plantPaper} square>
              <Tabs
                scrollButtons="on"
                variant="scrollable"
                indicatorColor="secondary"
                textColor="inherit"
                classes={{ indicator: styles.tabsHeader }}
                value={tabValue}
                onChange={handleChangeTab}
              >
                <Tab
                  value={0}
                  label={
                    <span className={styles.detailTabLabel}>
                      {i18n.t("broadcast.globalTab.titleTab")}
                    </span>
                  }
                  icon={<Public />}
                  id="simple-tab-0"
                  disabled={loading}
                  data-testid="broadcastTab-global"
                />

                <Tab
                  value={1}
                  label={
                    <span className={styles.detailTabLabel}>
                      {i18n.t("broadcast.partnerTab.titleTab")}
                    </span>
                  }
                  icon={<SupervisorAccount />}
                  id="simple-tab-1"
                  disabled={loading}
                  data-testid="broadcastTab-partner"
                />
              </Tabs>
            </Paper>
          )}
          {tabValue === 0 && isAdmin && (
            <CountryNotificationTab
              countries={countries}
              brands={brands}
              models={models}
              sizes={sizes}
              handleChangeDriveInfo={handleChangeDriveInfo}
            />
          )}
          {/*  */}
          {(tabValue === 1 || isPartner || isDsc) && (
            <PartnerNotificationTab
              brands={brands}
              models={models}
              sizes={sizes}
              handleChangeDriveInfo={handleChangeDriveInfo}
              isPartner={isPartner}
              partners={partners}
            />
          )}
        </div>
      )}
    </>
  );
};
