import packageJson from "../../../package.json";
// @ts-nocheck
import React, { useState, useContext, useEffect } from "react";
import "./main.scss";
import "./main.css";
import {
  IconButton,
  Snackbar,
  Slide,
  Link as MLink,
  Badge,
  createMuiTheme,
  ThemeProvider,
  Avatar,
} from "@material-ui/core";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import {
  Business,
  Settings,
  Menu as MenuIcon,
  ShowChart,
  ArrowForwardIos,
  ArrowBackIos,
  ArrowBack,
  DashboardOutlined,
  SettingsInputAntenna,
  Person,
} from "@material-ui/icons";
import AuthService from "../../services/AuthService";
import i18n from "../../locales/i18n";
import AppContext from "../../contexts/AppContext";
import SettingsDialog from "../molecule/settingsDialog/SettingsDialog";
import UserPopover from "../molecule/UserPopover/UserPopover";
import { Alert } from "@material-ui/lab";
import { useAuth0 } from "@auth0/auth0-react";
import { SnackbarClose, termsUrl } from "../../helpers/Common";
import { globalStatusColors } from "../../assets/css/Styles";
import { useCallback } from "react";
import { ClientesSolicitudesAPI } from "../../services/API";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import PartnerClientIcon from "../atoms/partnerClientIcon";

const Main = (props) => {
  const { children, history } = props;
  //Context
  // @ts-ignore
  const { global, setGlobal } = useContext(AppContext);
  const { logout } = useAuth0();
  const locale = i18n.locale || i18n.defaultLocale;
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [openSettings, setOpenSettings] = useState(false);
  const [openUserPopover, setUserPopover] = useState(false);
  const [anchorUser, setAnchorUser] = useState(null);
  const [broadcastAccess, setBroadcastAccess] = useState(false);

  const [requestNumber, setRequestNumber] = useState(0);
  const isPremium = AuthService.userPremium();
  const userInfo = AuthService.getUserInfo();
  const avatarText = userInfo
    ? userInfo.nombre.charAt(0) + userInfo.apellidos.charAt(0)
    : "N/A";

  //abrir Dialog Settings
  const openSettingsDialog = () => {
    setOpenSettings(!openSettings);
  };

  const logOutFunction = () => {
    AuthService.logOut();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    return <Link to="/login" />;
  };

  //handle Popover User
  const handleUserPopover = (event) => {
    if (openUserPopover) {
      setAnchorUser(null);
    } else {
      setAnchorUser(event.currentTarget);
    }
    setUserPopover(!openUserPopover);
  };

  const getNumberOfRequests = useCallback(() => {
    if (global.update) {
      setGlobal((prev) => ({ ...prev, update: false }));
    }
    ClientesSolicitudesAPI.getCount()
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.statusText);
        }
      })
      .then((data) => {
        setRequestNumber(data.requestNumber);
      })
      .catch((err) => {
        console.log("Get request number", err);
      });
  }, [global.update, setGlobal]);

  useEffect(() => {
    if (
      AuthService.hasRoleAdmin() ||
      AuthService.hasRoleDSC() ||
      AuthService.hasRolePartner()
    ) {
      getNumberOfRequests();
      //flag de acceso al broadcast
      setBroadcastAccess(true);
    }
  }, [getNumberOfRequests]);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: globalStatusColors.infoColor,
      },
    },
  });

  return (
    <div id="page">
      <>
        <ProSidebar
          collapsed={menuCollapse}
          id="menu"
          className={menuCollapse ? "menuhidder" : ""}
        >
          <SidebarHeader style={{ backgroundColor: "#E2000f" }}>
            {menuCollapse ? (
              <IconButton
                className="icon"
                aria-label="Hide Menu"
                style={{
                  height: "35px",
                  color: "white",
                  width: "35px",
                  margin: "7px",
                  marginTop: "15px",
                  marginLeft: "22px",
                  marginBottom: "14px",
                }}
                onClick={() => {
                  setMenuCollapse(!menuCollapse);
                }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  style={{
                    width: "200px",
                    margin: "7px",
                    marginTop: "12px",
                    marginLeft: "15px",
                    marginBottom: "10px",
                  }}
                  alt=""
                  // @ts-ignore
                  src={require("../img/logoDraw.png")}
                />
                <IconButton
                  className="icon"
                  aria-label="Hide Menu"
                  style={{ color: "white" }}
                  onClick={() => {
                    setMenuCollapse(!menuCollapse);
                  }}
                >
                  {menuCollapse ? <ArrowForwardIos /> : <ArrowBackIos />}
                </IconButton>
              </div>
            )}
          </SidebarHeader>
          <PerfectScrollbar>
            <SidebarContent>
              <Menu>
                <Tooltip
                  title={
                    isPremium
                      ? menuCollapse
                        ? i18n.t("sidebar.dashboard")
                        : ""
                      : i18n.t("sidebar.dashboardPremium")
                  }
                  placement="right"
                  arrow
                >
                  <ul>
                    <MenuItem
                      active={window.location.pathname === "/"}
                      icon={<DashboardOutlined />}
                      className={!isPremium && "sidebarMenuItemDisabled"}
                    >
                      {i18n.t("sidebar.dashboard")}
                      {isPremium !== 0 && <Link to="/" />}
                    </MenuItem>
                  </ul>
                </Tooltip>

                {AuthService.isAuthenticated() && (
                  <Tooltip
                    title={
                      menuCollapse ? i18n.t("sidebar.partnersClients") : ""
                    }
                    placement="right"
                    arrow
                  >
                    <MenuItem
                      active={window.location.pathname.includes(
                        "/partnersclients"
                      )}
                      icon={
                        <PartnerClientIcon
                          style={{ flexShrink: 0, fontSize: "25px" }}
                          color={
                            window.location.pathname.includes(
                              "/partnersclients"
                            ) && "black"
                          }
                        />
                      }
                    >
                      {i18n.t("sidebar.partnersClients")}
                      <Link to="/partnersclients" />
                    </MenuItem>
                  </Tooltip>
                )}
                <>
                  {AuthService.isAuthenticated() && (
                    <Tooltip
                      title={menuCollapse ? i18n.t("sidebar.plantas") : ""}
                      placement="right"
                      arrow
                    >
                      <MenuItem
                        active={window.location.pathname.includes("/plantas")}
                        icon={<Business />}
                      >
                        {i18n.t("sidebar.plantas")}
                        <Link to="/plantas" />
                      </MenuItem>
                    </Tooltip>
                  )}
                </>
                <>
                  {broadcastAccess && (
                    <>
                      <Tooltip
                        title={menuCollapse ? i18n.t("broadcast.title") : ""}
                        placement="right"
                        arrow
                      >
                        <MenuItem
                          active={window.location.pathname === "/broadcast"}
                          icon={<SettingsInputAntenna />}
                        >
                          {i18n.t("broadcast.title")}
                          <Link to="/broadcast" />
                        </MenuItem>
                      </Tooltip>
                    </>
                  )}
                  {AuthService.hasRoleAdmin() && (
                    <>
                      <Tooltip
                        title={menuCollapse ? i18n.t("kpi.title") : ""}
                        placement="right"
                        arrow
                      >
                        <MenuItem
                          active={window.location.pathname === "/kpi"}
                          icon={<ShowChart />}
                        >
                          {i18n.t("kpi.title")}
                          <Link to="/kpi" />
                        </MenuItem>
                      </Tooltip>
                    </>
                  )}
                </>
              </Menu>
            </SidebarContent>
          </PerfectScrollbar>
          <SidebarFooter style={{ borderTop: "transparent" }}>
            <Menu>
              {AuthService.isAuthenticated() && (
                <>
                  {(AuthService.hasRoleAdmin() ||
                    AuthService.hasRoleDSC() ||
                    AuthService.hasRolePartner()) && (
                    <Tooltip
                      title={menuCollapse ? i18n.t("sidebar.usuarios") : ""}
                      placement="right"
                      arrow
                    >
                      <MenuItem
                        active={window.location.pathname === "/users"}
                        icon={
                          <ThemeProvider theme={theme}>
                            <Badge badgeContent={requestNumber} color="primary">
                              <Person />
                            </Badge>
                          </ThemeProvider>
                        }
                      >
                        {i18n.t("sidebar.usuarios")}
                        <Link to={"/users"} />
                      </MenuItem>
                    </Tooltip>
                  )}
                </>
              )}
              <Tooltip
                title={menuCollapse ? i18n.t("sidebar.account") : ""}
                placement="right"
                arrow
                active={openUserPopover}
              >
                <MenuItem
                  icon={
                    <Avatar
                      style={
                        openUserPopover
                          ? {
                              backgroundColor: "#F4F4F4",
                              color: "#E2000F",
                              width: "35px",
                              height: "35px",
                              display: "flex",
                              borderRadius: "50%",
                              fontSize: "16px",
                              fontWeight: "bold",
                              paddingBottom: "2px",
                              textTransform: "uppercase",
                            }
                          : {
                              backgroundColor: "#B6000F",
                              color: "#FFFFFF",
                              width: "35px",
                              height: "35px",
                              display: "flex",
                              borderRadius: "50%",
                              fontSize: "16px",
                              fontWeight: "lighter",
                              paddingBottom: "2px",
                              textTransform: "uppercase",
                            }
                      }
                    >
                      {avatarText}
                    </Avatar>
                  }
                  onClick={handleUserPopover}
                >
                  {i18n.t("sidebar.account")}
                </MenuItem>
              </Tooltip>

              {AuthService.isAuthenticated() && (
                <Tooltip
                  title={menuCollapse ? i18n.t("sidebar.settings.title") : ""}
                  placement="right"
                  arrow
                >
                  <MenuItem
                    active={openSettings}
                    icon={<Settings />}
                    onClick={openSettingsDialog}
                  >
                    {i18n.t("sidebar.settings.title")}
                  </MenuItem>
                </Tooltip>
              )}
            </Menu>
          </SidebarFooter>
        </ProSidebar>
        {menuCollapse && (
          <div
            className="absoluteMenuButton"
            onClick={() => setMenuCollapse(!menuCollapse)}
          >
            <ArrowForwardIos />
          </div>
        )}
      </>
      <main id="main">
        <nav className="tool">
          <div className="navBar1">
            <IconButton
              className="icon"
              aria-label="Hide Menu"
              style={{ color: "white" }}
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBack />
            </IconButton>
          </div>

          <div className="title" data-testid="main-page-title">
            {global.pageTitle}
          </div>
        </nav>
        <PerfectScrollbar id="main-content">
          <div>{children}</div>
          <div
            style={{
              textAlign: "right",
              marginTop: 10,
              marginRight: 20,
            }}
          >
            <MLink
              target="_blank"
              style={{
                textDecoration: "none",
                marginLeft: 20,
                color: "black",
              }}
              href={`${termsUrl}/TermsAndConditions?culture=${locale}&appId=assetcare`}
            >
              <span
                style={{
                  textDecoration: "underline",
                  fontSize: 14,
                }}
              >
                {i18n.t("sidebar.terms")}
              </span>
            </MLink>
            <MLink
              target="_blank"
              style={{
                textDecoration: "none",
                marginLeft: 20,
                color: "black",
              }}
              href={`${termsUrl}/privacypolicy?culture=${locale}`}
            >
              <span
                style={{
                  textDecoration: "underline",
                  fontSize: 14,
                }}
              >
                {i18n.t("sidebar.privacity")}
              </span>
            </MLink>
            <span
              style={{
                fontSize: 14,
                marginLeft: 20,
              }}
            >
              v{packageJson.version}
            </span>
          </div>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={global?.snackbar?.open || false}
            TransitionComponent={Slide}
            style={{ marginTop: "4%" }}
            onClose={() => SnackbarClose(setGlobal)}
          >
            <Alert
              elevation={9}
              variant="outlined"
              severity={global?.snackbar?.severity || "success"}
              style={{ backgroundColor: "white" }}
            >
              {global?.snackbar?.message || ""}
            </Alert>
          </Snackbar>
        </PerfectScrollbar>
      </main>
      {/*Dialogo de settings*/}
      <SettingsDialog open={openSettings} close={openSettingsDialog} />
      <UserPopover
        open={openUserPopover}
        close={handleUserPopover}
        anchorUser={anchorUser}
        userInfo={AuthService.getUserInfo()}
        logOutFunction={logOutFunction}
      />
    </div>
  );
};
export default Main;
