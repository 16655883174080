import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  Fragment,
} from "react";
import i18n from "../../../locales/i18n";
import { TextField } from "@material-ui/core";
import {
  AddBox,
  DeleteOutline,
  Check,
  Clear,
  ExpandMore,
  Business,
  Room,
  KeyboardArrowRight,
} from "@material-ui/icons";
import AppContext from "../../../contexts/AppContext";
import RelativeBackdrop from "../../RelativeBackdrop";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { UsersAPI, ZonasAPI } from "../../../services/API";
import { SnackbarSuccess, SnackbarError } from "../../../helpers/Common";

export default function TabZoneAccess(props) {
  const { plantasOptions, userId, readOnly } = props;
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [selectedPlant, setSelectedPlant] = useState(undefined);
  const [listPlantZoneAccess, setListPlantZoneAccess] = useState([]);
  const [listPlantAccess, setListPlantAccess] = useState([]);
  const [loadingZones, setLoadingZones] = useState(false);
  const [optionZones, setOptionZones] = useState([]);
  const [addPlantMode, setAddPlantMode] = useState(false);
  const [addZoneMode, setAddZoneMode] = useState(false);
  //const [confirmDeleteMode, setConfirmDeleteMode] = useState(false);
  const [openRows, setOpenRows] = useState({});
  const [plantIdToAddZone, setPlantIdToAddZone] = useState(undefined);
  const [selectedZone, setSelectedZone] = useState(undefined);
  const [deletingAccess, setDeletingAccess] = useState({
    id: 0,
    isPlant: false,
    isZone: false,
  });

  const getUserPlantAccess = useCallback(() => {
    setLoading(true);
    UsersAPI.getPlantAccess(userId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => setListPlantAccess(data || []))
      .catch((e) => {
        console.error(e.message);
      })
      .finally(() => setLoading(false));
  }, [userId]);

  const styles = DetailTabs_Style();

  const handleAddPlant = async () => {
    setAddPlantMode(!addPlantMode);
  };

  const handleAddZone = async (plantId) => {
    setAddZoneMode(true);
    setPlantIdToAddZone(plantId);
  };

  const closeHandleAddZone = async () => {
    setAddZoneMode(false);
    setPlantIdToAddZone(undefined);
    setSelectedZone(undefined);
  };

  //Para frontend
  const AddUserPlantAccess = async () => {
    if (selectedPlant) {
      let newPlant = {
        id: selectedPlant.id,
        denominacion: selectedPlant.denominacion,
        zonas: [],
      };
      //poner planta en primer lugar
      setListPlantZoneAccess((old) => [newPlant, ...old]);
      setSelectedPlant(undefined);
      setOpenRows((old) => ({ ...old, [selectedPlant.id]: true }));
    }
  };

  const AddUserZoneAccess = async (zoneId) => {
    UsersAPI.addZoneAccess(userId, zoneId)
      .then((response) => {
        if (response.ok) {
          let zone = optionZones.find((z) => z.id === zoneId);
          addZoneToPlantInList(zone);
          SnackbarSuccess(
            setGlobal,
            i18n.t("gestorUsuarios.snackbar.update.success")
          );
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((e) => {
        console.error(e.message);
        SnackbarError(
          setGlobal,
          i18n.t("gestorUsuarios.snackbar.update.error")
        );
      })
      .finally(() => closeHandleAddZone());
  };

  //borrar acceso a una zona
  const handleRemoveZone = (zoneId) => {
    if (zoneId) {
      const deleteZoneList = [zoneId];
      removeZoneAccess(deleteZoneList, false);
    }
  };

  //borrar acceso a todas las zonas de una planta
  const handleRemovePlantAccess = (plantId) => {
    if (plantId) {
      const plantFound = listPlantZoneAccess.find((p) => p.id === plantId);
      const deleteZoneList = plantFound.zonas.map((z) => z.id);
      removeZoneAccess(deleteZoneList, false);
    }
  };

  const removeZoneAccess = (zoneIdList, confirmed) => {
    if (!confirmed) {
      //Enviar a backend
      RemoveUserZoneAccess(zoneIdList);
    } else {
      //cerrar modo de borrar
      closeDeletingMode();
      //actualizar lista
      setListPlantZoneAccess((old) =>
        old
          .map((planta) => ({
            ...planta,
            zonas: planta.zonas.filter((zona) => !zoneIdList.includes(zona.id)),
          }))
          .filter((planta) => planta.zonas.length > 0)
      );
    }
  };

  const RemoveUserZoneAccess = async (zoneList) => {
    UsersAPI.removeZoneAccess(userId, zoneList)
      .then((response) => {
        if (response.ok) {
          removeZoneAccess(zoneList, true);
          SnackbarSuccess(
            setGlobal,
            i18n.t("gestorUsuarios.snackbar.update.success")
          );
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((e) => {
        console.error(e.message);
        SnackbarError(
          setGlobal,
          i18n.t("gestorUsuarios.snackbar.update.error")
        );
      });
  };

  //Agregar Zona a planta en lista
  const addZoneToPlantInList = async (zona) => {
    let plantzoneIndex = listPlantZoneAccess.findIndex(
      (p) => p.id === zona.plantaId
    );

    if (plantzoneIndex === -1) {
      console.error("Planta no encontrada");
      return;
    }

    //Agregar zona a planta en la lista
    let updatedPlant = {
      ...listPlantZoneAccess[plantzoneIndex],
      zonas: [zona, ...listPlantZoneAccess[plantzoneIndex].zonas],
    };

    //actualizar lista
    let updatedList = [...listPlantZoneAccess];
    updatedList[plantzoneIndex] = updatedPlant;

    setListPlantZoneAccess(updatedList);
  };

  const getZoneOptions = useCallback(async (plantId) => {
    setLoadingZones(true);
    ZonasAPI.getZonesForSelector(plantId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setOptionZones(data || []);
      })
      .catch((e) => {
        console.error(e.message);
      })
      .finally(() => {
        setLoadingZones(false);
      });
  }, []);

  //cargar lista de zonas selector
  const callZoneOptions = async (newPlantId) => {
    //evitar llamadas innecesarias
    if (newPlantId) {
      getZoneOptions(newPlantId);
      setPlantIdToAddZone(newPlantId);
    }
    getZoneOptions(newPlantId);
  };

  //abrir y cerrar input de zonas independientemente
  const toggleRow = (id) => {
    setOpenRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const getZoneAccess = useCallback(async (userId) => {
    setLoading(true);
    UsersAPI.getZoneAccess(userId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setListPlantZoneAccess(data || []);
      })
      .catch((e) => {
        console.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //Cargar lista de plantas y zonas de usuario
  useEffect(() => {
    getZoneAccess(userId);
    getUserPlantAccess();
  }, [getZoneAccess, userId, getUserPlantAccess]);

  //fila de confirmar borrado
  const ConfirmDelete = () => {
    return (
      <>
        <TableCell colSpan={4}>
          {deletingAccess.isPlant
            ? i18n.t("user.AccesoZonas.confirmDeletePlant")
            : i18n.t("user.AccesoZonas.confirmDeleteZone")}
        </TableCell>
        <TableCell align="right">
          <Tooltip title={i18n.t("common.confirmar")} placement={"bottom"}>
            <span>
              <IconButton
                aria-label="confirmDelete"
                disabled={readOnly || !deletingAccess.id}
                onClick={() => {
                  if (deletingAccess.isPlant) {
                    //eliminar accesos de toda una planta
                    handleRemovePlantAccess(deletingAccess.id);
                  } else if (deletingAccess.isZone) {
                    //eliminar acceso a una zona
                    handleRemoveZone(deletingAccess.id);
                  }
                }}
              >
                <Check />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title={i18n.t("common.cancel")} placement={"bottom"}>
            <span>
              <IconButton
                aria-label="cancelDelete"
                onClick={() => {
                  closeDeletingMode();
                }}
              >
                <Clear />
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
      </>
    );
  };

  //abrir modo de borrar acceso
  const openDeletingMode = (id, isPlant, isZone) => {
    setDeletingAccess({
      id: id,
      isPlant: isPlant,
      isZone: isZone,
    });
  };

  //cerrar modo de borrar acceso
  const closeDeletingMode = () => {
    setDeletingAccess({
      id: 0,
      isPlant: false,
      isZone: false,
    });
  };

  const AutocompletePlants = () => {
    return (
      <Autocomplete
        style={{ width: "100%" }}
        groupBy={(row) => row?.partner?.razonSocial}
        fullWidth
        //Filtrar plantas que no estén en la lista
        options={plantasOptions.filter(
          (po) =>
            !listPlantZoneAccess.some((pza) => pza.id === po.id) &&
            !listPlantAccess.some((pa) => pa.plantaId === po.id)
        )}
        getOptionLabel={(row) =>
          `${row?.denominacion} (${row?.cliente?.razonSocial})`
        }
        value={selectedPlant || null}
        className={styles.materialTextField}
        onChange={(e, newValue) => setSelectedPlant(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={i18n.t("user.planta")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    );
  };

  const AutocompleteZone = (plantId) => {
    return (
      <Autocomplete
        style={{ width: "100%" }}
        fullWidth
        getOptionLabel={(option) => option.descripcion || ""}
        value={selectedZone || null}
        className={styles.materialTextField}
        options={optionZones?.filter(
          (zo) =>
            !listPlantZoneAccess.some((plant) =>
              plant.zonas.some((z) => z?.id === zo?.id)
            )
        )}
        onOpen={() => {
          callZoneOptions(plantId);
        }}
        filterOptions={(options) => options}
        loading={loadingZones}
        onChange={(e, newValue) => {
          setSelectedZone(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={i18n.t("user.AccesoZonas.zone")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    );
  };

  return (
    <RelativeBackdrop hidden={!loading} minWidth={"100%"}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" key={"tableZoneAccess"}>
          {/* Header */}
          <TableHead>
            <TableRow key={"header"}>
              <TableCell className={styles.userZoneAccessTitle}>
                {i18n.t("user.AccesoZonas.title")}
              </TableCell>
              <TableCell colSpan={4} align="right">
                <Tooltip
                  title={i18n.t("common.new.femenine")}
                  placement={"bottom"}
                >
                  <span>
                    <IconButton
                      aria-label="add"
                      disabled={
                        readOnly || addPlantMode || deletingAccess.id !== 0
                      }
                      onClick={handleAddPlant}
                    >
                      <AddBox />
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
            {/* Columns */}
            <TableRow key={"plantColumns"}>
              <TableCell colSpan={4} className={styles.userZoneAccessHeader}>
                {i18n.t("user.AccesoZonas.plant")}
              </TableCell>
              <TableCell align="right" className={styles.userZoneAccessHeader}>
                {i18n.t("user.AccesoZonas.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Row Adding Mode*/}
            {addPlantMode && (
              <TableRow key={"addNewplant"}>
                <TableCell colSpan={4}>
                  <AutocompletePlants />
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    title={i18n.t("common.new.femenine")}
                    placement={"bottom"}
                  >
                    <span>
                      <IconButton
                        aria-label="add"
                        disabled={readOnly || !selectedPlant}
                        onClick={() => {
                          AddUserPlantAccess();
                          setAddPlantMode(false);
                        }}
                      >
                        <Check />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={i18n.t("common.cancel")} placement={"bottom"}>
                    <span>
                      <IconButton
                        aria-label="cancel"
                        onClick={() => {
                          setAddPlantMode(false);
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {/* PLANTS AND ZONE BACKEND */}
          <TableBody>
            {/* PLANTS */}
            {listPlantZoneAccess
              //Filtrar plantas que estén en la lista del usuario que mira
              .filter((plant) =>
                plantasOptions.some((po) => po.id === plant.id)
              )
              .map((plant, index) => (
                <Fragment key={`plantRow_${plant.id}_${index}`}>
                  <TableRow>
                    {deletingAccess.id === plant.id &&
                    deletingAccess.isPlant ? (
                      <>
                        {/* Se esta eliminando una planta */}
                        <ConfirmDelete />
                      </>
                    ) : (
                      <>
                        <TableCell scope="row" colSpan={4}>
                          <IconButton
                            size="small"
                            onClick={() => toggleRow(plant.id)}
                          >
                            {openRows[plant.id] ? (
                              <ExpandMore />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </IconButton>
                          <Business />
                          {
                            plantasOptions.find((po) => po.id === plant.id)
                              ?.denominacion
                          }
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip
                            title={i18n.t("common.delete")}
                            placement={"bottom"}
                          >
                            <span>
                              <IconButton
                                aria-label="deletePlant"
                                disabled={
                                  readOnly ||
                                  addPlantMode ||
                                  deletingAccess.id !== 0
                                }
                                onClick={() => {
                                  openDeletingMode(plant.id, true, false);
                                }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  {/* ZONE ROWS */}
                  <TableRow key={"zoneRowHeader" + plant.id + "index" + index}>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={openRows[plant.id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Table
                          size="small"
                          aria-label="purchases"
                          key={"zoneTable" + plant.id}
                        >
                          <TableHead>
                            <TableRow
                              key={
                                "zoneRowSubHeader" + plant.id + "index" + index
                              }
                            >
                              <TableCell
                                className={styles.userZoneAccessSubTitle}
                              >
                                {i18n.t("user.AccesoZonas.managementZones")}
                              </TableCell>
                              <TableCell colSpan={4} align="right">
                                <Tooltip
                                  title={i18n.t("common.new.femenine")}
                                  placement={"bottom"}
                                >
                                  <span>
                                    <IconButton
                                      aria-label="add"
                                      disabled={
                                        readOnly ||
                                        addPlantMode ||
                                        deletingAccess.id !== 0
                                      }
                                      onClick={() => {
                                        handleAddZone(plant.id);
                                      }}
                                    >
                                      <AddBox />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                            {/* Columns */}
                            <TableRow
                              key={"zoneRowColumn" + plant.id + "index" + index}
                            >
                              <TableCell
                                colSpan={4}
                                className={styles.userZoneAccessHeader}
                              >
                                {i18n.t("user.AccesoZonas.zone")}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={styles.userZoneAccessHeader}
                              >
                                {i18n.t("user.AccesoZonas.actions")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* Zone Adding Mode*/}
                            {addZoneMode && plantIdToAddZone === plant.id && (
                              <TableRow key={"addZoneRow" + plant.id}>
                                <TableCell colSpan={4}>
                                  {AutocompleteZone(plant.id)}
                                </TableCell>
                                <TableCell align="right">
                                  <Tooltip
                                    title={i18n.t("common.new.femenine")}
                                    placement={"bottom"}
                                  >
                                    <span>
                                      <IconButton
                                        aria-label="add"
                                        disabled={readOnly || !selectedZone}
                                        onClick={() => {
                                          AddUserZoneAccess(selectedZone.id);
                                          closeHandleAddZone();
                                        }}
                                      >
                                        <Check />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    title={i18n.t("common.cancel")}
                                    placement={"bottom"}
                                  >
                                    <span>
                                      <IconButton
                                        aria-label="cancel"
                                        onClick={() => {
                                          closeHandleAddZone();
                                        }}
                                      >
                                        <Clear />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            )}
                            {plant.zonas.map((zone) => (
                              <TableRow key={`zoneRow_${zone.id}_${index}`}>
                                {deletingAccess.id === zone.id &&
                                deletingAccess.isZone ? (
                                  <>
                                    {/* Eliminando una zona */}
                                    <ConfirmDelete />
                                  </>
                                ) : (
                                  <>
                                    {/* Lista de zonas */}
                                    <TableCell scope="row" colSpan={4}>
                                      <Room />
                                      {zone.descripcion}
                                    </TableCell>

                                    <TableCell align="right">
                                      <Tooltip
                                        title={i18n.t("common.delete")}
                                        placement={"bottom"}
                                      >
                                        <span>
                                          <IconButton
                                            aria-label="deleteZone"
                                            disabled={
                                              readOnly ||
                                              addPlantMode ||
                                              deletingAccess.id !== 0
                                            }
                                            onClick={() => {
                                              //handleRemoveZone(zone.id);
                                              openDeletingMode(
                                                zone.id,
                                                false,
                                                true
                                              );
                                            }}
                                          >
                                            <DeleteOutline />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </TableCell>
                                  </>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </RelativeBackdrop>
  );
}
