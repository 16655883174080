import { IconButton, Tooltip } from "@material-ui/core";
import { CropFree, Delete, InfoOutlined, Publish } from "@material-ui/icons";
import i18n from "i18n-js";
import React, {
  useRef,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import AppContext from "../../../contexts/AppContext";
import {
  dataUriFromFormField,
  resizeImage,
  SnackbarError,
  SnackbarSuccess,
} from "../../../helpers/Common";
import { PartnersAPI } from "../../../services/API";
import AlertDialog from "../../AlertDialog";
import MouseOverPopover from "../../MouseOverPopover";

const API = {
  partner: PartnersAPI,
};

function LogoInput({ id, mode, logoURL, setLogoURL, readOnly }) {
  const styles = DetailTabs_Style();

  const [logo, setLogo] = useState();
  const [loading, setLoading] = useState(false);
  const logoInputRef = useRef(null);
  const deleteDialog = useRef(null);

  // @ts-ignore
  const { setGlobal } = useContext(AppContext);

  const getLogo = useCallback(() => {
    if (logoURL && logoURL !== "" && !logo) {
      setLoading(true);
      API[mode]
        .getLogo(id)
        .then((res) => (res.status === 200 ? res.json() : undefined))
        .then((data) => {
          if (data) {
            setLogo(data);
          } else {
            throw new Error("No data");
          }
        })
        .catch((error) => {
          console.log(error);
          SnackbarError(setGlobal, i18n.t("common.logo.snackbar.get.error"));
        })
        .finally(() => setLoading(false));
    }
  }, [id, mode, logo, logoURL, setGlobal]);

  useEffect(() => {
    getLogo();
  }, [getLogo]);

  const postLogo = async () => {
    const file = logoInputRef.current.files[0];

    if (file) {
      setLoading(true);
      const extension = file.name.split(".").pop();
      if (!["jpg", "jpeg", "png", "ico", "svg"].includes(extension)) {
        SnackbarError(
          setGlobal,
          i18n.t("common.logo.snackbar.post.errorFormat")
        );
        setLoading(false);
        return;
      }

      const dataUri = await dataUriFromFormField(file);

      const imgEl = document.createElement("img");
      imgEl.src = dataUri;

      imgEl.addEventListener("load", () => {
        const base64 = resizeImage(imgEl, 180).split(";base64,")[1];

        // react-pdf solo soporta imagenes png
        API[mode]
          .addLogo(id, base64, "png")
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            } else {
              throw new Error(res.statusText);
            }
          })
          .then((data) => {
            setLogo(data.base64);
            setLogoURL(data.url);
            SnackbarSuccess(
              setGlobal,
              i18n.t("common.logo.snackbar.post.success")
            );
          })
          .catch((error) => {
            console.log(error);
            SnackbarError(setGlobal, i18n.t("common.logo.snackbar.post.error"));
          })
          .finally(() => setLoading(false));
      });
    }
  };

  const deleteLogo = async () => {
    setLoading(true);
    API[mode]
      .deleteLogo(id)
      .then((res) => {
        if (res.status === 200) {
          setLogoURL(null);
          setLogo(undefined);
          SnackbarSuccess(
            setGlobal,
            i18n.t("common.logo.snackbar.delete.success")
          );
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((error) => {
        console.log(error);
        SnackbarError(setGlobal, i18n.t("common.logo.snackbar.delete.error"));
      })
      .finally(() => {
        setLoading(false);
        deleteDialog.current.close();
      });
  };

  if (readOnly && !logo) {
    return <></>;
  }

  return (
    <>
      <div className={styles.logoInputContainer}>
        <div className={styles.logoInputLabel}>
          <>
            {i18n.t("common.logo.title")}
            <MouseOverPopover
              content={
                <>
                  {i18n.t("common.logo.formats")}
                  <br />
                  {i18n.t("common.logo.help")}
                </>
              }
              parent={<InfoOutlined style={{ fontSize: 16 }} />}
            />
          </>
        </div>
        {logo ? (
          <img
            className={styles.logoInputImg}
            alt={i18n.t("common.logo.title")}
            src={logo}
          />
        ) : (
          <div className={styles.logoInputImg}>
            <CropFree style={{ fontSize: 130 }} />
          </div>
        )}
        {!readOnly && (
          <div className={styles.logoInputButtons}>
            <input
              type="file"
              id="logo_input"
              accept=".jpg,.jpeg,.png,.ico,.svg"
              ref={logoInputRef}
              onChange={postLogo}
              hidden
            />
            <Tooltip
              title={
                !loading
                  ? i18n.t("common.logo.addHelp")
                  : i18n.t("common.cargando")
              }
            >
              <span>
                <IconButton
                  disabled={loading}
                  onClick={() => logoInputRef.current.click()}
                >
                  <Publish />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={
                !loading
                  ? i18n.t("common.logo.deleteHelp")
                  : i18n.t("common.cargando")
              }
            >
              <span>
                <IconButton
                  disabled={!logo || loading}
                  onClick={() => deleteDialog.current.open()}
                >
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("common.dialogDeleteTitle")}
        text={i18n.t("common.dialogDeleteText")}
        cancelText={i18n.t("common.dialogDeleteCancelButtonText")}
        confirmText={i18n.t("common.dialogDeleteConfirmButtonText")}
        confirmAction={deleteLogo}
      />
    </>
  );
}

export default LogoInput;
