import React, { useEffect, useState, useCallback, useContext } from "react";
import MaterialTable from "material-table";
import AuthService from "../../../services/AuthService";
import i18n from "../../../locales/i18n";
import { PlantaStockEmergenciaAPI } from "../../../services/API";
import PlantaStockEmergencia from "../../../models/PlantaStockEmergencia";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { SnackbarError, SnackbarSuccess } from "../../../helpers/Common";
import AppContext from "../../../contexts/AppContext";
const materialTableTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000000",
    },
  },
});
export default function PlantaStockEmergenciaTable(props) {
  const { plantaId, isPremium, readOnly } = props;
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const [stock, setStock] = useState([]);
  const [loading, setLoading] = useState(false);
  const isPartner =
    AuthService.hasRolePartner() ||
    AuthService.hasRoleAdmin() ||
    AuthService.hasRoleDSC();
  const isAdmin = AuthService.hasRoleAdmin();
  const getStock = useCallback(async () => {
    setLoading(true);
    await PlantaStockEmergenciaAPI.getByPlanta(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setStock(data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });

    setLoading(false);
  }, [plantaId]);

  const addStock = async (stock) => {
    setLoading(true);
    stock.pntc.TC = "Not validated";
    await PlantaStockEmergenciaAPI.add({ ...stock, plantaId: plantaId })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          const errorMessage = await response.text();
          if (errorMessage.includes("Duplicated PN")) {
            SnackbarError(
              setGlobal,
              i18n.t("planta.stockEmergencia.duplicated")
            );
          } else {
            throw new Error("Something went wrong");
          }
        }
      })
      .then((data) => {
        if (data) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("planta.stockEmergencia.correctAdd")
          );
          getStock();
        }
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("planta.stockEmergencia.notFound"));
        setLoading(false);
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const updateStock = async (newData, oldData) => {
    setLoading(true);
    await PlantaStockEmergenciaAPI.update(new PlantaStockEmergencia(newData))
      .then(async (response) => {
        if (response.ok) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("planta.stockEmergencia.correctUpdate")
          );
          getStock();
        } else {
          const errorMessage = await response.text();
          if (errorMessage.includes("Duplicated PN")) {
            SnackbarError(
              setGlobal,
              i18n.t("planta.stockEmergencia.duplicated")
            );
          } else {
            throw new Error("Something went wrong");
          }
        }
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("planta.stockEmergencia.notFound"));
        setLoading(false);
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const deleteStock = async (stock) => {
    setLoading(true);

    await PlantaStockEmergenciaAPI.remove(stock.id)
      .then((response) => {
        if (response.ok) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("planta.stockEmergencia.correctDelete")
          );
          getStock();
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStock();
  }, [getStock]);

  return (
    //@ts-ignore
    <ThemeProvider theme={materialTableTheme}>
      <div data-testid="emergencyStock-container" hidden />
      {/*@ts-ignore*/}
      <MaterialTable
        style={{ width: "100%" }}
        title={null}
        // @ts-ignore
        localization={i18n.t("dataTableProps")}
        isLoading={loading}
        data={stock}
        options={{
          addRowPosition: "first",
          actionsColumnIndex: -1,
          sorting: true,
          showTitle: false,
          searchFieldAlignment: "left",
          pageSize: 10,
          //Permiso para exportar
          exportButton: isAdmin ? { csv: true } : false,
          exportCsv: (columns, renderData) => {
            PlantaStockEmergenciaAPI.getExport(plantaId)
              .then((response) => response.blob())
              .then((blob) => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = "emergency-stock.xlsx";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the element again
              });
          },
        }}
        columns={[
          {
            field: "pntc.pn",
            title: i18n.t("equipo.retrofitTable.reference"),
            validate: (rowData) => {
              var isEmpty = !rowData.pntc;
              return {
                isValid: !isEmpty,
                helperText: isEmpty ? i18n.t("common.required") : "",
              };
            },
          },
          {
            field: "pntc.tc",
            title: i18n.t("equipo.retrofitTable.description"),
            editable: "never",
          },
          {
            field: "countEquipos",
            title: i18n.t("planta.stockEmergencia.numberOfEquipments"),
            editable: "never",
          },
          {
            field: "countAlmacen",
            title: i18n.t("planta.stockEmergencia.numberInWarehouse"),
            editable: "never",
          },
        ]}
        editable={
          !readOnly && isPartner
            ? {
                onRowAdd: isPremium
                  ? (newData) => addStock(newData)
                  : undefined,
                onRowUpdate: (newData, oldData) =>
                  updateStock(newData, oldData),
                isEditable: () => isPremium,
                editTooltip: () =>
                  isPremium
                    ? i18n.t("common.edit")
                    : i18n.t("common.isPremium"),
                onRowDelete: (oldData) => deleteStock(oldData),
                isDeletable: () => isPremium,
                deleteTooltip: () =>
                  isPremium
                    ? i18n.t("common.delete")
                    : i18n.t("common.isPremium"),
              }
            : {}
        }
        actions={[
          {
            icon: "refresh",
            tooltip: i18n.t("alarma.actualizar"),
            isFreeAction: true,
            onClick: (event) => {
              getStock();
            },
          },
          {
            icon: "add_box",
            tooltip: i18n.t("common.isPremium"),
            disabled: true,
            onClick: () => {},
            hidden: isPremium,
            isFreeAction: true,
          },
        ]}
      />
    </ThemeProvider>
  );
}
