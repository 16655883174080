import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  createMuiTheme,
  ThemeProvider,
  Popover,
  Button,
  Collapse,
  ListItem,
  ListItemText,
  List,
  ListItemIcon,
  Checkbox,
  ListSubheader,
} from "@material-ui/core";
import i18n from "i18n-js";
import MaterialTable from "material-table";
import AlertDialog from "../../AlertDialog";
import { UsersAPI } from "../../../services/API";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

//Numero maximo de zonas a mostrar en la lista
const maxNList = 3;

export default function PlantaUsers(props) {
  const { plantaId, canSendMail } = props;
  const styles = DetailTabs_Style();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const sendAllDialog = useRef(null);
  const sendSingleDialog = useRef(null);
  const [isCollapseOpened, setOpenCollapse] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const materialTableTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#000000",
      },
    },
  });

  const getUsers = useCallback(() => {
    setLoading(true);
    UsersAPI.getUsersFromPlant(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .then((data) => {
        setUsers(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [plantaId]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const AccessFormatData = (accessList) => {
    return (
      <div className={styles.plantUserListContainer}>
        {accessList.map((access, index) => (
          <div key={index}>{i18n.t("planta.plantUser." + access)}</div>
        ))}
      </div>
    );
  };

  const ZonesFormatData = (zoneList) => {
    if (zoneList.length <= maxNList) {
      return (
        <div className={styles.plantUserListContainer}>
          {zoneList.map((zone, index) => (
            <div key={index}>
              {zone === "readOnlyAccess" ||
              zone === "partialManager" ||
              zone === "managerAccess"
                ? i18n.t("planta.plantUser." + zone)
                : zone}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <>
          <Button
            aria-describedby={id}
            variant="text"
            onClick={handleClick}
            style={{
              textTransform: "none",
              padding: "0",
              textDecoration: "underline",
            }}
          >
            {i18n.t("planta.plantUser.seeAllButton") +
              " (" +
              zoneList.length +
              ")"}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              //margen a la derecha
              horizontal: 160,
            }}
            className={styles.plantUserZonePopover}
          >
            <div className={styles.plantUserZonePopoverContainer}>
              <div className={styles.plantUserZonePopoverTitle}>
                {i18n.t("planta.plantUser.zoneList")}
              </div>

              {zoneList.map((zone, index) => (
                <li key={index}>{zone}</li>
              ))}
            </div>
          </Popover>
        </>
      );
    }
  };

  const SetDialogOpen = (data = null) => {
    if (data !== null) {
      setSelectedUsers([data]);
      sendSingleDialog.current.open();
    } else {
      sendAllDialog.current.open();
    }
  };

  const SendMail = () => {
    UsersAPI.sendPlantInfoEmailToRequestedUsers(plantaId, selectedUsers)
      .then((response) => {
        if (response.ok) {
          sendAllDialog.current.close();
          sendSingleDialog.current.close();
          setSelectedUsers([]);
        }
      })
      .catch((e) => console.error(e));
  };

  const handleCollapseSelect = useCallback(
    (user) => {
      let newList = [...selectedUsers];
      const uId = newList.findIndex(
        (item) => item.appUser.id === user.appUser.id
      );
      if (uId !== -1) {
        newList = selectedUsers.filter((u) => u.appUser.id !== user.appUser.id);
        setSelectedUsers(newList);
      } else {
        newList.push(user);
        setSelectedUsers(newList);
      }
    },
    [selectedUsers]
  );

  return (
    <div>
      <div data-testid="plantUsers-container">
        <ThemeProvider theme={materialTableTheme}>
          {/* @ts-ignore */}
          <MaterialTable
            style={{ width: "100%" }}
            title={null}
            // @ts-ignore
            localization={i18n.t("dataTableProps")}
            isLoading={loading}
            data={users}
            options={{
              sorting: true,
              showTitle: false,
              searchFieldAlignment: "left",
              pageSize: 10,
              actionsColumnIndex: -1,
            }}
            columns={[
              {
                field: "appUser.nombre",
                title: i18n.t("logs.user"),
                render: (rowData) =>
                  rowData.appUser
                    ? rowData.appUser.nombre + " " + rowData.appUser.apellidos
                    : "",
              },
              {
                field: "appUser.email",
                title: i18n.t("gestorUsuarios.detallesUsuario.email"),
                render: (rowData) =>
                  rowData.appUser ? rowData.appUser.email : "",
              },
              {
                field: "accessList",
                title: i18n.t("user.AccesoPlantas.title"),
                render: (rowData) => AccessFormatData(rowData?.accessList),
              },
              {
                field: "zoneManagerList",
                title: i18n.t("user.AccesoZonas.managementZones"),
                render: (rowData) => ZonesFormatData(rowData?.zoneManagerList),
              },
            ]}
            actions={
              canSendMail && [
                {
                  icon: "mail_outlined",
                  tooltip: i18n.t("user.Mailing.EnviarATodos.ToolTip"),
                  hidden: false,
                  isFreeAction: true,
                  iconProps: { datatestid: "sendAllMail" },
                  onClick: () => SetDialogOpen(),
                },
                (rowData) => ({
                  icon: "mail_outlined",
                  tooltip:
                    i18n.t("user.Mailing.EnviarAUno.ToolTip") +
                    " " +
                    rowData.appUser.email,
                  hidden: false,
                  onClick: (_, rowData) => SetDialogOpen(rowData),
                }),
              ]
            }
          />
        </ThemeProvider>
      </div>
      <AlertDialog
        ref={sendAllDialog}
        title={i18n.t("user.Mailing.EnviarATodos.Title")}
        confirmText={i18n.t("common.confirmar")}
        cancelText={i18n.t("common.cancelar")}
        cancelAction={() => {
          sendAllDialog.current.close();
          setSelectedUsers([]);
          setOpenCollapse(false);
        }}
        confirmAction={() => SendMail()}
        text={
          <div>
            <div>
              <ListSubheader style={{ backgroundColor: "#FFFFFF", padding: 0 }}>
                <div>{i18n.t("user.Mailing.EnviarATodos.Body")}</div>
                <ListItem
                  button
                  style={{}}
                  onClick={() => {
                    setOpenCollapse((old) => !old);
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="default"
                      edge="start"
                      disableRipple
                      checked={selectedUsers.length > 0}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (selectedUsers.length > 0) {
                          setSelectedUsers([]);
                        } else {
                          setSelectedUsers(users);
                        }
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.t("user.Mailing.EnviarATodos.ExpandAll")}
                  />
                  {isCollapseOpened ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              </ListSubheader>
              <Collapse in={isCollapseOpened}>
                {isCollapseOpened && users.length > 0 ? (
                  <List component="div">
                    {users.map((user) => (
                      <ListItem key={user.appUser.id}>
                        <ListItemIcon>
                          <Checkbox
                            checked={selectedUsers.includes(user) || false}
                            edge="start"
                            color="default"
                            onClick={() => handleCollapseSelect(user)}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            user.appUser.nombre + " " + user.appUser.apellidos
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <div></div>
                )}
              </Collapse>
            </div>
          </div>
        }
      />
      <AlertDialog
        ref={sendSingleDialog}
        title={i18n.t("user.Mailing.EnviarAUno.Title")}
        text={i18n.t("user.Mailing.EnviarAUno.Body")}
        confirmText={i18n.t("common.confirmar")}
        cancelText={i18n.t("common.cancelar")}
        cancelAction={() => {
          sendSingleDialog.current.close();
          setSelectedUsers([]);
        }}
        confirmAction={() => SendMail()}
      />
    </div>
  );
}
