import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "", filter) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  if (filter) {
    return fetch(`${baseUrl}/api/clienteSolicitudes/filter/${filter}`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });
  } else {
    return fetch(`${baseUrl}/api/clienteSolicitudes/${id}`, {
      headers: { Authorization: `Bearer ${access_token}` },
    });
  }
}

export function getByDipId(dipId) {
  return fetch(`${baseUrl}/api/clienteSolicitudes/request/${dipId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getInfiniteRequest(filter, userFilter) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  if (filter) {
    return fetch(
      `${baseUrl}/api/clienteSolicitudes/infiniteRequest/${filter}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userFilter),
      }
    );
  }
}

export function add(solicitud, userToken) {
  return fetch(`${baseUrl}/api/clienteSolicitudes`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(solicitud),
  });
}

export function update(cliente) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clienteSolicitudes/${cliente.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(cliente),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clienteSolicitudes/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function reject(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clienteSolicitudes/${id}/reject`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function getCount() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clienteSolicitudes/count`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}
