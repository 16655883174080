import React, { useCallback, useContext, useEffect, useState } from "react";
import AlertDialog from "../../AlertDialog";
import { PlantasAPI } from "../../../services/API";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  Cancel,
  ArrowForwardIos,
  ArrowBackIos,
  Check,
  Link,
} from "@material-ui/icons";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import { SnackbarError, SnackbarSuccess } from "../../../helpers/Common";
import i18n from "../../../locales/i18n";
import AppContext from "../../../contexts/AppContext";

function ClientLinkPlantsDialog({
  dialogRef,
  open,
  setOpen,
  clientId,
  partnerId,
  cliente,
  updateCliente,
}) {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const styles = DetailTabs_Style();

  const [loading, setLoading] = useState(false);
  const [plantList, setPlantList] = useState(undefined);
  const [selectedPlants, setSelectedPlants] = useState([]);
  const [confirmStep, setConfirmStep] = useState(false);

  const getPlants = useCallback(() => {
    if (clientId && partnerId && open) {
      setLoading(true);
      PlantasAPI.getPlantasByPartnerForSelector(partnerId, clientId)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error(res.statusText);
          }
        })
        .then((data) => setPlantList(data))
        .catch((err) => {
          console.error(err);
          setPlantList([]);
        })
        .finally(() => setLoading(false));
    }
  }, [clientId, partnerId, open]);

  useEffect(() => {
    setSelectedPlants([]);
    getPlants();
  }, [getPlants]);

  const handleConfirm = () => {
    PlantasAPI.changeClientMassive(clientId, selectedPlants)
      .then((res) => {
        if (res.ok) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("cliente.linkPlantsDialog.snackbar.success")
          );
        } else {
          throw new Error(`${res.status} - ${res.statusText}`);
        }
      })
      .catch((err) => {
        SnackbarError(
          setGlobal,
          i18n.t("cliente.linkPlantsDialog.snackbar.error")
        );
        console.log(err);
      })
      .finally(() => {
        dialogRef.current.close();
        setOpen(false);
        setConfirmStep(false);
        setSelectedPlants([]);
        updateCliente();
      });
  };

  const selectDeselectPlants = (id) => {
    const semaf = selectedPlants.indexOf(id);

    if (semaf === -1) {
      setSelectedPlants([...selectedPlants, id]);
    } else {
      setSelectedPlants((old) => old.filter((item) => item !== id));
    }
  };

  return (
    <AlertDialog
      maxWidth={"md"}
      ref={dialogRef}
      contentStyle={{ paddingTop: 0, marginTop: 8 }}
      title={
        <>
          <Link /> {i18n.t("cliente.linkPlantsDialog.title")}
        </>
      }
      loading={loading}
      text={
        !confirmStep ? (
          <div style={{ minWidth: 800 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell>
                    <b>{i18n.t("cliente.linkPlantsDialog.table.plant")}</b>
                  </TableCell>
                  <TableCell>
                    <b>{i18n.t("cliente.linkPlantsDialog.table.country")}</b>
                  </TableCell>
                  <TableCell>
                    <b>{i18n.t("cliente.linkPlantsDialog.table.poblacion")}</b>
                  </TableCell>
                  <TableCell>
                    <b>{i18n.t("cliente.linkPlantsDialog.table.client")}</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plantList?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      {i18n.t("cliente.linkPlantsDialog.table.noData")}
                    </TableCell>
                  </TableRow>
                ) : (
                  plantList?.map((row) => (
                    <TableRow
                      key={row.id}
                      style={
                        selectedPlants.includes(row.id)
                          ? { backgroundColor: "#F4F4F4" }
                          : {}
                      }
                    >
                      <TableCell>
                        <Checkbox
                          checked={selectedPlants.includes(row.id)}
                          disableRipple
                          color="default"
                          onChange={() => selectDeselectPlants(row.id)}
                        />
                      </TableCell>
                      <TableCell>
                        {row.denominacion || i18n.t("common.n/a")}
                      </TableCell>
                      <TableCell>
                        {i18n.t(`country.${row?.country?.name}`)}
                      </TableCell>
                      <TableCell>
                        {row.poblacion || i18n.t("common.n/a")}
                      </TableCell>
                      <TableCell>
                        {row.cliente.razonSocial || i18n.t("common.n/a")}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div>
            {i18n.t("cliente.linkPlantsDialog.confirmTextStart")}
            <br />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>{i18n.t("cliente.linkPlantsDialog.table.plant")}</b>
                  </TableCell>
                  <TableCell>
                    <b>{i18n.t("cliente.linkPlantsDialog.table.client")}</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedPlants?.map((id) => {
                  const plant = plantList?.find((p) => p.id === id);

                  return (
                    <TableRow key={plant.id}>
                      <TableCell>
                        {plant?.denominacion || i18n.t("common.n/a")}
                      </TableCell>
                      <TableCell>
                        <b>
                          {plant?.cliente?.razonSocial || i18n.t("common.n/a")}
                        </b>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <br />
            {i18n.t("cliente.linkPlantsDialog.confirmTextClient")}{" "}
            <strong>{cliente.razonSocial}</strong>,{" "}
            {i18n.t("cliente.linkPlantsDialog.confirmTextEnd")}
          </div>
        )
      }
      cancelText={
        !confirmStep ? (
          <>
            <Cancel className={styles.saveIcon} /> {i18n.t("common.cancelar")}
          </>
        ) : (
          <>
            <ArrowBackIos className={styles.saveIcon} />{" "}
            {i18n.t("cliente.linkPlantsDialog.prev")}
          </>
        )
      }
      confirmText={
        !confirmStep ? (
          <>
            <ArrowForwardIos className={styles.saveIcon} />{" "}
            {i18n.t("cliente.linkPlantsDialog.next")}
          </>
        ) : (
          <>
            <Check className={styles.saveIcon} /> {i18n.t("common.confirmar")}
          </>
        )
      }
      confirmDisabled={selectedPlants?.length === 0}
      confirmAction={() => {
        if (confirmStep) {
          handleConfirm();
        } else {
          setConfirmStep(true);
          document.getElementById("alert-dialog-main").scrollTop = 0;
        }
      }}
      cancelAction={(e) => {
        if (confirmStep) {
          setConfirmStep(false);
        } else {
          dialogRef.current.close();
          setOpen(false);
        }
      }}
      redTitle={true}
    />
  );
}

export default ClientLinkPlantsDialog;
