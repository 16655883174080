// @ts-nocheck
import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import MaterialTable from "material-table";
import i18n from "../../../locales/i18n";
import { Typography } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { EquiposAPI } from "../../../services/API";
import AlertDialog from "../../AlertDialog";
import AppContext from "../../../contexts/AppContext";
import { SnackbarSuccess, SnackbarError } from "../../../helpers/Common";
import { EquipoExchangeAPI } from "../../../services/API";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import AuthService from "../../../services/AuthService";

export default function EquipoExchangeTable(props) {
  const { keyuser, isPremium, readOnly, equipo, sustituciones } = props;
  const locale = i18n.locale ? i18n.locale : i18n.defaultLocale;
  const [exchangeReference, setExchangeReference] = useState([]);
  const materialTableTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#000000",
      },
    },
  });

  const [loadingExchange, setLoadingExchange] = useState(false);
  const isDsc = AuthService.hasRoleDSC();
  const isPartner = AuthService.hasRolePartner();
  const isReadOnly = readOnly || (!isPartner && !isDsc && !keyuser);
  const [sendingOferta, setSendingOferta] = useState(false);
  const [exchangeOffer, setExchangeOffer] = useState({ pn: "", tc: "" });
  const styles = DetailTabs_Style();
  const ofertaDialog = useRef(null);
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  //Add Exchange
  const addExchange = async (newPn) => {
    setLoadingExchange(true);
    const driveId = equipo.id;
    const pn = newPn.pn;

    await EquipoExchangeAPI.addExchange(driveId, pn)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          const errorMessage = await response.text();
          if (errorMessage.includes("Duplicated PN")) {
            SnackbarError(
              setGlobal,
              i18n.t("planta.stockEmergencia.duplicated")
            );
          } else {
            throw new Error("Something went wrong");
          }
        }
      })
      .then((data) => {
        if (data) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("planta.stockEmergencia.correctAdd")
          );
          getAllExchanges();
        }
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("planta.stockEmergencia.notFound"));
        //setLoading(false);
        console.error(error);
      })
      .finally(() => setLoadingExchange(false));
  };

  //update Exchange
  const updeteExchange = async (newPn, oldPn) => {
    setLoadingExchange(true);
    const driveId = equipo.id;
    const newPN = newPn.pn;
    const oldPN = oldPn.pn;

    await EquipoExchangeAPI.updateExchange(driveId, newPN, oldPN)
      .then(async (response) => {
        if (response.ok) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("planta.stockEmergencia.correctUpdate")
          );
          getAllExchanges();
        } else {
          const errorMessage = await response.text();
          if (errorMessage.includes("Duplicated PN")) {
            SnackbarError(
              setGlobal,
              i18n.t("planta.stockEmergencia.duplicated")
            );
          } else {
            throw new Error("Something went wrong");
          }
        }
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("planta.stockEmergencia.notFound"));
        setLoadingExchange(false);
        console.error(error);
      })
      .finally(() => setLoadingExchange(false));
  };

  //Delete exchange
  const deleteExchange = async (pnDeleted) => {
    setLoadingExchange(true);
    const driveId = equipo.id;
    const pn = pnDeleted.pn;

    await EquipoExchangeAPI.deleteExchange(driveId, pn)
      .then((response) => {
        if (response.ok) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("planta.stockEmergencia.correctDelete")
          );
          getAllExchanges();
          return response.json();
        } else {
          SnackbarError(setGlobal, i18n.t("planta.stockEmergencia.notFound"));
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoadingExchange(false);
      })
      .finally(() => setLoadingExchange(false));
  };

  //Enviar oferta exchange
  const sendOferta = async () => {
    setSendingOferta(true);
    try {
      let response = await EquiposAPI.sendOfertaExchange(
        equipo.id,
        exchangeOffer.pn,
        exchangeOffer.tc
      );
      if (response.ok) {
        ofertaDialog.current.close();
        SnackbarSuccess(setGlobal, i18n.t("equipo.ofertaEnviada"));
      } else {
        const error = await response.text();
        throw new Error(error);
      }
    } catch (error) {
      ofertaDialog.current.close();
      SnackbarError(setGlobal, i18n.t("equipo.ofertaError"));
    }

    setSendingOferta(false);
  };

  //Get exchange
  const getAllExchanges = useCallback(() => {
    setLoadingExchange(true);
    EquipoExchangeAPI.getExchangeEquipment(equipo.id)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        if (data) {
          let preExchange = data;
          setExchangeReference(
            preExchange.map((exchange) => ({
              pn: exchange.pntc.pn,
              tc: exchange.pntc.tc,
              id: exchange.pntc.id,
            }))
          );
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoadingExchange(false));
  }, [equipo.id]);

  //Primer Get exchange de la pagina
  useEffect(() => {
    getAllExchanges();
  }, [getAllExchanges]);

  return (
    <ThemeProvider theme={materialTableTheme}>
      <MaterialTable
        style={{ width: "100%" }}
        title={i18n.t("equipo.exchangeTable.title")}
        // @ts-ignore
        localization={i18n.t("dataTableProps")}
        isLoading={loadingExchange}
        data={exchangeReference}
        options={{
          addRowPosition: "first",
          actionsColumnIndex: -1,
          sorting: true,
          showTitle: true,
          paging: false,
          search: false,
          searchFieldAlignment: "left",
        }}
        columns={[
          {
            field: "pn",
            title: i18n.t("equipo.exchangeTable.reference"),
            validate: (rowData) => {
              var isEmpty = !rowData.pn;
              var isValid = !isEmpty;
              return {
                isValid: isValid,
                helperText: isEmpty
                  ? i18n.t("common.required")
                  : !isValid
                  ? i18n.t("common.unique")
                  : "",
              };
            },
          },
          {
            field: "tc",
            title: i18n.t("equipo.exchangeTable.description"),
            editable: "never",
          },
        ]}
        detailPanel={[
          {
            icon: () => <ArrowForwardIos />,
            tooltip: i18n.t("equipo.sustitutos.exchange"),
            render: (rowData) => {
              return (
                <ThemeProvider theme={materialTableTheme}>
                  <MaterialTable
                    // @ts-ignore
                    localization={i18n.t("dataTableProps")}
                    data={sustituciones.filter(
                      (data) => data.pn === rowData.pn
                    )}
                    style={{ backgroundColor: "whitesmoke" }}
                    columns={[
                      {
                        title: i18n.t("equipo.exchangeTable.reference"),
                        field: "pn",
                      },
                      {
                        title: "TC / TYPE",
                        field: "tc",
                      },
                      {
                        title: "SN",
                        field: "sn",
                        render: (rowData) => (
                          <a
                            href={`/plantas/${rowData.zona.plantaId}/zonas/${rowData.zonaId}/equipos/${rowData.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={rowData.sn}
                            className={styles.linkColor}
                          >
                            <Typography>{rowData.sn}</Typography>
                          </a>
                        ),
                      },
                      {
                        title: i18n.t("equipo.sustitutos.found_in"),
                        field: "zona.descripcion",
                      },
                    ]}
                    options={{
                      showTitle: false,
                      search: false,
                      paging: false,
                      headerStyle: { backgroundColor: "whitesmoke" },
                    }}
                    components={{
                      Toolbar: (props) => <></>,
                    }}
                  />
                </ThemeProvider>
              );
            },
          },
        ]}
        actions={[
          {
            icon: "description",
            tooltip: i18n.t("equipo.documentacion"),
            onClick: (event, rowData) => {
              window.open(
                `https://store.danfoss.com/${locale}/p/${rowData.pn}`,
                "_blank"
              );
            },
          },
          {
            icon: "add_shopping_cart",
            tooltip: i18n.t("equipo.pedirOferta"),
            onClick: (event, rowData) => {
              setExchangeOffer({ pn: rowData.pn, tc: rowData.tc });
              ofertaDialog.current.open();
            },
          },
          {
            icon: "info",
            tooltip: i18n.t("equipo.retrofitTable.myDriveAssistant"),
            onClick: (event, rowData) => {
              window.open("https://mydriveassistant.danfoss.com/", "_blank");
            },
            isFreeAction: true,
          },
          {
            icon: "add_box",
            tooltip: i18n.t("common.isPremium"),
            onClick: () => {},
            disabled: true,
            hidden: isPremium,
            isFreeAction: true,
          },
        ]}
        editable={
          !isReadOnly && isPremium
            ? {
                onRowAdd: isPremium
                  ? (newData) => addExchange(newData)
                  : undefined,
                onRowUpdate: (newData, oldData) =>
                  updeteExchange(newData, oldData),
                onRowDelete: (oldData) => deleteExchange(oldData),
              }
            : {}
        }
      />
      <AlertDialog
        ref={ofertaDialog}
        loading={sendingOferta}
        title={i18n.t("equipo.ofertaTitulo")}
        text={i18n.t("equipo.ofertaTexto")}
        cancelText={i18n.t("equipo.alertDialog.cancelText")}
        confirmText={i18n.t("equipo.ofertaSolicitar")}
        confirmAction={() => sendOferta()}
      />
    </ThemeProvider>
  );
}
