import React, { useCallback, useContext, useEffect, useState } from "react";
import AlertDialog from "../../AlertDialog";
import i18n from "../../../locales/i18n";
import { EquipoMantenimientosAPI, EquiposAPI } from "../../../services/API";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { SnackbarError, SnackbarSuccess } from "../../../helpers/Common";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import AppContext from "../../../contexts/AppContext";

function MaintenanceCopyToDialog({
  open,
  setOpen,
  dialogRef,
  drive,
  maintenance,
}) {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [equipos, setEquipos] = useState([]);
  const [confirmedDrives, setConfirmedDrives] = useState([]);
  const [startup, setStartup] = useState(null);

  const getEquipos = useCallback(() => {
    setLoading(true);
    EquiposAPI.getEquiposToCopy(drive.zonaId)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.statusText);
        }
      })
      .then((data) => {
        if (data) {
          setEquipos(data.filter((row) => row.id !== drive.id && row.brand));
        } else {
          setEquipos([]);
        }
      })
      .catch((err) => {
        setEquipos([]);
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [drive]);

  useEffect(() => {
    setConfirmedDrives([]);
    setStartup(maintenance.startup);
    if (firstLoad && open && drive) {
      setFirstLoad(false);
      getEquipos();
    }
  }, [drive, open, getEquipos, firstLoad, maintenance]);

  const handleConfirm = () => {
    let resultMaintenance = { ...maintenance, id: 0 };

    if (!maintenance.personalizado) {
      resultMaintenance.startup = startup;
    }

    EquipoMantenimientosAPI.addOrUpdateMassive(
      confirmedDrives,
      resultMaintenance
    ).then((res) => {
      if (res.ok) {
        SnackbarSuccess(
          setGlobal,
          i18n.t("maintenance.snackbar.copyTo.success")
        );
        dialogRef.current.close();
        setOpen(false);
      } else {
        SnackbarError(setGlobal, i18n.t("maintenance.snackbar.copyTo.error"));
        console.log(res);
      }
    });
  };

  const styles = DetailTabs_Style();

  const selectDeselectDrives = (id) => {
    let semaf = confirmedDrives.indexOf(id);

    if (semaf === -1) {
      setConfirmedDrives((old) => [...old, id]);
    } else {
      setConfirmedDrives((old) => old.filter((row) => row !== id));
    }
  };

  return (
    /* @ts-ignore */
    <AlertDialog
      maxWidth={"md"}
      ref={dialogRef}
      title={i18n.t("maintenance.copyTo.title")}
      loading={loading}
      text={
        <Table style={{ width: 800 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              <TableCell>
                <b>{i18n.t("maintenance.copyTo.tag")}</b>
              </TableCell>
              <TableCell>
                <b>{i18n.t("maintenance.copyTo.model")}</b>
              </TableCell>
              <TableCell>
                <b>{i18n.t("maintenance.copyTo.pn")}</b>
              </TableCell>
              <TableCell>
                <b>{i18n.t("maintenance.copyTo.frame")}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {equipos.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {i18n.t("maintenance.copyTo.noData")}
                </TableCell>
              </TableRow>
            ) : (
              equipos.map((row) => (
                <TableRow
                  key={row.id}
                  style={
                    confirmedDrives.includes(row.id)
                      ? { backgroundColor: "#F4F4F4" }
                      : !maintenance.personalizado && row.frame !== drive.frame
                      ? { opacity: 0.5 }
                      : {}
                  }
                >
                  <TableCell>
                    <Checkbox
                      checked={confirmedDrives.includes(row.id)}
                      disabled={
                        !maintenance.personalizado && row.frame !== drive.frame
                      }
                      disableRipple
                      color="default"
                      onChange={() => selectDeselectDrives(row.id)}
                    />
                  </TableCell>
                  <TableCell>
                    {row.idCliente || i18n.t("maintenance.copyTo.undefined")}
                  </TableCell>
                  <TableCell>
                    {row.model?.name || i18n.t("maintenance.copyTo.undefined")}
                  </TableCell>
                  <TableCell>{row.pn}</TableCell>
                  <TableCell
                    style={
                      row.frame === drive.frame
                        ? {}
                        : { fontWeight: "bold", color: "red" }
                    }
                  >
                    {row.frame}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      }
      cancelText={i18n.t("common.cancelar")}
      confirmText={i18n.t("common.confirmar")}
      confirmAction={handleConfirm}
      confirmDisabled={
        confirmedDrives.length === 0 || (!maintenance.personalizado && !startup)
      }
      cancelAction={() => {
        dialogRef.current.close();
        setOpen(false);
      }}
      redTitle={true}
      bottomLeft={
        !maintenance.personalizado && (
          <TextField
            className={styles.materialTextField}
            required
            type="date"
            variant="outlined"
            value={startup ? startup.substring(0, 10) : ""}
            label={i18n.t("maintenance.fields.startup")}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setStartup(e.target.value)}
          />
        )
      }
    />
  );
}

export default MaintenanceCopyToDialog;
