import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Badge,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import DisableFilter from "../../atoms/disableFilter";
import { Cancel, Check, FilterList } from "@material-ui/icons";
import React, { useEffect, useState, useCallback } from "react";
import i18n from "../../../locales/i18n";
import { CountriesAPI } from "../../../services/API";
import RelativeBackdrop from "../../RelativeBackdrop";
import {
  DetailTabs_Style,
  CardList_Style,
  Common_Style,
  globalColors,
} from "../../../assets/css/Styles";
import "react-perfect-scrollbar/dist/css/styles.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { es, enUS as en, de, fr, it, pl } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// Tema para el calendario
const theme = createMuiTheme({
  overrides: {
    // @ts-ignore
    MuiPickersDay: {
      day: {
        color: "#484848", // Color del día
      },
      daySelected: {
        backgroundColor: "#E2000F", // Color día seleccionado
        "&:hover": {
          backgroundColor: "#B6000F", // Color de hover sobre el día seleccionado
        },
      },
      current: {
        color: "#B6000F", // Color del día actual
      },
    },

    MuiPickersYear: {
      root: {
        color: "#484848", // Color del año no seleccionado
        "&:hover": {
          color: "#B6000F", // Color de hover sobre el año
        },
        "&:focus": {
          color: "#B6000F", // Color de focus sobre el año
        },
      },
      yearSelected: {
        color: "#E2000F", // Color del año seleccionado
        fontWeight: "bold", // Bold del año seleccionado
        "&:hover": {
          color: "#B6000F", // Color de hover sobre el año
        },
        "&:focus": {
          color: "#B6000F", // Color de focus sobre el año
        },
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#E2000F", // Color de fondo de la barra del calendario
      },
    },
    MuiDialogActions: {
      root: {
        "& button": {
          color: "#303030", // Color botones de acción
          textTransform: "none", // Botones en minúsculas
        },
      },
    },
  },
});

//Para los estilos para los inputs de fecha
const useStyles = makeStyles({
  underline: {
    "&:before": {
      borderBottomColor: "black !important",
    },
    "&:after": {
      borderBottomColor: "black !important",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottomColor: "black !important",
    },
  },
});

export default function ListFilter(props) {
  const {
    //titulo del filtro
    filterTitle,
    //filtro paises
    hasCountryFilter,
    countryId,
    //filtro usuarios
    hasUserFilter,
    requestNumber,
    statusFilter,
    //filtro ordenes
    hasSorting,
    sortList,
    sortSelected,
    //filtro de fechas
    hasDateFilter,
    fromDate,
    toDate,
    //Mostrar o no Demo
    hideDemo,
    hideDemoLabel,
    //funciones
    handleClearFilter,
    handleApplyFilter,
  } = props;
  const locale = i18n.locale || i18n.defaultLocale;
  const [localeDate, setLocaleDate] = useState(en);
  const commonStyles = Common_Style();
  const inputstyles = useStyles();
  const styles = DetailTabs_Style();
  const secondaryStyles = CardList_Style();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [validValues, setValidValues] = useState(true);
  //Estados previos antes de aplicar el filtro
  const previusFilter = {
    statusFilter: statusFilter || 4,
    countryId: countryId || 0,
    sortSelected: sortSelected || 0,
    fromDate: fromDate || null,
    toDate: toDate || null,
    hideDemo: hideDemo || false,
  };
  const [genericFilter, setGenericFilter] = useState(previusFilter);

  //abrir Dialog
  const openDialog = () => {
    setOpen(true);
  };

  //cerrar Dialog
  const closeDialog = (filtersApplied) => {
    if (!filtersApplied) {
      setGenericFilter(previusFilter);
    }
    setOpen(false);
  };

  //Get countries
  const getCountries = useCallback(() => {
    CountriesAPI.getWithRegion()
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        //Ordenar por region y nombre
        data.sort((a, b) => {
          //ordenar por region
          if (a.regionId !== b.regionId) {
            return a.regionId - b.regionId;
          }
          //Ordenar por nombre
          return i18n
            .t(`country.${a.name}`)
            .localeCompare(i18n.t(`country.${b.name}`));
        });
        setCountries(data);
      })
      .catch((error) => {
        console.error("Error GetCountry:", error);
        setLoading(false);
      });
  }, []);

  const hangleSortChange = (e) => {
    let newSort = e.target.value;
    newSort = parseInt(newSort);
    //confirmar que newSort no sea null
    if (!newSort) {
      newSort = 0;
    }
    setGenericFilter({
      ...genericFilter,
      sortSelected: newSort,
    });
  };

  //Fecha From
  const handleFromDateChange = (date) => {
    let newDate = dateFormated(date);

    setGenericFilter({
      ...genericFilter,
      fromDate: newDate,
    });
  };

  //Fecha To
  const handleToDateChange = (date) => {
    let newDate = dateFormated(date);

    setGenericFilter({
      ...genericFilter,
      toDate: newDate,
    });
  };

  //Convertir la fecha a UTC para evitar problemas con la hora
  const dateFormated = (date) => {
    let dateFormated = date;
    //Si la fecha es valida
    if (date && !isNaN(date.getTime())) {
      dateFormated = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
    }
    return dateFormated;
  };

  //Error en la fecha From
  const handleFromDateError = (error) => {
    setFromDateError(error);
  };

  //Error en la fecha To
  const handleToDateError = (error) => {
    setToDateError(error);
  };

  //Switch
  const handleHidenDemo = () => {
    setGenericFilter({
      ...genericFilter,
      hideDemo: !genericFilter.hideDemo,
    });
  };

  //Reset de filtros y cerrar
  const cleanAndClose = () => {
    handleClearFilter();
    setGenericFilter({
      statusFilter: 4,
      countryId: 0,
      sortSelected: 0,
      fromDate: null,
      toDate: null,
      hideDemo: true,
    });
    closeDialog(true);
  };

  //Aplicar filtro (se aplica en el padre)
  const applyFilter = () => {
    closeDialog(true);
    handleApplyFilter(genericFilter);
  };

  //Para las traducciones para el calendario de los selectores
  useEffect(() => {
    switch (locale) {
      case "es":
        setLocaleDate(es);
        break;

      case "de":
        setLocaleDate(de);
        break;

      case "fr":
        setLocaleDate(fr);
        break;

      case "it":
        setLocaleDate(it);
        break;

      case "pl":
        setLocaleDate(pl);
        break;

      default:
        setLocaleDate(en);
    }
  }, [locale]);

  //Validar formulario (por ahora solo fechas)
  useEffect(() => {
    if (fromDateError || toDateError) {
      setValidValues(false);
    } else {
      setValidValues(true);
    }
  }, [fromDateError, toDateError]);

  //Cargar paises
  useEffect(() => {
    if (hasCountryFilter) {
      getCountries();
    }
  }, [getCountries, hasCountryFilter]);

  return (
    <>
      {/* Boton de filtro que se muestra */}
      <Button
        data-testid="listFilter-button"
        onClick={openDialog}
        startIcon={<FilterList />}
        variant="outlined"
        className={commonStyles.secondaryButtonRaw}
      >
        {i18n.t("dashboard.filters")}
      </Button>

      {/* Dialog */}
      <Dialog open={open} onClose={() => {}}>
        <RelativeBackdrop hidden={!loading}>
          <DialogTitle className={styles.filterListTitle}>
            <FilterList /> {filterTitle}
          </DialogTitle>
          <DialogContent>
            <div
              className={styles.filterListContainer}
              data-testid="listFilter-container"
            >
              {/* Filtro de ordenes */}
              {hideDemoLabel && (
                <div className={styles.flexRowForm}>
                  <div className={styles.flexCellForm}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="listFilter-hideDemo"
                          checked={genericFilter.hideDemo}
                          className={styles.materialSwich}
                          onChange={handleHidenDemo}
                        />
                      }
                      label={hideDemoLabel}
                    />
                  </div>
                </div>
              )}
              {/* Filtro de ordenes */}
              {hasSorting && (
                <div className={styles.flexRowForm}>
                  <div className={styles.flexCellForm}>
                    <RadioGroup
                      name="listFilter-sorting"
                      value={genericFilter.sortSelected.toString()}
                      onChange={hangleSortChange}
                    >
                      {sortList.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Radio
                              style={{
                                color: "black",
                                backgroundColor: "transparent",
                              }}
                            />
                          }
                          value={item.value}
                          label={item.label}
                          checked={genericFilter.sortSelected === item.value}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </div>
              )}
              {/* Filtro de usuario */}
              {hasUserFilter && (
                <div className={styles.flexRowForm}>
                  <div className={styles.flexCellForm}>
                    <TextField
                      value={genericFilter.statusFilter}
                      fullWidth
                      select
                      label={i18n.t("registros.filterregisters.label")}
                      className={styles.materialTextField}
                      defaultValue={4}
                      onChange={(e) => {
                        setGenericFilter({
                          ...genericFilter,
                          statusFilter: e.target.value,
                        });
                      }}
                      inputProps={{
                        name: "listFilter-userStatus",
                      }}
                    >
                      <MenuItem value={4}>
                        {i18n.t("registros.filterregisters.loged")}
                      </MenuItem>
                      <MenuItem value={0}>
                        {i18n.t("registros.filterregisters.all")}
                      </MenuItem>
                      <MenuItem value={1}>
                        {i18n.t("registros.filterregisters.pending")}
                        <Badge
                          badgeContent={requestNumber}
                          color="primary"
                          className={secondaryStyles.userCardBadge}
                        />
                      </MenuItem>
                      <MenuItem value={2}>
                        {i18n.t("registros.filterregisters.accepted")}
                      </MenuItem>
                      <MenuItem value={3}>
                        {i18n.t("registros.filterregisters.rejected")}
                      </MenuItem>
                    </TextField>
                  </div>
                </div>
              )}
              {/* Filtro de paises */}
              {hasCountryFilter && (
                <div className={styles.flexRowForm}>
                  <div className={styles.flexCellForm}>
                    <Autocomplete
                      autoComplete={true}
                      groupBy={(option) =>
                        option?.region?.regionCode || i18n.t("common.n/a")
                      }
                      fullWidth
                      autoHighlight
                      options={countries}
                      getOptionLabel={(c) =>
                        i18n.t(`country.${c.name || "null"}`)
                      }
                      value={
                        countries.find(
                          (c) => c.id === genericFilter.countryId
                        ) || null
                      }
                      className={styles.materialTextField}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={i18n.t("planta.pais")}
                          name="listFilter-countryId"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      onChange={(e, newValue) => {
                        setGenericFilter({
                          ...genericFilter,
                          countryId: newValue?.id || null,
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              {/* Filtro de fechas */}
              {hasDateFilter && (
                <div className={styles.flexRowForm}>
                  <div className={styles.flexCellForm}>
                    <ThemeProvider theme={theme}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={localeDate}
                      >
                        <div
                          className={
                            commonStyles.plantListAccordionDetailsContainer
                          }
                        >
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-startDate"
                            label={i18n.t("planta.filterregisters.startDate")}
                            format="dd/MM/yyyy"
                            value={genericFilter.fromDate}
                            onChange={handleFromDateChange}
                            onError={(error) => {
                              handleFromDateError(error);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="dd/MM/yyyy"
                            okLabel={i18n.t("common.confirmar")}
                            cancelLabel={i18n.t("common.cancelar")}
                            invalidDateMessage={i18n.t(
                              "planta.filterregisters.invalidDateMessage"
                            )}
                            minDateMessage={i18n.t(
                              "planta.filterregisters.notValidDate"
                            )}
                            maxDateMessage={i18n.t(
                              "planta.filterregisters.notValidDate"
                            )}
                            InputProps={{
                              name: "listFilter-fromDate",
                              classes: {
                                underline: inputstyles.underline,
                              },
                            }}
                            className={styles.materialTextField}
                          />
                          <div> __ </div>
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label={i18n.t("planta.filterregisters.endDate")}
                            format="dd/MM/yyyy"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={genericFilter.toDate}
                            placeholder="dd/MM/yyyy"
                            onChange={handleToDateChange}
                            onError={(error) => {
                              handleToDateError(error);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            okLabel={i18n.t("common.confirmar")}
                            cancelLabel={i18n.t("common.cancelar")}
                            invalidDateMessage={i18n.t(
                              "planta.filterregisters.invalidDateMessage"
                            )}
                            minDateMessage={i18n.t(
                              "planta.filterregisters.notValidDate"
                            )}
                            maxDateMessage={i18n.t(
                              "planta.filterregisters.notValidDate"
                            )}
                            InputProps={{
                              name: "listFilter-toDate",
                              classes: {
                                underline: inputstyles.underline,
                              },
                            }}
                            className={styles.materialTextField}
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </div>
                </div>
              )}
            </div>
          </DialogContent>

          {/* Botones de acciones */}
          <DialogActions>
            <div className={styles.filterListActions}>
              <div className={styles.filterListClearButtonContainer}>
                <Button
                  data-testid="listFilter-clear-button"
                  onClick={() => {
                    cleanAndClose();
                  }}
                  startIcon={<DisableFilter color={globalColors.activeColor} />}
                  variant="outlined"
                  className={styles.secondaryButton}
                >
                  {i18n.t("common.clear")}
                </Button>
              </div>
              <Button
                data-testid="listFilter-close-button"
                onClick={() => {
                  closeDialog(false);
                }}
                startIcon={<Cancel />}
                variant="outlined"
                className={styles.secondaryButton}
              >
                {i18n.t("planta.revisionEquipos.close")}
              </Button>
              <Button
                data-testid="listFilter-apply-button"
                disabled={!validValues}
                onClick={applyFilter}
                className={styles.saveDialogEquipmentButton}
                variant="contained"
                startIcon={<Check />}
              >
                {i18n.t("dashboard.alertDialog.confirmText")}
              </Button>
            </div>
          </DialogActions>
        </RelativeBackdrop>
      </Dialog>
    </>
  );
}
