// @ts-nocheck
import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import MaterialTable from "material-table";
import i18n from "../../../locales/i18n";
import { Typography } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { EquiposAPI } from "../../../services/API";
import { globalStatusColors } from "../../../assets/css/Styles";
import AlertDialog from "../../AlertDialog";
import AppContext from "../../../contexts/AppContext";
import { SnackbarSuccess, SnackbarError } from "../../../helpers/Common";
//NEW
import { EquipoRetrofitApi } from "../../../services/API";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import AuthService from "../../../services/AuthService";

export default function EquipoRetrofitTable(props) {
  const {
    keyuser,
    isPremium,
    readOnly,
    equipo,
    sustituciones,
    reloadRetrofit,
  } = props;
  const locale = i18n.locale ? i18n.locale : i18n.defaultLocale;
  const [retrofitReference, setRetrofitReference] = useState([]);
  const materialTableTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#000000",
      },
    },
  });

  const [loadingRetrofit, setLoadingRetrofit] = useState(false);
  const isDsc = AuthService.hasRoleDSC();
  const isPartner = AuthService.hasRolePartner();
  const isReadOnly = readOnly || (!isPartner && !isDsc && !keyuser);
  const [sendingOferta, setSendingOferta] = useState(false);
  const [retroOffer, setRetroOffer] = useState({ pn: "", tc: "" });
  const [isCalculated, setIsCalculated] = useState(false);
  const styles = DetailTabs_Style();

  const ofertaDialog = useRef(null);
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);

  //Add retrofit
  const addRetrofit = async (newPn) => {
    setLoadingRetrofit(true);
    const driveId = equipo.id;
    const pn = newPn.pn;

    await EquipoRetrofitApi.addRetrofit(driveId, pn)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          const errorMessage = await response.text();
          if (errorMessage.includes("Duplicated PN")) {
            SnackbarError(
              setGlobal,
              i18n.t("planta.stockEmergencia.duplicated")
            );
          } else {
            throw new Error("Something went wrong");
          }
        }
      })
      .then((data) => {
        if (data) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("planta.stockEmergencia.correctAdd")
          );
          getAllRetrofits();
        }
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("planta.stockEmergencia.notFound"));
        //setLoading(false);
        console.error(error);
      })
      .finally(() => setLoadingRetrofit(false));
  };

  //update retrofit
  const updateRetrofit = async (newPn, oldPn) => {
    setLoadingRetrofit(true);
    const driveId = equipo.id;
    const newPN = newPn.pn;
    const oldPN = oldPn.pn;

    await EquipoRetrofitApi.updateRetrofit(driveId, newPN, oldPN)
      .then(async (response) => {
        if (response.ok) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("planta.stockEmergencia.correctUpdate")
          );
          getAllRetrofits();
        } else {
          const errorMessage = await response.text();
          if (errorMessage.includes("Duplicated PN")) {
            SnackbarError(
              setGlobal,
              i18n.t("planta.stockEmergencia.duplicated")
            );
          } else {
            throw new Error("Something went wrong");
          }
        }
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("planta.stockEmergencia.notFound"));
        setLoadingRetrofit(false);
        console.error(error);
      })
      .finally(() => setLoadingRetrofit(false));
  };

  //Delete retrofit
  const deleteRetrofit = async (pnDeleted) => {
    setLoadingRetrofit(true);
    const driveId = equipo.id;
    const pn = pnDeleted.pn;

    await EquipoRetrofitApi.deleteRetrofit(driveId, pn)
      .then((response) => {
        if (response.ok) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("planta.stockEmergencia.correctDelete")
          );
          getAllRetrofits();
          return response.json();
        } else {
          SnackbarError(setGlobal, i18n.t("planta.stockEmergencia.notFound"));
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoadingRetrofit(false);
      })
      .finally(() => setLoadingRetrofit(false));
  };

  const validatePN = async (rowToValidate) => {
    setLoadingRetrofit(true);
    const pn = rowToValidate.pn;

    await EquipoRetrofitApi.validatePN(pn)
      .then((response) => {
        if (response.ok) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("planta.stockEmergencia.correctUpdate")
          );
          getAllRetrofits();
          return response.json();
        } else {
          SnackbarError(setGlobal, i18n.t("planta.stockEmergencia.notFound"));
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoadingRetrofit(false);
      })
      .finally(() => setLoadingRetrofit(false));
  };

  //Enviar oferta
  const sendOferta = async () => {
    setSendingOferta(true);
    try {
      let response = await EquiposAPI.sendOfertaRetrofit(
        equipo.id,
        retroOffer.pn,
        retroOffer.tc
      );
      if (response.ok) {
        ofertaDialog.current.close();
        SnackbarSuccess(setGlobal, i18n.t("equipo.ofertaEnviada"));
      } else {
        const error = await response.text();
        throw new Error(error);
      }
    } catch (error) {
      ofertaDialog.current.close();
      SnackbarError(setGlobal, i18n.t("equipo.ofertaError"));
    }

    setSendingOferta(false);
  };

  //Get retrofit
  const getAllRetrofits = useCallback(() => {
    setLoadingRetrofit(true);
    EquipoRetrofitApi.getRetrofitEquipment(equipo.id)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        var allRetrofit = data.allRetrofit;
        setRetrofitReference(
          allRetrofit.map((retrofit) => ({
            pn: retrofit.newPN,
            tc: retrofit.newTC,
            isNotIntegrable: retrofit.isNotIntegrable,
            id: retrofit.id,
          }))
        );
        //Cuando hay algun retrofit de la tabla retrofitProposal
        if (allRetrofit.some((r) => r.id !== 0)) {
          setIsCalculated(true);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoadingRetrofit(false));
  }, [equipo.id]);

  //Actualizar cuando se guarda un equipo
  useEffect(() => {
    if (reloadRetrofit === true) {
      getAllRetrofits();
    }
  }, [reloadRetrofit, getAllRetrofits]);

  //Primer Get retrofit de la pagina
  useEffect(() => {
    getAllRetrofits();
  }, [getAllRetrofits]);

  return (
    <ThemeProvider theme={materialTableTheme}>
      <MaterialTable
        style={{ width: "100%" }}
        title={i18n.t("equipo.retrofitTable.title")}
        // @ts-ignore
        localization={i18n.t("dataTableProps")}
        isLoading={loadingRetrofit}
        data={retrofitReference}
        options={{
          addRowPosition: "first",
          actionsColumnIndex: -1,
          sorting: true,
          showTitle: true,
          paging: false,
          search: false,
          searchFieldAlignment: "left",
        }}
        columns={[
          {
            field: "pn",
            title: i18n.t("equipo.retrofitTable.reference"),
            validate: (rowData) => {
              var isEmpty = !rowData.pn;
              var isValid = !isEmpty;
              return {
                isValid: isValid,
                helperText: isEmpty
                  ? i18n.t("common.required")
                  : !isValid
                  ? i18n.t("common.unique")
                  : "",
              };
            },
          },
          {
            field: "tc",
            title: i18n.t("equipo.retrofitTable.description"),
            editable: "never",
          },
        ]}
        detailPanel={[
          {
            icon: () => <ArrowForwardIos />,
            tooltip: i18n.t("equipo.sustitutos.retrofit"),
            render: (rowData) => {
              return (
                <ThemeProvider theme={materialTableTheme}>
                  <MaterialTable
                    // @ts-ignore
                    localization={i18n.t("dataTableProps")}
                    data={sustituciones.filter(
                      (data) => data.pn === rowData.pn
                    )}
                    style={{ backgroundColor: "whitesmoke" }}
                    columns={[
                      {
                        title: i18n.t("equipo.retrofitTable.reference"),
                        field: "pn",
                      },
                      {
                        title: "TC / TYPE",
                        field: "tc",
                      },
                      {
                        title: "SN",
                        field: "sn",
                        render: (rowData) => (
                          <a
                            href={`/plantas/${rowData.zona.plantaId}/zonas/${rowData.zonaId}/equipos/${rowData.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={rowData.sn}
                            className={styles.linkColor}
                          >
                            <Typography>{rowData.sn}</Typography>
                          </a>
                        ),
                      },
                      {
                        title: i18n.t("equipo.sustitutos.found_in"),
                        field: "zona.descripcion",
                      },
                    ]}
                    options={{
                      showTitle: false,
                      search: false,
                      paging: false,
                      headerStyle: { backgroundColor: "whitesmoke" },
                    }}
                    components={{
                      Toolbar: (props) => <></>,
                    }}
                  />
                </ThemeProvider>
              );
            },
          },
        ]}
        actions={[
          {
            icon: isCalculated ? "bolt" : "brightness1",
            tooltip: isCalculated
              ? i18n.t("equipo.retrofitTable.multipleVoltages")
              : null,
            iconProps: {
              style: {
                color: isCalculated
                  ? globalStatusColors.infoColor
                  : "transparent",
              },
            },

            disabled: !isCalculated,
            //para evitar el puto WARNING
            onClick: (event, rowData) => {},
            isFreeAction: true,
          },

          (rowData) =>
            rowData.isNotIntegrable
              ? {
                  icon: "warning",
                  iconProps: {
                    style: { color: globalStatusColors.warningColor },
                  },
                  tooltip: i18n.t("equipo.retrofitTable.notIntegrable"),
                  disabled: true,
                }
              : {
                  icon: "brightness1",
                  iconProps: {
                    style: { color: "transparent" },
                  },
                  disabled: true,
                },

          (rowData) =>
            rowData.id
              ? {
                  icon: "verified_user",
                  iconProps: {
                    style: { color: globalStatusColors.infoColor },
                  },
                  tooltip: i18n.t("equipo.retrofitTable.retrofitRecomendation"),
                  disabled: true,
                }
              : rowData.tc === "Not validated"
              ? {
                  icon: "published_with_changes",
                  iconProps: {
                    style: { color: globalStatusColors.warningColor },
                  },
                  tooltip: i18n.t("equipo.retrofitTable.retrofitNotValid"),
                  onClick: (event, rowData) => {
                    validatePN(rowData);
                  },
                }
              : {
                  icon: "brightness1",
                  iconProps: {
                    style: { color: "transparent" },
                  },
                  disabled: true,
                },

          {
            icon: "description",
            tooltip: i18n.t("equipo.documentacion"),
            onClick: (event, rowData) => {
              window.open(
                `https://store.danfoss.com/${locale}/p/${rowData.pn}`,
                "_blank"
              );
            },
          },
          {
            icon: "add_shopping_cart",
            tooltip: i18n.t("equipo.pedirOferta"),
            onClick: (event, rowData) => {
              setRetroOffer({ pn: rowData.pn, tc: rowData.tc });
              ofertaDialog.current.open();
            },
          },
          {
            icon: "info",
            tooltip: i18n.t("equipo.retrofitTable.myDriveAssistant"),
            onClick: (event, rowData) => {
              window.open("https://mydriveassistant.danfoss.com/", "_blank");
            },
            isFreeAction: true,
          },
          {
            icon: "add_box",
            tooltip: i18n.t("common.isPremium"),
            onClick: () => {},
            disabled: true,
            hidden: isPremium,
            isFreeAction: true,
          },
        ]}
        editable={
          !isReadOnly
            ? {
                onRowAdd: isPremium
                  ? (newData) => addRetrofit(newData)
                  : undefined,
                isEditable: (rowData) => rowData.id === 0 && isPremium,
                isDeletable: (rowData) => rowData.id === 0 && isPremium,
                onRowUpdate: (newData, oldData) =>
                  updateRetrofit(newData, oldData),
                onRowDelete: (oldData) => deleteRetrofit(oldData),
              }
            : {}
        }
      />
      <AlertDialog
        ref={ofertaDialog}
        loading={sendingOferta}
        title={i18n.t("equipo.ofertaTitulo")}
        text={i18n.t("equipo.ofertaTexto")}
        cancelText={i18n.t("equipo.alertDialog.cancelText")}
        confirmText={i18n.t("equipo.ofertaSolicitar")}
        confirmAction={() => sendOferta()}
      />
    </ThemeProvider>
  );
}
