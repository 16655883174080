import React, { useEffect, useState } from "react";
import AlertDialog from "../../AlertDialog";
import i18n from "i18n-js";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { PartnersAPI } from "../../../services/API";
import moment from "moment";
import { Launch } from "@material-ui/icons";

export default function SubscriptionManagementDialog(props) {
  const { dialogRef, handlePartner, handleClient, isPartner } = props;

  const [loading, setLoading] = useState(false);
  const [setsubscriptions, setSetsubscriptions] = useState([]);

  const handleClose = () => {
    dialogRef.current.close();
  };

  const getSubscriptions = () => {
    setLoading(true);
    PartnersAPI.getSubscriptions()
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.statusText);
        }
      })
      .then((data) => {
        if (data) {
          setSetsubscriptions(data);
        } else {
          setSetsubscriptions([]);
        }
      })
      .catch((err) => {
        setSetsubscriptions([]);
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <AlertDialog
      maxWidth="lg"
      ref={dialogRef}
      title={i18n.t("partnersClients.subscriptionsModal.title")}
      loading={loading}
      text={
        <Table style={{ width: 1200 }}>
          <TableHead>
            {!isPartner ? (
              <TableRow>
                <TableCell>
                  <b>{i18n.t("partnersClients.subscriptionsModal.name")}</b>
                </TableCell>
                <TableCell>
                  <b>{i18n.t("partnersClients.subscriptionsModal.type")}</b>
                </TableCell>
                <TableCell>
                  <b>
                    {i18n.t(
                      "partnersClients.subscriptionsModal.tryoutStartDate"
                    )}
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    {i18n.t("partnersClients.subscriptionsModal.tryoutEndDate")}
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    {i18n.t(
                      "partnersClients.subscriptionsModal.premiumStartDate"
                    )}
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    {i18n.t(
                      "partnersClients.subscriptionsModal.premiumEndDate"
                    )}
                  </b>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell>
                  <b>{i18n.t("partnersClients.subscriptionsModal.name")}</b>
                </TableCell>
                <TableCell>
                  <b>
                    {i18n.t("partnersClients.subscriptionsModal.firstLogin")}
                  </b>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {setsubscriptions.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {i18n.t("maintenance.copyTo.noData")}
                </TableCell>
              </TableRow>
            ) : (
              setsubscriptions.map((row, index) =>
                !isPartner ? (
                  <TableRow key={index}>
                    <TableCell>
                      <IconButton
                        data-testid={"premiumPartner-" + row.name}
                        size="small"
                        onClick={() => {
                          handleClose();
                          if (row.type === "client") {
                            handleClient(row.partnerId, row.clientId);
                          } else {
                            handlePartner(row.partnerId);
                          }
                        }}
                      >
                        <Launch />
                      </IconButton>{" "}
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {i18n.t("partnersClients.subscriptionsModal." + row.type)}
                    </TableCell>
                    <TableCell>
                      {row.tryoutStartDate
                        ? moment(row.tryoutStartDate).format("DD-MM-YYYY")
                        : "N/D"}
                    </TableCell>
                    <TableCell>
                      {row.tryoutEndDate
                        ? moment(row.tryoutEndDate).format("DD-MM-YYYY")
                        : "N/D"}
                    </TableCell>
                    <TableCell>
                      {row.premiumStartDate
                        ? moment(row.premiumStartDate).format("DD-MM-YYYY")
                        : "N/D"}
                    </TableCell>
                    <TableCell>
                      {row.premiumEndDate
                        ? moment(row.premiumEndDate).format("DD-MM-YYYY")
                        : "N/D"}
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow  key={index}>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleClose();
                          if (row.type === "client") {
                            handleClient(row.partnerId, row.clientId);
                          } else {
                            handlePartner(row.partnerId);
                          }
                        }}
                      >
                        <Launch />
                      </IconButton>{" "}
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {row.firstLogin
                        ? moment(row.firstLogin).format("DD-MM-YYYY")
                        : "N/D"}
                    </TableCell>
                  </TableRow>
                )
              )
            )}
          </TableBody>
        </Table>
      }
      confirmText={i18n.t("common.cerrar")}
      confirmAction={handleClose}
      cancelAction={handleClose}
      redTitle={true}
    />
  );
}
