export default class ClienteBase {
  /** @type {number} */ id = undefined;
  /** @type {string} */ cif = null;
  /** @type {string} */ razonSocial = null;
  /** @type {string} */ direccion = null;
  /** @type {string} */ cp = null;
  /** @type {string} */ poblacion = null;
  /** @type {string} */ provincia = null;
  /** @type {number} */ countryId = null;
  /** @type {string} */ avatar = null;
  /** @type {number} */ partnerId = undefined;
  /** @type {string} */ salesforceId = null;
  /** @type {boolean} */ infoBySalesforce = false;
  /** @type {string} */ reportLogoURL = null;
  /** @type {boolean} */ isDanfossOwnedClient = false;
  /** @type {boolean} */ isPremium = false;
  /** @type {boolean} */ isTryout = false;
  premiumStartDate = undefined;
  premiumEndDate = undefined;
  tryoutStartDate = undefined;
  tryoutEndDate = undefined;
  /** @type {boolean} */ isTest = false;
  /** @type {boolean} */ isDemo = false;

  /**@param {Object} IntiObj  */
  constructor(IntiObj) {
    if (IntiObj.id) {
      this.id = IntiObj.id;
    }
    if (IntiObj.cif) {
      this.cif = IntiObj.cif;
    }
    if (IntiObj.razonSocial) {
      this.razonSocial = IntiObj.razonSocial;
    }
    if (IntiObj.direccion) {
      this.direccion = IntiObj.direccion;
    }
    if (IntiObj.cp) {
      this.cp = IntiObj.cp;
    }
    if (IntiObj.poblacion) {
      this.poblacion = IntiObj.poblacion;
    }
    if (IntiObj.provincia) {
      this.provincia = IntiObj.provincia;
    }
    if (IntiObj.countryId) {
      this.countryId = IntiObj.countryId;
    }
    if (IntiObj.avatar) {
      this.avatar = IntiObj.avatar;
    }
    if (IntiObj.partnerId) {
      this.partnerId = IntiObj.partnerId;
    }
    if (IntiObj.salesforceId) {
      this.salesforceId = IntiObj.salesforceId;
    }
    if (IntiObj.infoBySalesforce) {
      this.infoBySalesforce = IntiObj.infoBySalesforce;
    }
    if (IntiObj.reportLogoURL) {
      this.reportLogoURL = IntiObj.reportLogoURL;
    }
    if (IntiObj.isDanfossOwnedClient) {
      this.isDanfossOwnedClient = IntiObj.isDanfossOwnedClient;
    }
    if (IntiObj.isPremium) {
      this.isPremium = IntiObj.isPremium;
    }
    if (IntiObj.isTryout) {
      this.isTryout = IntiObj.isTryout;
    }
    if (IntiObj.premiumStartDate) {
      this.premiumStartDate = IntiObj.premiumStartDate;
    }
    if (IntiObj.premiumEndDate) {
      this.premiumEndDate = IntiObj.premiumEndDate;
    }
    if (IntiObj.tryoutStartDate) {
      this.tryoutStartDate = IntiObj.tryoutStartDate;
    }
    if (IntiObj.tryoutEndDate) {
      this.tryoutEndDate = IntiObj.tryoutEndDate;
    }
    if (IntiObj.isTest) {
      this.isTest = IntiObj.isTest;
    }
    if (IntiObj.isDemo) {
      this.isDemo = IntiObj.isDemo;
    }
  }
}
