import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  DetailTabs_Style,
  TabReportPlantaStyle,
} from "../../../assets/css/Styles";
import i18n from "../../../locales/i18n";
// eslint-disable-next-line no-unused-vars
import { ReportOptionsModel } from "./pdf/helpers";

const pageSizeSelector = ["A4", "A3", "A2", "letter"];
const pageOrientationSelector = [
  { name: "portrait", value: "portrait" },
  { name: "landscape", value: "landscape" },
];
const dateFormatSelector = ["DD/MM/YYYY", "MM/DD/YYYY"];
const languageSelector = [
  { name: "english", value: "en" },
  { name: "spanish", value: "es" },
  { name: "german", value: "de" },
  { name: "polish", value: "pl" },
  { name: "italian", value: "it" },
  { name: "french", value: "fr" },
  { name: "turkish", value: "tr" },
];

/**
 * Dialog para modificar las opciones del report.
 * @Component
 * @param {Object} props
 * @param {boolean} props.open Estado actual del dialog abierto o cerrado.
 * @param {function} props.setOpen Funcion para abrir o cerrar dialog.
 * @param {ReportOptionsModel} props.options Opciones del report.
 * @param {function} props.setOptions Funcion para cambiar las opciones del report.
 */
function ReportOptions({ open, setOpen, options, setOptions }) {
  const styles = DetailTabs_Style();
  const ownStyles = TabReportPlantaStyle();

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={"md"}>
      <DialogTitle className={ownStyles.reportOptionsTitle}>
        <div>{i18n.t("report.reportOptions.title")}</div>
      </DialogTitle>
      <Divider />
      <DialogContent className={ownStyles.reportOptionsContent}>
        <TextField
          fullWidth
          select
          className={styles.materialTextField}
          label={i18n.t("report.reportOptions.size")}
          value={options.pageSize || ""}
          onChange={(e) =>
            setOptions((old) => ({ ...old, pageSize: e.target.value }))
          }
          name="size"
          id="size"
        >
          {pageSizeSelector.map((row) => (
            <MenuItem key={row} value={row}>
              {row || ""}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          className={styles.materialTextField}
          label={i18n.t("report.reportOptions.orientation")}
          value={options.pageOrientation || ""}
          onChange={(e) =>
            setOptions((old) => ({ ...old, pageOrientation: e.target.value }))
          }
          name="orientation"
          id="orientation"
        >
          {pageOrientationSelector.map((row) => (
            <MenuItem key={row.value} value={row.value}>
              {i18n.t(`report.reportOptions.${row.name}`) || ""}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          className={styles.materialTextField}
          label={i18n.t("report.reportOptions.dateFormat")}
          value={options.dateFormat || ""}
          onChange={(e) =>
            setOptions((old) => ({ ...old, dateFormat: e.target.value }))
          }
          name="dateFormat"
          id="dateFormat"
        >
          {dateFormatSelector.map((row) => (
            <MenuItem key={row} value={row}>
              {row || ""}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          className={styles.materialTextField}
          label={i18n.t("report.reportOptions.language")}
          value={options.language || ""}
          onChange={(e) =>
            setOptions((old) => ({ ...old, language: e.target.value }))
          }
          name="language"
          id="language"
        >
          {languageSelector.map((row) => (
            <MenuItem key={row.value} value={row.value}>
              {i18n.t(`report.reportOptions.${row.name}`) || ""}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
    </Dialog>
  );
}

export default ReportOptions;
