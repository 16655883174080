import React, { useCallback, useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import i18n from "../../../locales/i18n";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { UsersAPI } from "../../../services/API";
import {
  AccessType,
  SnackbarError,
  SnackbarSuccess,
} from "../../../helpers/Common";
import AppContext from "../../../contexts/AppContext";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import RelativeBackdrop from "../../RelativeBackdrop";

function UserPlantaAccess({
  userId,
  selectedPlant,
  setSelectedPlant,
  plantasOptions,
  readOnly,
}) {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const styles = DetailTabs_Style();

  const [loading, setLoading] = useState(true);
  const [listPlantAccess, setListPlantAccess] = useState([]);

  const getUserPlantAccess = useCallback(() => {
    setLoading(true);
    UsersAPI.getPlantAccess(userId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => setListPlantAccess(data || []))
      .catch((e) => {
        console.error(e.message);
      })
      .finally(() => setLoading(false));
  }, [userId]);

  useEffect(() => {
    getUserPlantAccess();
  }, [getUserPlantAccess]);

  const AddUserPlantAccess = async () => {
    setLoading(true);
    UsersAPI.addPlantAccess(userId, selectedPlant?.id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setListPlantAccess((old) => [...old, data]);
        SnackbarSuccess(
          setGlobal,
          i18n.t("gestorUsuarios.snackbar.update.success")
        );
      })
      .catch((e) => {
        console.error(e.message);
        SnackbarError(
          setGlobal,
          i18n.t("gestorUsuarios.snackbar.update.error")
        );
      })
      .finally(() => {
        setSelectedPlant(undefined);
        setLoading(false);
      });
  };

  const UpdateUserPlantAccess = async (id, accessType) => {
    setLoading(true);
    UsersAPI.changePlantAccessType(id, accessType)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setListPlantAccess((old) =>
          old.map((pa) => (pa.id === id ? data : pa))
        );
        SnackbarSuccess(
          setGlobal,
          i18n.t("gestorUsuarios.snackbar.update.success")
        );
      })
      .catch((e) => {
        console.error(e.message);
        SnackbarError(
          setGlobal,
          i18n.t("gestorUsuarios.snackbar.update.error")
        );
      })
      .finally(() => {
        setSelectedPlant(undefined);
        setLoading(false);
      });
  };

  const RemoveUserPlantAccess = async (oldData) => {
    setLoading(true);
    UsersAPI.removePlantAccess(userId, oldData.plantaId)
      .then((response) => {
        if (response.ok) {
          setListPlantAccess((old) =>
            old.filter((p) => p.plantaId !== oldData.plantaId)
          );
          SnackbarSuccess(
            setGlobal,
            i18n.t("gestorUsuarios.snackbar.update.success")
          );
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((e) => {
        console.error(e.message);
        SnackbarError(
          setGlobal,
          i18n.t("gestorUsuarios.snackbar.update.error")
        );
      })
      .finally(() => setLoading(false));
  };

  const enrichedData = listPlantAccess.map((pa) => {
    const plant = plantasOptions.find((po) => po.id === pa.plantaId);
    return {
      ...pa,
      planta: plant?.denominacion || "",
      cliente: plant?.cliente?.razonSocial || "",
      partner: plant?.partner?.razonSocial || "",
    };
  });

  const columns = [
    {
      title: i18n.t("user.AccesoPlantas.partner"),
      field: "partner",
      defaultGroupOrder: 0,
      editable: "never",
    },
    {
      title: i18n.t("user.AccesoPlantas.planta"),
      field: "planta",
      editComponent: () => (
        <Autocomplete
          style={{ width: "300%" }}
          groupBy={(row) => row?.partner?.razonSocial}
          fullWidth
          options={plantasOptions.filter(
            (po) => !listPlantAccess.some((pa) => pa.plantaId === po.id)
          )}
          getOptionLabel={(row) =>
            `${row?.denominacion} (${row?.cliente?.razonSocial})`
          }
          value={selectedPlant}
          className={styles.materialTextField}
          onChange={(e, newValue) => setSelectedPlant(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={i18n.t("user.planta")}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      ),
    },
    {
      title: i18n.t("user.AccesoPlantas.cliente"),
      field: "cliente",
      editable: "never",
    },
    {
      title: i18n.t("user.AccesoPlantas.accessType"),
      field: "accessType",
      render: (row) => (
        <FormControl className={styles.materialTextField}>
          <Select
            value={row.accessType}
            onChange={(e) => UpdateUserPlantAccess(row.id, e.target.value)}
            inputProps={{
              readOnly: readOnly,
            }}
          >
            {Object.keys(AccessType).map((key) => (
              <MenuItem key={key} value={AccessType[key]}>
                {i18n.t(`user.AccesoPlantas.typeSelector.${AccessType[key]}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      editable: "never",
    },
  ];

  return (
    <RelativeBackdrop hidden={!loading}>
      {/* @ts-ignore */}
      <MaterialTable
        isLoading={loading}
        style={{ width: "100%" }}
        columns={columns}
        data={enrichedData}
        title={i18n.t("user.AccesoPlantas.title")}
        options={{ search: false, actionsColumnIndex: -1 }}
        editable={
          !readOnly
            ? {
                onRowAdd: AddUserPlantAccess,
                onRowDelete: RemoveUserPlantAccess,
              }
            : () => {}
        }
      />
    </RelativeBackdrop>
  );
}

export default UserPlantaAccess;
