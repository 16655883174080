import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getPartnerList() {
  return fetch(`${baseUrl}/api/partners/list`);
}

export function getWithUserAccessPermisions() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;
  return fetch(`${baseUrl}/api/partners/withUserAccessPermisions`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getPartnersForSelector() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/selector`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function reloadPermisions() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;
  return fetch(`${baseUrl}/api/partners/reloadPermisions`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getInfinitePartners(partnersRendered = 0, searchQuery = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/partners/withUserAccessPermisions/${partnersRendered}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(searchQuery),
    }
  );
}

export function getInfPartnersRefined(partnerClientFilter) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/infinitePartnersClients`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(partnerClientFilter),
  });
}

export function getPartnersBroadcast() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/broadcast`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function getClientsFromPartner(partnerId = 0) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/clientsPartner/${partnerId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function getOfCliente(clienteid) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/cliente/${clienteid}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getPartnerCountry(country) {
  return fetch(`${baseUrl}/api/partners/country/${country}`);
}

export function createDemoPartner(country) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/createDemoPartner/${country}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function add(partner) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(partner),
  });
}

export function update(partner) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/${partner.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(partner),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function getLogo(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/${id}/logo`, {
    method: "GET",
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getLogin(loginParameter) {
  return fetch(`${baseUrl}/api/partners/login/${loginParameter}`, {
    method: "GET",
  });
}

export function addLogo(id, base64, extension) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/${id}/logo`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ base64, extension }),
  });
}

export function deleteLogo(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/${id}/logo`, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getSubscriptions() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/subscriptionManagement`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}
