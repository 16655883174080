// @ts-nocheck
// eslint-disable-next-line no-unused-vars
import { PreManOptionsModel, ReportOptionsModel } from "../helpers";
import React from "react";
import { Image, Page, Text, View } from "@react-pdf/renderer";
import { GeneralStyles, ResultStyles } from "../ReportPDFStyles";
import getText from "../helpers/traductions";
import WaterMark from "./WaterMarkPDF";
import Footer from "./FooterPDF";

/**
 * Pestaña de resultados (Result) del PDF.
 * @param {object} props
 * @param {ReportOptionsModel} props.reportOptions Opciones del report.
 * @param {Array} props.pictures Array con las imagenes de las graficas.
 * @param {any} props.header Header del report.
 * @param {any} props.waterMark Marca de agua por si algun equipo tiene riesgo indefinido.
 */
function Result({ reportOptions, pictures, header, waterMark }) {
  const lang = reportOptions.language || "en";
  const pieStyle = [
    ResultStyles["pie" + reportOptions.pageSize] || ResultStyles.pieA4,
    ResultStyles.pieDefault,
  ];

  return (
    <Page
      style={GeneralStyles.body}
      size={reportOptions.pageSize || "A4"}
      orientation={"portrait"}
    >
      {header}
      <View style={ResultStyles.container} fixed>
        <View style={ResultStyles.stackContainer}>
          <Text style={ResultStyles.title}>
            {getText(lang, "drive.family")}:
          </Text>
          <Image
            style={ResultStyles.stackImage}
            src={pictures.family.current}
          />
        </View>
        <View style={ResultStyles.pieRow}>
          <View style={ResultStyles.pieContainer}>
            <Text style={ResultStyles.title}>
              {getText(lang, "drive.lifecycle")}:
            </Text>
            <Image style={pieStyle} src={pictures.lifeCycle.current} />
          </View>
          <View style={ResultStyles.pieContainer}>
            <Text style={ResultStyles.title}>
              {getText(lang, "drive.criticality")}:
            </Text>
            <Image style={pieStyle} src={pictures.criticality.current} />
          </View>
        </View>
        <View style={ResultStyles.pieRow}>
          <View style={ResultStyles.pieContainer}>
            <Text style={ResultStyles.title}>
              {getText(lang, "drive.condition")}:
            </Text>
            <Image style={pieStyle} src={pictures.condition.current} />
          </View>
          <View style={ResultStyles.pieContainer}>
            <Text style={ResultStyles.title}>
              {getText(lang, "drive.risk")}:
            </Text>
            <Image style={pieStyle} src={pictures.risk.current} />
          </View>
        </View>
        <View style={ResultStyles.stackContainer}>
          <Text style={ResultStyles.title}>{getText(lang, "drive.year")}:</Text>
          <Image style={ResultStyles.stackImage} src={pictures.year.current} />
        </View>
      </View>
      <Footer lang={lang} />
      <WaterMark text={waterMark} orientation={"portrait"} />
    </Page>
  );
}

export default Result;
