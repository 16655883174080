import React, { useEffect, useState, useContext, useCallback } from "react";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import i18n from "../../../locales/i18n";
import { PlantasAPI } from "../../../services/API";
import SearchField from "../../molecule/SearchField/SearchField";
import AuthService from "../../../services/AuthService";
import AppContext from "../../../contexts/AppContext";
import {
  Common_Style,
  CardList_Style,
  DetailTabs_Style,
} from "../../../assets/css/Styles";
import CardPlantClient from "../../../components/molecule/cardPlantClient/cardPlantClient";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  MenuItem,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  Tooltip,
  Badge,
} from "@material-ui/core";
import { MoreVert, Email } from "@material-ui/icons";
import moment from "moment";
import RequestAccessDialog from "./RequestAccessDialog";
import ListFilter from "../../molecule/ListFilter/ListFilter";

//para filtro de plantas
const originalPlantFilter = {
  plantsRendered: 0,
  searchQuery: "",
  countryId: 0,
  from: null,
  to: null,
  hideDemo: true,
  sorting: 0,
};

export default function PlantasList(props) {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const [plantas, setPlantas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMorePlants, setHasMorePlants] = useState(true);
  const [isFirstInput, setIsFirstInput] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const commonStyles = Common_Style();
  const styles = CardList_Style();
  const detailStyles = DetailTabs_Style();
  const currentUserHasRoleEndCustomer = AuthService.hasRoleEndCustomer();
  //Solicitud de acceso a plantas
  const [openRequestAccessDialog, setOpenRequestAccessDialog] = useState(false);
  const [plantFilter, setPlantFilter] = useState(originalPlantFilter);

  //getPlantas
  const getPlantas = useCallback((plantFilter, isNewQuery) => {
    if (!plantFilter) {
      plantFilter = originalPlantFilter;
    }
    setLoading(true);
    PlantasAPI.getInfinitePlants(plantFilter)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        if (isNewQuery) {
          setPlantas(data.plantas);
        } else {
          setPlantas((old) => old.concat(data.plantas));
        }
        setHasMorePlants(data.hasMorePlants);

        //actualizar el total de plantas
        const totalPlants = data.plantas.length + plantFilter.plantsRendered;
        setPlantFilter({ ...plantFilter, plantsRendered: totalPlants });
        //acctualizar accesos
        AuthService.updateUserAccessPermisions(data.userAccessPermissions);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  //aplicar filtros a lista
  const handleApplyFilter = (genericFilter) => {
    setPlantas([]);

    //variables de filtro
    const sorting = genericFilter.sortSelected;
    const countryId = genericFilter.countryId || 0;
    const from = genericFilter.fromDate;
    const to = genericFilter.toDate;
    const hideDemo = genericFilter.hideDemo;

    //nuevo filtro
    const newPlantFilter = {
      ...plantFilter,
      plantsRendered: 0,
      sorting: sorting,
      searchQuery: plantFilter.searchQuery,
      countryId: countryId,
      from: from,
      to: to,
      hideDemo: hideDemo,
    };

    setPlantFilter(newPlantFilter);
    getPlantas(newPlantFilter, true);
  };

  //Limpiar filtros
  const handleClearFilter = () => {
    setPlantas([]);
    getPlantas(null, true);
  };

  //Abrir menu de acciones
  const openActionsMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  //abrir Dialog Exchange
  const openExchange = () => {
    setAnchorEl(null);
    setOpenRequestAccessDialog(true);
  };

  //cerrar Dialog Retrofit
  const closeExchange = () => {
    setOpenRequestAccessDialog(false);
  };

  //Busqueda con delay
  useEffect(() => {
    const searchDelay = setTimeout(() => {
      if (isFirstInput) {
        setPlantas([]);
        getPlantas(plantFilter, true);
      }
      setIsFirstInput(false);
    }, 600);
    return () => clearTimeout(searchDelay);
  }, [getPlantas, isFirstInput, plantFilter]);

  //Primer getPlantas
  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
    if (
      window["AltidVars"] !== undefined &&
      window["AltidVars"].Tabs_PlantasDetails !== undefined
    ) {
      window["AltidVars"].Tabs_PlantasDetails = undefined;
    }
    //Limpeza de filtros de drives
    localStorage.removeItem("savedFilters");
    localStorage.removeItem("storageZoneInfo");
    getPlantas(null, true);
    setGlobal((prev) => ({
      ...prev,
      pageTitle: i18n.t("planta.tituloPaginaLista"),
    }));
  }, [getPlantas, setGlobal]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <div className={commonStyles.mainContainer}>
        <div className={commonStyles.searchFieldContainer}>
          <div className={commonStyles.searchField}>
            <SearchField
              inputBaseProps={{
                placeholder: i18n.t("common.search"),
                value: plantFilter.searchQuery,
                onChange: (event) => {
                  setIsFirstInput(true);
                  setPlantFilter({
                    ...plantFilter,
                    plantsRendered: 0,
                    searchQuery: event.target.value,
                  });
                },
              }}
            />
            <Button
              variant="contained"
              className={commonStyles.primaryButtonRaw}
              onClick={openActionsMenu}
            >
              <MoreVert className={styles.addCircleOutline} />
              {i18n.t("planta.actions.actionButton")}
            </Button>
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              className={detailStyles.menuMargin}
              keepMounted
              onClose={() => setAnchorEl(null)}
            >
              <Tooltip
                arrow
                placement="left"
                title={i18n.t("request.plantAccess.title")}
              >
                <MenuItem
                  data-testid="drives-exchangeButton"
                  className={detailStyles.menuItemSpace}
                  onClick={() => {
                    openExchange();
                  }}
                >
                  <ListItemIcon>
                    <Email className={styles.addCircleOutline} />
                  </ListItemIcon>
                  <ListItemText>
                    {" "}
                    {i18n.t("request.plantAccess.button")}
                  </ListItemText>
                </MenuItem>
              </Tooltip>
            </Menu>
            <Badge
              badgeContent={""}
              variant="dot"
              //mostrar cuando haya algun filtro
              invisible={
                plantFilter.countryId === 0 &&
                plantFilter.from === null &&
                plantFilter.to === null &&
                plantFilter.sorting === 0 &&
                plantFilter.hideDemo === true
              }
              className={styles.filterBadge}
            >
              <ListFilter
                filterTitle={i18n.t("planta.filterregisters.title")}
                //Filtro de demo
                hideDemo={plantFilter.hideDemo}
                hideDemoLabel={
                  //mostrar solo en caso de ebdcustomers
                  currentUserHasRoleEndCustomer
                    ? false
                    : i18n.t("planta.filterregisters.hideDemo")
                }
                //sorting filter
                hasSorting
                sortList={[
                  {
                    value: 0,
                    label: i18n.t("planta.filterregisters.alphabeticalSorting"),
                  },
                  {
                    value: 1,
                    label: i18n.t(
                      "planta.filterregisters.chronologicalSorting"
                    ),
                  },
                ]}
                sortSelected={plantFilter.sorting}
                //filtro de paises
                hasCountryFilter
                countryId={plantFilter.countryId}
                //filtro de fechas
                hasDateFilter
                fromDate={plantFilter.from}
                toDate={plantFilter.to}
                //funciones
                handleClearFilter={handleClearFilter}
                handleApplyFilter={handleApplyFilter}
              />
            </Badge>
          </div>
        </div>

        <InfiniteScroll
          scrollableTarget="main-content"
          className={commonStyles.cardsContainerBase}
          style={{ overflow: "ignore" }}
          dataLength={plantas.length}
          next={() => getPlantas(plantFilter, false)}
          hasMore={hasMorePlants}
          loader
        >
          {plantas.map((planta, indx) => (
            <div key={indx}>
              <CardPlantClient
                isDanfossOwned={false}
                title={planta.denominacion}
                subtitle={`${planta.cliente.razonSocial}`}
                isTest={planta.isTest}
                contents={[
                  { key: "direccion", value: planta.direccion },
                  { key: "cp", value: planta.cp },
                  { key: "poblacion", value: planta.poblacion },
                  { key: "provincia", value: planta.provincia },
                  {
                    key: "pais",
                    value: i18n.t(`country.${planta.country.name || "null"}`),
                  },
                  { key: "nDrives", value: planta.nDrivers },
                  {
                    key: "creationDate",
                    value: planta.fechaCreacion
                      ? moment(planta.fechaCreacion).format("DD/MM/YYYY")
                      : i18n.t("common.n/a"),
                  },
                ]}
                type="planta"
                className={
                  planta?.infoBySalesforce
                    ? commonStyles.cardBase
                    : commonStyles.cardBaseNotValidated
                }
                onClick={() => {
                  props.history.push(`./plantas/${planta.id}`);
                }}
                style={styles}
                isDemo={planta.isDemo}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
      <RequestAccessDialog
        open={openRequestAccessDialog}
        closeFunction={closeExchange}
      />
    </RelativeBackdrop>
  );
}
