import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function getExchangeEquipment(equipmentId = 0) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/${equipmentId}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function addExchange(driveId = 0, retrofitPn = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/${driveId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(retrofitPn),
  });
}

export function deleteExchange(driveId = 0, retrofitPn = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/${driveId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(retrofitPn),
  });
}

export function updateExchange(driveId = 0, newPN = "", oldPN = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;
  const bodyRequest = {
    newPN: newPN,
    oldPN: oldPN,
  };

  return fetch(`${baseUrl}/api/equipoExchange/${driveId}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyRequest),
  });
}

export function postPNSExchange(
  plantaId,
  driversChecked,
  partNumbers,
  cardNumbers
) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;
  const bodyRequest = {
    driversChecked: driversChecked,
    partNumbers: partNumbers,
    cardNumbers: cardNumbers,
  };

  return fetch(`${baseUrl}/api/equipoExchange/driveList/${plantaId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyRequest),
  });
}

export function validatePN(pnToValidate = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(pnToValidate),
  });
}
