import VaconMaintenance from "./vacon-maintenance-calculation.json";
import VLTMaintenance from "./vlt-maintenance-calculation.json";
import AuthService from "../services/AuthService";

export const devVersion = "ID238";
export const termsUrl = process.env.REACT_APP_TERMS_URL;

/* export const DIPextraDataUrl = "https://api.danfoss.com/dip/profile/users/";
export const DIPDomain = "accounts.danfoss.com";
export const DIPClientId = "Obt2COPV2xsVrGxyty4VuU1OvJAEtaYV"; */

/**
 *  Funcion que cmprueva si un variador es Vacon
 * True - Si
 * False - No
 */
export function isVacon(brand) {
  if (brand?.type === 1) {
    return true;
  } else {
    return false;
  }
}

export function isValidFrameSize(brand, frame) {
  if (!brand || !brand.isDanfoss || !frame || frame === "") {
    return false;
  } else {
    if (isVacon(brand)) {
      return VaconMaintenance.frames
        .map((f) => f.sizes)
        .flat()
        .includes(frame);
    } else if (brand?.type === 2) {
      return VLTMaintenance.frames
        .map((f) => f.sizes)
        .flat()
        .includes(frame);
    } else {
      return false;
    }
  }
}

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result.toString();
      resolve(baseURL.split("base64,")[1]);
    };
  });
};

export function dataUriFromFormField(field) {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      resolve(reader.result);
    });

    reader.readAsDataURL(field);
  });
}

export function resizeImage(imgEl, size) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = size;
  canvas.height = size;

  ctx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL();
}

export const delay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = parseInt(setTimeout(callback, ms).toString());
  };
})();

/**
 *  Funcion que devuelve la cadena de entrada
 *  con la primera letra en mayuscula
 */
export const capitalizeFirstLetter = (string) => {
  if (string.length <= 3) {
    return string.toUpperCase();
  } else {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

/**
 * Constante con los roles
 */
export const Roles = {
  Admin: "keyuser",
  DSC: "dsc",
  Partner: "partner",
  EndCustomer: "endcustomer",
};

/**
 * Constante con los niveles de acceso
 */
export const AccessLevel = {
  Admin: "keyuser",
  DSC: "dsc",
  Partner: "partner",
  Client: "client",
  Plant: "plant",
  Zone: "zone",
  None: "none",
};

/**
 * Constante con los tipos de acceso
 */
export const AccessType = {
  Manager: "manager",
  Partial: "partial",
  RO: "ro",
};

/**
 * Metodo utilizado para dividir un array en sub-arrays de un tamaño determinado, se utiliza cuando la descarga del pdf es demasiado grande y es necesario repartirlo.
 * @param {Array} array Listado de equipos a dividir.
 * @param {number} size Tamaño de sub-array.
 * @returns {Array.<Array>} Siempre debe de devolver un array de arrays.
 */
export const splitArray = (array, size) => {
  if (!array || !size || array.length === 0) {
    return [array];
  }
  let result = [];
  let count = 0;

  for (let i = 0; i < Math.ceil(array.length / size); i++) {
    result.push(array.slice(count, count + size));
    count += size;
  }

  return result;
};
/**
 *  Permite mostrar el Snackbar para dar feedback
 *  Severity options: success, warning, info, error
 */
const SnackbarGlobal = (setGlobal, _severity, _message) => {
  // @ts-ignore
  setGlobal((prev) => ({
    ...prev,
    snackbar: {
      open: true,
      message: _message,
      severity: _severity,
    },
  }));
};

export const SnackbarSuccess = (setGlobal, message) => {
  SnackbarGlobal(setGlobal, "success", message);
};
export const SnackbarWarning = (setGlobal, message) => {
  SnackbarGlobal(setGlobal, "warning", message);
};
export const SnackbarInfo = (setGlobal, message) => {
  SnackbarGlobal(setGlobal, "info", message);
};
export const SnackbarError = (setGlobal, message) => {
  SnackbarGlobal(setGlobal, "error", message);
};

export const SnackbarClose = (setGlobal) => {
  setGlobal((prev) => ({
    ...prev,
    snackbar: {
      ...prev?.snackbar,
      open: false,
      message: "",
    },
  }));
};

/**
 * Se utiliza para objeter las props de un Parent y pasarselas al children que quieras, asi puedes poner etiquetas entre Parent y Children
 * @Example <CloneProps>{(props) => <div><Children {...props} /></div>}</CloneProps>
 */
export function CloneProps(props) {
  const { children, ...other } = props;
  return children(other);
}

/*
Comprueba si el usuario tiene alguna zona manager de esa planta
*/
export function EndcustomerHasSomeZoneManager(plantaId = 0) {
  const userInfo = AuthService.getUserInfo();

  //planta
  let plantFound = userInfo.plantaAccesos.find(
    (p) => String(p.plantaId) === String(plantaId)
  );

  if (plantFound === undefined) {
    return false;
  }

  //lista de zonas de la planta
  let plantZones = plantFound.planta.zonas;

  //lista de zonas manager del usuario
  let userZoneManager = userInfo.zonaAccesos.filter(
    (z) => z.accessType === AccessType.Manager
  );

  //comprobar si el usuario tiene acceso a alguna zona de la planta
  let userHasZoneManager = plantZones.some((z) =>
    userZoneManager.some((uz) => uz.plantaZonaId === z.id)
  );

  return userHasZoneManager;
}

/*
Comprueba si el usuario tiene acceso a la zona
*/
export function EndcustomerCanMangeThisZone(zoneId = 0) {
  const userInfo = AuthService.getUserInfo();

  let zoneFound = userInfo.zonaAccesos.find(
    (z) => String(z.plantaZonaId) === String(zoneId)
  );

  //usuario es manager de esta zona
  if (zoneFound !== undefined && zoneFound.accessType === AccessType.Manager) {
    return true;
  }

  return false;
}
