import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Cancel, Save } from "@material-ui/icons";
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import i18n from "../../../locales/i18n";
import { EquipoRetrofitApi } from "../../../services/API";
import AlertDialog from "../../AlertDialog";
import RelativeBackdrop from "../../RelativeBackdrop";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import "react-perfect-scrollbar/dist/css/styles.css";
import AppContext from "../../../contexts/AppContext";
import { SnackbarSuccess, SnackbarError } from "../../../helpers/Common";

/**
 * Dialog para Revision de variadores
 * @Component
 * @param {Object} props
 * @param {Boolean} props.open
 * @param {number} props.plantaId
 * @param {() => void} props.closeFunction
 * @param {() => void} props.reloadDriveList
 * @param {() => void} props.cleanChecks
 * @param {any[]} props.drivesChecked
 * @param {any[]} props.checkDrivesInactive
 */
export default function DialogRetrofitProposal(props) {
  const {
    open,
    closeFunction,
    drivesChecked,
    checkDrivesInactive,
    plantaId,
    cleanChecks,
    reloadDriveList,
  } = props;
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [partNumbers, setPartNumbers] = useState("");
  const [cardNumbers, setCardNumbers] = useState("");
  const [textError, setTextError] = useState("");
  const [validPNs, setValidPNs] = useState([]);
  const [notValidPNs, setnotValidPNs] = useState([]);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const styles = DetailTabs_Style();
  const resultDialog = useRef(null);

  const postDrivesList = useCallback(
    async (plantaId, checkDrivesInactive, partNumbes, cardNumbers) => {
      if (checkDrivesInactive.length === 0) {
        setTextError(i18n.t("planta.actions.retrofitDialog.errors.noDrives"));
        return;
      }
      setLoading(true);

      EquipoRetrofitApi.postPNSRetrofit(
        plantaId,
        checkDrivesInactive,
        partNumbes,
        cardNumbers
      )
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          } else {
            const errorMessage = await response.text();
            if (errorMessage.includes("Drive not found")) {
              setTextError(
                i18n.t("planta.actions.retrofitDialog.errors.driveNotFound")
              );
            }
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          if (data) {
            cleanChecks();
            setValidPNs(data.validate);
            setnotValidPNs(data.notValidated);
            if (data.validate.length <= 0) {
              SnackbarError(
                setGlobal,
                i18n.t("planta.actions.retrofitDialog.referencesNotAdded")
              );
            } else {
              SnackbarSuccess(
                setGlobal,
                i18n.t("planta.actions.retrofitDialog.referencesAdded")
              );
            }
            setShowResultDialog(true);
          }
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          SnackbarError(
            setGlobal,
            i18n.t("planta.actions.retrofitDialog.referencesNotAdded")
          );
        });
    },
    [cleanChecks, setGlobal]
  );

  const handlePNChange = (event) => {
    const value = event.target.value;
    setError(value.length > 0 && false);
    setPartNumbers(value);
  };

  const handleCNChange = (event) => {
    const value = event.target.value;
    setError(value.length > 0 && false);
    setCardNumbers(value);
  };

  useEffect(() => {
    if (showResultDialog) {
      setShowResultDialog(false);
      closeFunction();
      resultDialog.current.open();
    }
  }, [showResultDialog, closeFunction]);

  return (
    <>
      <Dialog open={open} onClose={() => {}}>
        <RelativeBackdrop hidden={!loading}>
          <DialogTitle className={styles.dialogEmergencyStockTitle}>
            {i18n.t("planta.actions.retrofitDialog.title")}
          </DialogTitle>
          <DialogContent>
            <div>
              <div className={styles.dialogESNDrivesContainer}>
                <div>
                  {i18n.t("planta.actions.retrofitDialog.nDriversSelected", {
                    nDrives: drivesChecked?.length,
                  })}
                </div>
                {/* Mensaje que aparece cuando hay algún equipo que no cumple las condiciones */}
                {(checkDrivesInactive.length !== drivesChecked.length ||
                  textError) && (
                  <>
                    <div className={styles.dialogDrivesEnfasisText}>
                      {i18n.t(
                        "planta.actions.retrofitDialog.nDriversInactive",
                        { nDrives: checkDrivesInactive.length }
                      )}
                    </div>
                    {textError && (
                      <div className={styles.dialogDrivesErrorText}>
                        {textError}
                      </div>
                    )}
                    <div className={styles.dialogESNDrivesContainer}>
                      <div className={styles.dialogDrivesEnfasisText}>
                        {i18n.t(
                          "planta.actions.retrofitDialog.validConditions"
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className={styles.dialogESTextContainer}>
                {i18n.t("planta.actions.retrofitDialog.pnInstructions")}
              </div>
              <TextField
                value={partNumbers || ""}
                variant="outlined"
                className={styles.materialTextFieldOutlined}
                rows={4}
                fullWidth
                multiline
                label={i18n.t("planta.actions.retrofitDialog.pnField")}
                InputLabelProps={{
                  shrink: true,
                }}
                name="pnProposal"
                placeholder={i18n.t(
                  "planta.actions.retrofitDialog.placeholder"
                )}
                onChange={handlePNChange}
                error={error}
                helperText={
                  error && i18n.t("planta.actions.retrofitDialog.errorVoidInfo")
                }
              />
              <div className={styles.dialogESTextContainer}>
                {i18n.t("planta.actions.retrofitDialog.cnInstructions")}
              </div>
              <TextField
                value={cardNumbers || ""}
                variant="outlined"
                className={styles.materialTextFieldOutlined}
                rows={4}
                fullWidth
                multiline
                label={i18n.t("planta.actions.retrofitDialog.cnField")}
                InputLabelProps={{
                  shrink: true,
                }}
                name="ocProposal"
                placeholder={i18n.t(
                  "planta.actions.retrofitDialog.placeholder"
                )}
                onChange={handleCNChange}
                error={error}
                helperText={
                  error && i18n.t("planta.actions.retrofitDialog.errorVoidInfo")
                }
              />
            </div>
          </DialogContent>

          <DialogActions>
            <div className={styles.dialogEmergencyStockActions}>
              <Button
                onClick={() => {
                  partNumbers.trim().length > 0 || cardNumbers.trim().length > 0
                    ? postDrivesList(
                        plantaId,
                        checkDrivesInactive,
                        partNumbers,
                        cardNumbers
                      )
                    : setError(true);
                }}
                className={styles.saveDialogEquipmentButton}
                variant="contained"
                endIcon={<Save />}
              >
                {i18n.t("planta.revisionEquipos.save")}
              </Button>

              <Button
                onClick={() => {
                  closeFunction();
                  setTextError("");
                }}
                endIcon={<Cancel />}
                variant="outlined"
                className={styles.closeDialogEquipmentButton}
              >
                {i18n.t("planta.revisionEquipos.close")}
              </Button>
            </div>
          </DialogActions>
        </RelativeBackdrop>
      </Dialog>
      {/*@ts-ignore*/}
      <AlertDialog
        activeTitle={true}
        ref={resultDialog}
        loading={loading}
        title={i18n.t("planta.actions.retrofitDialog.titleDialog")}
        text={
          <>
            <div className={styles.dialogESAlertContainer}>
              {validPNs.length > 0 && (
                <>
                  <div>
                    {i18n.t("planta.actions.retrofitDialog.validContentDialog")}
                  </div>
                  <div className={styles.dialogESListContainer}>
                    {validPNs.map((pn, index) => (
                      <li key={index}>{pn}</li>
                    ))}
                  </div>
                </>
              )}

              {notValidPNs.length > 0 && (
                <>
                  <br />
                  <div>
                    {i18n.t(
                      "planta.actions.retrofitDialog.notValidContentDialog"
                    )}
                  </div>
                  <div className={styles.dialogESListContainer}>
                    {notValidPNs.map((pn, index) => (
                      <li key={index}>{pn}</li>
                    ))}
                  </div>
                </>
              )}
            </div>
          </>
        }
        confirmText={i18n.t("common.confirmar")}
        confirmAction={() => {
          //para renovar lista y filtro
          reloadDriveList();
          setCardNumbers("");
          setPartNumbers("");
          setTextError("");
          resultDialog.current.close();
        }}
      />
    </>
  );
}
