import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { Cancel, Email } from "@material-ui/icons";
import React, { useEffect, useState, useCallback, useContext } from "react";
import i18n from "../../../locales/i18n";
import { PlantasAPI, ZonasAPI, UsersAPI } from "../../../services/API";
import RelativeBackdrop from "../../RelativeBackdrop";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import "react-perfect-scrollbar/dist/css/styles.css";
import AppContext from "../../../contexts/AppContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SnackbarSuccess, SnackbarError } from "../../../helpers/Common";

const AllZonesOption = {
  id: -1,
  descripcion: "All zones",
};
/**
 * Dialog para Revision de variadores
 * @Component
 * @param {Object} props
 * @param {Boolean} props.open
 * @param {() => void} props.closeFunction
 */
export default function RequestAccessDialog(props) {
  const { open, closeFunction } = props;
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [plantasOptions, setPlantasOptions] = useState([]);
  const [loadingZones, setLoadingZones] = useState(false);
  const [optionZones, setOptionZones] = useState([]);
  const [access, setAccess] = useState("readOnly");
  const [zonesSelected, setZonesSelected] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState(undefined);
  const [email, setEmail] = useState("");
  const styles = DetailTabs_Style();

  const getPlantsOptions = () => {
    setLoading(true);
    PlantasAPI.getPlantasForSelector()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setPlantasOptions(data);
      })
      .catch((e) => {
        console.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getZoneOptions = useCallback(async (plantId) => {
    setLoadingZones(true);
    ZonasAPI.getZonesForSelector(plantId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setOptionZones(data || []);
      })
      .catch((e) => {
        console.error(e.message);
      })
      .finally(() => {
        setLoadingZones(false);
      });
  }, []);

  const handleCNChange = (event) => {
    const value = event.target.value;
    setEmail(value);
  };

  //cambiar acceso
  const handleAccess = (event) => {
    let access = event.target.value;
    setAccess(access);

    if (selectedPlant) {
      let allZonesTraslated = AllZonesOption;
      allZonesTraslated.descripcion = i18n.t("request.plantAccess.allZones");
      setZonesSelected([allZonesTraslated]);
    } else {
      setZonesSelected([]);
    }
  };

  const handleInputChangeZonesSelected = (event, value) => {
    let zoneList = value;
    let allZonesTraslated = AllZonesOption;
    allZonesTraslated.descripcion = i18n.t("request.plantAccess.allZones");
    //en caso de que no haya ninguna zona seleccionada, se añade la opcion de todas las zonas
    if (zoneList.length <= 0) {
      zoneList.push(allZonesTraslated);
      setOptionZones(
        optionZones.filter((zone) => zone.id !== allZonesTraslated.id)
      );
    } else {
      let oldHasAllZoneOption = zonesSelected.some(
        (zone) => zone.id === allZonesTraslated.id
      );
      let newListHasAllZoneOption = zoneList.some(
        (zone) => zone.id === allZonesTraslated.id
      );

      if (oldHasAllZoneOption && newListHasAllZoneOption) {
        //Lista de zonas sin la opcion de todas las zonas
        zoneList = zoneList.filter((zone) => zone.id !== allZonesTraslated.id);
        if (!optionZones.some((zone) => zone.id === allZonesTraslated.id)) {
          setOptionZones([allZonesTraslated, ...optionZones]);
        }
      } else if (!oldHasAllZoneOption && newListHasAllZoneOption) {
        zoneList = [allZonesTraslated];
      }
    }
    setErrors((old) => ({ ...old, zonesSelected: "" }));

    setZonesSelected(zoneList);
  };

  const plantChangeEvent = (plantId) => {
    let zones = [];
    let allZonesTraslated = AllZonesOption;
    allZonesTraslated.descripcion = i18n.t("request.plantAccess.allZones");
    if (plantId) {
      zones.push(allZonesTraslated);
    }
    setErrors((old) => ({ ...old, zonesSelected: "" }));
    setZonesSelected(zones);
  };

  const cleanAndCloseForm = () => {
    setEmail("");
    setSelectedPlant(undefined);
    setZonesSelected([]);
    setErrors({});
    setAccess("readOnly");
    closeFunction();
  };

  const validateAccessRequest = () => {
    var correctValidation = true;
    if (email) {
      let emailCopy = email;
      emailCopy = emailCopy.trim();
      if (!/\S+@\S+\.\S+/.test(emailCopy)) {
        setErrors((old) => ({
          ...old,
          email: i18n.t("request.plantAccess.errors.emailInvalid"),
        }));
        correctValidation = false;
      } else {
        setErrors((old) => ({ ...old, email: "" }));
      }
    } else {
      setErrors((old) => ({
        ...old,
        email: i18n.t("request.plantAccess.errors.emailEmpty"),
      }));
      correctValidation = false;
    }

    if (selectedPlant) {
      setErrors((old) => ({ ...old, selectedPlant: "" }));
    } else {
      setErrors((old) => ({
        ...old,
        selectedPlant: i18n.t("request.plantAccess.errors.plantEmpty"),
      }));
      correctValidation = false;
    }

    if (zonesSelected.length > 0) {
      setErrors((old) => ({ ...old, zonesSelected: "" }));
    } else {
      setErrors((old) => ({
        ...old,
        zonesSelected: i18n.t("request.plantAccess.errors.zonesEmpty"),
      }));
      correctValidation = false;
    }

    if (correctValidation) {
      const requestAccess = {
        emailAccess: email,
        plantId: selectedPlant.id,
        zoneListIds: zonesSelected.map((zone) => zone.id),
        access: access,
      };
      SendRequestAccess(requestAccess);
    }
  };

  const SendRequestAccess = async (request) => {
    setLoading(true);
    UsersAPI.RequestAccesByOtherUser(request)
      .then(async (response) => {
        if (response.ok) {
          SnackbarSuccess(setGlobal, i18n.t("request.alert.text"));

          cleanAndCloseForm();
        } else {
          let resp = await response.json();
          if (resp.message && resp.message === "Partner email not found") {
            SnackbarError(
              setGlobal,
              i18n.t("request.plantAccess.errors.emailPartnerNotExists")
            );
          } else {
            throw new Error("Something went wrong");
          }
        }
      })
      .catch((e) => {
        console.error(e);
        SnackbarError(setGlobal, i18n.t("request.alert.error"));
      })
      .finally(() => setLoading(false));
  };

  //cargar lista de plantas
  useEffect(() => {
    getPlantsOptions();
  }, []);

  //cargar zonas cuando se selecciona una planta
  useEffect(() => {
    if (selectedPlant) {
      getZoneOptions(selectedPlant.id);
    }
    plantChangeEvent(selectedPlant);
  }, [getZoneOptions, selectedPlant]);

  return (
    <>
      <Dialog open={open} onClose={() => {}}>
        <RelativeBackdrop hidden={!loading}>
          <DialogTitle className={styles.dialogEmergencyStockTitle}>
            {i18n.t("request.plantAccess.title")}
          </DialogTitle>
          <DialogContent>
            <div
              className={styles.mainCardFormContainer}
              style={{ gap: "16px" }}
            >
              <div className={styles.dialogESNDrivesContainer}>
                {i18n.t("request.plantAccess.text")}
              </div>

              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <TextField
                    className={styles.materialTextField}
                    required
                    fullWidth
                    value={email || ""}
                    label={i18n.t("request.plantAccess.email")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="email"
                    error={!!errors["email"]}
                    helperText={errors["email"] || ""}
                    onChange={handleCNChange}
                  />
                </div>
              </div>
              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <Autocomplete
                    style={{ width: "200%" }}
                    groupBy={(row) => row?.partner?.razonSocial}
                    fullWidth
                    options={plantasOptions}
                    getOptionLabel={(row) =>
                      `${row?.denominacion} (${row?.cliente?.razonSocial})`
                    }
                    value={selectedPlant}
                    className={styles.materialTextField}
                    onChange={(e, newValue) => setSelectedPlant(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={i18n.t("user.planta")}
                        error={!!errors["selectedPlant"]}
                        helperText={errors["selectedPlant"] || ""}
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <RadioGroup
                    aria-label="access"
                    name="access"
                    value={access || "readOnly"}
                    onChange={handleAccess}
                  >
                    <FormControlLabel
                      control={<Radio className={styles.materialRadio} />}
                      value={"readOnly"}
                      label={i18n.t("request.plantAccess.readOnly")}
                    />
                    <FormControlLabel
                      control={<Radio className={styles.materialRadio} />}
                      value={"manager"}
                      label={i18n.t("request.plantAccess.manager")}
                    />
                    <FormControlLabel
                      control={<Radio className={styles.materialRadio} />}
                      value={"partial"}
                      label={i18n.t("request.plantAccess.partial")}
                    />
                  </RadioGroup>
                </div>
              </div>
              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <Autocomplete
                    disabled={!selectedPlant || access !== "manager"}
                    multiple
                    style={{ width: "200%" }}
                    fullWidth
                    options={optionZones?.filter(
                      (zoOption) =>
                        !zonesSelected.some(
                          (zoSelected) => zoSelected.id === zoOption.id
                        )
                    )}
                    loading={loadingZones}
                    getOptionLabel={(option) => option.descripcion || ""}
                    value={zonesSelected}
                    getOptionSelected={(option, value) => option.id === value}
                    className={styles.materialTextField}
                    onChange={handleInputChangeZonesSelected}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={i18n.t("request.plantAccess.zones")}
                        error={!!errors["zonesSelected"]}
                        helperText={errors["zonesSelected"] || ""}
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            <div className={styles.dialogEmergencyStockActions}>
              <Button
                onClick={() => {
                  validateAccessRequest();
                }}
                disabled={
                  email === "" || !selectedPlant || zonesSelected.length <= 0
                }
                className={styles.saveDialogEquipmentButton}
                variant="contained"
                endIcon={<Email />}
              >
                {i18n.t("request.plantAccess.send")}
              </Button>

              <Button
                onClick={() => {
                  cleanAndCloseForm();
                }}
                endIcon={<Cancel />}
                variant="outlined"
                className={styles.closeDialogEquipmentButton}
              >
                {i18n.t("planta.revisionEquipos.close")}
              </Button>
            </div>
          </DialogActions>
        </RelativeBackdrop>
      </Dialog>
    </>
  );
}
