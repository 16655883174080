import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Grid,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  AttachFile,
  CloudDownload,
  Delete,
  AddCircleOutline,
} from "@material-ui/icons";
import { SnackbarSuccess, SnackbarError } from "../../../helpers/Common";
import AppContext from "../../../contexts/AppContext";
import RelativeBackdrop from "../../RelativeBackdrop";
import { DocumentsAPI } from "../../../services/API";
import SearchField from "../../molecule/SearchField/SearchField";
import i18n from "../../../locales/i18n";
import AlertDialog from "../../AlertDialog";
import { filter } from "../../../helpers/Filter";
import {
  DetailTabs_Style,
  Common_Style,
  CardList_Style,
} from "../../../assets/css/Styles";

export default function TabDocumentosPlanta({
  planta,
  goBack,
  isPremium,
  hasPartialAccess,
  readOnly,
}) {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [description, setDescription] = useState("");
  const [search, setSearch] = useState("");

  const styles = DetailTabs_Style();
  const cardStyles = CardList_Style();
  const commonStyles = Common_Style();

  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const fileInputRef = useRef(null);
  const uploadDialog = useRef(null);
  const deleteDialog = useRef(null);

  const loadDocs = useCallback(() => {
    setLoading(true);

    DocumentsAPI.getByParent("planta", planta.id)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .then((data) => {
        setDocuments(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => setLoading(false));
  }, [planta]);

  const downloadDoc = async (doc) => {
    setLoading(true);
    try {
      const response = await DocumentsAPI.download(doc.id);
      if (response.ok) {
        const blob = await response.blob();
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = doc.name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        var error = await response.text();
        throw new Error(error);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const uploadDoc = async () => {
    setLoading(true);

    const file = fileInputRef.current.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("description", description);

    DocumentsAPI.create("planta", planta.id, formData)
      .then(async (response) => {
        if (response.ok) {
          SnackbarSuccess(setGlobal, i18n.t("documents.snackbar.new.success"));
          loadDocs();
        } else {
          SnackbarError(setGlobal, i18n.t("documents.snackbar.new.error"));
          if (response.status === 404 || response.status === 401) {
            goBack();
            throw new Error("Something went wrong on Documents");
          }
          var error = await response.text();
          throw new Error(error);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });

    uploadDialog.current.close();
  };

  const deleteDoc = async (document) => {
    setLoading(true);
    deleteDialog.current.close();

    DocumentsAPI.remove(document.id)
      .then(async (response) => {
        if (response.ok) {
          loadDocs();
          SnackbarSuccess(
            setGlobal,
            i18n.t("documents.snackbar.deleted.success")
          );
        } else {
          SnackbarError(setGlobal, i18n.t("documents.snackbar.deleted.error"));
          if (response.status === 404 || response.status === 401) {
            goBack();
            throw new Error("Something went wrong on Documents");
          }
          var error = await response.text();
          throw new Error(error);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  };
  useEffect(() => loadDocs(), [loadDocs]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <div className={commonStyles.mainContainer}>
        <div className={commonStyles.searchFieldContainer}>
          <div className={commonStyles.searchField}>
            <SearchField
              inputBaseProps={{
                placeholder: i18n.t("common.search"),
                value: search,
                onChange: (event) => {
                  setSearch(event.target.value);
                },
              }}
              goBack={goBack}
            />
            {(!readOnly || hasPartialAccess) && (
              <Tooltip
                title={i18n.t("common.isPremium")}
                placement={"right"}
                disableHoverListener={isPremium}
                arrow
              >
                <div style={{ display: "flex" }}>
                  <Button
                    data-testid="documents-addButton"
                    variant="contained"
                    className={commonStyles.primaryButtonRaw}
                    onClick={() => uploadDialog.current.open()}
                    disabled={!isPremium}
                  >
                    <AddCircleOutline className={styles.addCircleOutline} />
                    {i18n.t("common.new.masculine")}
                  </Button>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={cardStyles.documentCardContainer}>
          {filter(documents, search).map((document, index) => (
            <Card className={cardStyles.documentCard} key={index.toString()}>
              <CardHeader
                className={cardStyles.documentCardLeft}
                title={document.name}
                classes={{ content: "overflow-hidden" }}
                titleTypographyProps={{ noWrap: true, variant: "body2" }}
                subheaderTypographyProps={{ noWrap: true, variant: "body2" }}
                subheader={
                  document.description || i18n.t("common.noDescription")
                }
                avatar={
                  <Avatar className={styles.tabHeaderAvatar}>
                    <AttachFile />
                  </Avatar>
                }
              />
              <div className={cardStyles.documentCardRight}>
                <Button
                  variant="outlined"
                  onClick={() => downloadDoc(document)}
                  name="uploadDocument"
                  className={styles.iconButton}
                >
                  <CloudDownload />
                </Button>
                <Button
                  name="deleteDocument"
                  variant="outlined"
                  onClick={() => deleteDialog.current.open(document)}
                  className={styles.iconButton}
                  disabled={!isPremium}
                  hidden={readOnly}
                >
                  <Delete />
                </Button>
              </div>
            </Card>
          ))}
        </div>
      </div>
      {/*@ts-ignore*/}
      <AlertDialog
        ref={uploadDialog}
        title={i18n.t("planta.documents.upload")}
        loading={loading}
        text={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="documentFile"
                inputRef={fileInputRef}
                type="file"
                className={styles.materialTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                className={styles.materialTextField}
                fullWidth
                //className="mt-3"
                value={description}
                label={i18n.t("planta.documents.description")}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        }
        cancelText={i18n.t("documents.alertDialog.cancelText")}
        confirmText={i18n.t("documents.alertDialog.upload")}
        confirmAction={() =>
          fileInputRef.current.files.length === 0 ? null : uploadDoc()
        }
      />
      {/*@ts-ignore*/}
      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("documents.alertDialog.title")}
        text={i18n.t("documents.alertDialog.text")}
        cancelText={i18n.t("documents.alertDialog.cancelText")}
        confirmText={i18n.t("documents.alertDialog.confirmText")}
        confirmAction={() => deleteDoc(deleteDialog.current.getItem())}
      />
    </RelativeBackdrop>
  );
}
