import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import i18n from "../locales/i18n";
import RelativeBackdrop from "../components/RelativeBackdrop";
import { Button } from "@material-ui/core";
import { Cancel, FilterList, InfoOutlined } from "@material-ui/icons";
import { EquiposAPI } from "../services/API";
import ScheduledPreventiveMaintenance from "../components/ScheduledPreventiveMaintenance";
import AssessmentChart from "../components/AssessmentChart";
import AppContext from "../contexts/AppContext";
import { Common_Style } from "../assets/css/Styles";
import DashboardFilters, {
  defaultFilters,
  setDashboardFiltersOnLocalStorage,
} from "../components/organism/dashboardFilters/dashboardFilters";
import DashboardHeader from "../components/organism/dashboardHeader/DashboardHeader";
import AuthService from "../services/AuthService";

export default function Home({ history, setNavBarTitle }) {
  const isPremiumUser = AuthService.userPremium();

  if (!isPremiumUser) {
    history.push("/plantas");
  }

  // @ts-ignore
  const { setGlobal } = useContext(AppContext);

  const isDSC = AuthService.hasRoleDSC();
  const isAdmin = AuthService.hasRoleAdmin();

  const [loading, setLoading] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [graphData, setGraphData] = useState(undefined);
  const [spmData, setSpmData] = useState(null);
  const [originalFilters, setOriginalFilters] = useState(undefined);
  const [antFilter, setAntFilter] = useState({});
  const [usedFilters, setUsedFilters] = useState(defaultFilters);
  const [filterButtons, setFilterButtons] = useState([]);
  const [antFilterButtons, setAntFilterButtons] = useState([]);
  const [allFilters, setAllFilters] = useState({});

  const getAssessment = useCallback(() => {
    setLoading(true);

    EquiposAPI.getAssessment()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setGraphData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getFilteredAssessment = useCallback((filters) => {
    if (Object.keys(filters).length === 0) {
      return;
    }

    setLoadingFilters(true);
    setLoading(true);

    EquiposAPI.postAssessment(filters)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setGraphData(data);
        setSpmData(data.scheduledMaintenances);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingFilters(false);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setGlobal((prev) => ({ ...prev, pageTitle: i18n.t("dashboard.titulo") }));
  }, [setGlobal]);

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;

    getAssessment();
  }, [getAssessment]);

  const filterDialog = useRef(null);

  const openFilters = () => {
    setAntFilter(usedFilters);
    filterDialog.current.open();
  };

  const applyFilter = () => {
    getFilteredAssessment(usedFilters);
    setAntFilterButtons(filterButtons);
    //localStorage.setItem("dashboarFilters", JSON.stringify(usedFilters));
    filterDialog.current.close();
  };

  const onClickFilterChip = (name) => {
    setFilterButtons((old) => {
      let result = [...old];

      if (name === "partners") {
        result = result.filter(
          (item) =>
            item.name !== name &&
            item.name !== "plant" &&
            item.name !== "zone" &&
            item.name !== "clients"
        );
      } else if (name === "clients") {
        result = result.filter(
          (item) =>
            item.name !== name && item.name !== "plant" && item.name !== "zone"
        );
      } else if (name === "brands") {
        result = result.filter(
          (item) => item.name !== "brands" && item.name !== "models"
        );
      } else {
        result = result.filter((item) => item.name !== name);
      }

      setAntFilterButtons(result);

      return result;
    });

    setUsedFilters((prev) => {
      let result = {
        ...prev,
        [name]: allFilters[name],
      };

      if (name === "brands") {
        result.notDefined.brands = true;
        result.notDefined.models = originalFilters?.notDefined?.models || [];
      } else if (Object.keys(result.notDefined).includes(name)) {
        result.notDefined[name] = true;
      }

      getFilteredAssessment(result);
      setDashboardFiltersOnLocalStorage(result);

      return result;
    });
  };

  const cancelFilters = () => {
    // @ts-ignore
    setUsedFilters(antFilter);
    setFilterButtons(antFilterButtons);
    filterDialog.current.close();
  };
  const commonStyles = Common_Style();

  return (
    <RelativeBackdrop hidden={!loading}>
      <div className={commonStyles.mainContainer}>
        {isPremiumUser === 2 && (
          <div className={commonStyles.partiallyPremiumBanner}>
            <InfoOutlined className={commonStyles.partiallyPremiumBannerIcon} />
            {i18n.t("dashboard.partiallyPremium")}
          </div>
        )}
        <DashboardHeader history={history} />
        <div id="totalDrives-test" data-testid="totalDrives-test" hidden>
          {graphData?.totalDrives}
        </div>
        <div style={{ gap: "8px", display: "flex", flexDirection: "row" }}>
          <Button
            data-testid="dashboard-filter-button"
            onClick={() => openFilters()}
            startIcon={<FilterList />}
            variant="outlined"
            className={commonStyles.secondaryButtonRaw}
          >
            {i18n.t("dashboard.filters")}
          </Button>
          {filterButtons.filter(
            (f) =>
              (f.name === "plant" ||
                f.name === "zone" ||
                f.name === "clients" ||
                ((isDSC || isAdmin) && f.name === "partners")) &&
              f.count !== 0
          ).length > 0 && (
            <Button
              key={"plant_zone_clients_chip"}
              onClick={() =>
                isDSC || isAdmin
                  ? onClickFilterChip("partners")
                  : onClickFilterChip("clients")
              }
              variant="outlined"
              startIcon={<Cancel />}
              className={commonStyles.secondaryButtonRaw}
            >
              {isDSC || isAdmin
                ? i18n.t("dashboard.filterButtons.partners")
                : i18n.t("dashboard.filterButtons.clients")}
            </Button>
          )}
          {filterButtons.filter(
            (f) => (f.name === "models" || f.name === "brands") && f.count !== 0
          ).length > 0 && (
            <Button
              key={"models_brands_chip"}
              onClick={() => onClickFilterChip("brands")}
              variant="outlined"
              startIcon={<Cancel />}
              className={commonStyles.secondaryButtonRaw}
            >
              {i18n.t("dashboard.filterButtons.brand")}
            </Button>
          )}
          {filterButtons.map(
            (data) =>
              data.count !== 0 &&
              (data.name !== "plant" &&
              data.name !== "clients" &&
              data.name !== "zone" &&
              data.name !== "brands" &&
              data.name !== "models" &&
              data.name !== "partners" ? (
                <>
                  <Button
                    key={`${data.name}_chip`}
                    onClick={() => onClickFilterChip(data.name)}
                    variant="outlined"
                    startIcon={<Cancel />}
                    className={commonStyles.secondaryButtonRaw}
                  >
                    {i18n.t("dashboard.filterButtons." + data.name)}
                  </Button>
                </>
              ) : null)
          )}
        </div>
        <AssessmentChart loading={loading} data={graphData || {}} />
        <ScheduledPreventiveMaintenance filteredData={spmData} />
        <DashboardFilters
          dialogRef={filterDialog}
          loading={loadingFilters}
          setLoading={setLoadingFilters}
          usedFilters={usedFilters}
          setUsedFilters={setUsedFilters}
          originalFilters={originalFilters}
          setOriginalFilters={setOriginalFilters}
          applyFilter={applyFilter}
          cancelFilters={cancelFilters}
          setFilterButtons={setFilterButtons}
          setAllFilters={setAllFilters}
        />
      </div>
    </RelativeBackdrop>
  );
}
