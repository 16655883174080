import React, { useState, useContext, useRef } from "react";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  FormControl,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { SupervisorAccount, Send, Cancel } from "@material-ui/icons";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import i18n from "../../../locales/i18n";
import AppContext from "../../../contexts/AppContext";
import { BroadcastAPI } from "../../../services/API";
import {
  SnackbarSuccess,
  SnackbarError,
  SnackbarWarning,
} from "../../../helpers/Common";
import RelativeBackdrop from "../../RelativeBackdrop";
import AlertDialog from "../../AlertDialog";
import { Autocomplete } from "@material-ui/lab";
import AuthService from "../../../services/AuthService";

const defaultBroadcast = {
  brandId: undefined,
  modelsId: [],
  frameSizes: [],
  newLifeCicle: null,
  message: null,
};

export default function PartnerNotificationTab(props) {
  const { brands, models, sizes, handleChangeDriveInfo, isPartner, partners } =
    props;
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const styles = DetailTabs_Style();
  const [broadcast, setBroadcast] = useState(defaultBroadcast);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const confirmDialog = useRef(null);
  const [partnerId, setPartnerId] = useState(
    !isPartner ? 0 : AuthService.getPartnerId()
  );

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "brandId" && value !== broadcast.brandId) {
      setBroadcast((prevState) => ({
        ...prevState,
        modelsId: undefined,
        frameSizes: null,
      }));
    }

    setBroadcast((prevState) => ({ ...prevState, [name]: value }));
    if (errors[name]) {
      setErrors((e) => ({ ...errors, [name]: undefined }));
    }
  };

  const confirmWhetherThrowNotice = () => {
    if (validateForm()) {
      confirmDialog.current.open();
    }
  };

  //validar formulario
  const validateForm = () => {
    let newErrors = {};

    //comprobar campos
    if (!broadcast.brandId) {
      newErrors["brandId"] = true;
    }
    if (!broadcast.modelsId || broadcast.modelsId.length === 0) {
      newErrors["modelsId"] = true;
    }
    if (!broadcast.frameSizes || broadcast.frameSizes.length === 0) {
      newErrors["frameSizes"] = true;
    }
    if (broadcast.newLifeCicle == null) {
      newErrors["newLifeCicle"] = true;
    }
    if (!broadcast.message) {
      newErrors["message"] = true;
    }
    if (!partnerId) {
      newErrors["partner"] = true;
    }

    //actualizar errores
    setErrors(newErrors);

    //comprobar si hay errores
    if (Object.keys(newErrors).length) {
      return false;
    }
    return true;
  };

  const throwNotice = () => {
    confirmDialog.current.close();
    setLoading(true);
    const data = {
      ...broadcast,
      countriesId: null,
      modelsId: broadcast.modelsId.map((m) => m?.id),
    };
    BroadcastAPI.throwNoticePartner(data, partnerId)
      .then(async (response) => {
        let json = await response.json();
        if (response.ok) {
          resetForm();
          setLoading(false);
          if (json === 1) {
            SnackbarSuccess(
              setGlobal,
              i18n.t("broadcast.sentDialog.bodySing", { number: json })
            );
          } else if (json > 1) {
            SnackbarSuccess(
              setGlobal,
              i18n.t("broadcast.sentDialog.bodyPlur", { number: json })
            );
          } else {
            SnackbarWarning(
              setGlobal,
              i18n.t("broadcast.sentDialog.bodyZero", { number: json })
            );
          }
        } else {
          setLoading(false);
          if (json["errors"]?.anyPlant) {
            SnackbarWarning(
              setGlobal,
              i18n.t("broadcast.partnerTab.anyPlantAlert")
            );
          } else {
            setErrors(json["errors"]);
          }
        }
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("broadcast.error"));
        setLoading(false);
        console.error(error);
      });
  };

  const resetForm = () => {
    setBroadcast(defaultBroadcast);
    setErrors({});
    if (!isPartner) {
      setPartnerId(0);
    }
  };

  return (
    <Card>
      <RelativeBackdrop hidden={!loading}>
        <div className={styles.mainFormContainer}>
          <div className={styles.formCardContainer}>
            <form autoComplete="off">
              <div className={styles.flexRowForm}>
                <CardHeader
                  title={i18n.t("broadcast.partnerTab.titleCard")}
                  subheader={i18n.t("broadcast.partnerTab.subTitleCard")}
                  avatar={
                    <Avatar
                      aria-label="avatar"
                      className={styles.tabHeaderAvatar}
                    >
                      <SupervisorAccount />
                    </Avatar>
                  }
                />
              </div>
              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      label={i18n.t("broadcast.brand")}
                      value={broadcast.brandId || ""}
                      className={styles.materialTextField}
                      error={errors["brandId"] !== undefined}
                      helperText={
                        errors["brandId"] ? i18n.t("textField.required") : null
                      }
                      required
                      name="brandId"
                      onChange={(e) => {
                        handleInputChange(e);
                        handleChangeDriveInfo(e);
                      }}
                    >
                      {brands.map((brand) => (
                        <MenuItem key={brand.id} value={brand.id}>
                          {brand.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </div>
                <div className={styles.flexCellForm}>
                  <FormControl fullWidth required>
                    <Autocomplete
                      multiple
                      options={models}
                      value={broadcast.modelsId || []}
                      getOptionLabel={(row) => row.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={styles.materialTextField}
                          variant="standard"
                          label={i18n.t("broadcast.model")}
                          InputLabelProps={{
                            required: true,
                          }}
                          name="modelsId"
                          error={errors["modelsId"] !== undefined}
                          helperText={
                            errors["modelsId"]
                              ? i18n.t("textField.required")
                              : null
                          }
                        />
                      )}
                      onChange={(e, newValue) => {
                        let fakeEvent = {
                          target: {
                            value: newValue,
                            type: "list",
                            name: "modelsId",
                          },
                        };
                        handleInputChange(fakeEvent);
                      }}
                    />
                  </FormControl>
                </div>
              </div>

              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <FormControl fullWidth required>
                    <Autocomplete
                      multiple
                      options={sizes}
                      value={broadcast.frameSizes || []}
                      getOptionLabel={(row) => row}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={styles.materialTextField}
                          variant="standard"
                          label={i18n.t("broadcast.frameSize")}
                          InputLabelProps={{
                            required: true,
                          }}
                          name="frameSizes"
                          error={errors["frameSizes"] !== undefined}
                          helperText={
                            errors["frameSizes"]
                              ? i18n.t("textField.required")
                              : null
                          }
                        />
                      )}
                      onChange={(e, newValue) => {
                        let fakeEvent = {
                          target: {
                            value: newValue,
                            type: "list",
                            name: "frameSizes",
                          },
                        };
                        handleInputChange(fakeEvent);
                      }}
                    />
                  </FormControl>
                </div>
                <div className={styles.flexCellForm}>
                  <FormControl fullWidth required>
                    <TextField
                      select
                      label={i18n.t("broadcast.newLifeCicle")}
                      value={
                        broadcast.newLifeCicle !== undefined &&
                        broadcast.newLifeCicle !== null
                          ? broadcast.newLifeCicle
                          : ""
                      }
                      className={styles.materialTextField}
                      error={errors["newLifeCicle"] !== undefined}
                      helperText={
                        errors["newLifeCicle"]
                          ? i18n.t("textField.required")
                          : null
                      }
                      required
                      name="newLifeCicle"
                      onChange={handleInputChange}
                    >
                      <MenuItem value={0}>
                        {i18n.t("common.activo.singularM")}
                      </MenuItem>
                      <MenuItem value={1}>{i18n.t("common.limitado")}</MenuItem>
                      <MenuItem value={2}>{i18n.t("common.inactivo")}</MenuItem>
                    </TextField>
                  </FormControl>
                </div>
              </div>

              {!isPartner && (
                <div className={styles.flexRowForm}>
                  <div className={styles.flexCellForm}>
                    <FormControl fullWidth required>
                      <Autocomplete
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        value={partners.find((p) => p.id === partnerId) || null}
                        options={partners || []}
                        getOptionLabel={(option) => option.razonSocial || ""}
                        autoComplete={!partnerId}
                        freeSolo={false}
                        onChange={(event, newValue) => {
                          setPartnerId(newValue?.id ? newValue.id : 0);
                          if (errors["partner"] && newValue) {
                            setErrors((e) => ({
                              ...errors,
                              partner: undefined,
                            }));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: isPartner,
                            }}
                            InputLabelProps={{
                              shrink: !isPartner ? true : undefined,
                              required: true,
                            }}
                            name="partner"
                            label={i18n.t("user.partner")}
                            variant="standard"
                            className={styles.materialTextField}
                            error={errors["partner"] !== undefined}
                            helperText={
                              errors["partner"]
                                ? i18n.t("textField.required")
                                : null
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
              )}

              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <TextField
                    required
                    fullWidth
                    className={styles.materialTextField}
                    value={broadcast.message || ""}
                    label={i18n.t("broadcast.message")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={5}
                    error={errors["message"] !== undefined}
                    helperText={
                      errors["message"] ? i18n.t("textField.required") : null
                    }
                    name="message"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className={styles.flexRowForm}>
                <div className={styles.flexCellForm}>
                  <Button
                    variant="contained"
                    className={styles.primaryFormButton}
                    onClick={() => confirmWhetherThrowNotice()}
                  >
                    <Send className={styles.saveIcon} />
                    {i18n.t("broadcast.confirmDialog.confirmText")}
                  </Button>
                  <Button
                    variant="outlined"
                    className={styles.secondaryFormButton}
                    onClick={() => resetForm()}
                  >
                    <Cancel className={styles.saveIcon} />
                    {i18n.t("common.cancel")}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/*@ts-ignore*/}
        <AlertDialog
          ref={confirmDialog}
          title={i18n.t("broadcast.confirmDialog.title")}
          text={i18n.t("broadcast.partnerTab.alertText")}
          cancelText={i18n.t("broadcast.confirmDialog.cancelText")}
          confirmText={i18n.t("broadcast.confirmDialog.confirmText")}
          confirmAction={() => throwNotice()}
        />
      </RelativeBackdrop>
    </Card>
  );
}
