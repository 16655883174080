// @ts-nocheck
import React from "react";
import { Text, View } from "@react-pdf/renderer";
/**
 * Opciones para seleccionar las pestañas a mostrar y imprimir.
 * @argument {object} analisys Flag para saber si se debe mostrar la tab de análisis.
 * @argument {object} result Flag para saber si se debe mostrar la tab de resultados.
 * @argument {object} information Flag para saber si se debe mostrar la tab de información.
 * @argument {object} summary Flag para saber si se debe mostrar la tab de resumen.
 * @argument {object} recommendation Flag para saber si se debe mostrar la tab de recomendación.
 * @argument {object} preMan Flag para saber si se debe mostrar la tab de mantenimiento preventivo.
 * @argument {object} emStock Flag para saber si se debe mostrar la tab de stock de emergencia.
 */
class ShowTabsOptionsModel {
  analysis = { value: true, premium: false };
  results = { value: true, premium: false };
  information = { value: true, premium: false };
  summary = { value: true, premium: false };
  recommendation = { value: true, premium: false };
  preMan = { value: false, premium: true };
  emeSto = { value: false, premium: true };

  constructor(isPremium) {
    this.preMan.value = isPremium || false;
    this.emeSto.value = isPremium || false;
  }
}

/**
 * Opciones generales del report (Afectan a todas las pestañas).
 * @argument {string} pageSize Tamaño de la hoja.
 * @argument {string} pageOrientation Orientación de la hoja.
 * @argument {string} dateFormat Formato de la fecha.
 * @argument {string} language Idioma del report.
 */
class ReportOptionsModel {
  pageSize = "A4";
  pageOrientation = "portrait";
  dateFormat = "DD/MM/YYYY";
  language = "en";
}

/**
 * Opciones por defecto para la pestaña de Portada (Cover).
 * @argument {Date} reportDate Fecha del report.
 * @argument {string} plantName Nombre de la planta.
 * @argument {string} plantAddress Dirección de la planta.
 * @argument {string} clientName Nombre del cliente.
 * @argument {string} clientAddress Dirección del cliente.
 * @argument {string} partnerName Nombre del partner.
 * @argument {string} partnerAddress Dirección del partner.
 * @argument {string} partnerEmail Email del partner.
 * @argument {string} analysedBy Nombre del usuario.
 * @argument {string} generatedBy Nombre del usuario que genero el report.
 * @argument {*} customBackground Imagen de fondo personalizada.
 */
class CoverOptionsModel {
  reportDate = new Date();
  plantName = "";
  plantAddress = "";
  clientName = "";
  clientAddress = "";
  partnerName = "";
  partnerAddress = "";
  partnerEmail = "";
  assessmentBy = "";
  analysedBy = "";
  customBackground = undefined;
  displayPartnerLogo = false;
  displayClientLogo = false;

  constructor(plant, user, isPremium) {
    this.plantName = plant.denominacion || "";
    this.plantAddress = `${plant.direccion}, ${plant.poblacion || ""}, ${
      plant.cp || ""
    }, ${plant.country?.name || ""}`;
    this.assessmentBy = user.username || "";
    this.analysedBy = `${user?.nombre || ""} ${user?.apellidos || ""}`;
    if (isPremium) {
      this.displayPartnerLogo = true;
      this.displayClientLogo = true;
    }
  }
}

/**
 * Opciones generales para las pestañas de Análisis (Site Analysis), resumen (Summary), recomendación (Recommendation) y Mantenimiento Preventivo (Prev. maintenance).
 * @Component
 * @argument {"analysis" | "summary" | "recommendation" | "preMan"} mode Flag utilizada para adaptar el componente segun la pestaña.
 * @argument {string} orderBy Campo por el que se ordena la tabla de equipos.
 * @argument {"ascendent" | "descendent"} orderType Tipo de orden de la tabla.
 * @argument {Array.<boolean>} column Columnas que se deben mostrar en la tabla.
 * @argument {number} font Tamaño de la fuente.
 * @argument {number} width Se utiliza para calcular el ancho de las columnas.
 * @argument {Array.<number>} years Lista de años que se debe mostrar en la pestaña de Preventive Maintenance.
 * @argument {number} yearStart El primer año en el que se ha encontrado mantenimiento a un equipo.
 * @argument {number} yearEnd El ultimo año en el que se ha encontrado mantenimiento a un equipo.
 */
class GeneralOptionsModel {
  mode = null;
  orderBy = null;
  orderType = null;
  column = null;
  font = null;
  width = null;
  years = null;
  yearStart = null;
  yearEnd = null;
}

class AnalysisOptionsModel extends GeneralOptionsModel {
  constructor() {
    super();
    super.orderBy = "brand";
    super.orderType = "ascendent";
    super.column = [
      false,
      false,
      false,
      false,
      false,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
    ];
    super.font = 10;
    super.width = 7;
    super.mode = "analysis";
  }
}

class SummaryOptionsModel extends GeneralOptionsModel {
  constructor() {
    super();
    super.orderBy = "model";
    super.orderType = "ascendent";
    super.column = [
      false,
      false,
      false,
      false,
      false,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    super.font = 10;
    super.width = 7;
    super.mode = "summary";
  }
}

class RecommendationOptionsModel extends GeneralOptionsModel {
  constructor() {
    super();
    super.orderBy = "model";
    super.orderType = "ascendent";
    super.column = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
    ];
    super.font = 10;
    super.width = 7;
    super.mode = "recommendation";
  }
}

const year = new Date().getFullYear();

class PreManOptionsModel extends GeneralOptionsModel {
  constructor(yearStart, yearEnd) {
    super();
    super.orderBy = "brand";
    super.orderType = "ascendent";
    super.years = [
      year - 3,
      year - 2,
      year - 1,
      year,
      year + 1,
      year + 2,
      year + 3,
      year + 4,
      year + 5,
      year + 6,
    ];
    super.yearStart = 0;
    super.yearEnd = 100 + 20;
    super.column = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    super.font = 8;
    super.width = 7;
    super.yearStart = yearStart;
    super.yearEnd = yearEnd > year + 20 ? year + 20 : yearEnd;
    super.mode = "preMan";
  }
}

class EmStockOptionsModel extends GeneralOptionsModel {
  constructor() {
    super();
    super.orderBy = "countEquipos";
    super.orderType = "descendent";
    super.mode = "emeSto";
  }
}

/**
 * Utilizado para cambiar la pestaña a modo horizontal, si se sobrepasa un ancho maximo. Se utiliza comparandolo con 'width' de las opciones (options.width).
 */
const maxWidthV = 14;
/**
 * Limites de tamaño de la fuente en modo vertical segun el ancho almacenado en las opciones. (TamañoDeFuenteMaximo = fontLimitsV[options.width]).
 */
const fontLimitsV = {
  7: 11,
  8: 11,
  9: 10,
  10: 9,
  11: 9,
  12: 8,
  13: 8,
  14: 8,
};

/**
 * Limites de tamaño de la fuente en modo horizontal segun el ancho almacenado en las opciones. (TamañoDeFuenteMaximo = fontLimitsH[options.width]).
 */
const fontLimitsH = {
  10: 11,
  11: 11,
  12: 10,
  13: 10,
  14: 9,
  15: 9,
  16: 8,
  17: 8,
  18: 7,
};

/**
 * Limites de tamaño de la fuente en la pestaña Preventive Maintenance. (TamañoDeFuenteMaximo = fontLimitsPM[options.width]).
 */
const fontLimitsPM = {
  8: 10,
  9: 9,
  10: 9,
  11: 8,
  12: 8,
  13: 7,
  14: 7,
  15: 6,
  16: 6,
  17: 5,
  18: 5,
};

/**
 * Abreviacion para los valores de ciclo de vida (LifeCycle) de un equipo. (statusAbbreviation[equipo.cicloVida])
 */
const statusAbbreviation = ["A", "L", "I", "U"];
/**
 * Abreviacion para los valores de criticidad (Criticality) de un equipo. (criticalityAbbreviation[equipo.criticidad])
 */
const criticalityAbbreviation = ["H", "M", "L", "U"];
/**
 * Abreviacion para los valores de estado (Condition) de un equipo. (conditionAbbreviation[equipo.estado])
 */
const conditionAbbreviation = ["G", "M", "P", "U"];
/**
 * Abreviacion para los valores de riesgo (Risk) de un equipo. (riskAbbreviation[equipo.riesgo])
 */
const riskAbbreviation = ["H", "M", "L", "U"];

const green = "#14C510";
const yellow = "#FFAA00";
const red = "#E2000F";
const grey = "#969696";

/**
 * Array con los colores de las celdas segun el ciclo de vida (LifeCycle) y el estado (Condition) de un equipo. (statCondColors[cicloVida || estado])
 */
const statCondColors = [green, yellow, red, grey];
/**
 * Array con los colores de las celdas segun la criticidad (Criticality) y el riesgo (Risk) de un equipo. (critRiskColors[criticidad || riesgo])
 */
const critRiskColors = [red, yellow, green, grey];

/**
 * Metodo utlizado para ordenar una lista de equipos segun un campo y una direccion.
 * @param {string} field Campo por el que se ordena la lista.
 * @param {"ascendent" | "descendent"} direction Direccion del orden.
 * @param {Array} data Lista de equipos.
 */
const orderList = (field, direction, data) => {
  let result = [...data];

  switch (field) {
    case "brand":
    case "model":
      if (direction === "ascendent") {
        result = result.sort((a, b) =>
          a[field]
            ? b[field]
              ? a[field]?.name.toUpperCase() > b[field]?.name.toUpperCase()
                ? 1
                : -1
              : 1
            : -1
        );
      }
      if (direction === "descendent") {
        result = result.sort((a, b) =>
          a[field]
            ? b[field]
              ? a[field]?.name.toUpperCase() > b[field]?.name.toUpperCase()
                ? -1
                : 1
              : -1
            : 1
        );
      }
      break;
    case "zona":
      if (direction === "ascendent") {
        result = result.sort((a, b) =>
          a.zona.descripcion.toUpperCase() > b.zona.descripcion.toUpperCase()
            ? 1
            : -1
        );
      }
      if (direction === "descendent") {
        result = result.sort((a, b) =>
          a.zona.descripcion.toUpperCase() < b.zona.descripcion.toUpperCase()
            ? 1
            : -1
        );
      }
      break;
    default:
      if (direction === "ascendent") {
        result = result.sort((a, b) =>
          a[field] && a[field].toString().trim() !== ""
            ? b[field] && b[field].toString().trim() !== ""
              ? typeof a[field] === "string" && typeof b[field] === "string"
                ? a[field].toUpperCase() > b[field].toUpperCase()
                  ? 1
                  : -1
                : a[field] > b[field]
                ? 1
                : -1
              : 1
            : -1
        );
      }
      if (direction === "descendent") {
        result = result.sort((a, b) =>
          a[field] && a[field].toString().trim() !== ""
            ? b[field] && b[field].toString().trim() !== ""
              ? typeof a[field] === "string" && typeof b[field] === "string"
                ? a[field].toUpperCase() > b[field].toUpperCase()
                  ? -1
                  : 1
                : a[field] > b[field]
                ? -1
                : 1
              : -1
            : 1
        );
      }
  }

  return result;
};

/**
 * Metodo utilizado para dividir un texto en 4 partes iguales y asi que haga salto de linea sin añadir guiones.
 * @param {string} text Texto a dividir.
 */
const sliceText = (text) => {
  if (!text) return "";

  if (text.length < 4) {
    return (
      <div>
        <Text>{text}</Text>
      </div>
    );
  }

  let textLength = Math.ceil(text.length / 4);

  return (
    <>
      <View>
        <Text>{text.slice(0, textLength)}</Text>
      </View>
      <View>
        <Text>{text.slice(textLength, textLength * 2)}</Text>
      </View>
      <View>
        <Text>{text.slice(textLength * 2, textLength * 3)}</Text>
      </View>
      <View>
        <Text>{text.slice(textLength * 3)}</Text>
      </View>
    </>
  );
};

export {
  ShowTabsOptionsModel,
  ReportOptionsModel,
  CoverOptionsModel,
  GeneralOptionsModel,
  AnalysisOptionsModel,
  SummaryOptionsModel,
  RecommendationOptionsModel,
  PreManOptionsModel,
  EmStockOptionsModel,
  fontLimitsV,
  maxWidthV,
  fontLimitsH,
  fontLimitsPM,
  statusAbbreviation,
  criticalityAbbreviation,
  conditionAbbreviation,
  riskAbbreviation,
  green,
  yellow,
  red,
  statCondColors,
  critRiskColors,
  orderList,
  sliceText,
};
