import i18n from "i18n-js";

import es from "./es.json";
import en from "./en.json";
import de from "./de.json";
import fr from "./fr.json";
import it from "./it.json";
import pl from "./pl.json";
import tr from "./tr.json";

i18n.fallbacks = true;
i18n.translations = { es, en, de, fr, it, pl, tr };
i18n.defaultLocale = 'en';

export default i18n;