import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

function getByParent(parent, parentId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/document/by/${parent}/${parentId}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

function download(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/document/download/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

function downloadEquipoImages(equipoId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/document/download/equipo/${equipoId}/images`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

function create(parent, parentId, formData) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/document/${parent}/${parentId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    body: formData,
  });
}

function createEquipoImages(equipoId, base64Images) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/document/equipo/${equipoId}/images`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(base64Images),
  });
}

function remove(documentId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/document/${documentId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export { getByParent, download, downloadEquipoImages, create, createEquipoImages, remove };
