// @ts-nocheck
import React, { useEffect, useState, useCallback, useContext } from "react";
import MaterialTable from "material-table";
import AuthService from "../../../services/AuthService";
import i18n from "../../../locales/i18n";
import {
  EquipoStockEmergenciaAPI,
  PlantaStockEmergenciaAPI,
} from "../../../services/API";
import { Typography } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { createMuiTheme } from "@material-ui/core/styles";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import { ThemeProvider } from "@material-ui/styles";
import { SnackbarError, SnackbarSuccess } from "../../../helpers/Common";
import AppContext from "../../../contexts/AppContext";

export default function EquipoStockEmergenciaTable(props) {
  const {
    equipoId,
    plantaId,
    isPremium,
    vacon,
    loading,
    setLoading,
    getStock,
    stock,
    sustituciones,
    readOnly,
  } = props;
  const isKeyUser = AuthService.hasRoleAdmin();
  const isDsc = AuthService.hasRoleDSC();
  const isPartner = AuthService.hasRolePartner();
  const [plantaStock, setPlantaStock] = useState([]);
  const [plantaStockDic, setPlantaStockDic] = useState({});
  const styles = DetailTabs_Style();
  const { setGlobal } = useContext(AppContext);
  const materialTableTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#000000",
      },
    },
  });

  const getPlantaStock = useCallback(async () => {
    setLoading(true);
    await PlantaStockEmergenciaAPI.getByPlanta(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        let dictionary = Object.assign(
          {},
          ...data.map((x) => ({ [x.pntc.pn]: x.pntc.pn + " / " + x.pntc.tc }))
        );
        setPlantaStockDic(dictionary);
        setPlantaStock(data);
      })
      .catch((error) => {});
    setLoading(false);
  }, [plantaId, setLoading]);

  const addStock = async (newStock) => {
    //evitar duplicados
    if (
      stock.some(
        (s) =>
          s.plantaStockEmergencia.pntc.pn ===
          newStock.plantaStockEmergencia.pntc.pn
      )
    ) {
      SnackbarError(setGlobal, i18n.t("planta.stockEmergencia.duplicated"));
      return;
    }
    setLoading(true);

    newStock.plantaStockEmergenciaId = plantaStock.find(
      (p) => p.pntc.pn === newStock.plantaStockEmergencia.pntc.pn
    ).id;
    newStock.plantaStockEmergencia = null;

    await EquipoStockEmergenciaAPI.add({ ...newStock, equipoId: equipoId })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        if (data) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("planta.stockEmergencia.correctAdd")
          );
          getStock();
        }
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("planta.stockEmergencia.notFound"));
        console.error(error);
      });
  };

  const deleteStock = async (stock) => {
    setLoading(true);

    await EquipoStockEmergenciaAPI.remove(stock.id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        if (data) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("planta.stockEmergencia.correctDelete")
          );
          getStock();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (equipoId) {
      //getStock();
      getPlantaStock();
    }
  }, [equipoId, getPlantaStock]);

  return (
    <ThemeProvider theme={materialTableTheme}>
      <MaterialTable
        style={{ width: "100%" }}
        title={i18n.t("equipo.emergencyStock")}
        // @ts-ignore
        localization={i18n.t("dataTableProps")}
        isLoading={loading}
        data={stock}
        options={{
          addRowPosition: "first",
          actionsColumnIndex: -1,
          sorting: true,
          showTitle: true,
          paging: false,
          search: false,
          searchFieldAlignment: "left",
        }}
        columns={[
          {
            field: "plantaStockEmergencia.pntc.pn",
            title: i18n.t("equipo.emergencyStockReference"),
            render: (rowData) => (
              <a
                className={styles.linkColor}
                href={`https://store.danfoss.com/${i18n.locale}/p/${rowData.plantaStockEmergencia.pntc.pn}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {rowData.plantaStockEmergencia.pntc.pn}
              </a>
            ),
            validate: (rowData) => {
              var isEmpty = !rowData.plantaStockEmergencia;
              var isValid =
                !isEmpty &&
                plantaStock &&
                plantaStock.some(
                  (p) => p.pntc.pn === rowData.plantaStockEmergencia.pntc.pn
                );
              return {
                isValid: isValid,
                helperText: isEmpty
                  ? i18n.t("common.required")
                  : isValid
                  ? ""
                  : i18n.t("planta.stockEmergencia.notFound"),
              };
            },
            lookup: plantaStockDic,
          },
          {
            field: "plantaStockEmergencia.pntc.tc",
            title: i18n.t("equipo.retrofitTable.description"),
            editable: "never",
          },
        ]}
        detailPanel={[
          {
            icon: () => <ArrowForwardIos />,
            tooltip: i18n.t("equipo.emergencyStock"),
            render: (rowData) => {
              return (
                <ThemeProvider theme={materialTableTheme}>
                  <MaterialTable
                    // @ts-ignore
                    localization={i18n.t("dataTableProps")}
                    data={sustituciones.filter(
                      (data) =>
                        data.pn === rowData.plantaStockEmergencia.pntc.pn
                    )}
                    style={{ backgroundColor: "whitesmoke" }}
                    columns={[
                      {
                        title: i18n.t("equipo.emergencyStockReference"),
                        field: "pn",
                      },
                      {
                        title: "TC / TYPE",
                        field: "tc",
                      },
                      {
                        title: "SN",
                        field: "sn",
                        render: (rowData) => (
                          <a
                            className={styles.linkColor}
                            href={`/plantas/${rowData.zona.plantaId}/zonas/${rowData.zonaId}/equipos/${rowData.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={rowData.sn}
                          >
                            <Typography>{rowData.sn}</Typography>
                          </a>
                        ),
                      },
                      {
                        title: i18n.t("equipo.sustitutos.found_in"),
                        field: "zona.descripcion",
                      },
                    ]}
                    options={{
                      showTitle: false,
                      search: false,
                      paging: false,
                      headerStyle: { backgroundColor: "whitesmoke" },
                    }}
                    components={{
                      Toolbar: (props) => <></>,
                    }}
                  />
                </ThemeProvider>
              );
            },
          },
        ]}
        editable={
          !readOnly && (isPartner || isDsc || isKeyUser)
            ? {
                onRowAdd: isPremium
                  ? (newData) => addStock(newData)
                  : undefined,
                onRowDelete: (oldData) => deleteStock(oldData),
                isDeletable: () => isPremium,
                deleteTooltip: () =>
                  isPremium
                    ? i18n.t("common.delete")
                    : i18n.t("common.isPremium"),
              }
            : {}
        }
        actions={[
          {
            icon: "refresh",
            tooltip: i18n.t("alarma.actualizar"),
            isFreeAction: true,
            onClick: (event) => {
              getStock();
            },
          },
          {
            icon: "add_box",
            tooltip: i18n.t("common.isPremium"),
            disabled: true,
            onClick: () => {},
            hidden: isPremium,
            isFreeAction: true,
          },
        ]}
      />
    </ThemeProvider>
  );
}
