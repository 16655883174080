import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  Business,
  ExpandLess,
  ExpandMore,
  Group,
  PeopleOutline,
  Room,
} from "@material-ui/icons";
import i18n from "../../../locales/i18n";
import AlertDialog from "../../AlertDialog";
import { DetailTabs_Style, globalColors } from "../../../assets/css/Styles";
import { DashboardAPI, PlantasAPI } from "../../../services/API";
import { LibraryAddCheckOutlined } from "@material-ui/icons";
import "react-perfect-scrollbar/dist/css/styles.css";
import AuthService from "../../../services/AuthService";
import DisableFilter from "../../atoms/disableFilter";

export const defaultFilters = {
  notDefined: {
    brands: true,
    models: {},
    yearOfManufacture: true,
    sizeKw: true,
    sizeA: true,
    ipRating: true,
    frameSize: true,
  },
  brands: [],
  clients: [],
  partners: [],
  plants: [],
  designationTag: [],
  yearOfManufacture: [],
  sizeKw: [],
  sizeA: [],
  ipRating: [],
  frameSize: [],
  lifeCycleStatus: [],
  condition: [],
  criticality: [],
  risk: [],
  status: [],
  warehouse: false,
};

export const setDashboardFiltersOnLocalStorage = (data) => {
  AuthService.setOnLocalStorage(
    "dashBoardFilter_notDefined",
    JSON.stringify(data?.notDefine || defaultFilters.notDefined)
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_brands",
    JSON.stringify(data?.brands || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_clients",
    JSON.stringify(data?.clients || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_partners",
    JSON.stringify(data?.partners || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_plants",
    JSON.stringify(data?.plants || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_designationTag",
    JSON.stringify(data?.designationTag || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_yearOfManufacture",
    JSON.stringify(data?.yearOfManufacture || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_sizeKw",
    JSON.stringify(data?.sizeKw || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_sizeA",
    JSON.stringify(data?.sizeA || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_ipRating",
    JSON.stringify(data?.ipRating || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_frameSize",
    JSON.stringify(data?.frameSize || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_lifeCycleStatus",
    JSON.stringify(data?.lifeCycleStatus || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_condition",
    JSON.stringify(data?.condition || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_criticality",
    JSON.stringify(data?.criticality || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_risk",
    JSON.stringify(data?.risk || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_status",
    JSON.stringify(data?.status || [])
  );
  AuthService.setOnLocalStorage(
    "dashBoardFilter_warehouse",
    JSON.stringify(data?.warehouse || [])
  );
};

export const getDashboardFiltersFromLocalStorage = () => {
  let notDefined = JSON.parse(
    localStorage.getItem("dashBoardFilter_notDefined") ||
      JSON.stringify(defaultFilters.notDefined)
  );
  let brands = JSON.parse(
    localStorage.getItem("dashBoardFilter_brands") || "[]"
  );
  let clients = JSON.parse(
    localStorage.getItem("dashBoardFilter_clients") || "[]"
  );
  let partners = JSON.parse(
    localStorage.getItem("dashBoardFilter_partners") || "[]"
  );
  let plants = JSON.parse(
    localStorage.getItem("dashBoardFilter_plants") || "[]"
  );
  let designationTag = JSON.parse(
    localStorage.getItem("dashBoardFilter_designationTag") || "[]"
  );
  let yearOfManufacture = JSON.parse(
    localStorage.getItem("dashBoardFilter_yearOfManufacture") || "[]"
  );
  let sizeKw = JSON.parse(
    localStorage.getItem("dashBoardFilter_sizeKw") || "[]"
  );
  let sizeA = JSON.parse(localStorage.getItem("dashBoardFilter_sizeA") || "[]");
  let ipRating = JSON.parse(
    localStorage.getItem("dashBoardFilter_ipRating") || "[]"
  );
  let frameSize = JSON.parse(
    localStorage.getItem("dashBoardFilter_frameSize") || "[]"
  );
  let lifeCycleStatus = JSON.parse(
    localStorage.getItem("dashBoardFilter_lifeCycleStatus") || "[]"
  );
  let condition = JSON.parse(
    localStorage.getItem("dashBoardFilter_condition") || "[]"
  );
  let criticality = JSON.parse(
    localStorage.getItem("dashBoardFilter_criticality") || "[]"
  );
  let risk = JSON.parse(localStorage.getItem("dashBoardFilter_risk") || "[]");
  let status = JSON.parse(
    localStorage.getItem("dashBoardFilter_status") || "[]"
  );
  let warehouse = JSON.parse(
    localStorage.getItem("dashBoardFilter_warehouse") || "[]"
  );

  return {
    notDefined,
    brands,
    clients,
    partners,
    plants,
    designationTag,
    yearOfManufacture,
    sizeKw,
    sizeA,
    ipRating,
    frameSize,
    lifeCycleStatus,
    condition,
    criticality,
    risk,
    status,
    warehouse,
  };
};

export const removeDashboardFiltersFromLocalStorage = () => {
  localStorage.removeItem("dashBoardFilter_notDefined");
  localStorage.removeItem("dashBoardFilter_brands");
  localStorage.removeItem("dashBoardFilter_clients");
  localStorage.removeItem("dashBoardFilter_partners");
  localStorage.removeItem("dashBoardFilter_plants");
  localStorage.removeItem("dashBoardFilter_designationTag");
  localStorage.removeItem("dashBoardFilter_yearOfManufacture");
  localStorage.removeItem("dashBoardFilter_sizeKw");
  localStorage.removeItem("dashBoardFilter_sizeA");
  localStorage.removeItem("dashBoardFilter_ipRating");
  localStorage.removeItem("dashBoardFilter_frameSize");
  localStorage.removeItem("dashBoardFilter_lifeCycleStatus");
  localStorage.removeItem("dashBoardFilter_condition");
  localStorage.removeItem("dashBoardFilter_criticality");
  localStorage.removeItem("dashBoardFilter_risk");
  localStorage.removeItem("dashBoardFilter_status");
  localStorage.removeItem("dashBoardFilter_warehouse");
};

export default function (props) {
  const {
    dialogRef,
    loading,
    setLoading,
    usedFilters,
    setUsedFilters,
    originalFilters,
    setOriginalFilters,
    getData,
    cancelFilters,
    applyFilter,
    plantId,
    setFilterButtons,
    setAllFilters,
  } = props;

  const styles = DetailTabs_Style();

  const isDSC = AuthService.hasRoleDSC();
  const isAdmin = AuthService.hasRoleAdmin();

  const [open, setOpen] = useState("");
  const [auxOpen, setAuxOpen] = useState("");
  const [openPartner, setOpenPartner] = useState(0);
  const [openClient, setOpenClient] = useState(0);
  const [openPlant, setOpenPlant] = useState(0);
  const [openBrand, setOpenBrand] = useState(0);

  const handleGeneralDropdown = (value) => {
    let time = 0;
    if (open === value) {
      time = 500;
    }
    setOpen((old) => (old !== value ? value : ""));
    const setOptionsDelay = setTimeout(() => setAuxOpen(value), time);

    return () => clearTimeout(setOptionsDelay);
  };

  const handleDropdown = (value, setOpenState) => {
    setOpenState((old) => (old !== value ? value : 0));
  };

  const [filters, setFilters] = useState(defaultFilters);

  const getFilters = useCallback(
    (isSeted) => {
      setLoading(true);
      (!plantId ? DashboardAPI.getfilters() : PlantasAPI.getFilters(plantId))
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401) {
            window.location.replace("/unauthorized");
          } else {
            throw new Error("getFilterError " + response.statusText);
          }
        })
        .then((data) => {
          setFilters(data);
          setOriginalFilters(data);
          if (!plantId) {
            setAllFilters(data);
            setDashboardFiltersOnLocalStorage(data);
          } else {
            localStorage.setItem("reportFilters", JSON.stringify(data));
          }
          if (!isSeted) {
            if (!plantId) {
              data.status = data.status.map((s) => s !== 2);
            }
            setUsedFilters(data);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    },
    [setLoading, plantId, setOriginalFilters, setAllFilters, setUsedFilters]
  );

  const addToFilterButton = (type) => {
    if (!plantId) {
      setFilterButtons((prev) => [
        ...prev.filter((f) => f.name !== type),
        {
          name: type,
          count: prev.filter((f) => f.name === type)[0]?.count + 1 || 1,
        },
      ]);
    }
  };

  const removeFromFilterButton = (type) => {
    if (!plantId) {
      setFilterButtons((prev) => [
        ...prev.filter((f) => f.name !== type),
        {
          name: type,
          count: prev.filter((f) => f.name === type)[0]?.count - 1 || 0,
        },
      ]);
    }
  };

  const handleCheckAllButton = (field, num) => {
    if (!plantId) {
      setFilterButtons((prev) => [
        ...prev.filter((f) => f.name !== field),
        {
          name: field,
          count: num,
        },
      ]);
    }
  };

  const addToButtonCounter = (field, num) => {
    if (!plantId) {
      setFilterButtons((prev) => [
        ...prev.filter((f) => f.name !== field),
        {
          name: field,
          count: (prev.filter((f) => f.name === field)[0]?.count || 0) + num,
        },
      ]);
    }
  };

  const removeToButtonCounter = (field, num) => {
    if (!plantId) {
      setFilterButtons((prev) => [
        ...prev.filter((f) => f.name !== field),
        {
          name: field,
          count: (prev.filter((f) => f.name === field)[0]?.count || 0) - num,
        },
      ]);
    }
  };

  const handleChange = (type, id, name) => {
    // Deep copy of filters to prevent strange things when working with it
    var activeFilters = JSON.parse(JSON.stringify(usedFilters));

    // Check Brand
    if (type === "brands") {
      //See if brand is allredy cheched
      if (activeFilters.brands.filter((b) => b.id === id).length === 0) {
        // Is not checked
        // Add to the array
        activeFilters.notDefined.models[name] = true;
        activeFilters.brands.push(filters.brands.filter((b) => b.id === id)[0]);
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
        removeToButtonCounter(
          "models",
          filters.brands.filter((b) => b.id === id)[0].models.length + 1
        );
      } else {
        // Is checked
        // Delete ftom array
        activeFilters.notDefined.models[name] = false;
        activeFilters.brands = activeFilters.brands.filter((b) => b.id !== id);
        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
        addToButtonCounter(
          "models",
          filters.brands.filter((b) => b.id === id)[0].models.length + 1
        );
      }
    }

    // Check Model
    if (type === "models") {
      // Deep Copy of filters to work with it
      let filtersC = JSON.parse(JSON.stringify(filters));
      // Find the brand of the model we are checking
      let brand = filtersC.brands.filter(
        (b) => b.models.filter((m) => m.id === parseInt(id, 10)).length !== 0
      )[0];
      // If brand exist in active filters
      if (activeFilters.brands.filter((b) => b.id === brand.id).length === 0) {
        // Brand does not exist
        // Filter the models of the brand to only leave the checked
        brand.models = brand.models.filter((m) => m.id === parseInt(id, 10));
        // Add brand to the array of filters
        activeFilters.brands.push(brand);
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton("models");
      } else {
        // Brand exist in filters
        // Retrieve the frand from the filters
        let brandAC = activeFilters.brands.filter((b) => b.id === brand.id)[0];
        //Check if model is already checked
        if (
          activeFilters.brands.filter(
            (b) =>
              b.models.filter((m) => m.id === parseInt(id, 10)).length !== 0
          ).length === 0
        ) {
          // Model is not checked
          // Add the model to the array in the brand
          brandAC.models.push(
            brand.models.filter((m) => m.id === parseInt(id, 10))[0]
          );

          // Remove counter from list of buttons for dashboard
          removeFromFilterButton(type);
        } else {
          // Model is checked
          // Delete the Model from the array of brands
          brandAC.models = brandAC.models.filter(
            (m) => m.id !== parseInt(id, 10)
          );
          // Add counter from list of buttons for dashboard
          addToFilterButton(type);

          // Check if brand has any model left
          if (
            brandAC.models.length === 0 &&
            !activeFilters.notDefined.models[brand.name]
          ) {
            // If brand is empty, delete it from the array
            activeFilters.brands = activeFilters.brands.filter(
              (b) => b.id !== brandAC.id
            );
            // Add counter from list of buttons for dashboard
            addToFilterButton("brands");
          }
        }
      }
    }

    // Check Model
    if (type === "nd_model") {
      // Deep Copy of filters to work with it
      // Find the brand of the model we are checking
      let brand = { ...filters.brands.filter((b) => b.id === id)[0] };
      // If brand exist in active filters
      if (activeFilters.brands.filter((b) => b.id === brand.id).length === 0) {
        // Brand does not exist
        // Filter the models of the brand to only leave the checked
        activeFilters.notDefined.models[name] = true;
        // Add brand to the array of filters
        brand.models = [];
        activeFilters.brands.push(brand);
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton("models");
        if (brand.models.length === 0) {
          removeFromFilterButton("brands");
        }
      } else {
        // Brand exist in filters
        // Retrieve the frand from the filters
        let brandAC = activeFilters.brands.filter((b) => b.id === brand.id)[0];
        //Check if model is already checked
        if (!activeFilters.notDefined.models[name]) {
          // Model is not checked
          // Add the model to the array in the brand
          activeFilters.notDefined.models[name] = true;
          // Remove counter from list of buttons for dashboard
          removeFromFilterButton("models");
        } else {
          // Model is checked
          // Delete the Model from the array of brands
          activeFilters.notDefined.models[name] = false;
          // Add counter from list of buttons for dashboard
          addToFilterButton("models");

          // Check if brand has any model left
          if (brandAC.models.length === 0) {
            // If brand is empty, delete it from the array
            activeFilters.brands = activeFilters.brands.filter(
              (b) => b.id !== brandAC.id
            );
            // Add counter from list of buttons for dashboard
            addToFilterButton("brands");
          }
        }
      }
    }

    // Check Partner
    if (type === "partners") {
      //See if partner is allredy cheched
      if (
        activeFilters.partners.filter((b) => b.id === parseInt(id, 10))
          .length === 0
      ) {
        // Is not checked
        // Add to the array
        activeFilters.partners.push(
          filters.partners.filter((b) => b.id === parseInt(id, 10))[0]
        );
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
        removeToButtonCounter(
          "clients",
          filters.partners.filter((b) => b.id === parseInt(id, 10))[0].clientes
            ?.length || 0
        );
        var countPl = 0;
        var filteredCl = filters.partners.filter(
          (b) => b.id === parseInt(id, 10)
        )[0].clientes;
        filteredCl.forEach((element) => {
          countPl += element.plantas.length;
        });
        removeToButtonCounter("plant", countPl);
        var countZo = 0;
        filteredCl.forEach((element) => {
          element.plantas.forEach((plant) => {
            countZo += plant.zonas.length;
          });
        });
        removeToButtonCounter("zone", countZo);
      } else {
        // Is checked
        // Delete ftom array
        activeFilters.partners = activeFilters.partners.filter(
          (b) => b.id !== parseInt(id, 10)
        );
        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
        addToButtonCounter(
          "clients",
          filters.partners.filter((b) => b.id === parseInt(id, 10))[0].clientes
            ?.length || 0
        );
        var countmPl = 0;
        var filteredmCl = filters.partners.filter(
          (b) => b.id === parseInt(id, 10)
        )[0].clientes;
        filteredmCl.forEach((element) => {
          countmPl += element.plantas.length;
        });
        addToButtonCounter("plant", countmPl);
        var countmZo = 0;
        filteredmCl.forEach((element) => {
          element.plantas.forEach((plant) => {
            countmZo += plant.zonas.length;
          });
        });
        addToButtonCounter("zone", countmZo);
      }
    }

    // Check Client
    if (type === "clients") {
      // Check if we are DSC
      if (isDSC || isAdmin) {
        // Dashboard filters
        // Deep Copy of filters to work with it
        var filtersCopy = JSON.parse(JSON.stringify(filters));
        // Find the partner of the client we are checking
        var partner = filtersCopy.partners.filter(
          (p) =>
            p.clientes.filter((c) => c.id === parseInt(id, 10)).length !== 0
        )[0];

        // If partner exist in active filters
        if (
          activeFilters.partners.filter((p) => p.id === partner.id).length === 0
        ) {
          // Partner does not exist
          // Filter the clients of the partner to only leave the checked
          partner.clientes = partner.clientes.filter(
            (c) => c.id === parseInt(id, 10)
          );
          // Add partner to the array of filters
          activeFilters.partners.push(partner);
          // Remove counter from list of buttons for dashboard
          removeFromFilterButton("partners");
          removeFromFilterButton(type);
          removeToButtonCounter(
            "plant",
            partner.clientes.filter((b) => b.id === parseInt(id, 10))[0].plantas
              ?.length || 0
          );
          var counterPl = 0;
          var filteredCli = partner.clientes.filter(
            (b) => b.id === parseInt(id, 10)
          )[0].plantas;
          filteredCli.forEach((element) => {
            counterPl += element.zonas.length;
          });
          removeToButtonCounter("zone", counterPl);
        } else {
          // Partner exist in filters
          // Retrieve the partner from the filters
          var partnerAC = activeFilters.partners.filter(
            (p) => p.id === partner.id
          )[0];
          //See if client is allredy cheched
          if (
            activeFilters.partners.filter(
              (b) =>
                b.clientes.filter((m) => m.id === parseInt(id, 10)).length !== 0
            ).length === 0
          ) {
            // Is not checked
            // Add to the array
            partnerAC.clientes.push(
              partner.clientes.filter((b) => b.id === parseInt(id, 10))[0]
            );
            // Remove counter from list of buttons for dashboard
            removeFromFilterButton(type);
            var countPla = 0;
            var filterCl = partner.clientes.filter(
              (b) => b.id === parseInt(id, 10)
            );
            filterCl.forEach((element) => {
              countPla += element.plantas.length;
            });
            removeToButtonCounter("plant", countPla);
            var countZon = 0;
            filterCl.forEach((element) => {
              element.plantas.forEach((plant) => {
                countZon += plant.zonas.length;
              });
            });
            removeToButtonCounter("zone", countZon);
          } else {
            // Is checked
            // Delete ftom array
            partnerAC.clientes = partnerAC.clientes.filter(
              (b) => b.id !== parseInt(id, 10)
            );
            // Add counter from list of buttons for dashboard
            addToFilterButton(type);
            var countPlant = 0;
            var filteredClient = partner.clientes.filter(
              (b) => b.id === parseInt(id, 10)
            );
            filteredClient.forEach((element) => {
              countPlant += element.plantas.length;
            });
            addToButtonCounter("plant", countPlant);
            var countZone = 0;
            filteredClient.forEach((element) => {
              element.plantas.forEach((plant) => {
                countZone += plant.zonas.length;
              });
            });
            addToButtonCounter("zone", countZone);

            // Check if partner has any client left
            if (partnerAC.clientes.length === 0) {
              // If partner is empty, delete it from the array
              activeFilters.partners = activeFilters.partners.filter(
                (b) => b.id !== partnerAC.id
              );
              // Add counter from list of buttons for dashboard
              addToFilterButton("partners");
            }
          }
        }
      } else {
        //See if client is allredy cheched
        if (
          activeFilters.clients.filter((b) => b.id === parseInt(id, 10))
            .length === 0
        ) {
          // Is not checked
          // Add to the array
          activeFilters.clients.push(
            filters.clients.filter((b) => b.id === parseInt(id, 10))[0]
          );
          // Remove counter from list of buttons for dashboard
          removeFromFilterButton(type);
          removeToButtonCounter(
            "plant",
            filters.clients.filter((b) => b.id === parseInt(id, 10))[0].plantas
              ?.length || 0
          );
          var countr = 0;
          var filteredr = filters.clients.filter(
            (b) => b.id === parseInt(id, 10)
          )[0].plantas;
          filteredr.forEach((element) => {
            countr += element.zonas.length;
          });
          removeToButtonCounter("zone", countr);
        } else {
          // Is checked
          // Delete ftom array
          activeFilters.clients = activeFilters.clients.filter(
            (b) => b.id !== parseInt(id, 10)
          );
          // Add counter from list of buttons for dashboard
          addToFilterButton(type);
          addToButtonCounter(
            "plant",
            filters.clients.filter((b) => b.id === parseInt(id, 10))[0].plantas
              ?.length || 0
          );
          var count = 0;
          var filtered = filters.clients.filter(
            (b) => b.id === parseInt(id, 10)
          )[0].plantas;
          filtered.forEach((element) => {
            count += element.zonas.length;
          });
          addToButtonCounter("zone", count);
        }
      }
    }

    // Check Plant
    if (type === "plant") {
      // Check if we are in dashboard filters or report filters
      if (!plantId) {
        // Dashboard filters
        // Deep Copy of filters to work with it
        var filtersCop = JSON.parse(JSON.stringify(filters));
        // Check if we are DSC
        if (isDSC || isAdmin) {
          // Find the partner of the plant we are checking
          var partnerPlant = filtersCop.partners.filter(
            (p) =>
              p.clientes.filter(
                (b) =>
                  b.plantas.filter((m) => m.id === parseInt(id, 10)).length !==
                  0
              ).length !== 0
          )[0];
          // If partner exist in active filters
          if (
            activeFilters.partners.filter((b) => b.id === partnerPlant.id)
              .length === 0
          ) {
            // Paratner does not exist
            // Filter the plants of the partner to only leave the checked
            partnerPlant.clientes = partnerPlant.clientes.filter(
              (c) =>
                (c.plantas = c.plantas.filter(
                  (m) => m.id === parseInt(id, 10)
                )) && c.plantas.length !== 0
            );
            // Add client to the array of filters
            activeFilters.partners.push(partnerPlant);
            // Remove counter from list of buttons for dashboard
            removeFromFilterButton("partners");
            removeFromFilterButton("clients");
            removeFromFilterButton(type);
            var filteredCliZo = partnerPlant.clientes.filter(
              (b) =>
                b.plantas.filter((p) => p.id === parseInt(id, 10)).length !== 0
            )[0].plantas;
            var plantFilter = filteredCliZo.filter(
              (pl) => pl.id === parseInt(id, 10)
            )[0];
            removeToButtonCounter("zone", plantFilter.zonas.length);
          } else {
            // Partner exist in filters
            // Retrieve the partner from the filters
            var partnerAc = activeFilters.partners.filter(
              (p) => p.id === partnerPlant.id
            )[0];

            // Find the client of the plant we are checking
            var clientFp = partnerPlant.clientes.filter(
              (b) =>
                b.plantas.filter((m) => m.id === parseInt(id, 10)).length !== 0
            )[0];
            // Check if client exist in active filters
            if (
              partnerAc.clientes.filter((b) => b.id === clientFp.id).length ===
              0
            ) {
              // Client does not exist
              // Filter the plants of the client to only leave the checked
              clientFp.plantas = clientFp.plantas.filter(
                (m) => m.id === parseInt(id, 10)
              );
              // Add client to the array of filters
              partnerAc.clientes.push(clientFp);
              // Remove counter from list of buttons for dashboard
              removeFromFilterButton("clients");
            } else {
              // Client exist in filters
              // Retrieve the client from the filters
              var clientACTp = partnerAc.clientes.filter(
                (b) => b.id === clientFp.id
              )[0];
              //Check if plant is already checked
              if (
                clientACTp.plantas.filter((m) => m.id === parseInt(id, 10))
                  .length === 0
              ) {
                // Plant is not checked
                // Add the plant to the array in the client
                clientACTp.plantas.push(
                  clientFp.plantas.filter((c) => c.id === parseInt(id, 10))[0]
                );
                // Remove counter from list of buttons for dashboard
                removeFromFilterButton(type);
                var filteredClPl = clientFp.plantas.filter(
                  (pl) => pl.id === parseInt(id, 10)
                )[0];
                removeToButtonCounter("zone", filteredClPl.zonas.length);
              } else {
                // Plant is checked
                // Delete the Plant from the array of clients
                clientACTp.plantas = clientACTp.plantas.filter(
                  (pl) => pl.id !== parseInt(id, 10)
                );
                // Add counter from list of buttons for dashboard
                addToFilterButton(type);
                var filteredPlr = clientFp.plantas.filter(
                  (pl) => pl.id === parseInt(id, 10)
                )[0];
                addToButtonCounter("zone", filteredPlr.zonas.length);

                // Check if client has any plant left
                if (clientACTp.plantas.length === 0) {
                  // If client is empty, delete it from the array
                  partnerAc.clientes = partnerAc.clientes.filter(
                    (b) => b.id !== clientACTp.id
                  );
                  // Add counter from list of buttons for dashboard
                  addToFilterButton("clients");
                }

                // Check if partner has any client left
                if (partnerAc.clientes.length === 0) {
                  // If partner is empty, delete it from the array
                  activeFilters.partners = activeFilters.partners.filter(
                    (b) => b.id !== partnerAc.id
                  );
                  // Add counter from list of buttons for dashboard
                  addToFilterButton("partners");
                }
              }
            }
          }
        } else {
          // Find the client of the plant we are checking
          var client = filtersCop.clients.filter(
            (b) =>
              b.plantas.filter((m) => m.id === parseInt(id, 10)).length !== 0
          )[0];
          // If client exist in active filters
          if (
            activeFilters.clients.filter((b) => b.id === client.id).length === 0
          ) {
            // Client does not exist
            // Filter the plants of the client to only leave the checked
            client.plantas = client.plantas.filter(
              (m) => m.id === parseInt(id, 10)
            );
            // Add client to the array of filters
            activeFilters.clients.push(client);
            // Remove counter from list of buttons for dashboard
            removeFromFilterButton("clients");
            removeFromFilterButton(type);
            removeToButtonCounter(
              "zone",
              client.plantas.filter((m) => m.id === parseInt(id, 10))[0].zonas
                .length
            );
          } else {
            // Client exist in filters
            // Retrieve the client from the filters
            var clientAC = activeFilters.clients.filter(
              (b) => b.id === client.id
            )[0];
            //Check if plant is already checked
            if (
              activeFilters.clients.filter(
                (b) =>
                  b.plantas.filter((m) => m.id === parseInt(id, 10)).length !==
                  0
              ).length === 0
            ) {
              // Plant is not checked
              // Add the plant to the array in the client
              clientAC.plantas.push(
                client.plantas.filter((m) => m.id === parseInt(id, 10))[0]
              );
              // Remove counter from list of buttons for dashboard
              removeFromFilterButton(type);
              var filteredP = client.plantas.filter(
                (m) => m.id === parseInt(id, 10)
              )[0].zonas.length;
              removeToButtonCounter("zone", filteredP);
            } else {
              // Plant is checked
              // Delete the Plant from the array of clients
              clientAC.plantas = clientAC.plantas.filter(
                (m) => m.id !== parseInt(id, 10)
              );
              // Add counter from list of buttons for dashboard
              addToFilterButton(type);
              var countZ = 0;
              var filteredZ = client.plantas.filter(
                (b) => b.id === parseInt(id, 10)
              );
              filteredZ.forEach((element) => {
                countZ += element.zonas.length;
              });
              addToButtonCounter("zone", countZ);

              // Check if client has any plant left
              if (clientAC.plantas.length === 0) {
                // If client is empty, delete it from the array
                activeFilters.clients = activeFilters.clients.filter(
                  (b) => b.id !== clientAC.id
                );
                // Add counter from list of buttons for dashboard
                addToFilterButton("clients");
              }
            }
          }
        }
      } else {
        // Report filters
        //See if plant is allredy cheched
        if (
          activeFilters.plants.filter((b) => b.id === parseInt(id, 10))
            .length === 0
        ) {
          // Is not checked
          // Add to the array
          activeFilters.plants.push(
            filters.plants.filter((b) => b.id === parseInt(id, 10))[0]
          );
        } else {
          // Is checked
          // Delete ftom array
          activeFilters.plants = activeFilters.plants.filter(
            (b) => b.id !== parseInt(id, 10)
          );
        }
      }
    }

    // Check zone
    if (type === "zone") {
      // Deep Copy of filters to work with it
      var filtersCo = JSON.parse(JSON.stringify(filters));
      // Check if we are in dashboard filters or report filters
      if (!plantId) {
        // Dashboard filters
        // Check if we are DSC
        if (isDSC || isAdmin) {
          // Find the partner of the plant of the zone we are checking
          var partnerF = filtersCo.partners.filter(
            (p) =>
              p.clientes.filter(
                (c) =>
                  c.plantas.filter(
                    (m) =>
                      m.zonas.filter((z) => z.id === parseInt(id, 10))
                        .length !== 0
                  ).length !== 0
              ).length !== 0
          )[0];

          // If partner exist in active filters
          if (
            activeFilters.partners.filter((p) => p.id === partnerF.id)
              .length === 0
          ) {
            // Partner does not exist
            // Filter the zones of the plants of the client of the partner to only leave the checked
            partnerF.clientes = partnerF.clientes.filter(
              (c) =>
                (c.plantas = c.plantas.filter(
                  (p) =>
                    (p.zonas = p.zonas.filter(
                      (z) => z.id === parseInt(id, 10)
                    )) && p.zonas.length !== 0
                )) && c.plantas.length !== 0
            );
            // Add client to the array of filters
            activeFilters.partners.push(partnerF);
            // Remove counter from list of buttons for dashboard
            removeFromFilterButton("partners");
            removeFromFilterButton("clients");
            removeFromFilterButton("plant");
            removeFromFilterButton(type);
          } else {
            // Partner exist in filters
            // Retrieve the partner from the filters
            var partnerACT = activeFilters.partners.filter(
              (p) => p.id === partnerF.id
            )[0];
            // Find the client of the plant of the zone we are checking
            var clientFi = partnerF.clientes.filter(
              (b) =>
                b.plantas.filter(
                  (m) =>
                    m.zonas.filter((z) => z.id === parseInt(id, 10)).length !==
                    0
                ).length !== 0
            )[0];
            // If client exist in active filters
            if (
              partnerACT.clientes.filter((b) => b.id === clientFi.id).length ===
              0
            ) {
              // Client does not exist
              // Filter the plants of the client to only leave the checked
              clientFi.plantas = clientFi.plantas.filter(
                (m) =>
                  (m.zonas = m.zonas.filter(
                    (z) => z.id === parseInt(id, 10)
                  )) && m.zonas.length !== 0
              );
              // Add client to the array of filters
              partnerACT.clientes.push(clientFi);
              // Remove counter from list of buttons for dashboard
              removeFromFilterButton("clients");
              removeFromFilterButton("plant");
              removeFromFilterButton(type);
            } else {
              // Client exist in filters
              // Retrieve the client from the filters
              var clientACTI = partnerACT.clientes.filter(
                (b) => b.id === clientFi.id
              )[0];

              // Find the plant of the zone we are checking
              var plantFi = clientFi.plantas.filter(
                (b) =>
                  b.zonas.filter((m) => m.id === parseInt(id, 10)).length !== 0
              )[0];

              // Check if plant exist in active filters
              if (
                clientACTI.plantas.filter((m) => m.id === plantFi.id).length ===
                0
              ) {
                // Plant does not exist
                // Filter the zones of the plant to only leave the checked
                plantFi.zonas = plantFi.zonas.filter(
                  (m) => m.id === parseInt(id, 10)
                );
                // Add plant to the array of filters
                clientACTI.plantas.push(plantFi);
                // Remove counter from list of buttons for dashboard
                removeFromFilterButton("plant");
                removeFromFilterButton(type);
              } else {
                // Plant exist in filters
                // Retrieve the plant from the filters
                var plantACTz = clientACTI.plantas.filter(
                  (m) => m.id === plantFi.id
                )[0];
                //Check if zone is already checked
                if (
                  plantACTz.zonas.filter((z) => z.id === parseInt(id, 10))
                    .length === 0
                ) {
                  // Zone is not checked
                  // Add the zone to the array in the plant
                  plantACTz.zonas.push(
                    plantFi.zonas.filter((z) => z.id === parseInt(id, 10))[0]
                  );
                  // Remove counter from list of buttons for dashboard
                  removeFromFilterButton(type);
                } else {
                  // Zone is checked
                  // Delete the Zone from the array of plants
                  plantACTz.zonas = plantACTz.zonas.filter(
                    (z) => z.id !== parseInt(id, 10)
                  );

                  // Add counter from list of buttons for dashboard
                  addToFilterButton(type);

                  // Check if plant has any zone left
                  if (plantACTz.zonas.length === 0) {
                    // If plant is empty, delete it from the array
                    clientACTI.plantas = clientACTI.plantas.filter(
                      (m) => m.id !== plantACTz.id
                    );
                    // Add counter from list of buttons for dashboard
                    addToFilterButton("plant");
                  }

                  // Check if client has any plant left
                  if (clientACTI.plantas.length === 0) {
                    // If client is empty, delete it from the array
                    partnerACT.clientes = partnerACT.clientes.filter(
                      (b) => b.id !== clientACTI.id
                    );
                    // Add counter from list of buttons for dashboard
                    addToFilterButton("clients");
                  }

                  // Check if partner has any client left
                  if (partnerACT.clientes.length === 0) {
                    // If partner is empty, delete it from the array
                    activeFilters.partners = activeFilters.partners.filter(
                      (b) => b.id !== partnerACT.id
                    );
                    // Add counter from list of buttons for dashboard
                    addToFilterButton("partners");
                  }
                }
              }
            }
          }
        } else {
          // Find the client of the plant of the zone we are checking
          var clientF = filtersCo.clients.filter(
            (b) =>
              b.plantas.filter(
                (m) =>
                  m.zonas.filter((z) => z.id === parseInt(id, 10)).length !== 0
              ).length !== 0
          )[0];
          // If client exist in active filters
          if (
            activeFilters.clients.filter((b) => b.id === clientF.id).length ===
            0
          ) {
            // Client does not exist
            // Filter the plants of the client to only leave the checked
            clientF.plantas = clientF.plantas.filter(
              (m) =>
                (m.zonas = m.zonas.filter((z) => z.id === parseInt(id, 10))) &&
                m.zonas.length !== 0
            );
            // Add client to the array of filters
            activeFilters.clients.push(clientF);
            // Remove counter from list of buttons for dashboard
            removeFromFilterButton("clients");
            removeFromFilterButton("plant");
            removeFromFilterButton(type);
          } else {
            // Client exist in filters
            // Retrieve the client from the filters
            var clientACT = activeFilters.clients.filter(
              (b) => b.id === clientF.id
            )[0];

            // Find the plant of the zone we are checking
            var plantF = clientF.plantas.filter(
              (b) =>
                b.zonas.filter((m) => m.id === parseInt(id, 10)).length !== 0
            )[0];

            // Check if plant exist in active filters
            if (
              clientACT.plantas.filter((m) => m.id === plantF.id).length === 0
            ) {
              // Plant does not exist
              // Filter the zones of the plant to only leave the checked
              plantF.zonas = plantF.zonas.filter(
                (m) => m.id === parseInt(id, 10)
              );
              // Add plant to the array of filters
              clientACT.plantas.push(plantF);
              // Remove counter from list of buttons for dashboard
              removeFromFilterButton("plant");
            } else {
              // Plant exist in filters
              // Retrieve the plant from the filters
              var plantACT = clientACT.plantas.filter(
                (m) => m.id === plantF.id
              )[0];
              //Check if zone is already checked
              if (
                plantACT.zonas.filter((z) => z.id === parseInt(id, 10))
                  .length === 0
              ) {
                // Zone is not checked
                // Add the zone to the array in the plant
                plantACT.zonas.push(
                  plantF.zonas.filter((z) => z.id === parseInt(id, 10))[0]
                );
                // Remove counter from list of buttons for dashboard
                removeFromFilterButton(type);
              } else {
                // Zone is checked
                // Delete the Zone from the array of plants
                plantACT.zonas = plantACT.zonas.filter(
                  (z) => z.id !== parseInt(id, 10)
                );

                // Add counter from list of buttons for dashboard
                addToFilterButton(type);

                // Check if plant has any zone left
                if (plantACT.zonas.length === 0) {
                  // If plant is empty, delete it from the array
                  clientACT.plantas = clientACT.plantas.filter(
                    (m) => m.id !== plantACT.id
                  );
                  // Add counter from list of buttons for dashboard
                  addToFilterButton("plant");
                }

                // Check if client has any plant left
                if (clientACT.plantas.length === 0) {
                  // If client is empty, delete it from the array
                  activeFilters.clients = activeFilters.clients.filter(
                    (b) => b.id !== clientACT.id
                  );
                  // Add counter from list of buttons for dashboard
                  addToFilterButton("clients");
                }
              }
            }
          }
        }
      } else {
        // Report filters
        // Find the plant of the zone we are checking
        var plant = filtersCo.plants.filter(
          (b) => b.zonas.filter((m) => m.id === parseInt(id, 10)).length !== 0
        )[0];
        // If plant exist in active filters
        if (
          activeFilters.plants.filter((b) => b.id === plant.id).length === 0
        ) {
          // Plant does not exist
          // Filter the zones of the plant to only leave the checked
          plant.zonas = plant.zonas.filter((m) => m.id === parseInt(id, 10));
          // Add plant to the array of filters
          activeFilters.plants.push(plant);
        } else {
          // Plant exist in filters
          // Retrieve the plant from the filters
          var plantAC = activeFilters.plants.filter(
            (b) => b.id === plant.id
          )[0];
          //Check if zone is already checked
          if (
            activeFilters.plants.filter(
              (b) =>
                b.zonas.filter((m) => m.id === parseInt(id, 10)).length !== 0
            ).length === 0
          ) {
            // Zone is not checked
            // Add the zone to the array in the plant
            plantAC.zonas.push(
              plant.zonas.filter((m) => m.id === parseInt(id, 10))[0]
            );
          } else {
            // Zone is checked
            // Delete the Zone from the array of plants
            plantAC.zonas = plantAC.zonas.filter(
              (m) => m.id !== parseInt(id, 10)
            );

            // Check if plant has any zone left
            if (plantAC.zonas.length === 0) {
              // If plant is empty, delete it from the array
              activeFilters.plants = activeFilters.plants.filter(
                (b) => b.id !== plantAC.id
              );
            }
          }
        }
      }
    }

    // Check Designation Tag
    if (type === "tag") {
      if (!activeFilters.designationTag.includes(id)) {
        activeFilters.designationTag.push(id);
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
      } else {
        activeFilters.designationTag = activeFilters.designationTag.filter(
          (t) => t !== id
        );
        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
      }
    }

    // Check Year of manufacture
    if (type === "yearOfManufacture") {
      if (!activeFilters.yearOfManufacture.includes(parseInt(id, 10))) {
        activeFilters.yearOfManufacture.push(parseInt(id, 10));
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
      } else {
        activeFilters.yearOfManufacture =
          activeFilters.yearOfManufacture.filter((t) => t !== parseInt(id, 10));

        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
      }
    }

    // Check Size KW
    if (type === "sizeKw") {
      if (!activeFilters.sizeKw.includes(id)) {
        activeFilters.sizeKw.push(id);
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
      } else {
        activeFilters.sizeKw = activeFilters.sizeKw.filter((t) => t !== id);
        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
      }
    }

    // Check Size A
    if (type === "sizeA") {
      if (!activeFilters.sizeA.includes(id)) {
        activeFilters.sizeA.push(id);
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
      } else {
        activeFilters.sizeA = activeFilters.sizeA.filter((t) => t !== id);
        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
      }
    }

    // Check Ip Rating
    if (type === "ipRating") {
      if (!activeFilters.ipRating.includes(id)) {
        activeFilters.ipRating.push(id);
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
      } else {
        activeFilters.ipRating = activeFilters.ipRating.filter((t) => t !== id);
        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
      }
    }

    // Check Frame Size
    if (type === "frameSize") {
      if (!activeFilters.frameSize.includes(id)) {
        activeFilters.frameSize.push(id);
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
      } else {
        activeFilters.frameSize = activeFilters.frameSize.filter(
          (t) => t !== id
        );
        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
      }
    }

    // Check Life Cycle Status
    if (type === "lcs") {
      if (!activeFilters.lifeCycleStatus.includes(parseInt(id, 10))) {
        activeFilters.lifeCycleStatus.push(parseInt(id, 10));
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
      } else {
        activeFilters.lifeCycleStatus = activeFilters.lifeCycleStatus.filter(
          (t) => t !== parseInt(id, 10)
        );
        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
      }
    }

    // Check condition
    if (type === "condition") {
      if (!activeFilters.condition.includes(parseInt(id, 10))) {
        activeFilters.condition.push(parseInt(id, 10));
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
      } else {
        activeFilters.condition = activeFilters.condition.filter(
          (t) => t !== parseInt(id, 10)
        );
        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
      }
    }

    // Check criticality
    if (type === "criticality") {
      if (!activeFilters.criticality.includes(parseInt(id, 10))) {
        activeFilters.criticality.push(parseInt(id, 10));
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
      } else {
        activeFilters.criticality = activeFilters.criticality.filter(
          (t) => t !== parseInt(id, 10)
        );
        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
      }
    }

    // Check criticality
    if (type === "risk") {
      if (!activeFilters.risk.includes(parseInt(id, 10))) {
        activeFilters.risk.push(parseInt(id, 10));
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
      } else {
        activeFilters.risk = activeFilters.risk.filter(
          (t) => t !== parseInt(id, 10)
        );
        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
      }
    }

    // Check status
    if (type === "status") {
      if (!activeFilters.status.includes(parseInt(id, 10))) {
        activeFilters.status.push(parseInt(id, 10));
        // Remove counter from list of buttons for dashboard
        removeFromFilterButton(type);
      } else {
        activeFilters.status = activeFilters.status.filter(
          (t) => t !== parseInt(id, 10)
        );
        // Add counter from list of buttons for dashboard
        addToFilterButton(type);
      }
    }

    setUsedFilters(activeFilters);
  };

  const getFilter = useCallback(() => {
    var filter = undefined;
    if (!plantId) {
      filter = getDashboardFiltersFromLocalStorage();
    } else {
      filter = JSON.parse(localStorage.getItem("reportFilters"));
    }
    if (filter) {
      setUsedFilters(filter);
      return filter;
    } else {
      return {};
    }
  }, [setUsedFilters, plantId]);

  useEffect(() => {
    var filter = getFilter();
    var isSeted = Object.keys(filter).length !== 0;
    Promise.all([getFilters(isSeted)]).then(() => {
      if (getData && isSeted) {
        getData(filter);
      }
    });
  }, [getData, getFilter, getFilters, plantId]);

  useEffect(() => {
    var filter = getFilter();
    var isSeted = Object.keys(filter).length !== 0;
    var retivedFilters =
      filters.plants &&
      filters.plants.length !== 0 &&
      filters.brands.length !== 0;

    // eslint-disable-next-line no-unused-expressions
    if (getData) {
      if (isSeted) {
        getData(filter);
      } else if (retivedFilters) {
        getData(filters);
      }
    }
  }, [filters, getData, getFilter, plantId]);

  // PRAISE THE SUUUUN!! 🫷🥵🫸 ☀️
  const checkUncheckedAll = (field, ndField) => {
    if (
      usedFilters[field].length === filters[field].length &&
      (usedFilters.notDefined[field] === undefined ||
        usedFilters.notDefined[field])
    ) {
      setUsedFilters((old) => {
        var res = JSON.parse(JSON.stringify(old));
        // Adding filter buttons
        if (field === "partners") {
          handleCheckAllButton("partners", filters[field].length);
          filters.partners.forEach((p) => {
            addToButtonCounter("clients", p.clientes.length);
            p.clientes.forEach((c) => {
              addToButtonCounter("plant", c.plantas.length);
              c.plantas.forEach((pl) => {
                addToButtonCounter("zone", pl.zonas.length);
              });
            });
          });
        }
        if (field === "clients") {
          handleCheckAllButton("clients", filters[field].length);
          filters.clients.forEach((c) => {
            addToButtonCounter("plant", c.plantas.length);
            c.plantas.forEach((p) => {
              addToButtonCounter("zone", p.zonas.length);
            });
          });
        } else if (field === "brands") {
          // +1 for N/A option
          handleCheckAllButton("brands", filters.brands.length + 1);
          Object.keys(res.notDefined.models).forEach(
            (key) => (res.notDefined.models[key] = false)
          );
          filters.brands.forEach((b) => {
            addToButtonCounter("models", b.models.length + 1);
          });
        } else {
          handleCheckAllButton(field, filters[field].length);
        }
        if (!ndField) {
          // Deleting from the filters
          return {
            ...res,
            [field]: [],
          };
        } else {
          // Deleting from the filters and setting notDefined to false
          return {
            ...res,
            [field]: [],
            notDefined: { ...res.notDefined, [field]: false },
          };
        }
      });
    } else {
      setUsedFilters((old) => {
        var res = JSON.parse(JSON.stringify(old));
        // Removing filter buttons
        if (field === "partners") {
          handleCheckAllButton("clients", 0);
          handleCheckAllButton("plant", 0);
          handleCheckAllButton("zone", 0);
        }
        if (field === "clients") {
          handleCheckAllButton("plant", 0);
          handleCheckAllButton("zone", 0);
        }
        if (field === "brands") {
          handleCheckAllButton("models", 0);
          Object.keys(res.notDefined.models).forEach(
            (key) => (res.notDefined.models[key] = true)
          );
        }
        handleCheckAllButton(field, 0);

        if (!ndField) {
          // Adding to the filters
          return { ...res, [field]: [...filters[field]] };
        } else {
          // Adding to the filters and setting notDefined to true
          return {
            ...res,
            [field]: [...filters[field]],
            notDefined: { ...res.notDefined, [field]: true },
          };
        }
      });
    }
  };

  const checkUncheckAllButton = (field, ndField) => {
    if (
      !field ||
      Object.keys(filters).filter((key) => key === field).length === 0 ||
      Object.keys(filters).filter((key) => key === field).length === 0
    ) {
      return <></>;
    }

    return (
      <Button
        className={styles.secondaryButton}
        style={{ marginRight: 10 }}
        onClick={(e) => {
          e.stopPropagation();
          checkUncheckedAll(field, ndField);
        }}
        startIcon={<LibraryAddCheckOutlined />}
      >
        {usedFilters[field]?.length === filters[field]?.length &&
        (usedFilters.notDefined[field] === undefined ||
          usedFilters.notDefined[field])
          ? i18n.t("dashboard.alertDialog.uncheckAll")
          : i18n.t("dashboard.alertDialog.checkAll")}
      </Button>
    );
  };

  return (
    <>
      {/* @ts-ignore */}
      <AlertDialog
        maxWidth={"md"}
        ref={dialogRef}
        title={i18n.t("dashboard.alertDialog.filters")}
        loading={loading}
        text={
          // @ts-ignore
          <div style={{ height: "500px", width: "550px" }}>
            <List>
              {!plantId ? (
                isDSC || isAdmin ? (
                  <>
                    <ListItem
                      button
                      onClick={() => handleGeneralDropdown("partners")}
                    >
                      <ListItemText
                        primary={i18n.t(
                          "dashboard.alertDialog.sections.partner"
                        )}
                      />
                      {checkUncheckAllButton("partners")}
                      {open === "partners" ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open === "partners"} timeout="auto">
                      {auxOpen === "partners" && (
                        <List component="div" disablePadding>
                          {filters.partners.map((pa) => (
                            <ListDropDown
                              key={`partner_${pa.id}`}
                              id={pa.id}
                              name={pa.denominacion}
                              type={"partners"}
                              open={openPartner === pa.id}
                              onClick={() =>
                                handleDropdown(pa.id, setOpenPartner)
                              }
                              onCheckBoxClick={handleChange}
                              colection={usedFilters.partners}
                              text={pa.denominacion}
                              extraIcon={<PeopleOutline />}
                              className={styles.checkBoxForm}
                            >
                              {pa.clientes.map((c) => (
                                <ListDropDown
                                  key={`partner_${pa.id}-client_${c.id}`}
                                  id={c.id}
                                  name={c.denominacion}
                                  type={"clients"}
                                  open={openClient === c.id}
                                  onClick={() =>
                                    handleDropdown(c.id, setOpenClient)
                                  }
                                  onCheckBoxClick={handleChange}
                                  colection={
                                    usedFilters.partners.filter(
                                      (par) => par.id === pa.id
                                    )[0]?.clientes
                                  }
                                  text={c.denominacion}
                                  extraIcon={<Group />}
                                  className={styles.checkBoxForm}
                                  style={{ paddingLeft: 50 }}
                                >
                                  {c.plantas.map((p) => (
                                    <ListDropDown
                                      key={`partner_${pa.id}-client_${c.id}-plant_${p.id}`}
                                      id={p.id}
                                      name={p.denominacion}
                                      type={"plant"}
                                      open={openPlant === p.id}
                                      onClick={() =>
                                        handleDropdown(p.id, setOpenPlant)
                                      }
                                      onCheckBoxClick={handleChange}
                                      colection={
                                        usedFilters.partners
                                          .filter((par) => par.id === pa.id)[0]
                                          ?.clientes.filter(
                                            (cl) => cl.id === c.id
                                          )[0]?.plantas
                                      }
                                      text={p.denominacion}
                                      extraIcon={<Business />}
                                      className={styles.checkBoxForm}
                                      style={{ paddingLeft: 100 }}
                                    >
                                      {p.zonas.map((z) => (
                                        <ListItem
                                          style={{ paddingLeft: 150 }}
                                          key={`partner_${pa.id}-client_${c.id}-plant_${p.id}-zone_${z.id}`}
                                        >
                                          <ListItemIcon>
                                            <Checkbox
                                              className={styles.checkBoxForm}
                                              color="default"
                                              edge="start"
                                              name={z.id.toString()}
                                              onClick={() => {
                                                handleChange(
                                                  "zone",
                                                  z.id,
                                                  z.descripcion
                                                );
                                              }}
                                              checked={
                                                (usedFilters.partners.filter(
                                                  (par) => par.id === pa.id
                                                ).length !== 0
                                                  ? usedFilters.partners
                                                      .filter(
                                                        (par) =>
                                                          par.id === pa.id
                                                      )[0]
                                                      ?.clientes.filter(
                                                        (cl) => cl.id === c.id
                                                      ).length !== 0
                                                    ? usedFilters.partners
                                                        .filter(
                                                          (par) =>
                                                            par.id === pa.id
                                                        )[0]
                                                        ?.clientes.filter(
                                                          (cl) => cl.id === c.id
                                                        )[0]
                                                        ?.plantas.filter(
                                                          (pl) => pl.id === p.id
                                                        ).length !== 0
                                                      ? usedFilters.partners
                                                          .filter(
                                                            (par) =>
                                                              par.id === pa.id
                                                          )[0]
                                                          ?.clientes.filter(
                                                            (cl) =>
                                                              cl.id === c.id
                                                          )[0]
                                                          ?.plantas.filter(
                                                            (pl) =>
                                                              pl.id === p.id
                                                          )[0]
                                                          ?.zonas.filter(
                                                            (zo) =>
                                                              zo.id === z.id
                                                          ).length !== 0
                                                      : false
                                                    : false
                                                  : false) || false
                                              }
                                            />
                                          </ListItemIcon>
                                          <ListItemIcon>
                                            <Room />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={z.descripcion}
                                          />
                                        </ListItem>
                                      ))}
                                    </ListDropDown>
                                  ))}
                                </ListDropDown>
                              ))}
                            </ListDropDown>
                          ))}
                        </List>
                      )}
                    </Collapse>
                  </>
                ) : (
                  <>
                    <ListItem
                      button
                      onClick={() => handleGeneralDropdown("clients")}
                    >
                      <ListItemText
                        primary={i18n.t(
                          "dashboard.alertDialog.sections.client"
                        )}
                      />
                      {checkUncheckAllButton("clients")}
                      {open === "clients" ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open === "clients"} timeout="auto">
                      {open === "clients" && (
                        <List component="div" disablePadding>
                          {filters.clients.map((c) => (
                            <ListDropDown
                              key={`client_${c.id}`}
                              id={c.id}
                              name={c.denominacion}
                              type={"clients"}
                              open={openClient === c.id}
                              onClick={() =>
                                handleDropdown(c.id, setOpenClient)
                              }
                              onCheckBoxClick={handleChange}
                              colection={usedFilters.clients}
                              text={c.denominacion}
                              extraIcon={<Group />}
                              className={styles.checkBoxForm}
                            >
                              {c.plantas.map((p) => (
                                <ListDropDown
                                  key={`client_${c.id}-plant_${p.id}`}
                                  id={p.id}
                                  name={p.denominacion}
                                  type={"plant"}
                                  open={openPlant === p.id}
                                  onClick={() =>
                                    handleDropdown(p.id, setOpenPlant)
                                  }
                                  onCheckBoxClick={handleChange}
                                  colection={
                                    usedFilters.clients.filter(
                                      (cl) => cl.id === c.id
                                    )[0]?.plantas
                                  }
                                  text={p.denominacion}
                                  className={styles.checkBoxForm}
                                  extraIcon={<Business />}
                                  style={{ paddingLeft: 50 }}
                                >
                                  {p.zonas.map((z) => (
                                    <ListItem
                                      style={{ paddingLeft: 100 }}
                                      key={`client_${c.id}-plant_${p.id}-zone_${z.id}`}
                                    >
                                      <ListItemIcon>
                                        <Checkbox
                                          className={styles.checkBoxForm}
                                          color="default"
                                          edge="start"
                                          name={z.id.toString()}
                                          onClick={() => {
                                            handleChange(
                                              "zone",
                                              z.id,
                                              z.descripcion
                                            );
                                          }}
                                          checked={
                                            (usedFilters.clients.filter(
                                              (cl) => cl.id === c.id
                                            ).length !== 0
                                              ? usedFilters.clients
                                                  .filter(
                                                    (cl) => cl.id === c.id
                                                  )[0]
                                                  ?.plantas.filter(
                                                    (pl) => pl.id === p.id
                                                  ).length !== 0
                                                ? usedFilters.clients
                                                    .filter(
                                                      (cl) => cl.id === c.id
                                                    )[0]
                                                    ?.plantas.filter(
                                                      (pl) => pl.id === p.id
                                                    )[0]
                                                    ?.zonas.filter(
                                                      (zo) => zo.id === z.id
                                                    ).length !== 0
                                                : false
                                              : false) || false
                                          }
                                        />
                                      </ListItemIcon>
                                      <ListItemIcon>
                                        <Room />
                                      </ListItemIcon>
                                      <ListItemText primary={z.descripcion} />
                                    </ListItem>
                                  ))}
                                </ListDropDown>
                              ))}
                            </ListDropDown>
                          ))}
                        </List>
                      )}
                    </Collapse>
                  </>
                )
              ) : (
                <>
                  <ListItem
                    button
                    onClick={() => handleGeneralDropdown("plant")}
                  >
                    <ListItemText
                      primary={i18n.t("dashboard.alertDialog.sections.plant")}
                    />
                    {checkUncheckAllButton("plants")}
                    {open === "plant" ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={open === "plant"} timeout="auto">
                    {open === "plant" && (
                      <List component="div" disablePadding>
                        {filters?.plants?.map((p) => (
                          <ListDropDown
                            key={`plant_${p.id}`}
                            id={p.id}
                            name={p.denominacion}
                            type={"plant"}
                            open={openPlant === p.id}
                            onClick={() => handleDropdown(p.id, setOpenPlant)}
                            onCheckBoxClick={handleChange}
                            colection={usedFilters.plants}
                            text={p.denominacion}
                            className={styles.checkBoxForm}
                          >
                            {p.zonas.map((m) => (
                              <ListItem
                                style={{ paddingLeft: 50 }}
                                key={`plant_${p.id}-zone_${m.id}`}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    className={styles.checkBoxForm}
                                    color="default"
                                    edge="start"
                                    name={m.id.toString()}
                                    onClick={() => {
                                      handleChange("zone", m.id, m.descripcion);
                                    }}
                                    checked={
                                      (usedFilters.plants.filter(
                                        (pl) => pl.id === p.id
                                      ).length !== 0
                                        ? usedFilters.plants
                                            .filter((pl) => pl.id === p.id)[0]
                                            ?.zonas.filter(
                                              (zo) => zo.id === m.id
                                            ).length !== 0
                                        : false) || false
                                    }
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ListItemText primary={m.descripcion} />
                              </ListItem>
                            ))}
                          </ListDropDown>
                        ))}
                      </List>
                    )}
                  </Collapse>
                </>
              )}
              <ListItem button onClick={() => handleGeneralDropdown("brands")}>
                <ListItemText
                  primary={i18n.t("dashboard.alertDialog.sections.brand")}
                />
                {checkUncheckAllButton("brands", true)}
                {open === "brands" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open === "brands"} timeout="auto">
                {auxOpen === "brands" && (
                  <List component="div" disablePadding>
                    <ListItem
                      style={{ paddingLeft: 25 }}
                      key={"notDefined_brands"}
                    >
                      <ListItemIcon>
                        <Checkbox
                          className={styles.checkBoxForm}
                          color="default"
                          edge="start"
                          checked={usedFilters?.notDefined?.brands || false}
                          onClick={() => {
                            setUsedFilters({
                              ...usedFilters,
                              notDefined: {
                                ...usedFilters.notDefined,
                                brands: !usedFilters.notDefined.brands,
                              },
                            });
                            if (usedFilters?.notDefined?.brands) {
                              addToFilterButton("brands");
                            } else {
                              removeFromFilterButton("brands");
                            }
                          }}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.t("dashboard.alertDialog.na")}
                      />
                    </ListItem>
                    {filters.brands.map((b) => (
                      <ListDropDown
                        key={`brand_${b.id}`}
                        id={b.id}
                        name={b.name}
                        type="brands"
                        open={openBrand === b.id}
                        onClick={() => handleDropdown(b.id, setOpenBrand)}
                        onCheckBoxClick={handleChange}
                        colection={usedFilters.brands}
                        text={b.name}
                        className={styles.checkBoxForm}
                      >
                        <ListItem
                          style={{ paddingLeft: 50 }}
                          key={"notDefined_models_" + b.name}
                        >
                          <ListItemIcon>
                            <Checkbox
                              className={styles.checkBoxForm}
                              color="default"
                              edge="start"
                              checked={
                                usedFilters?.notDefined?.models[b.name] || false
                              }
                              onClick={() =>
                                handleChange("nd_model", b.id, b.name)
                              }
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={i18n.t("dashboard.alertDialog.na")}
                          />
                        </ListItem>
                        {b.models.map((m) => (
                          <ListItem
                            style={{ paddingLeft: 50 }}
                            key={`model_${m.id}`}
                          >
                            <ListItemIcon>
                              <Checkbox
                                className={styles.checkBoxForm}
                                color="default"
                                edge="start"
                                checked={
                                  (usedFilters.brands.filter(
                                    (br) => br.id === b.id
                                  ).length !== 0
                                    ? usedFilters.brands
                                        .filter((br) => br.id === b.id)[0]
                                        ?.models.filter((mo) => mo.id === m.id)
                                        .length !== 0
                                    : false) || false
                                }
                                onClick={() => {
                                  handleChange("models", m.id, m.name);
                                }}
                                disableRipple
                              />
                            </ListItemIcon>
                            <ListItemText primary={m.name} />
                          </ListItem>
                        ))}
                      </ListDropDown>
                    ))}
                  </List>
                )}
              </Collapse>
              <ListItem
                button
                onClick={() => handleGeneralDropdown("yearOfManufacture")}
              >
                <ListItemText
                  primary={i18n.t("dashboard.alertDialog.sections.year")}
                />
                {checkUncheckAllButton("yearOfManufacture", true)}
                {open === "yearOfManufacture" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open === "yearOfManufacture"} timeout="auto">
                {auxOpen === "yearOfManufacture" &&
                  (filters.yearOfManufacture.length > 0 ? (
                    <List component="div" disablePadding>
                      <ListItem
                        style={{ paddingLeft: 25 }}
                        key={"notDefined_yearOfManufacture"}
                      >
                        <ListItemIcon>
                          <Checkbox
                            className={styles.checkBoxForm}
                            color="default"
                            edge="start"
                            checked={
                              usedFilters?.notDefined?.yearOfManufacture ||
                              false
                            }
                            onClick={() => {
                              setUsedFilters({
                                ...usedFilters,
                                notDefined: {
                                  ...usedFilters.notDefined,
                                  yearOfManufacture:
                                    !usedFilters.notDefined.yearOfManufacture,
                                },
                              });
                              if (usedFilters?.notDefined?.yearOfManufacture) {
                                addToFilterButton("yearOfManufacture");
                              } else {
                                removeFromFilterButton("yearOfManufacture");
                              }
                            }}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={i18n.t("dashboard.alertDialog.na")}
                        />
                      </ListItem>
                      {filters.yearOfManufacture.map((p) => (
                        <ListItem style={{ paddingLeft: 25 }} key={`year_${p}`}>
                          <ListItemIcon>
                            <Checkbox
                              className={styles.checkBoxForm}
                              color="default"
                              edge="start"
                              checked={
                                usedFilters?.yearOfManufacture?.includes(p) ||
                                false
                              }
                              onClick={() =>
                                handleChange("yearOfManufacture", p)
                              }
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={p} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <div style={{ paddingLeft: 50 }}>
                      <b>{i18n.t("dashboard.alertDialog.sections.ndYear")}</b>
                    </div>
                  ))}
              </Collapse>
              <ListItem button onClick={() => handleGeneralDropdown("sizeKw")}>
                <ListItemText
                  primary={i18n.t("dashboard.alertDialog.sections.sizekw")}
                />
                {checkUncheckAllButton("sizeKw", true)}
                {open === "sizeKw" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open === "sizeKw"} timeout="auto">
                {auxOpen === "sizeKw" &&
                  (filters.sizeKw.length > 0 ? (
                    <List component="div" disablePadding>
                      <ListItem
                        style={{ paddingLeft: 25 }}
                        key={"notDefined_sizeKw"}
                      >
                        <ListItemIcon>
                          <Checkbox
                            className={styles.checkBoxForm}
                            color="default"
                            checked={usedFilters?.notDefined?.sizeKw || false}
                            onClick={() => {
                              setUsedFilters({
                                ...usedFilters,
                                notDefined: {
                                  ...usedFilters.notDefined,
                                  sizeKw: !usedFilters.notDefined.sizeKw,
                                },
                              });
                              if (usedFilters?.notDefined?.sizeKw) {
                                addToFilterButton("sizeKw");
                              } else {
                                removeFromFilterButton("sizeKw");
                              }
                            }}
                            edge="start"
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={i18n.t("dashboard.alertDialog.na")}
                        />
                      </ListItem>
                      {filters.sizeKw.map((p) => (
                        <ListItem
                          style={{ paddingLeft: 25 }}
                          key={`sizeKw_${p}`}
                        >
                          <ListItemIcon>
                            <Checkbox
                              className={styles.checkBoxForm}
                              color="default"
                              checked={
                                usedFilters?.sizeKw?.includes(p) || false
                              }
                              onClick={() => handleChange("sizeKw", p)}
                              edge="start"
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={p} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <div style={{ paddingLeft: 50 }}>
                      <b>{i18n.t("dashboard.alertDialog.sections.ndSizekw")}</b>
                    </div>
                  ))}
              </Collapse>
              <ListItem button onClick={() => handleGeneralDropdown("sizeA")}>
                <ListItemText
                  primary={i18n.t("dashboard.alertDialog.sections.sizea")}
                />
                {checkUncheckAllButton("sizeA", true)}
                {open === "sizeA" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open === "sizeA"} timeout="auto">
                {auxOpen === "sizeA" &&
                  (filters.sizeA.length > 0 ? (
                    <List component="div" disablePadding>
                      <ListItem
                        style={{ paddingLeft: 25 }}
                        key={"notDefined_sizeA"}
                      >
                        <ListItemIcon>
                          <Checkbox
                            className={styles.checkBoxForm}
                            color="default"
                            edge="start"
                            checked={usedFilters?.notDefined?.sizeA || false}
                            onChange={() => {
                              setUsedFilters({
                                ...usedFilters,
                                notDefined: {
                                  ...usedFilters.notDefined,
                                  sizeA: !usedFilters.notDefined.sizeA,
                                },
                              });
                              if (usedFilters?.notDefined?.sizeA) {
                                addToFilterButton("sizeA");
                              } else {
                                removeFromFilterButton("sizeA");
                              }
                            }}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={i18n.t("dashboard.alertDialog.na")}
                        />
                      </ListItem>
                      {filters.sizeA.map((p) => (
                        <ListItem
                          style={{ paddingLeft: 25 }}
                          key={`sizeA_${p}`}
                        >
                          <ListItemIcon>
                            <Checkbox
                              className={styles.checkBoxForm}
                              color="default"
                              edge="start"
                              checked={usedFilters?.sizeA?.includes(p) || false}
                              onChange={() => handleChange("sizeA", p)}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={p} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <div style={{ paddingLeft: 50 }}>
                      <b>{i18n.t("dashboard.alertDialog.sections.ndSizea")}</b>
                    </div>
                  ))}
              </Collapse>
              <ListItem
                button
                onClick={() => handleGeneralDropdown("ipRating")}
              >
                <ListItemText
                  primary={i18n.t("dashboard.alertDialog.sections.ipRating")}
                />
                {checkUncheckAllButton("ipRating", true)}
                {open === "ipRating" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open === "ipRating"} timeout="auto">
                {auxOpen === "ipRating" &&
                  (filters.ipRating.length > 0 ? (
                    <List component="div" disablePadding>
                      <ListItem
                        style={{ paddingLeft: 25 }}
                        key={"notDefined_ip"}
                      >
                        <ListItemIcon>
                          <Checkbox
                            className={styles.checkBoxForm}
                            color="default"
                            edge="start"
                            checked={usedFilters?.notDefined?.ipRating || false}
                            onChange={(e) => {
                              setUsedFilters({
                                ...usedFilters,
                                notDefined: {
                                  ...usedFilters.notDefined,
                                  ipRating: !usedFilters.notDefined.ipRating,
                                },
                              });
                              if (usedFilters?.notDefined?.ipRating) {
                                addToFilterButton("ipRating");
                              } else {
                                removeFromFilterButton("ipRating");
                              }
                            }}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={i18n.t("dashboard.alertDialog.na")}
                        />
                      </ListItem>
                      {filters.ipRating.map((p) => (
                        <ListItem style={{ paddingLeft: 25 }} key={`ip_${p}`}>
                          <ListItemIcon>
                            <Checkbox
                              className={styles.checkBoxForm}
                              color="default"
                              edge="start"
                              checked={
                                usedFilters?.ipRating?.includes(p) || false
                              }
                              onChange={() => handleChange("ipRating", p)}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={p} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <div style={{ paddingLeft: 50 }}>
                      <b>
                        {i18n.t("dashboard.alertDialog.sections.ndIpRating")}
                      </b>
                    </div>
                  ))}
              </Collapse>
              <ListItem
                button
                onClick={() => handleGeneralDropdown("frameSize")}
              >
                <ListItemText
                  primary={i18n.t("dashboard.alertDialog.sections.frame")}
                />
                {checkUncheckAllButton("frameSize", true)}
                {open === "frameSize" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open === "frameSize"} timeout="auto">
                {auxOpen === "frameSize" &&
                  (filters.frameSize.length > 0 ? (
                    <List component="div" disablePadding>
                      <ListItem
                        style={{ paddingLeft: 25 }}
                        key={"notDefined_frame"}
                      >
                        <ListItemIcon>
                          <Checkbox
                            className={styles.checkBoxForm}
                            color="default"
                            edge="start"
                            checked={
                              usedFilters?.notDefined?.frameSize || false
                            }
                            onChange={() => {
                              setUsedFilters({
                                ...usedFilters,
                                notDefined: {
                                  ...usedFilters.notDefined,
                                  frameSize: !usedFilters.notDefined.frameSize,
                                },
                              });
                              if (usedFilters?.notDefined?.frameSize) {
                                addToFilterButton("frameSize");
                              } else {
                                removeFromFilterButton("frameSize");
                              }
                            }}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={i18n.t("dashboard.alertDialog.na")}
                        />
                      </ListItem>
                      {filters.frameSize.map((p) => (
                        <ListItem
                          style={{ paddingLeft: 25 }}
                          key={`frame_${p}`}
                        >
                          <ListItemIcon>
                            <Checkbox
                              className={styles.checkBoxForm}
                              color="default"
                              edge="start"
                              checked={
                                usedFilters?.frameSize?.includes(p) || false
                              }
                              onChange={() => handleChange("frameSize", p)}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText primary={p} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <div style={{ paddingLeft: 50 }}>
                      <b>{i18n.t("dashboard.alertDialog.sections.ndFrame")}</b>
                    </div>
                  ))}
              </Collapse>
              <ListItem
                button
                onClick={() => handleGeneralDropdown("lifeCycleStatus")}
              >
                <ListItemText
                  primary={i18n.t("dashboard.alertDialog.sections.lifecycle")}
                />
                {open === "lifeCycleStatus" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open === "lifeCycleStatus"} timeout="auto">
                {auxOpen === "lifeCycleStatus" &&
                  (filters.lifeCycleStatus.length > 1 ? (
                    <List component="div" disablePadding>
                      {filters.lifeCycleStatus.map((p) => (
                        <ListItem
                          style={{ paddingLeft: 25 }}
                          key={`lifeCycle_${p}`}
                        >
                          <ListItemIcon>
                            <Checkbox
                              className={styles.checkBoxForm}
                              color="default"
                              edge="start"
                              disableRipple
                              checked={
                                usedFilters?.lifeCycleStatus?.includes(p) ||
                                false
                              }
                              onChange={() => handleChange("lcs", p)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              p === 0
                                ? i18n.t("common.activo.singularM")
                                : p === 1
                                ? i18n.t("common.limitado")
                                : p === 2
                                ? i18n.t("common.inactivo")
                                : i18n.t("common.indefinido")
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <div style={{ paddingLeft: 50 }}>
                      <b>
                        {i18n.t("dashboard.alertDialog.sections.sameLifecycle")}
                      </b>
                    </div>
                  ))}
              </Collapse>
              <ListItem
                button
                onClick={() => handleGeneralDropdown("condition")}
              >
                <ListItemText
                  primary={i18n.t("dashboard.alertDialog.sections.condition")}
                />
                {open === "condition" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open === "condition"} timeout="auto">
                {auxOpen === "condition" &&
                  (filters.condition.length > 1 ? (
                    <List component="div" disablePadding>
                      {filters.condition.map((p) => (
                        <ListItem
                          style={{ paddingLeft: 25 }}
                          key={`condition_${p}`}
                        >
                          <ListItemIcon>
                            <Checkbox
                              className={styles.checkBoxForm}
                              color="default"
                              edge="start"
                              disableRipple
                              checked={
                                usedFilters?.condition?.includes(p) || false
                              }
                              onChange={() => handleChange("condition", p)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              p === 0
                                ? i18n.t("common.bueno")
                                : p === 1
                                ? i18n.t("common.medio")
                                : p === 2
                                ? i18n.t("common.pobre")
                                : i18n.t("common.indefinido")
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <div style={{ paddingLeft: 50 }}>
                      <b>
                        {i18n.t("dashboard.alertDialog.sections.sameCondition")}
                      </b>
                    </div>
                  ))}
              </Collapse>
              <ListItem
                button
                onClick={() => handleGeneralDropdown("criticality")}
              >
                <ListItemText
                  primary={i18n.t("dashboard.alertDialog.sections.criticality")}
                />
                {open === "criticality" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open === "criticality"} timeout="auto">
                {auxOpen === "criticality" &&
                  (filters.criticality.length > 1 ? (
                    <List component="div" disablePadding>
                      {filters.criticality.map((p) => (
                        <ListItem
                          style={{ paddingLeft: 25 }}
                          key={`criticality_${p}`}
                        >
                          <ListItemIcon>
                            <Checkbox
                              className={styles.checkBoxForm}
                              color="default"
                              edge="start"
                              disableRipple
                              checked={
                                usedFilters?.criticality?.includes(p) || false
                              }
                              onChange={() => handleChange("criticality", p)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              p === 0
                                ? i18n.t("common.alto")
                                : p === 1
                                ? i18n.t("common.medio")
                                : p === 2
                                ? i18n.t("common.bajo")
                                : i18n.t("common.indefinido")
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <div style={{ paddingLeft: 50 }}>
                      <b>
                        {i18n.t(
                          "dashboard.alertDialog.sections.sameCriticality"
                        )}
                      </b>
                    </div>
                  ))}
              </Collapse>
              <ListItem button onClick={() => handleGeneralDropdown("risk")}>
                <ListItemText
                  primary={i18n.t("dashboard.alertDialog.sections.risk")}
                />
                {open === "risk" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open === "risk"} timeout="auto">
                {auxOpen === "risk" &&
                  (filters.risk.length > 1 ? (
                    <List component="div" disablePadding>
                      {filters.risk.map((p) => (
                        <ListItem style={{ paddingLeft: 25 }} key={`risk_${p}`}>
                          <ListItemIcon>
                            <Checkbox
                              className={styles.checkBoxForm}
                              color="default"
                              edge="start"
                              disableRipple
                              checked={usedFilters?.risk?.includes(p) || false}
                              onChange={() => handleChange("risk", p)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              p === 0
                                ? i18n.t("common.alto")
                                : p === 1
                                ? i18n.t("common.medio")
                                : p === 2
                                ? i18n.t("common.bajo")
                                : i18n.t("common.indefinido")
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <div style={{ paddingLeft: 50 }}>
                      <b>{i18n.t("dashboard.alertDialog.sections.sameRisk")}</b>
                    </div>
                  ))}
              </Collapse>
              <ListItem button onClick={() => handleGeneralDropdown("status")}>
                <ListItemText
                  primary={i18n.t("dashboard.alertDialog.sections.status")}
                />
                {open === "status" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open === "status"} timeout="auto">
                {auxOpen === "status" && (
                  <List component="div" disablePadding>
                    {filters.status.map((p) => (
                      <ListItem style={{ paddingLeft: 25 }} key={`status_${p}`}>
                        <ListItemIcon>
                          <Checkbox
                            className={styles.checkBoxForm}
                            color="default"
                            edge="start"
                            disableRipple
                            checked={usedFilters?.status?.includes(p) || false}
                            onChange={() => handleChange("status", p)}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            p === 0
                              ? i18n.t("common.driveStatus.inOperation")
                              : p === 1
                              ? i18n.t("common.driveStatus.notInOperation")
                              : i18n.t("common.driveStatus.dispossed")
                          }
                        />
                      </ListItem>
                    ))}
                    <ListItem style={{ paddingLeft: 25 }} key={"warehouse"}>
                      <ListItemIcon>
                        <Checkbox
                          className={styles.checkBoxForm}
                          color="default"
                          edge="start"
                          name={"warehouse"}
                          disableRipple
                          checked={usedFilters?.warehouse || false}
                          onClick={() =>
                            setUsedFilters((old) => ({
                              ...old,
                              warehouse: !old.warehouse,
                            }))
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={i18n.t(
                          "dashboard.alertDialog.sections.statuses.warehouse"
                        )}
                      />
                    </ListItem>
                  </List>
                )}
              </Collapse>
            </List>
          </div>
        }
        cancelText={i18n.t("dashboard.alertDialog.cancelText")}
        confirmText={i18n.t("dashboard.alertDialog.confirmText")}
        confirmAction={() => applyFilter()}
        cancelAction={() => cancelFilters()}
        bottomLeft={
          <Button
            className={styles.secondaryButton}
            onClick={() => {
              setUsedFilters(originalFilters);
              setFilterButtons && setFilterButtons([]);
            }}
            variant="outlined"
            style={{ margin: 0 }}
            startIcon={<DisableFilter color={globalColors.accentColor} />}
          >
            {i18n.t("common.clear")}
          </Button>
        }
        redTitle={plantId ? true : false}
      />
    </>
  );
}

function ListDropDown(props) {
  const {
    id,
    name,
    type,
    open,
    onClick,
    onCheckBoxClick,
    colection,
    text,
    extraIcon,
    className,
    style,
    children,
  } = props;

  const [auxOpen, setAuxOpen] = useState(undefined);

  // Si no se hace la animacion de cerrar no funciona bien (Gracias Material)
  useEffect(() => {
    let time = 0;
    if (!open) {
      time = 500;
    }
    const setOptionsDelay = setTimeout(() => setAuxOpen(open), time);
    return () => clearTimeout(setOptionsDelay);
  }, [open]);

  return (
    <div>
      <ListItem
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        button
        style={style ? style : { paddingLeft: 25 }}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={
              colection?.filter((t) => t.id === id).length > 0 ? true : false
            }
            disableRipple
            className={className}
            color="default"
            onClick={(event) => {
              event.stopPropagation();
              onCheckBoxClick(type, id, name);
            }}
          />
        </ListItemIcon>
        {extraIcon && <ListItemIcon>{extraIcon}</ListItemIcon>}
        <ListItemText primary={text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding>
          {auxOpen && children}
        </List>
      </Collapse>
    </div>
  );
}
