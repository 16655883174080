import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import i18n from "../../../locales/i18n";
import { UsersAPI, PlantasAPI, PartnersAPI } from "../../../services/API";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import {
  TextField,
  Card,
  CardHeader,
  Avatar,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Paper,
  Tabs,
  Tab,
} from "@material-ui/core";
import {
  NotInterested,
  Check,
  Save,
  Cancel,
  Business,
  AccountTree,
  Person,
} from "@material-ui/icons";
import AuthService from "../../../services/AuthService";
import AlertDialog from "../../../components/AlertDialog";
import AppContext from "../../../contexts/AppContext";
import { Common_Style, DetailTabs_Style } from "../../../assets/css/Styles";
import { Roles, SnackbarSuccess, SnackbarError } from "../../../helpers/Common";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TabZoneAccess from "../../organism/tabUserZoneAccess/UserZoneAccess";
import UserPlantaAccess from "../../organism/tabUserPlantaAccess/UserPlantaAccess";

class UserInfo {
  /**
   * @param {String} partnerIdWithAccess - the starting coordinate
   * @param {String} role - An array of point coordinates
   */
  constructor(partnerIdWithAccess, role) {
    this.partnerIdWithAccess = partnerIdWithAccess;
    this.role = role;
  }
}

/**
 * Detalle del usuario
 * @Component
 * @param {Object} props - parametros del componente
 * @param {Object} props.match
 * @param {Object} props.history
 * @param {boolean} props.keyuser
 */
export default function UserDetails(props) {
  const currentUser = AuthService.getUserInfo();
  const currentUserHasRoleEndCustomer = AuthService.hasRoleEndCustomer();
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  //const { match, keyuser, history } = props;
  const { match } = props;
  const { id } = match.params;
  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(false);
  const confirmDialog = useRef(null);
  /**
   * @type {[UserInfo, React.Dispatch<UserInfo>]} state
   */
  const [userInfo, setUserInfo] = useState(new UserInfo(null, null));
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [region, setRegion] = useState("");
  const [pais, setPais] = useState("");
  const [email, setEmail] = useState("");
  const [dipId, seDipId] = useState("");
  const [inactivo, setInactivo] = useState(false);
  const [sinPartner, setSinPartner] = useState(false);
  const [role, setRole] = useState("");
  const [thisUserAsReadOnly, setThisUserAsReadOnly] = useState(false);
  const [isRegionManager, setIsRegionManager] = useState(false);
  const [isGlobal, setIsGlobal] = useState(false);
  const [errors, setErrors] = useState({});

  const [partnerIdWithAccess, setPartnerIdWithAccess] = useState("");
  const [rolesOptions, setRolesOptions] = useState([]);
  const [partnersOptions, setPartnersOptions] = useState([]);

  const [plantasOptions, setPlantasOptions] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState(undefined);
  const commonStyles = Common_Style();
  const styles = DetailTabs_Style();
  const [actualUserInfo] = useState(AuthService.getUserInfo());

  const [endcustomerTab, setEndcustomerTab] = useState(0);

  const getUser = useCallback(() => {
    setLoading(true);
    return UsersAPI.get(id)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          window.location.replace("/unauthorized");
          throw new Error(error);
        }
      })
      .then(
        ({
          user,
          readOnly,
          partner,
          inactivo,
          sinPartner,
          role,
          partnerIdWithAccess,
        }) => {
          setEmail(user.email);
          seDipId(user.dipId);
          setNombre(user.nombre);
          setApellidos(user.apellidos);
          setRegion(user.country.region.regionCode);
          setPais(user.country.name);
          setThisUserAsReadOnly(user.readOnly);
          setIsRegionManager(user.isRegionManager);
          setIsGlobal(user.isGlobal);
          setInactivo(inactivo);
          setSinPartner(sinPartner);
          var notEditable =
            readOnly ||
            (actualUserInfo.roles.includes(Roles.Partner) &&
              partner !== AuthService.getPartnerId()) ||
            inactivo;
          setReadOnly(notEditable);
          setPartnerIdWithAccess(
            partnerIdWithAccess.length > 0 ? partnerIdWithAccess[0] : ""
          );
          setRole(role);
          setUserInfo({
            partnerIdWithAccess: partnerIdWithAccess,
            role: role,
          });
        }
      )
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [actualUserInfo.roles, id]);

  const getRolesOptions = () => {
    UsersAPI.getRoles()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setRolesOptions(data);
      })
      .catch(() => {});
  };

  const getPartnerOptions = () => {
    PartnersAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setPartnersOptions(data);
      })
      .catch(() => {});
  };

  const getPlantsOptions = () => {
    PlantasAPI.getPlantasForSelector()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setPlantasOptions(data);
      })
      .catch((e) => {
        console.error(e.message);
      });
  };

  const cambioEstadoUsuario = () => {
    setLoading(true);
    confirmDialog.current.open();
  };

  const SaveRoles = () => {
    const ChangeRoleRequest = {
      user: id,
      role: role,
      partner: partnerIdWithAccess,
      plant: selectedPlant?.id,
      readOnly: thisUserAsReadOnly,
      isRegionManager: isRegionManager,
      isGlobal: isGlobal,
    };

    if (
      (role === Roles.EndCustomer && selectedPlant?.id) ||
      (role === Roles.Partner && partnerIdWithAccess) ||
      role === Roles.DSC ||
      role === Roles.Admin
    ) {
      UsersAPI.changeRole(ChangeRoleRequest)
        .then((response) => {
          if (response.ok) {
            setUserInfo({
              partnerIdWithAccess: partnerIdWithAccess,
              role: role,
            });
            setSelectedPlant(undefined);
            SnackbarSuccess(
              setGlobal,
              i18n.t("gestorUsuarios.snackbar.update.success")
            );
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch((e) => {
          console.error(e.message);
          SnackbarError(
            setGlobal,
            i18n.t("gestorUsuarios.snackbar.update.error")
          );
        });
    } else if (role === Roles.EndCustomer) {
      setErrors((e) => ({ ...errors, planta: "error" }));
    } else if (role === Roles.Partner) {
      setErrors((e) => ({ ...errors, partner: "error" }));
    }
  };

  const restartRoles = () => {
    getUser();
  };

  const handleChangeTabEndCustomer = (event, newValue) => {
    setEndcustomerTab(newValue);
  };

  useEffect(() => {
    setGlobal((prev) => ({
      ...prev,
      pageTitle: i18n.t("gestorUsuarios.titulo"),
    }));
    document.getElementById("main-content").scrollTop = 0;
  }, [setGlobal]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getUser(),
      getPlantsOptions(),
      getRolesOptions(),
      getPartnerOptions(),
    ]).then(() => {
      setLoading(false);
    });
  }, [getUser]);

  return (
    <>
      <RelativeBackdrop hidden={!loading}>
        <div className={commonStyles.mainContainer}>
          {role === Roles.EndCustomer &&
            role === userInfo.role &&
            !inactivo && (
              <Paper className={styles.userPaper} square>
                <Tabs
                  scrollButtons="on"
                  variant="scrollable"
                  indicatorColor="secondary"
                  textColor="inherit"
                  classes={{ indicator: styles.tabsHeader }}
                  value={endcustomerTab}
                  onChange={handleChangeTabEndCustomer}
                >
                  <Tab
                    value={0}
                    label={
                      <span className={styles.detailTabLabel}>
                        {i18n.t("user.user")}
                      </span>
                    }
                    icon={<Person />}
                    id="simple-tab-0"
                    disabled={loading}
                    data-testid="userTab-plant"
                  />
                  <Tab
                    value={1}
                    label={
                      <span className={styles.detailTabLabel}>
                        {i18n.t("user.AccesoPlantas.title")}
                      </span>
                    }
                    icon={<Business />}
                    id="simple-tab-0"
                    disabled={loading}
                    data-testid="userTab-plant"
                  />
                  <Tab
                    value={2}
                    label={
                      <span className={styles.detailTabLabel}>
                        {i18n.t("user.AccesoZonas.title")}
                      </span>
                    }
                    icon={<AccountTree />}
                    id="simple-tab-0"
                    disabled={loading || !Roles.EndCustomer}
                    data-testid="userTab-plant"
                  />
                </Tabs>
              </Paper>
            )}

          <form autoComplete="off">
            {endcustomerTab === 0 && (
              <Card>
                {
                  <div className={styles.mainCardFormHeader}>
                    <CardHeader
                      title={
                        nombre && apellidos
                          ? `${nombre} ${apellidos}`
                          : i18n.t("gestorUsuarios.detallesUsuario.tituloCard")
                      }
                      subheader={email}
                      avatar={
                        <Avatar className={styles.tabHeaderAvatar}>
                          <Person />
                        </Avatar>
                      }
                    />
                  </div>
                }
                <div className={styles.flexRowForm}>
                  <div className={styles.flexCellForm}>
                    <TextField
                      fullWidth
                      className={styles.materialTextField}
                      value={nombre ? nombre : ""}
                      label={i18n.t("user.nombre")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      name="nombre"
                    />
                  </div>
                  <div className={styles.flexCellForm}>
                    <TextField
                      fullWidth
                      className={styles.materialTextField}
                      value={apellidos ? apellidos : ""}
                      label={i18n.t("user.apellidos")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      name="apellidos"
                    />
                  </div>
                </div>
                <div className={styles.flexRowForm}>
                  <div className={styles.flexCellForm}>
                    <TextField
                      fullWidth
                      className={styles.materialTextField}
                      value={region ? region : ""}
                      label={i18n.t("user.region")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      name="region"
                    />
                  </div>
                  <div className={styles.flexCellForm}>
                    <TextField
                      fullWidth
                      className={styles.materialTextField}
                      value={pais ? pais : ""}
                      label={i18n.t("user.pais")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      name="pais"
                    />
                  </div>
                </div>
                <div className={styles.flexRowForm}>
                  <div className={styles.flexCellForm}>
                    <TextField
                      fullWidth
                      className={styles.materialTextField}
                      value={email ? email : ""}
                      label={i18n.t("user.email")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      name="email"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className={styles.flexRowForm}>
                  <div className={styles.flexCellForm}>
                    <TextField
                      fullWidth
                      className={styles.materialTextField}
                      value={dipId ? dipId : ""}
                      label={i18n.t("user.dipId")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      name="dipId"
                      autoComplete="off"
                    />
                  </div>
                </div>
                {currentUser.userid !== id ? (
                  <div className={styles.flexRowForm}>
                    <div className={styles.flexCellForm}>
                      {readOnly ? (
                        <TextField
                          fullWidth
                          className={styles.materialTextField}
                          value={role?.toUpperCase() || ""}
                          label={i18n.t("user.rol")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: true,
                          }}
                          autoComplete="off"
                        />
                      ) : (
                        <TextField
                          fullWidth
                          disabled={
                            readOnly ||
                            (role === Roles.Partner &&
                              currentUser.roles[0] === Roles.Partner)
                          }
                          select
                          label={i18n.t("user.rol")}
                          value={role ? role : ""}
                          className={styles.materialTextField}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          required
                          name="rol"
                          onChange={(e) => setRole(e.target.value)}
                        >
                          {rolesOptions.map((role) => (
                            <MenuItem value={role.name} key={role.name}>
                              {role.name[0].toUpperCase() + role.name.slice(1)}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={styles.flexRowForm}>
                    <div className={styles.flexCellForm}>
                      <TextField
                        fullWidth
                        className={styles.materialTextField}
                        value={
                          role.length > 0
                            ? role[0].toUpperCase() + role.slice(1)
                            : role
                        }
                        label={i18n.t("user.rol")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          readOnly: true,
                        }}
                        name="rol"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                )}
                {role === Roles.DSC && (
                  <div className={styles.flexRowForm}>
                    <div className={styles.flexCellForm}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={
                              isGlobal || isRegionManager
                                ? styles.checkBoxFormDisabled
                                : styles.checkBoxForm
                            }
                            checked={thisUserAsReadOnly}
                            onChange={() =>
                              setThisUserAsReadOnly((old) => !old)
                            }
                            disabled={readOnly || isGlobal || isRegionManager}
                          />
                        }
                        label={i18n.t("user.readOnly")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={
                              isGlobal
                                ? styles.checkBoxFormDisabled
                                : styles.checkBoxForm
                            }
                            checked={isRegionManager}
                            onChange={() => {
                              setIsRegionManager(!isRegionManager);
                              setThisUserAsReadOnly(false);
                            }}
                            disabled={readOnly || isGlobal}
                          />
                        }
                        label={i18n.t("user.regionManager")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={
                              isRegionManager
                                ? styles.checkBoxFormDisabled
                                : styles.checkBoxForm
                            }
                            checked={isGlobal}
                            onChange={() => {
                              setIsGlobal((old) => !old);
                              setThisUserAsReadOnly(true);
                            }}
                            disabled={readOnly || isRegionManager}
                          />
                        }
                        label={i18n.t("user.isGlobal")}
                      />
                    </div>
                  </div>
                )}
                {role === Roles.Partner && (
                  <div className={styles.flexRowForm}>
                    <div className={styles.flexCellForm}>
                      <TextField
                        fullWidth
                        disabled={
                          readOnly ||
                          (role === Roles.Partner &&
                            currentUser.roles[0] === Roles.Partner)
                        }
                        select
                        label={i18n.t("user.partner")}
                        value={partnerIdWithAccess ? partnerIdWithAccess : ""}
                        className={styles.materialTextField}
                        inputProps={{
                          readOnly: readOnly,
                        }}
                        required
                        name="partner"
                        error={errors["partner"] !== undefined}
                        helperText={
                          errors["partner"]
                            ? i18n.t("textField.required")
                            : null
                        }
                        onChange={(e) => {
                          setErrors((e) => ({
                            ...errors,

                            partner: undefined,
                          }));

                          setPartnerIdWithAccess(e.target.value);
                        }}
                      >
                        {partnersOptions.map((partner) => (
                          <MenuItem value={partner.id} key={partner.id}>
                            {partner.razonSocial}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                )}
                {role === Roles.EndCustomer && role !== userInfo.role && (
                  <div className={styles.flexRowForm}>
                    <div className={styles.flexCellForm}>
                      <Autocomplete
                        disabled={readOnly}
                        groupBy={(row) => row?.partner?.razonSocial}
                        fullWidth
                        options={plantasOptions}
                        getOptionLabel={(row) =>
                          `${row?.denominacion} (${row?.cliente?.razonSocial})`
                        }
                        value={selectedPlant || null}
                        className={styles.materialTextField}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={i18n.t("user.planta")}
                            required
                            name="planta"
                            error={errors["planta"] !== undefined}
                            helperText={
                              errors["planta"]
                                ? i18n.t("textField.required")
                                : null
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                              readOnly: readOnly,
                            }}
                          />
                        )}
                        onChange={(e, newValue) => setSelectedPlant(newValue)}
                      />
                    </div>
                  </div>
                )}
                {!loading && (
                  <div className={styles.flexRowForm}>
                    <div className={styles.flexCellForm}>
                      {!(
                        role === Roles.Partner &&
                        currentUser.roles[0] === Roles.Partner
                      ) &&
                        currentUser.userid !== id &&
                        !readOnly && (
                          <>
                            <Button
                              variant="contained"
                              className={styles.primaryFormButton}
                              onClick={() => SaveRoles()}
                            >
                              <Save className={styles.saveIcon} />
                              {i18n.t("common.save")}
                            </Button>
                            <Button
                              variant="outlined"
                              className={styles.secondaryFormButton}
                              onClick={() => restartRoles()}
                            >
                              <Cancel className={styles.saveIcon} />
                              {i18n.t("common.cancel")}
                            </Button>
                          </>
                        )}
                      {currentUser.userid !== id &&
                        !currentUserHasRoleEndCustomer &&
                        !sinPartner &&
                        role !== Roles.Admin &&
                        role !== Roles.DSC &&
                        role !== currentUser.roles[0] && (
                          <Button
                            variant="outlined"
                            className={styles.secondaryFormButton}
                            startIcon={inactivo ? <Check /> : <NotInterested />}
                            onClick={() => {
                              cambioEstadoUsuario();
                            }}
                          >
                            {inactivo
                              ? i18n.t(
                                  "gestorUsuarios.detallesUsuario.habilitar"
                                )
                              : i18n.t(
                                  "gestorUsuarios.detallesUsuario.deshabilitar"
                                )}
                          </Button>
                        )}
                    </div>
                  </div>
                )}
              </Card>
            )}
            {endcustomerTab === 1 && (
              <UserPlantaAccess
                userId={id}
                selectedPlant={selectedPlant}
                setSelectedPlant={setSelectedPlant}
                plantasOptions={plantasOptions}
                readOnly={readOnly || currentUserHasRoleEndCustomer}
              />
            )}
            {endcustomerTab === 2 && (
              <TabZoneAccess
                plantasOptions={plantasOptions}
                userId={id}
                readOnly={readOnly}
                currentUserHasRoleEndCustomer={currentUserHasRoleEndCustomer}
              />
            )}
          </form>
        </div>
      </RelativeBackdrop>
      {/* @ts-ignore */}
      <AlertDialog
        ref={confirmDialog}
        title={
          inactivo
            ? i18n.t("gestorUsuarios.detallesUsuario.alertState.titleEnable")
            : i18n.t("gestorUsuarios.detallesUsuario.alertState.titleDisable")
        }
        text={
          inactivo
            ? i18n.t("gestorUsuarios.detallesUsuario.alertState.textEnable")
            : i18n.t("gestorUsuarios.detallesUsuario.alertState.textDisable")
        }
        cancelText={i18n.t(
          "gestorUsuarios.detallesUsuario.alertDialog.cancelText"
        )}
        confirmText={i18n.t("common.save")}
        cancelAction={() => {
          confirmDialog.current.close();
          setLoading(false);
        }}
        confirmAction={() =>
          inactivo
            ? UsersAPI.habilitarUsuario(id)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  } else {
                    throw new Error("Something went wrong");
                  }
                })
                .then((data) => {
                  setInactivo(data.inactivo);
                  setReadOnly(false);
                  setLoading(false);
                })
                .catch((error) => {
                  setLoading(false);
                })
                .finally(() => {
                  setLoading(false);
                  confirmDialog.current.close();
                })
            : UsersAPI.deshabilitarUsuario(id)
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  } else {
                    throw new Error("Something went wrong");
                  }
                })
                .then((data) => {
                  setInactivo(data.inactivo);
                  setReadOnly(true);
                  setLoading(false);
                })
                .catch((error) => {
                  setLoading(false);
                })
                .finally(() => {
                  setLoading(false);
                  confirmDialog.current.close();
                })
        }
      />
    </>
  );
}
