import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import { Assignment, Build } from "@material-ui/icons";
import { DialogActions, Button } from "@material-ui/core";
import i18n from "../locales/i18n";
import moment from "moment";

export default function EventDialog(props) {
  const { open, events, onClose, onItemClick } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <List>
        {events.map((event, index) => (
          <ListItem
            key={event.name + event.startDate.toString() + index}
            button
            onClick={() => onItemClick(event)}
          >
            <ListItemAvatar>
              <Avatar
                style={{
                  background: "#303030",
                }}
              >
                {event.isTask ? <Assignment /> : <Build />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={event.name}
              className={
                (event.state === 1
                  ? "calendarModalCompletedPM"
                  : event.state === 2 && "calendarModalCancelledPM") || ""
              }
              secondary={
                event.isTask
                  ? event.description
                  : moment(event.startDate).format("DD/MM/YYYY") +
                    " " +
                    (event.state === 3
                      ? i18n.t("maintenance.altualScheduled.proposed")
                      : event.state === 2
                      ? i18n.t("maintenance.altualScheduled.cancelled")
                      : event.state === 1
                      ? i18n.t("maintenance.altualScheduled.done")
                      : i18n.t("maintenance.altualScheduled.scheduled"))
              }
            />
          </ListItem>
        ))}
      </List>
      <DialogActions>
        <Button
          variant="outlined"
          style={{
            marginLeft: 20,
            color: "#E2000F",
            borderRadius: 2,
            borderColor: "#E2000F",
            height: 40,
            paddingLeft: 15,
            paddingRight: 15,
            textTransform: "none",
          }}
          onClick={handleClose}
        >
          {i18n.t("common.cerrar")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EventDialog.propTypes = {
  events: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
